import { useEffect, useState } from "react";
import "../../components/proposal/Proposal.less";
import api from "../../utils/api";
import { RootStateOrAny, useSelector } from "react-redux";

type Props = {
  setup?: any;
};


const Warranty = (props: Props) => {
  const { adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const [twoYearWarrentyData, settwoYearWarrentyData] = useState({ title: '', type: '', description: '' });

  useEffect(() => {
    (async () => {
      try {
        // setLoading(true)
        // const warrentyResponse: any = await api.get("proposal/proposal-default/warrenty")
        const warrentyData = adminDefaults.proposalDefaults.find(
          (item: any) => item.type === "warrenty"
        );
        settwoYearWarrentyData(warrentyData)
        // setLoading(false)
      } catch (error: any) {
        throw new Error(error)
      }
    })()
  }, [])

  return (
    <div
      className="terms-container"
      style={{ marginTop: 30, marginBottom: 50, marginLeft: 30 }}
    >
      <div className="tasks-wrapper" style={{ marginTop: "50px" }}>
        <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>{twoYearWarrentyData?.title}</h3>
        <div
          className="terms-text" style={{ fontWeight: "normal", marginTop: "25px" }}
          dangerouslySetInnerHTML={{ __html: twoYearWarrentyData?.description }}
        />
      </div>
    </div>
  );
};

export default Warranty;
