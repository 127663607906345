import { Form, message, Popconfirm, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import ActionDropDown from './ActionDropDown';
import EquipmentEditable from './EquipmentEditable';
import { compareObjects } from '../../../utils/helpers';
import {
  removeServiceFromProject,
  bulkUpdateRates,
  addServiceToProject,
  addRatesToOptions,
  projectRateDeleteAndOptionRatesUpdate,
} from '../../../redux/project/action';
import _ from 'lodash';
import {
  deepEqualCheck,
  deepEqualCheckForClone,
} from '../../../utils/objectCheckers';
var crypto = require('crypto');

type Props = {
  rates: any;
  setTableValues: any;
  showExpandRows: any;
};
let equipmentClone: any;
const EquipmentSurfaces = (props: Props) => {
  const dispatch = useDispatch<any>();
  const [data, setData] = useState(props?.rates);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );

  useEffect(() => {
    const project = _.cloneDeep(items[currentProject]); // Deep copy to avoid mutations
    const hasOptionEquipmentRates = project.options.some(
      (option: any) => option.rates?.equipmentRates?.length > 0,
    );
    if (hasOptionEquipmentRates) {
      const equipmentLaborRates = [...props.rates];
      let laborRatesNotFoundInOption: string[] = [];

      // Collect all equipment rate IDs from all options
      const allEquipmentRateIds = project.options?.flatMap((option: any) =>
        option?.rates?.equipmentRates?.map((equipmentRate: any) => {
          if (!equipmentRate.isDeleted) return equipmentRate.projectLaborRate;
        }),
      );

      // Filter rates that are not found in any of the equipmentRates
      laborRatesNotFoundInOption = equipmentLaborRates
        .filter((rate) => !allEquipmentRateIds.includes(rate._id))
        .map((rate) => rate._id);

      setData(
        props.rates.filter(
          (item: any) => !laborRatesNotFoundInOption.includes(item._id),
        ),
      );
    } else {
      setData(props.rates);
    }
  }, [props.rates]);

  const isEditing = (record: any) => record._id === editingKey;
  const edit = (record: any) => {
    equipmentClone = { ...record };
    form.setFieldsValue({
      item: '',
      type: '',
      cost: '',
      tax: '',
      ...record,
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (id: React.Key) => {
    try {
      const row = (await form.validateFields()) as any;

      const newData = [...data];

      const index = newData.findIndex((item) => id === item._id);
      if (index > -1) {
        const item = newData[index];
        const testData: any = { ...item, ...row };
        let updatedObject: any = {};
        for (const key in testData) {
          if (testData[key] !== equipmentClone[key]) {
            updatedObject[key] = testData[key];
          }
        }
        const body = {
          category: 'equipment',
          rate: { ...updatedObject, modified: true },
          project: items[currentProject].projectId,
        };

        let project: any = _.cloneDeep(items[currentProject]);
        let projectEquipmentIndex =
          project.projectRates.equipmentRates.findIndex(
            (element: any) => element._id === item._id,
          );
        for (var key in updatedObject) {
          if (
            typeof project.projectRates.equipmentRates[projectEquipmentIndex][
              key
            ] === 'number'
          ) {
            project.projectRates.equipmentRates[projectEquipmentIndex][key] =
              +updatedObject[key];
          } else {
            project.projectRates.equipmentRates[projectEquipmentIndex][key] =
              updatedObject[key];
          }
        }
        project.projectRates.equipmentRates[projectEquipmentIndex].modified =
          true;
        //some calculations will be required
        for (const singleOption of project.options) {
          let optionEquipmentIndex =
            singleOption.rates.equipmentRates.findIndex(
              (element: any) => element.projectLaborRate === item._id,
            );
          for (var key in updatedObject) {
            if (optionEquipmentIndex > -1) {
              if (
                typeof singleOption.rates.equipmentRates[projectEquipmentIndex][
                  key
                ] === 'number'
              ) {
                singleOption.rates.equipmentRates[optionEquipmentIndex][key] =
                  +updatedObject[key];
              } else {
                singleOption.rates.equipmentRates[optionEquipmentIndex][key] =
                  updatedObject[key];
              }
            }
          }
          if (optionEquipmentIndex > -1) {
            singleOption.rates.equipmentRates[optionEquipmentIndex].modified =
              true;
          }
        }
        dispatch(bulkUpdateRates(body, item._id, project, adminDefaults));

        // dispatch(setActiveProject(project));

        // api
        //   .patch(`${API_BASE}rates/project/${item._id}`, body)
        //   .then(() => {})
        //   .catch((err) => console.error(err));
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleWhenEditedRateAlreadyExits = (
    rateThatExits: any,
    editedRate: any,
  ) => {
    console.log(rateThatExits, editedRate);
    let reduxObj = [];
    let project: any = _.cloneDeep(items[currentProject]);
    let optionRatesToBeDeletedIdArray = [];
    for (const singleOption of project.options) {
      const foundRateIndex = singleOption.rates.equipmentRates.findIndex(
        (item: any) => item.projectLaborRate === rateThatExits._id,
      );
      const foundEditedRateIndex = singleOption.rates.equipmentRates.findIndex(
        (item: any) => item.projectLaborRate === editedRate._id,
      );
      if (foundEditedRateIndex !== -1) {
        console.log(singleOption.rates.equipmentRates[foundEditedRateIndex]);
        singleOption.rates.equipmentRates[foundEditedRateIndex].isDeleted =
          true;
        optionRatesToBeDeletedIdArray.push(
          singleOption.rates.equipmentRates[foundEditedRateIndex]._id,
        );
      }
      if (foundRateIndex === -1) {
        let count = 0;
        let periods = 1;
        let item = rateThatExits.item;
        let tax = rateThatExits.tax;
        let project = rateThatExits.project;
        let defaultRate = rateThatExits.defaultRate;
        let type = 'daily';
        let cost = rateThatExits.dailyCost;
        let option = singleOption.optionInfo._id;
        let totalCost =
          count *
          periods *
          (cost + rateThatExits.surcharges + rateThatExits.puAndDelivery) *
          (1 + tax / 100);
        const projectLaborRate = rateThatExits._id;
        let _id = crypto.randomBytes(12).toString('hex');
        let newObj = {
          _id,
          projectLaborRate: projectLaborRate,
          count,
          periods,
          item,
          surcharges: rateThatExits.surcharges,
          puAndDelivery: rateThatExits.puAndDelivery,
          tax,
          project,
          defaultRate,
          type,
          cost,
          option,
          totalCost,
        };
        reduxObj.push(newObj);
        singleOption.rates.equipmentRates.push(...reduxObj);
      }
    }
    const editedRateIndexInProject =
      project.projectRates.equipmentRates.findIndex(
        (item: any) => item._id === editedRate._id,
      );
    console.log(editedRateIndexInProject);
    project.projectRates.equipmentRates[editedRateIndexInProject].isDeleted =
      true;

    // project.projectRates.carpentryRates.splice(editedRateIndexInProject, 1);
    const body = {
      category: 'equipment',
      projectRateToBeDeletedId: editedRate._id,
      optionRate: reduxObj,
      optionRatesToBeDeletedIdArray,
      project: project.projectId,
    };
    console.log(body, project);
    dispatch(
      projectRateDeleteAndOptionRatesUpdate(
        body,
        project.projectId,
        project,
        adminDefaults,
      ),
    );
  };

  const newOnSave = async (id: string) => {
    const row = (await form.validateFields()) as any;
    const newData = [...data];
    const index = newData.findIndex((item) => id === item._id);
    if (index > -1) {
      const item = newData[index];
      if (compareObjects(row, item)) {
        setEditingKey('');
        return;
      }
      let updatedObject: any = { ...item, ...row };
      updatedObject.modified = true;
      console.log(updatedObject);
      const notDeletedRates = props.rates.filter(
        (item: any) => item.isDeleted === false,
      );
      console.log(notDeletedRates, props.rates);
      let rateAlreadyExists: any;
      if (updatedObject.isCloned) {
        const isCloned = notDeletedRates.filter(
          (item: any) => item.isCloned === true,
        );
        console.log(isCloned);
        rateAlreadyExists = deepEqualCheckForClone(updatedObject, isCloned);
        console.log(rateAlreadyExists);
      } else {
        const isNotCloned = notDeletedRates.filter(
          (item: any) => item.isCloned === false,
        );
        console.log(isNotCloned);
        const temp = isNotCloned.filter(
          (item: any) => item.defaultRate._id === updatedObject.defaultRate._id,
        );
        console.log(temp);
        rateAlreadyExists = deepEqualCheck(updatedObject, temp);
        console.log(rateAlreadyExists);
      }

      if (rateAlreadyExists.result) {
        console.log('all things Done');
        handleWhenEditedRateAlreadyExits(rateAlreadyExists.rate, updatedObject);
        setEditingKey('');
      } else {
        save(id);
      }
    }
  };

  const handleDelete = (id: string) => {
    let project: any = _.cloneDeep(items[currentProject]);
    let index = project.projectRates.equipmentRates.findIndex(
      (item: any) => item._id === id,
    );
    project.projectRates.equipmentRates[index].isDeleted = true;
    props.setTableValues({
      equipmentRates: project.projectRates.equipmentRates,
    });
    for (const singleOption of project.options) {
      let index = singleOption.rates.equipmentRates.findIndex(
        (item: any) => item.projectLaborRate === id,
      );
      if (index !== -1) {
        singleOption.rates.equipmentRates[index].isDeleted = true;
      }
    }
    dispatch(removeServiceFromProject(id, 'equipment', project, adminDefaults));
  };

  const handleEquipmentClone = (
    cloneData: any,
    index: number,
    surfaceName: string,
  ) => {
    const result = props.rates.some((obj: any) => {
      let name: string = obj.item;
      if (name.replaceAll(' ', '') === surfaceName[index].replaceAll(' ', '')) {
        return true;
      }
    });
    if (result === true) {
      message.error('Name already taken');
    } else {
      const newRates = {
        ...cloneData,
        item: surfaceName[index],
        project: items[currentProject].projectId,
        _id: crypto.randomBytes(12).toString('hex'),
        isCloned: true,
      };
      // delete newRates.defaultRate;

      const notDeletedRates = props.rates.filter(
        (item: any) => item.isDeleted === false,
      );
      const isCloned = notDeletedRates.filter(
        (item: any) => item.isCloned === true,
      );
      console.log(isCloned);
      const rateAlreadyExists = deepEqualCheckForClone(newRates, isCloned);
      console.log(rateAlreadyExists);
      if (rateAlreadyExists) {
        message.error('Rate Already Exists');
      } else {
        const body = [
          {
            category: 'equipment',
            rate: [newRates],
          },
        ];

        const newData = [...data];
        newData.push(body[0].rate[0]);
        setData(newData);

        let project = _.cloneDeep(items[currentProject]);
        dispatch(addServiceToProject(items[currentProject].projectId, body));

        project.projectRates.equipmentRates = newData;
        let tempEquipmentNewRates = [];
        for (const entity of project.options) {
          let newRate = [];
          for (const element of body[0].rate) {
            let count = 0;
            let periods = 1;
            let item = element.item;
            let tax = element.tax;
            let project = element.project;
            let type = 'daily';
            let cost = element.cost;
            let option = entity.optionInfo._id;
            let totalCost = count * periods * cost * (1 + tax / 100);
            const projectLaborRateId = element._id;
            let _id = crypto.randomBytes(12).toString('hex');
            newRate.push({
              _id,
              projectLaborRate: projectLaborRateId,
              count,
              periods,
              item,
              tax,
              project,
              type,
              cost,
              option,
              totalCost,
              defaultRate: element.defaultRate,
              surcharges: element.surcharges,
              puAndDelivery: element.puAndDelivery,
            });
          }

          entity.rates.equipmentRates.push(...newRate);
          tempEquipmentNewRates.push(...newRate);
        }
        let equipmentRequestBody = [
          {
            category: 'equipment',
            rate: [...tempEquipmentNewRates],
          },
        ];
        dispatch(
          addRatesToOptions(equipmentRequestBody, project, adminDefaults),
        );
      }
    }

    // api
    //   .post(`${API_BASE}rates/project/${items[currentProject].projectId}`, body)
    //   .then((resp: any) => {
    //     const newData = [...data];
    //     newData.push(resp[0]);
    //     setData(newData);
    //   })
    //   .catch((err) => console.error(err));
  };

  const equipmentColumns = [
    {
      title: 'Item',
      dataIndex: 'item',
      width: '200px',
      editable: true,
    },

    {
      title: 'Type',
      dataIndex: 'type',
      width: '120px',
      editable: true,
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      render: (data: any) => {
        return (
          <>
            {data !== null && data !== undefined && (
              <>
                {parseFloat(data)?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  style: 'currency',
                  currency: 'USD',
                })}
              </>
            )}
          </>
        );
      },
      editable: true,
      width: '120px',
    },
    {
      title: 'Delivery',
      dataIndex: 'puAndDelivery',
      render: (data: any) => {
        return (
          <>
            {data && (
              <>
                {parseFloat(data)?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  style: 'currency',
                  currency: 'USD',
                })}
              </>
            )}
          </>
        );
      },
      editable: true,
      width: '120px',
    },
    {
      title: 'Surcharges',
      dataIndex: 'surcharges',
      render: (data: any) => {
        return (
          <>
            {data && (
              <>
                {parseFloat(data)?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  style: 'currency',
                  currency: 'USD',
                })}
              </>
            )}
          </>
        );
      },
      editable: true,
      width: '120px',
    },
    {
      title: 'Tax %',
      dataIndex: 'tax',
      editable: true,
      render: (data: string) => <div>{data}</div>,
    },
    {
      dataIndex: 'action',
      title: 'Action',
      width: '80px',
      render: (_: any, record: any, index: number) => {
        const editable = isEditing(record);
        return !editable ? (
          <>
            <ActionDropDown
              handleClone={handleEquipmentClone}
              handleDelete={handleDelete}
              handleEdit={edit}
              data={record}
              index={index}
              category="equipment"
            />
          </>
        ) : (
          <span>
            <Typography.Link
              onClick={() => newOnSave(record._id)}
              style={{ marginRight: 8 }}
            >
              <CheckOutlined />
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>
                <CloseOutlined />
              </a>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const setDataRates = (rates: any) => {
    setData(rates);
  };

  const mergedColumns = equipmentColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === 'item' ? 'text' : 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        form,
        data,
        setDataRates,
        editing: isEditing(record),
      }),
    };
  });

  const showExpandableRows = () => {
    if (props.showExpandRows) {
      return data.map((row: any) => row._id);
    } else {
      return [];
    }
  };

  const expandedRowContent = (record: any) => {
    console.log(record);
    const options = items[currentProject].options;
    let listOfOptions = [];
    for (const singleOption of options) {
      const isFound = singleOption.rates.equipmentRates.some(
        (item: any) => item.projectLaborRate === record._id && !item.isDeleted,
      );
      if (isFound) {
        listOfOptions.push(singleOption.optionInfo.title);
      }
    }
    console.log(listOfOptions);
    return (
      <div style={{ width: '79%', margin: 'auto' }}>
        {listOfOptions.length === 0 ? (
          <Typography>No Options Created</Typography>
        ) : (
          <Typography>{listOfOptions.join(', ')}</Typography>
        )}
      </div>
    );
  };

  return (
    <Form form={form} component={false}>
      <Table
        scroll={{ x: 900 }}
        rowKey="_id"
        components={{
          body: {
            cell: EquipmentEditable,
          },
        }}
        rowClassName={(_, index) =>
          index % 2 === 0
            ? 'table_row table-row-light'
            : 'table_row table-row-dark'
        }
        loading={!data ? true : false}
        pagination={false}
        columns={mergedColumns}
        dataSource={data
          .filter((item: any) => !item.isDeleted)
          .sort((a: any, b: any) => a.item.localeCompare(b.item))}
        expandable={{
          expandedRowRender: (record, index) => expandedRowContent(record),
          expandIcon: () => null,
          expandedRowKeys: showExpandableRows(),
        }}
      />
    </Form>
  );
};

export default EquipmentSurfaces;
