import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import RoleNavigationSteps from "../../components/role-steps/RoleNavigationSteps";
import { useHistory } from "react-router-dom";
import "../estimator/Steps.less";
import { useMediaQuery } from "react-responsive";
import { Col, Dropdown, Menu, Row, Space, Steps } from "antd";

import { LeftOutlined, RightOutlined, DownOutlined } from "@ant-design/icons";
import WarningErrorModal from "../../components/warning-error-modal/WarningErrorModal";
import { useEffect, useState } from "react";
import { setEditsFoundInPaintOrderBool } from "../../redux/project/action";

const { Step } = Steps;
type Props = {
  current: number;
};

const JobJacketSteps = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:1400px)" });

  const history = useHistory();
  const dispatch = useDispatch();
  const { items, currentProject, editsFoundInPaintOrder } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const navigationData = {
    projectId: items[currentProject]?.projectId,
    current: 2,
  };

  useEffect(() => {
    dispatch(setEditsFoundInPaintOrderBool(false));
  }, []);

  const steps = [
    {
      title: "Proposal-C",
    },
    {
      title: "Work Order- Paint",
    },
    {
      title: "Work Order- Carpentry",
    },
    {
      title: "Paint Materials",
    },
    {
      title: "Carpentry Materials",
    },
    {
      title: "Contract",
    },
    {
      title: "Media/documents",
    },
  ];

  const handleModalOk = () => {
    dispatch(setEditsFoundInPaintOrderBool(false));
  };

  const handleStep = (select: number) => {
    if (editsFoundInPaintOrder) {
      setIsWarningModalOpen(true);
      return;
    }
    switch (select) {
      case 0:
        history.push(`/proposal-c/${items[currentProject]?.projectId}`);
        break;
      case 1:
        history.push(`/work-order-paint/${items[currentProject]?.projectId}`);
        break;
      case 2:
        history.push(
          `/work-order-carpentry/${items[currentProject]?.projectId}`,
        );
        break;
      case 3:
        history.push(`/paint-order/${items[currentProject]?.projectId}`);
        break;
      case 4:
        history.push(`/wood-order/${items[currentProject]?.projectId}`);
        break;
      case 5:
        history.push(
          `/job-jacket-contract/${items[currentProject]?.projectId}`,
        );
        break;
      case 6:
        history.push(`/job-jacket-media/${items[currentProject]?.projectId}`);
        break;
    }
  };

  const menuItems: any = (
    <Menu>
      {steps.map((item: any, index: number) => {
        return (
          <Menu.Item
            onClick={() => {
              handleStep(index);
            }}
          >
            {" "}
            {item.title}{" "}
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <>
      <div className="main--steps--holder">
        <RoleNavigationSteps data={navigationData} />
      </div>
      {!isTabletOrMobile ? (
        <div className="main--tabs--holder" style={{ overflowX: "scroll" }}>
          <Steps
            type="navigation"
            current={props.current}
            className="site-navigation-steps"
          >
            {steps.map((item, index: number) => (
              <Step
                className="step"
                key={item.title}
                title={item.title}
                onClick={() => handleStep(index)}
              />
            ))}
          </Steps>
        </div>
      ) : (
        <div className="navigation-text-container">
          <Row align={"middle"}>
            <Col span={6} offset={4}>
              <p
                onClick={() => {
                  handleStep(props.current - 1);
                }}
              >
                <LeftOutlined /> Prev
              </p>
            </Col>
            <Col span={6} offset={0}>
              <Dropdown
                overlay={menuItems}
                trigger={["click"]}
                placement="bottom"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    {steps[props.current].title}
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Col>
            <Col span={6} offset={2}>
              <p
                onClick={() => {
                  handleStep(props.current + 1);
                }}
              >
                Next <RightOutlined />
              </p>
            </Col>
          </Row>
        </div>
      )}
      <WarningErrorModal
        title="Warning"
        showModal={isWarningModalOpen}
        setShowModal={setIsWarningModalOpen}
        message={`Are you sure you want to leave page? \nThe changes you have made to process order will not save.`}
        handleOK={handleModalOk}
        handleCancel={() => setIsWarningModalOpen(false)}
      />
    </>
  );
};

export default JobJacketSteps;
