import { Col, Dropdown, Menu, Row, Space, Steps } from "antd";
import { useSelector, RootStateOrAny } from "react-redux";
import { LeftOutlined, RightOutlined, DownOutlined } from "@ant-design/icons";

import RoleNavigationSteps from "../../components/role-steps/RoleNavigationSteps";
import { useHistory } from "react-router-dom";
import "./ProductionSteps.less";
import { useMediaQuery } from "react-responsive";
const { Step } = Steps;
type Props = {
  current: number;
};

const steps = [
  {
    title: "PJCC",
  },
  {
    title: "Pre-Presentation Checklist",
  },
  {
    title: "Check Request",
  },
];

const ProductionSteps = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:800px)" });

  const history = useHistory();
  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );
  const navigationData = {
    projectId: items[currentProject]?.projectId,
    current: 1,
  };

  const handleStep = (select: number) => {
    switch (select) {
      case 0:
        history.push(`/production-pjcc/${items[currentProject].projectId}`);
        break;
      case 1:
        history.push(`/pre-checklist/${items[currentProject].projectId}`);
        break;
      case 2:
        history.push(
          `/production-associate-check-request/${items[currentProject].projectId}`,
        );
        break;
    }
  };

  const menuItems: any = (
    <Menu>
      {steps.map((item: any, index: number) => {
        return (
          <Menu.Item onClick={() => handleStep(index)}>{item.title}</Menu.Item>
        );
      })}
    </Menu>
  );

  const handlePrev = () => {
    const prev = props.current - 1;
    if (prev >= 0) {
      handleStep(prev);
    }
  };
  const handleNext = () => {
    const next = props.current + 1;
    if (next < steps.length) {
      handleStep(next);
    }
  };
  return (
    <>
      <div className="main--steps--holder">
        <RoleNavigationSteps data={navigationData} />
      </div>

      {!isTabletOrMobile ? (
        <div className="main--tabs--holder">
          <Steps
            type="navigation"
            current={props.current}
            className="site-navigation-steps"
          >
            {steps.map((item, index: number) => (
              <Step
                className="step"
                key={item.title}
                title={item.title}
                onClick={() => handleStep(index)}
              />
            ))}
          </Steps>
        </div>
      ) : (
        <div className="navigation-text-container">
          <Row align={"middle"}>
            <Col span={6} offset={4}>
              <p onClick={handlePrev}>
                <LeftOutlined /> Prev
              </p>
            </Col>
            <Col span={6} offset={0}>
              <Dropdown
                overlay={menuItems}
                trigger={["click"]}
                placement="bottom"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    {steps[props.current].title}
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Col>
            <Col span={6} offset={2}>
              <p onClick={handleNext}>
                Next <RightOutlined />
              </p>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ProductionSteps;
