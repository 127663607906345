import React, { useState } from "react";
import { Card, Form, Input, Button, Row, Col, Select } from "antd";
import "./EditJobsite.less";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { updateProjectById } from "../../redux/project/action";
import { usStates, canadaStates } from "../../constant/index";

const EditJobsite: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const [form] = Form.useForm();
  const [isEdit, isEditSet] = useState(false);
  const [states] = useState<any>([...usStates, ...canadaStates]);

  const onFinish = (values: any) => {
    const data = {
      ...items[currentProject].projectInfo,
      jobName: values.jobName,
      clientName: values.clientName,
      streetAddressOne: values.streetAddressOne,
      streetAddressTwo: values.streetAddressTwo,
      city: values.city,
      state: values.state,
      country: values.country,
      zip: values.zip,
    };

    isEditSet(false);

    dispatch(updateProjectById(data));
  };

  const showEditForm = () => {
    isEditSet(true);
  };

  const onCancel = () => {
    isEditSet(false);
    form.resetFields();
  };

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "+" || e.key === "-" || e.key === "." || e.key === "e") {
      e.preventDefault();
    }
  };

  const handleCountry = (value: any) => {
    if (usStates.includes(value)) {
      form.setFieldsValue({
        country: "United States",
      });
    }
    if (canadaStates.includes(value)) {
      form.setFieldsValue({
        country: "Canada",
      });
    }
  };

  return (
    <div>
      <Card className="root-container">
        {!isEdit ? (
          <div className="profile-content job-container form-container ">
            <div>
              <Row justify="space-between">
                <Col>
                  <h1 className="form-subtitle">Jobsite information detail</h1>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <p className="edit--text" onClick={showEditForm}>
                    Edit
                  </p>
                </Col>
              </Row>
            </div>
            <Row>
              <Col span={6}>
                <h4>Project Name</h4>
              </Col>
              <Col span={6}>
                <p style={{ wordBreak: "break-all" }}>
                  {items[currentProject]?.projectInfo?.jobName}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <h4>Client’s Name</h4>
              </Col>
              <Col span={6}>
                <p>{items[currentProject]?.projectInfo?.clientName}</p>
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <h4>Street Address 1</h4>
              </Col>
              <Col span={6}>
                <p>{items[currentProject]?.projectInfo?.streetAddressOne}</p>
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <h4>Street Address 2</h4>
              </Col>
              <Col span={6}>
                <p>{items[currentProject]?.projectInfo?.streetAddressTwo}</p>
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <h4>City</h4>
              </Col>
              <Col span={6}>
                <p>{items[currentProject]?.projectInfo?.city}</p>
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <h4>State</h4>
              </Col>
              <Col span={6}>
                <p>{items[currentProject]?.projectInfo?.state}</p>
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <h4>Zip</h4>
              </Col>
              <Col span={6}>
                <p>{items[currentProject]?.projectInfo?.zip}</p>
              </Col>
            </Row>

            <Row>
              <Col span={6}>
                <h4>Country</h4>
              </Col>
              <Col span={6}>
                <p>{items[currentProject]?.projectInfo?.country}</p>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="job-container form-container ">
            <Form
              onFinish={onFinish}
              form={form}
              scrollToFirstError
              autoComplete="off"
              layout="vertical"
            >
              <div>
                <h1 className="form-subtitle">Jobsite information</h1>
              </div>

              <div className="job-form" style={{ marginTop: "23px" }}>
                <Row gutter={17}>
                  <Col span={12}>
                    <div>
                      <Form.Item
                        initialValue={
                          items[currentProject]?.projectInfo?.jobName
                        }
                        hasFeedback
                        name="jobName"
                        label={<h1 className="form-text">Job Name</h1>}
                        rules={[
                          {
                            required: true,
                            message: "Please enter the Job Name!",
                          },
                          checkEmpty,
                        ]}
                      >
                        <Input className="form-input" />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <Form.Item
                        initialValue={
                          items[currentProject]?.projectInfo?.clientName
                        }
                        hasFeedback
                        name="clientName"
                        label={<h1 className="form-text">Client's Name</h1>}
                        rules={[
                          {
                            required: true,
                            message: "Please enter the Client Name!",
                          },
                          checkEmpty,
                        ]}
                      >
                        <Input className="form-input" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row gutter={17}>
                  <Col span={12}>
                    <div>
                      <Form.Item
                        initialValue={
                          items[currentProject]?.projectInfo?.streetAddressOne
                        }
                        hasFeedback
                        name="streetAddressOne"
                        label={<h1 className="form-text">Street Address 1</h1>}
                        rules={[
                          {
                            required: true,
                            message: "Please enter the Street Address!",
                          },
                          checkEmpty,
                        ]}
                      >
                        <Input className="form-input" />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <Form.Item
                        initialValue={
                          items[currentProject]?.projectInfo?.streetAddressTwo
                        }
                        name="streetAddressTwo"
                        label={
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <h1 className="form-text">Street Address 2</h1>
                            <p
                              style={{
                                color: "#CACACA",
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              (optional)
                            </p>
                          </div>
                        }
                        rules={[checkEmpty]}
                      >
                        <Input className="form-input" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row gutter={17}>
                  <Col span={12}>
                    <div>
                      <Form.Item
                        initialValue={items[currentProject]?.projectInfo?.city}
                        hasFeedback
                        name="city"
                        label={<h1 className="form-text">City</h1>}
                        rules={[
                          {
                            required: true,
                            message: "Please enter the City!",
                          },
                          checkEmpty,
                        ]}
                      >
                        <Input className="form-input" />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="EditJobsite--state--dropdown">
                      <Form.Item
                        initialValue={items[currentProject]?.projectInfo?.state}
                        hasFeedback
                        name="state"
                        label={<h1 className="form-text">State</h1>}
                        rules={[
                          {
                            required: true,
                            message: "Please enter the State!",
                          },
                          checkEmpty,
                        ]}
                      >
                        <Select
                          showSearch
                          onChange={handleCountry}
                          className="select-field"
                          placeholder="State"
                          size="large"
                          filterOption={(input: any, option: any) =>
                            (option?.children?.toString() ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {states?.sort().map((item: any, index: number) => {
                            return (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row gutter={17}>
                  <Col span={12}>
                    <div>
                      <Form.Item
                        initialValue={items[currentProject]?.projectInfo?.zip}
                        hasFeedback
                        name="zip"
                        label={<h1 className="form-text">Zip</h1>}
                        rules={[
                          {
                            required: true,
                            message: "Please enter the Zip code!",
                          },
                          {
                            max: 5,
                            message: "Code cannot exceed 5 digits",
                          },
                          checkEmpty,
                        ]}
                      >
                        <Input
                          type="number"
                          min={0}
                          className="form-input"
                          onKeyDown={handleKeyDown}
                          onPaste={(e: any) => {
                            const pastedText = e.clipboardData.getData("text");
                            if (pastedText < 0) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                      <Form.Item
                        initialValue={
                          items[currentProject]?.projectInfo?.country
                        }
                        hasFeedback
                        name="country"
                        label={<h1 className="form-text">Country</h1>}
                      >
                        {/* <Select
                          onChange={handleCountry}
                          className="select-field"
                          placeholder="Country"
                          size="large"
                        >
                          <Option value={"Canada"}>Canada</Option>
                          <Option value={"United States"}>United States</Option>
                        </Select> */}
                        <Input className="form-input" />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </div>

              {/* Button */}
              <div className="btn-container">
                <div>
                  <Button
                    onClick={onCancel}
                    style={{
                      border: "none",
                      color: "#FDB913",
                      width: "94px",
                      height: "40px",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    style={{
                      border: "none",
                      color: "#1F1F1F",
                      width: "94px",
                      height: "40px",
                    }}
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Card>
    </div>
  );
};

export default EditJobsite;
