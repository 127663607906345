import React, { useEffect, useState } from "react";
import { Layout, Card, Row, Button, Col, Divider } from "antd";
import ProposalHeader from "../../components/proposal/ProposalHeader";
import JobSiteHeader from "../../components/proposal/JobSiteHeader";
import ProjectSummary from "../../components/proposal/ProjectSummary";
import PriceSummaryC from "../../components/proposal/PriceSummaryC";
import ProjectDetails from "../../containers/proposal/ProjectDetails";
import Signature from "../../components/proposal/Signature";
import "../../components/proposal/Proposal.less";
import JobJacketSteps from "./JobJacketSteps";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import ResourceNotCreated from "../../components/other/ResourceNotCreated";
import useSyncProjectData from "../../utils/useSyncProjectData";
import { Spin } from "antd";
import axios from "axios";
import { API_BASE } from "../../constant";
import OptionImages from "../../containers/proposal/OptionImages";
import { isNull } from "lodash";
import { getAuthUser, getSelectedTenant } from "../../utils/authToken";

const { Content } = Layout;

const ProposalC: React.FC = () => {
  const history = useHistory();
  const user = getAuthUser();
  const tenantId = getSelectedTenant();
  const [options, setOptions] = useState<any>();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<any>();
  const [optionsList, setOptionsList] = useState<any>([]);
  const [optionsMediaList, setOptionsMediaList] = useState([]);
  const [loading, setlaoding] = useState(false);

  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const [isLoading] = useSyncProjectData(id);
  useEffect(() => {
    setOptions(
      items[currentProject]?.options
        .filter((option: any) => {
          return (
            option?.pjcc?.find((item: any) => item.name == "Total Labor")
              ?.cost > 0 && option.optionInfo.included === "included"
          );
        })
        .sort(
          (a: any, b: any) => a.optionInfo.position - b.optionInfo.position // Sort by position
        )
        ?.map((item: any) => item.optionInfo)
    );
    let optionsArray = [...items[currentProject].options] // Clone the array to avoid modifying the state
      .sort((a: any, b: any) => a.optionInfo.position - b.optionInfo.position)
      .map((item: any) => {
        if (item.optionInfo.included === "included") {
          return item.optionInfo.title;
        }
      });
    setOptionsList(optionsArray);
    window.scrollTo(0, 0);
    window.history.scrollRestoration = "manual";
  }, [dispatch]);

  useEffect(() => {
    if (items[currentProject].options.length > 0) {
      setlaoding(true);
      axios
        .get(
          `${API_BASE}upload-files/option-media/${items[currentProject].projectId}`,
          {
            headers: {
              Authorization: "Bearer " + user.accessToken,
              "tenant-id": !isNull(tenantId) ? tenantId : "",
            },
          }
        )
        .then((resp: any) => {
          if (resp?.data?.length > 0) {
            setOptionsMediaList(resp.data);
          }
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => {
          setlaoding(false);
        });
    }
  }, []);

  const handleNext = () => {
    history.push(`/work-order-paint/${id}`);
  };
  return (
    <>
      {isLoading === true ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <Content>
          <JobJacketSteps current={0} />
          <div className="steps-content">
            <div>
              <Card style={{ overflowX: "scroll" }}>
                <ProposalHeader
                  jobNumber={items[currentProject]?.projectInfo?.jobNumber}
                  date={items[currentProject]?.projectInfo?.proposal?.createdAt}
                  companyInfo={adminDefaults.companyInfo}
                  projectSubtype={
                    items[currentProject]?.projectInfo?.projectSubtype
                  }
                  projectType={items[currentProject]?.projectInfo?.projectType}
                />
                <hr
                  style={{
                    border: "1px solid #E8E8E8",
                    margin: 0,
                    borderBottom: "0px",
                  }}
                />
                <JobSiteHeader
                  city={items[currentProject]?.projectInfo?.city}
                  state={items[currentProject]?.projectInfo?.state}
                  zip={items[currentProject]?.projectInfo?.zip}
                  jobName={items[currentProject]?.projectInfo?.jobName}
                  address={items[currentProject]?.projectInfo?.streetAddressOne}
                  telephone={
                    items[currentProject]?.projectInfo?.primaryContactTelephone
                  }
                  email={
                    items[currentProject]?.projectInfo?.primaryContactEmail
                  }
                  associatePicture={
                    items[currentProject]?.projectInfo?.salesAssociate?.picture
                  }
                  associateName={
                    items[currentProject]?.projectInfo?.salesAssociate?.fullName
                  }
                  associateCell={
                    items[currentProject]?.projectInfo?.salesAssociate?.phone
                  }
                  associateEmail={
                    items[currentProject]?.projectInfo?.salesAssociate?.email
                  }
                  associateJobTitle={
                    items[currentProject]?.projectInfo?.salesAssociate?.jobTitle
                  }
                />
                <PriceSummaryC options={options} />
                <ProjectSummary
                  summary={
                    items[currentProject]?.projectInfo?.proposal?.projectSummary
                  }
                  optionsList={optionsList}
                />
                <ProjectDetails />
                <hr
                  style={{ border: "1px solid #E8E8E8", borderBottom: "0px" }}
                />

                <Signature />

                {items[currentProject].projectInfo?.proposal
                  ?.addOptionMedia && (
                  <>
                    {loading ? (
                      <>
                        <div
                          className="terms-container"
                          style={{ marginLeft: "30px", marginRight: "35px" }}
                        >
                          <h2 className="content-title">
                            ADDENDUM - ALL PICTURES
                          </h2>
                          <Divider
                            type="horizontal"
                            style={{
                              borderColor: "#FDB913",
                              borderWidth: 5,
                              marginTop: 0,
                            }}
                          ></Divider>
                          <Spin
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {optionsMediaList.length > 0 ? (
                          <div
                            className="terms-container"
                            style={{ marginLeft: "30px", marginRight: "35px" }}
                          >
                            <h2 className="content-title">
                              ADDENDUM - ALL PICTURES
                            </h2>
                            <Divider
                              type="horizontal"
                              style={{
                                borderColor: "#FDB913",
                                borderWidth: 5,
                                marginTop: 0,
                              }}
                            ></Divider>
                            <OptionImages
                              optionsMediaList={optionsMediaList}
                              setOptionsMediaList={setOptionsMediaList}
                              loading={loading}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </>
                )}
              </Card>
            </div>
          </div>
          <div className="onsite-btn-container">
            <Row gutter={12} justify="center" style={{ marginBottom: "20px" }}>
              <Col>
                <Button
                  onClick={handleNext}
                  type="primary"
                  className="save-button"
                >
                  Next
                </Button>
              </Col>
            </Row>
          </div>
        </Content>
      )}
    </>
  );
};

export default ProposalC;
