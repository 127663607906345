import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Badge,
  Card,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Layout,
  Menu,
  Modal,
  Row,
  Space,
  Tooltip,
} from 'antd';
import {
  BoxPlotOutlined,
  CheckOutlined,
  DownOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

import { getAuthUser } from '../../utils/authToken';
import { logout, tenantSelected } from '../../redux/user/action';
import { clearOfflineData } from '../../redux/project/action';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../assets/images/Pro-Driven-Edit.jpg';
import collapse from 'antd/lib/collapse';

const { Header } = Layout;

type Props = {
  title: string;
  handleMenuClick: any;
  collapse: boolean;
  onCollapse: (collapsed: boolean) => void;
  onToggleSidebar: (item: any) => void;
};

const HeaderComponent = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width:850px)' });
  const isMobile = useMediaQuery({ query: '(max-width:600px)' });
  function truncateText(text: any, maxLength: any) {
    if (text?.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    } else {
      return text;
    }
  }

  const { user, tenant } = useSelector((state: RootStateOrAny) => state.auth);
  const [hovered, setHovered] = useState<string | null>(null);
  const [isModal, setIsModal] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState<any>({});
  const [dropDownVisible, setDropDownVisible] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { online, outbox } = useSelector(
    (state: RootStateOrAny) => state.offline,
  );
  const [initials, setInitials] = useState('');
  const { currentProject, items } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );

  useEffect(() => {
    const initials = getInitials(user?.fullName);
    setInitials(initials);
  }, []);

  const getInitials = (name: string) => {
    try {
      const nameArray = name.split(' ');
      const firstName = nameArray[0];
      const lastName = nameArray[nameArray.length - 1];
      if (nameArray.length === 1) {
        const initials = firstName.charAt(0);
        return initials.toUpperCase();
      } else {
        const initials = firstName.charAt(0) + lastName.charAt(0);
        return initials.toUpperCase();
      }
    } catch (err) {
      console.log(err);
      return '';
    }
  };

  let tenantsList = user?.tenants;
  const currentTenant = tenantsList.find((item: any) => item._id === tenant);

  const handleSelectedTenant = (tenant: any) => {
    const userData = localStorage.getItem('user');
    const auth = localStorage.getItem('reduxPersist:auth');
    localStorage.clear();
    if (userData) {
      localStorage.setItem('user', userData);
    }
    if (auth) {
      localStorage.setItem('reduxPersist:auth', auth);
    }
    dispatch(clearOfflineData());
    dispatch(tenantSelected(tenant._id));
    setIsModal(false);
    history.push('/all-jobs');
  };

  const overlayContent = (
    <div
      style={{
        width: 225,
        border: '1px solid #f0f0f0',
        borderRadius: 4,
        background: '#fff',
      }}
    >
      {user.roles[0] !== 'super_admin' && (
        <Card
          bordered={false}
          style={{ margin: 0, padding: 5, marginBottom: 0 }}
        >
          <div
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              padding: 5,
              paddingLeft: 8,
            }}
          >
            Tenants
          </div>
          <Divider style={{ margin: '4px 0' }} />
          <ul style={{ listStyle: 'none', padding: 0, marginBottom: 0 }}>
            {tenantsList.map((item: any) => (
              <li
                key={item._id}
                style={{
                  fontSize: 14,
                  color: item._id === tenant ? '#fdb913' : '#000',
                  fontWeight: item._id === tenant ? 'bold' : 'normal',
                  backgroundColor:
                    hovered === item._id ? '#f0f0f0' : 'transparent',
                  cursor: 'pointer',
                  padding: '4px 8px',
                  borderRadius: 4,
                  marginBottom: 3,
                }}
                onMouseEnter={() => setHovered(item._id)}
                onMouseLeave={() => setHovered(null)}
                onClick={() => {
                  if (tenantsList.length > 1) {
                    setSelectedTenant(item);
                    setIsModal(true);
                  }
                }}
              >
                {item.companyName}
              </li>
            ))}
          </ul>
        </Card>
      )}
      <div
        onClick={() => {
          localStorage.clear();
          dispatch(logout());
          dispatch(clearOfflineData());
          history.push('/login');
        }}
        style={{
          fontWeight: 'bold',
          cursor: 'pointer',
          padding: '8px',
          borderTop: '1px solid #f0f0f0',
          backgroundColor: hovered === 'logout' ? '#f7d891' : 'transparent', // Hover effect
        }}
        onMouseEnter={() => setHovered('logout')}
        onMouseLeave={() => setHovered(null)}
      >
        Logout
      </div>
      <Modal
        open={isModal}
        title={
          <div style={{ fontWeight: 'bold' }}>
            Tenant Switching Confirmation
          </div>
        }
        onCancel={() => {
          setIsModal(false);
          setSelectedTenant(null); // Reset selected tenant if needed
        }}
        onOk={() => {
          handleSelectedTenant(selectedTenant);
          setDropDownVisible(false);
        }}
      >
        <p>
          Are you sure you want to switch tenant from{' '}
          <b>{currentTenant?.companyName}</b> to{' '}
          <b>{selectedTenant?.companyName}</b>?
        </p>
      </Modal>
    </div>
  );

  const handleVisibleChange = (visible: boolean) => {
    setDropDownVisible(visible); // Set dropdown visibility based on user interaction
  };

  return (
    <Header
      className="site-layout-sub-header-background"
      style={{
        padding: 0,
        backgroundColor: 'white',
      }}
    >
      {isTabletOrMobile && (props.collapse || !props.collapse) ? (
        <Row style={{ justifyContent: 'space-between' }}>
          <Col>
            <Link to="/">
              <img
                src={logo}
                alt="logo"
                style={{
                  width: '125px',
                  borderRadius: 10,
                  marginLeft: '25px',
                }}
              />
            </Link>

            <MenuOutlined
              onClick={() => {
                props.onToggleSidebar(false);
              }}
            />
          </Col>

          {isMobile ? null : (
            <Col>
              <p
                style={{ fontWeight: 'bold', fontSize: '20px', marginLeft: 50 }}
              >
                {currentProject >= 0
                  ? truncateText(items[currentProject].projectInfo.jobName, 30)
                  : ''}
              </p>
            </Col>
          )}

          <Col>
            <Row style={{ marginRight: 20 }} gutter={10}>
              <Col onClick={() => history.push('/user-profile')}>
                {user?.picture && online ? (
                  <>
                    <Avatar
                      style={{ cursor: 'pointer' }}
                      size={42}
                      src={user?.picture}
                    />
                  </>
                ) : (
                  <Avatar
                    style={{
                      // backgroundColor: "#f56a00",
                      // color: "#fff",
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}
                    size={42}
                  >
                    {initials}
                  </Avatar>
                )}
              </Col>
              <Col>
                {online ? (
                  <>
                    {outbox.length === 0 ? (
                      <Tooltip
                        title="Online"
                        placement="topLeft"
                        color="#00c20d"
                      >
                        <Badge status="success" />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Not-Sync"
                        placement="topLeft"
                        color="#ebc334"
                      >
                        <Badge status="warning" />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <Tooltip title="Offline" placement="topLeft" color="#d61818">
                    <Badge status="error" />
                  </Tooltip>
                )}
              </Col>
              <Col>
                <Dropdown
                  trigger={['click']}
                  overlay={overlayContent}
                  visible={dropDownVisible}
                  onVisibleChange={handleVisibleChange}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <Row style={{ justifyContent: 'space-between' }}>
          <col span={5}></col>
          {isMobile ? null : (
            <Col>
              <p
                style={{ fontWeight: 'bold', fontSize: '20px', marginLeft: 50 }}
              >
                {currentProject >= 0
                  ? truncateText(
                      items[currentProject]?.projectInfo?.jobName,
                      50,
                    )
                  : ''}
              </p>
            </Col>
          )}

          <Col>
            <Row style={{ marginRight: 20 }} gutter={10}>
              <Col onClick={() => history.push('/user-profile')}>
                {user?.picture && online ? (
                  <>
                    <Avatar
                      style={{ cursor: 'pointer' }}
                      size={42}
                      src={user?.picture}
                    />
                  </>
                ) : (
                  <Avatar
                    style={{
                      // backgroundColor: "#f56a00",
                      // color: "#fff",
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}
                    size={42}
                  >
                    {initials}
                  </Avatar>
                )}
              </Col>
              <Col>
                {online ? (
                  <>
                    {outbox.length === 0 ? (
                      <Tooltip
                        title="Online"
                        placement="topLeft"
                        color="#00c20d"
                      >
                        <Badge status="success" />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title="Not-Sync"
                        placement="topLeft"
                        color="#ebc334"
                      >
                        <Badge status="warning" />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <Tooltip title="Offline" placement="topLeft" color="#d61818">
                    <Badge status="error" />
                  </Tooltip>
                )}
              </Col>
              <Col>
                <Dropdown
                  trigger={['click']}
                  overlay={overlayContent}
                  visible={dropDownVisible}
                  onVisibleChange={handleVisibleChange}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Header>
  );
};

export default HeaderComponent;
