import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, message, Row } from "antd";
import { InfoCircleOutlined, EditOutlined } from "@ant-design/icons";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { setDiscount } from "../../redux/project-pjcc/action";
import {
  addDiscount,
  editDiscount,
  updateDiscount,
} from "../../redux/project/action";
import DiscountModal from "../discount-modal/DiscountModal";
import { useParams } from "react-router-dom";
import { customToFixed } from "../../utils/helpers";
var crypto = require("crypto");

type Props = {
  pjccData?: any;
  setPjccData?: any;
  projectAppliedDiscount?: any;
  setProjectAppliedDiscount?: any;
};

const Documents = (props: Props) => {
  const { id } = useParams<any>();
  const [selectedDiscount, setSelectedDiscount] = useState({});
  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);
  const [projectDiscounts, setProjectDiscounts] = useState([]);
  const dispatch = useDispatch();

  const { discountsApplied, projectPjcc } = useSelector(
    (state: RootStateOrAny) => state.projectPjcc
  );

  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const calculateExpressionDiscount = () => {
    const sumOfAllOptionsPrices = items[currentProject].options?.reduce(
      (initial: any, curValue: any) => {
        return initial + parseFloat(curValue.pjcc[15].totalAmount);
      },
      0
    );
    const fullBidRevenueIndex = props.pjccData.findIndex(
      (item: any) => item.name === "Full Bid Revenue"
    );
    const expressionDiscount =
      props.pjccData[fullBidRevenueIndex].totalAmount - sumOfAllOptionsPrices;
    return expressionDiscount;
  };
  useEffect(() => {
    const expressionDiscount = calculateExpressionDiscount();
    let projectDiscount = adminDefaults.discounts
      .filter(
        (item: any) =>
          item?.projectId === id || !item.hasOwnProperty("projectId")
      )
      .map((element: any) => {
        if (element.amountType === "expression") {
          return {
            ...element,
            amount: expressionDiscount.toFixed(2),
          };
        } else {
          return element;
        }
      });

    setProjectDiscounts(projectDiscount);
  }, [adminDefaults.discounts, items[currentProject].pjcc]);

  const handleCheckbox = (e: CheckboxChangeEvent, item: any) => {
    const priceToClient = props?.pjccData.find(
      (element: any) => element.index === 20
    );

    const totalCost = props?.pjccData.find(
      (element: any) => element.index === 15
    );
    if (e.target.checked) {
      if (
        item.amountType === "dollar" &&
        priceToClient?.totalAmount - parseFloat(item.amount) - totalCost.cost >=
        0
      ) {
        let dispatchBody = {
          ...item,
          checked: e.target.checked,
        };
        handleSetDiscount(dispatchBody);
        // dispatch(setDiscount({ ...item, checked: e.target.checked }));
      } else if (item.amountType === "percentage") {
        const estimatedPrice = props?.pjccData.find(
          (element: any) => element.index === 0
        );

        const discount = estimatedPrice?.totalAmount * (item.amount / 100);

        const grossMarginCost =
          priceToClient.totalAmount - discount - totalCost.cost;

        if (grossMarginCost >= 0) {
          handleSetDiscount({ ...item, checked: e.target.checked });
          // dispatch(setDiscount({ ...item, checked: e.target.checked }));
        } else {
          message.warn("Cannot apply discounts");
        }
      } else if (item.amountType === "expression") {
        if (item.amount <= 0) {
          let dispatchBody = {
            ...item,
            checked: e.target.checked,
          };
          handleSetDiscount(dispatchBody);
        } else {
          message.warn("Cannot apply discounts");
        }
      } else {
        message.warn("Cannot apply discounts");
      }
    } else {
      handleSetDiscount({ ...item, checked: e.target.checked });
      // dispatch(setDiscount({ ...item, checked: e.target.checked }));
    }
  };

  const handleSetDiscount = (dispatchBody: any) => {
    // dispatch(setDiscount({ ...item, checked: e.target.checked }));
    let newData = props.pjccData.map((item: any) => ({ ...item }));

    const discount = props.projectAppliedDiscount
      ? [...props.projectAppliedDiscount]
      : [];

    if (dispatchBody.checked) {
      discount.push(dispatchBody);
    } else {
      const discountIndex = discount.findIndex(
        (item: any) => item._id === dispatchBody._id
      );
      discount.splice(discountIndex, 1);
    }
    let priceToClient = newData[0].totalAmount;
    for (let item of discount) {
      if (item.amountType === "percentage") {
        priceToClient -= priceToClient * (item.amount / 100);
      } else if (item.amountType === "expression") {
        priceToClient += Number(item.amount);
      } else {
        priceToClient -= item.amount;
      }
    }

    newData[20].totalAmount = priceToClient;
    const pjccProjectFullBidCosts = newData.find(
      (item: any) => item.name === "Total Cost"
    );
    const pjccProjectPriceToClient = newData.find(
      (item: any) => item.name === "Price to Client"
    );

    // gross margin update
    newData[16].totalAmount = customToFixed(newData[20].totalAmount - newData[15].cost, 2);
    // project margin update
    newData[19].totalAmount =
      (1 -
        pjccProjectFullBidCosts.cost / pjccProjectPriceToClient.totalAmount) *
      100;
    // gross profit p/hr update
    newData[17].totalAmount = newData[16].totalAmount / newData[10].totalAmount;

    newData[17].totalAmount = isFinite(newData[17].totalAmount)
      ? newData[17].totalAmount
      : 0;
    props.setPjccData(newData);
    props.setProjectAppliedDiscount(discount);
  };

  const handleValue = (item: any) => {
    const found = props.projectAppliedDiscount?.find(
      (disc: any) => disc._id === item._id
    );
    return found ? true : false;
  };

  const handleAddDiscount = (values: any) => {
    dispatch(
      addDiscount({
        _id: crypto.randomBytes(12).toString("hex"),
        projectId: id,
        ...values,
      })
    );
    setShowDiscountModal(false);
  };

  const handleUpdateDiscount = (values: any) => {
    dispatch(editDiscount(values._id ? values._id : values.id, values));
    setShowDiscountModal(false);
    setSelectedDiscount({});
  };

  const handleEdit = (row: any) => {
    setSelectedDiscount(row);
    setShowDiscountModal(true);
  };

  return (
    <div
      className="document--holder"
      style={{ boxShadow: "0px 0px 13px 2px rgba(5, 21, 63, 0.04) " }}
    >
      <DiscountModal
        showModal={showDiscountModal}
        setShowModal={setShowDiscountModal}
        addDiscount={handleAddDiscount}
        updateDiscount={handleUpdateDiscount}
        discountValue={selectedDiscount}
      />
      <Card
        style={{
          paddingRight: "10px",
        }}
        headStyle={{
          fontWeight: "bold",
          fontSize: "22px",
          lineHeight: "26px",
          background: "white",
        }}
        title={
          <Row justify="space-between">
            <Col>Discount</Col>
            <Col>
              <Button
                onClick={() => {
                  setShowDiscountModal(true);
                  setSelectedDiscount({});
                }}
                className="setup-comm-add-btn"
                type="link"
              >
                + Add
              </Button>
            </Col>
          </Row>
        }
      >
        <div className="checkbox--list">
          {projectDiscounts?.map((item: any) => {
            return (
              <Row key={item?._id}>
                <Col span={18}>
                  <Checkbox
                    checked={handleValue(item)}
                    onChange={(e) => handleCheckbox(e, item)}
                  >
                    <h1>{item?.description}</h1>
                  </Checkbox>
                </Col>
                <Col span={4}>
                  <h1>
                    {item?.amountType === "dollar" ? `$` : ""}
                    {item?.amount}
                    {item?.amountType === "percentage" ? `%` : ""}
                  </h1>
                </Col>
                <Col span={2}>
                  {item?.projectId && (
                    <EditOutlined
                      onClick={() => handleEdit(item)}
                      style={{ color: "#FDB913", cursor: "pointer" }}
                    />
                  )}
                </Col>
              </Row>
            );
          })}
          <p>
            <InfoCircleOutlined />
            <small>
              If all options are completed at the same time, CertaPro will apply
              the discount
            </small>
          </p>
        </div>
      </Card>
    </div>
  );
};

export default Documents;
