import _, { isNull } from "lodash";
import { API_BASE } from "../constant";
import { getAuthUser, getSelectedTenant } from "./authToken";
import { pricingCalculation } from "./priceCalculation";
import moment from "moment";
var crypto = require("crypto");

export const verifyRole = (userRole: string[], roles: string[]) => {
  let roleMap = new Map();
  if (userRole) {
    for (let i = 0; i <= userRole.length; i++) {
      roleMap.set(userRole[i], userRole[i]);
    }
  }

  if (roles) {
    for (let i = 0; i <= roles.length; i++) {
      const isRoleExist = roleMap.get(roles[i]);
      if (isRoleExist) {
        return true;
      }
    }
  }

  return false;
};

export const getUpdatedOptionDetails = (originalRates: [], clone: []) => {
  let updatedData: any = [];
  for (let i = 0; i < originalRates.length; i++) {
    if (!_.isEqual(originalRates[i], clone[i])) {
      updatedData.push(originalRates[i]);
    }
  }
  return updatedData;
};

export const getArrayClone = (rates: []) => {
  if (rates) {
    return rates.map((item: any) => {
      return { ...item };
    });
  } else {
    return [];
  }
};

export const updateArrayItemById = (arr: any, itemId: string, fields: any) => {
  const arrClone = [...arr];
  const item = arrClone.find(({ id }) => id === itemId);
  if (item) {
    const itemIndex = arrClone.indexOf(item);
    arrClone.splice(itemIndex, 1, { ...item, ...fields });
  }
  return arrClone;
};

export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const offlineAction = (
  data: any,
  path: string,
  method: string,
  addAction: string,
  commitAction: string,
  fallbackAction: string,
) => {
  const user = getAuthUser();
  const tenantId = getSelectedTenant();
  return {
    type: addAction,
    payload: { data },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}${path}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
          method,
          body: JSON.stringify(data),
        },
        // action to dispatch when effect succeeds:
        commit: { type: commitAction, meta: { data } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: fallbackAction,
          meta: { data: { ...data, method, path } },
        },
      },
    },
  };
};

export const offlineActionWithCalculations = (
  data: any,
  path: string,
  method: string,
  addAction: string,
  commitAction: string,
  fallbackAction: string,
  project: any,
  adminDefaults: any,
) => {
  const user = getAuthUser();
  const tenantId = getSelectedTenant();
  var newProject = pricingCalculation(project, adminDefaults);

  return {
    type: addAction,
    payload: { newProject },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}${path}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
          method,
          body: JSON.stringify(data),
        },
        // action to dispatch when effect succeeds:
        commit: { type: commitAction, meta: { data } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: fallbackAction,
          meta: { data: { ...data, method, path } },
        },
      },
    },
  };
};

export const offlineActionDeleteWithCalculations = (
  data: any,
  path: string,
  method: string,
  addAction: string,
  commitAction: string,
  fallbackAction: string,
  project: any,
  adminDefaults: any,
) => {
  const user = getAuthUser();
  const tenantId = getSelectedTenant();
  var newProject = pricingCalculation(project, adminDefaults);

  return {
    type: addAction,
    payload: { newProject },
    meta: {
      offline: {
        effect: {
          url: `${API_BASE}${path}`,
          headers: {
            Authorization: "Bearer " + user.accessToken, //the token is a variable which holds the token,
            contentType: "application/json",
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
          method,
        },
        // action to dispatch when effect succeeds:
        commit: { type: commitAction, meta: { data } },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: fallbackAction,
          meta: { data: { ...data, method, path } },
        },
      },
    },
  };
};

export const getErrorPage = (path: any) => {
  switch (true) {
    // projects
    case path.startsWith("projects/project-state"):
      return "Error occurred while updating project state, please contact the support";
    case path.startsWith("projects/override-gm/"):
      return "This Error occurred while creating new option or updating option";
    case path.startsWith("projects/discounts-and-gm/"):
      return "Error occurred while updating Project PJCC";
    case path.startsWith("projects/send-email/"):
      return "Error occurred while sending mail to Estimator";
    case path.startsWith("projects"):
      return "Error occurred while updating Project";
    // proposal
    case path.startsWith("proposal/"):
      return "Error occurred while updating Proposal";
    // options
    case path.startsWith("options/update/included"):
      return "Error occurred while updating option state in option setup screen";
    case path.startsWith("options/"):
      return "Error occurred while updating option";
    case path.startsWith("options"):
      return "Error occurred while creating option";
    //pre-presentation-checklist
    case path.startsWith("pre-presentation-checklist/send-email"):
      return "Error occurred while sending mail in Pre-Presentation Checklist";
    case path.startsWith("pre-presentation-checklist"):
      return "Error occurred while updating Pre-Presentation Checklist";
    case path.startsWith("onsite-checklist-answers"):
      return "Error occurred while updating Onsite Checklist Answers";
    // check request
    case path.startsWith("check-request/send-email"):
      return "Error occurred while sending mail in check request";
    case path.startsWith("check-request"):
      return "Error occurred while updating Check Request";
    //notes
    case path.startsWith("notes"):
      return "Error occurred while adding notes";
    // rates
    case path.startsWith("rates/option"):
      return "Error occurred while updating labor rates of option";
    case path.startsWith("rates/project/"):
      return "Error occurred while updating labor rates of project";
    // project material
    case path.startsWith("material/project-material/"):
      return "Error occurred while updating project paint materials";
    // work order
    case path.startsWith("work-order/send-email"):
      return "Error occurred while sending mail in work order";
    default:
      return "Error occurred";
  }
};

export const customToFixed = (number: number, decimalPlaces: number) => {
  return Number(number?.toFixed(decimalPlaces));
};

export const compareObjects: any = (
  newObj: Record<string, any>,
  currentItemObj: Record<string, any>,
) => {
  if (Object.keys(newObj).length > Object.keys(currentItemObj).length)
    return false;
  const commonKeys = Object.keys(newObj).filter((key) =>
    Object.prototype.hasOwnProperty.call(currentItemObj, key),
  );

  return commonKeys.every((key) => {
    const newValue = newObj[key];
    const currentValue = currentItemObj[key];

    // Check if the values are objects and recurse if necessary
    if (
      typeof newValue === "object" &&
      newValue !== null &&
      typeof currentValue === "object" &&
      currentValue !== null
    ) {
      return compareObjects(newValue, currentValue);
    }

    if (typeof newValue === "number" && typeof currentValue === "string") {
      return newValue === parseFloat(currentValue);
    }

    // Compare values as strings
    return newValue?.toString() == currentValue?.toString();
  });
};

export const replaceNbsp: any = (obj: any) => {
  if (typeof obj === "string") {
    return obj.replace(/&nbsp;/g, " ");
  } else if (Array.isArray(obj)) {
    // Recursively call replaceNbsp for each element in the array
    return obj.map((item) => replaceNbsp(item));
  } else if (typeof obj === "object" && obj !== null) {
    // Recursively call replaceNbsp for each value in the object
    for (let key in obj) {
      obj[key] = replaceNbsp(obj[key]);
    }
  }
  return obj;
};

interface Entry {
  _id: any;
  jobName: any;
  clientName: any;
  streetAddressOne: any;
  streetAddressTwo?: any;
  city: any;
  state: any;
  zip: any;
  country: any;
  primaryContactName: any;
  primaryContactTelephone: any;
  primaryContactCell: any;
  primaryContactEmail: any;
  primaryContactAddress: any;
  segment: any;
  subSegment: any;
  estimateScheduledDate: any;
  leadDate: any;
  jobNumber: any;
  salesAssociate: any;
  estimator: any;
  productionAssociate: any;
  projectType: any;
  projectSubtype: any;
  createdAt: any;
  updatedAt: any;
  projectState: any;
  paintWageRate: any;
  carpentryWageRate: any;
  ssConst: any;
  setupConst: any;
  coatsMultiplier: any;
  tieredMargins: any;
  checkRequestRetainerPercent: any;
  projectStatus: any;
  proposal: any;
  proposalObject: any;
}

export const transformData = (
  uncleanedData: any[],
  adminDefaults: any,
  associatePeople: any,
  user: any,
): Entry[] => {
  interface States {
    [key: string]: string;
  }
  const mapSegment = (segment: string) => {
    let findSegment = adminDefaults.marketSegments.find(
      (item: any) => item.segmentName === segment,
    );
    return findSegment;
  };

  const mapAssociate = (name: string) => {
    let findAssociate = associatePeople.associates.find(
      (item: any) => item.fullName === name,
    );
    return findAssociate;
  };

  const getStateName = (abbreviation: any) => {
    type States = {
      [abbreviation: string]: { fullName: string; country: string };
    };

    const getStateInfo = (
      input: string,
    ): { fullName: string; country: string } | undefined => {
      const states: States = {
        AL: { fullName: "Alabama", country: "United States" },
        AK: { fullName: "Alaska", country: "United States" },
        AS: { fullName: "American Samoa", country: "United States" },
        AZ: { fullName: "Arizona", country: "United States" },
        AR: { fullName: "Arkansas", country: "United States" },
        CA: { fullName: "California", country: "United States" },
        CO: { fullName: "Colorado", country: "United States" },
        CT: { fullName: "Connecticut", country: "United States" },
        DE: { fullName: "Delaware", country: "United States" },
        DC: { fullName: "District Of Columbia", country: "United States" },
        FM: {
          fullName: "Federated States Of Micronesia",
          country: "United States",
        },
        FL: { fullName: "Florida", country: "United States" },
        GA: { fullName: "Georgia", country: "United States" },
        GU: { fullName: "Guam", country: "United States" },
        HI: { fullName: "Hawaii", country: "United States" },
        ID: { fullName: "Idaho", country: "United States" },
        IL: { fullName: "Illinois", country: "United States" },
        IN: { fullName: "Indiana", country: "United States" },
        IA: { fullName: "Iowa", country: "United States" },
        KS: { fullName: "Kansas", country: "United States" },
        KY: { fullName: "Kentucky", country: "United States" },
        LA: { fullName: "Louisiana", country: "United States" },
        ME: { fullName: "Maine", country: "United States" },
        MH: { fullName: "Marshall Islands", country: "United States" },
        MD: { fullName: "Maryland", country: "United States" },
        MA: { fullName: "Massachusetts", country: "United States" },
        MI: { fullName: "Michigan", country: "United States" },
        MN: { fullName: "Minnesota", country: "United States" },
        MS: { fullName: "Mississippi", country: "United States" },
        MO: { fullName: "Missouri", country: "United States" },
        MT: { fullName: "Montana", country: "United States" },
        NE: { fullName: "Nebraska", country: "United States" },
        NV: { fullName: "Nevada", country: "United States" },
        NH: { fullName: "New Hampshire", country: "United States" },
        NJ: { fullName: "New Jersey", country: "United States" },
        NM: { fullName: "New Mexico", country: "United States" },
        NY: { fullName: "New York", country: "United States" },
        NC: { fullName: "North Carolina", country: "United States" },
        ND: { fullName: "North Dakota", country: "United States" },
        MP: { fullName: "Northern Mariana Islands", country: "United States" },
        OH: { fullName: "Ohio", country: "United States" },
        OK: { fullName: "Oklahoma", country: "United States" },
        OR: { fullName: "Oregon", country: "United States" },
        PW: { fullName: "Palau", country: "United States" },
        PA: { fullName: "Pennsylvania", country: "United States" },
        PR: { fullName: "Puerto Rico", country: "United States" },
        RI: { fullName: "Rhode Island", country: "United States" },
        SC: { fullName: "South Carolina", country: "United States" },
        SD: { fullName: "South Dakota", country: "United States" },
        TN: { fullName: "Tennessee", country: "United States" },
        TX: { fullName: "Texas", country: "United States" },
        UT: { fullName: "Utah", country: "United States" },
        VT: { fullName: "Vermont", country: "United States" },
        VI: { fullName: "Virgin Islands", country: "United States" },
        VA: { fullName: "Virginia", country: "United States" },
        WA: { fullName: "Washington", country: "United States" },
        WV: { fullName: "West Virginia", country: "United States" },
        WI: { fullName: "Wisconsin", country: "United States" },
        WY: { fullName: "Wyoming", country: "United States" },
        AB: { fullName: "Alberta", country: "Canada" },
        BC: { fullName: "British Columbia", country: "Canada" },
        MB: { fullName: "Manitoba", country: "Canada" },
        NB: { fullName: "New Brunswick", country: "Canada" },
        NL: { fullName: "Newfoundland and Labrador", country: "Canada" },
        NS: { fullName: "Nova Scotia", country: "Canada" },
        ON: { fullName: "Ontario", country: "Canada" },
        PE: { fullName: "Prince Edward Island", country: "Canada" },
        QC: { fullName: "Quebec", country: "Canada" },
        SK: { fullName: "Saskatchewan", country: "Canada" },
        NT: { fullName: "Northwest Territories", country: "Canada" },
        NU: { fullName: "Nunavut", country: "Canada" },
        YT: { fullName: "Yukon", country: "Canada" },
      };

      const inputLowerCase = input?.toLowerCase();
      const abbreviationMatch = Object.entries(states).find(
        ([abbreviation]) => abbreviation?.toLowerCase() === inputLowerCase,
      );
      if (abbreviationMatch) {
        return abbreviationMatch[1];
      }

      const fullNameMatch = Object.values(states).find(
        (state) => state.fullName?.toLowerCase() === inputLowerCase,
      );
      if (fullNameMatch) {
        return fullNameMatch;
      }
      return undefined;
    };
    const stateInfo = getStateInfo(abbreviation);
    return stateInfo;
  };

  const convertDateFormat = (dateString: string): Date => {
    if (dateString) {
      const parts = dateString.split(" ");
      const dateParts = parts[0].split("/");
      const time = parts[1];
      let year = parseInt(dateParts[2]);
      if (year < 100) {
        year += 2000;
      }
      const month = parseInt(dateParts[0]) - 1;
      const day = parseInt(dateParts[1]);
      const hours = parseInt(time.split(":")[0]);
      const minutes = parseInt(time.split(":")[1]);
      return new Date(year, month, day, hours, minutes);
    } else {
      return new Date();
    }
  };

  return uncleanedData.map((entry: any) => {
    const addressComponents = [
      entry["Address 1: Street 1 (Customer) (Account)"],
      entry["Address 1: Street 2 (Customer) (Account)"],
      entry["Address 1: City (Customer) (Account)"],
      getStateName(entry["Address 1: State/Province (Customer) (Account)"])
        ?.fullName,
      entry["Address 1: ZIP/Postal Code (Customer) (Account)"],
      getStateName(entry["Address 1: State/Province (Customer) (Account)"])
        ?.country,
    ];
    const proposalObject = {
      _id: crypto.randomBytes(12).toString("hex"),
      setup: [],
      surface: [],
      termsAndConditions: [],
      optionImagesPerRow: 3,
      addOptionMedia: true,
    };
    return {
      _id: crypto.randomBytes(12).toString("hex"),
      jobName: entry["Job Name"],
      clientName: entry["Account Name (Customer) (Account)"],
      streetAddressOne: entry["Street"],
      streetAddressTwo: entry["Street 2"] || "",
      city: entry["City"],
      state: getStateName(entry["State/Province"])?.fullName,
      zip: entry["Postal Code"],
      country: getStateName(entry["State/Province"])?.country,
      primaryContactName: entry["Primary Contact"],
      primaryContactTelephone: entry["Phone"],
      primaryContactCell: entry["Mobile Phone (Primary Contact) (Contact)"],
      primaryContactEmail: entry["Email (Primary Contact) (Contact)"],
      primaryContactAddress: addressComponents.filter(Boolean).join(" "),
      segment: mapSegment(entry["Commercial Primary Segment"]),
      subSegment: entry["Commercial Sub Segment"],
      estimateScheduledDate: convertDateFormat(
        entry["Start Time (Estimate Appointment) (Appointment)"],
      ),
      leadDate: convertDateFormat(entry["Created On"]), // Confirm this
      jobNumber: entry["Job #"],
      salesAssociate: mapAssociate(entry["Sales Associate"]),
      estimator: entry["Commercial Estimator"]
        ? mapAssociate(entry["Commercial Estimator"])
        : mapAssociate(entry["Sales Associate"]),
      productionAssociate: mapAssociate(entry["Production Associate"]),
      projectType:
        entry["Job Type"]?.split("-")[0] === "Res"
          ? "Residential"
          : "Commercial",
      projectSubtype:
        entry["Job Type"]?.split("-")[1] === "Int" ? "Interior" : "Exterior",
      createdAt: moment().unix(),
      updatedAt: moment().unix(),
      projectState: 1,
      paintWageRate: adminDefaults.wageDefaults.find(
        (item: any) => item.title === "Painting Wage Rate",
      )?.wageValue,
      carpentryWageRate: adminDefaults.wageDefaults.find(
        (item: any) => item.title === "Carpentry Wage Rate",
      )?.wageValue,
      ssConst: adminDefaults.wageDefaults.find(
        (item: any) => item.title === "SS",
      )?.wageValue,
      setupConst: adminDefaults.wageDefaults.find(
        (item: any) => item.title === "Setup",
      )?.wageValue,
      coatsMultiplier: adminDefaults.wageDefaults.find(
        (item: any) => item.title === "Additional Coats Percentage",
      )?.wageValue,
      tieredMargins: adminDefaults.marginDefaults,
      checkRequestRetainerPercent:
        adminDefaults?.contractPaymentDefault?.retainerPercentage,
      projectStatus: "New Lead",
      createdBy: user._id,
      proposal: proposalObject._id,
      proposalObject: proposalObject,
    };
  });
};

export const productionTargetSum = (array: any) => {
  let sum = 0;
  for (let i = 0; i < array.length; i++) {
    if (typeof array[i] === "number" && !isNaN(array[i])) {
      sum += array[i];
    }
  }
  return sum;
};

// export const calculateDifferences = (obj: any, arr: any) => {
//   console.log("Mine array", arr)
//   const indexes = [1, 2, 3, 5, 6, 7, 11, 12];
//   const differences: any = [];
//   let sum = 0;

//   indexes.forEach(index => {
//     const objectValue = parseFloat(obj[index]) || 0;
//     const arrayItem = arr.find((item: any) => item.index === index);
//     const arrayValue = parseFloat(arrayItem?.productionTargets) || 0;
//     const difference = arrayValue - objectValue;
//     differences[index] = difference;
//     sum += difference;
//   });

//   return { differences, sum };
// };

export const calculateDifferences = (obj: any, arr: any) => {
  const indexes = [1, 2, 3, 5, 6, 7, 11, 12];
  const differences: any = [];
  let sum = 0;

  indexes.forEach((index) => {
    const objectValue = parseFloat(obj[index]) || 0;
    const arrayItem = arr.find((item: any) => item.index === index);

    if (arrayItem && arrayItem.workOrder) {
      const arrayValue = parseFloat(arrayItem.productionTargets) || 0;
      const difference = arrayValue - objectValue;
      differences[index] = difference;
      sum += difference;
    } else {
      differences[index] = 0;
    }
  });

  return { differences, sum };
};

interface PJCC {
  project: string;
  option: string;
  name: string;
  cost: number | null;
  amount: number;
  totalAmount: number;
  grossMargin?: number;
}

interface Data {
  pjcc: PJCC[];
}

interface Summary {
  name: string;
  cost: number;
  amount: number;
  totalAmount: number;
}
export const calculateSums = (data: Data[]): Summary[] => {
  const summaryMap: { [key: string]: Summary } = {};

  data.forEach((item) => {
    item.pjcc.forEach((pjcc) => {
      const { name, cost, amount, totalAmount } = pjcc;

      if (!summaryMap[name]) {
        summaryMap[name] = {
          name,
          cost: cost || 0,
          amount,
          totalAmount,
        };
      } else {
        summaryMap[name].cost += cost || 0;
        summaryMap[name].amount += amount;
        summaryMap[name].totalAmount += totalAmount;
      }
    });
  });

  return Object.values(summaryMap);
};

export const deepCompareArray = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) {
    return false; // Arrays must have the same length
  }

  // Compare each object in the array
  for (let i = 0; i < arr1?.length; i++) {
    if (!compareObjects(arr1[i], arr2[i])) {
      return false;
    }
  }

  return true;
};

export const customCompareFunctionPrepresentationCheckList = (
  obj1: any,
  obj2: any,
): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    // Ignore the __v key or keys that are not in obj2
    if (key === "__v" || !keys2.includes(key)) continue;

    // Special handling for "crew" key
    if (key === "crew") {
      if (typeof val1 === "object" && typeof val2 === "object") {
        if (val1?._id?.toString() !== val2?._id?.toString()) {
          return false;
        }
      } else if (typeof val1 === "object" && typeof val2 === "string") {
        if (val1?._id?.toString() !== val2?.toString()) {
          return false;
        }
      }
      continue;
    }

    // Special handling for "listOfSkills", which is an array
    if (key === "listOfSkills") {
      if (!Array.isArray(val1) || !Array.isArray(val2)) {
        return false;
      }
      if (val1.length !== val2.length) {
        return false;
      }

      // Sort arrays by _id for comparison
      const sortedVal1 = val1
        .slice()
        .sort((a, b) => a._id.localeCompare(b._id));
      const sortedVal2 = val2
        .slice()
        .sort((a, b) => a._id.localeCompare(b._id));

      // Compare arrays element-wise
      for (let i = 0; i < sortedVal1.length; i++) {
        if (
          !customCompareFunctionPrepresentationCheckList(
            sortedVal1[i],
            sortedVal2[i],
          )
        ) {
          return false;
        }
      }
      continue;
    }

    // Compare primitive values
    if (typeof val1 !== "object" || typeof val2 !== "object") {
      if (
        (key === "jobCompleteDate" || key === "projectStartDate") &&
        moment.isMoment(val2)
      ) {
        if (val1?.toString() !== val2.toISOString()) {
          return false;
        }
      } else if (val1?.toString() !== val2?.toString()) {
        return false;
      }
      continue;
    }

    // Recursively compare objects
    if (!customCompareFunctionPrepresentationCheckList(val1, val2)) {
      return false;
    }
  }

  for (const key of keys2) {
    if (!keys1.includes(key) && obj2[key] !== undefined) {
      console.log(`Extra key found in obj2: ${key}`);
      return false; // Return false if obj2 has extra keys
    }
  }

  return true; // Return true if all checks pass
};

export const customCompareFunctionOnsiteCheckList = (
  obj1: any,
  obj2: any,
): boolean => {
  const skipKeys = ["createdAt", "createdBy", "updatedAt", "_id"];

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  for (const key of keys1) {
    // Skip keys not present in obj2 or special keys
    if (obj1[key] === undefined || obj1[key] === "" || skipKeys.includes(key)) {
      continue;
    }

    const val1 = obj1[key];
    const val2 = obj2[key];
    // Special handling for 'otherChecklistAnswers' (array of objects)
    if (key === "otherChecklistAnswers") {
      if (val1?.length !== val2?.length) {
        return false; // Arrays differ in length
      }
      // Create shallow copies of the arrays before sorting
      const sortedVal1 = [...val1].sort((a: any, b: any) =>
        a.question._id.localeCompare(b.question._id),
      );
      const sortedVal2 = [...val2].sort((a: any, b: any) =>
        a.question._id.localeCompare(b.question._id),
      );

      for (let i = 0; i < sortedVal1.length; i++) {
        if (
          !customCompareFunctionOnsiteCheckList(sortedVal1[i], sortedVal2[i])
        ) {
          return false;
        }
      }
      continue; // Skip to next key after comparing the sorted arrays
    }

    // Handle object comparisons (recursively if necessary)
    if (
      typeof val1 === "object" &&
      val1 !== null &&
      typeof val2 === "object" &&
      val2 !== null
    ) {
      // No sorting for 'question' key now, just a straight comparison
      if (key === "question" && Array.isArray(val1) && Array.isArray(val2)) {
        if (val1.toString() !== val2.toString()) {
          return false;
        }
      } else {
        if (!customCompareFunctionOnsiteCheckList(val1, val2)) {
          return false;
        }
      }
    } else {
      // Compare non-object values as strings
      if (val1?.toString() !== val2?.toString()) {
        return false;
      }
    }
  }

  return true;
};
