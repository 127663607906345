import { Divider, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector, RootStateOrAny } from "react-redux";
import { useMediaQuery } from "react-responsive";

type Props = {
  options?: any;
  optionsPjcc: any;
  projectInfo?: any;
  setOptionsTotalPrice?: any;
};

const PriceSummary = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:800px)" });

  const [subTotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [tableData, setTableData] = useState<
    { title: string; estimatedPrice: string }[]
  >([]);

  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const calculateExpressionDiscount = () => {
    const sumOfAllOptionsPrices = items[currentProject].options?.reduce(
      (initial: any, curValue: any) => {
        return initial + parseFloat(curValue.pjcc[15].totalAmount);
      },
      0
    );
    const fullBidRevenueIndex = items[currentProject].pjcc.findIndex(
      (item: any) => item.name === "Full Bid Revenue"
    );
    const expressionDiscount =
      items[currentProject].pjcc[fullBidRevenueIndex].totalAmount -
      sumOfAllOptionsPrices;

    return expressionDiscount;
  };

  useEffect(() => {
    let totalAmount: number = props?.optionsPjcc?.reduce(
      (initial: any, value: any) => {
        return value.amount + initial;
      },
      0
    );
    setTotalAmount(totalAmount);
  }, [props]);

  useEffect(() => {
    let total = 0;
    const includeOptions = items[currentProject]?.options
      .filter((item: any) => item.optionInfo.included === "included")
      .sort(
        (a: any, b: any) => a.optionInfo.position - b.optionInfo.position // Sort by position
      );
    const rows = [];
    includeOptions.forEach((option: any) => {
      let estimatedPriceIndex = option.pjcc.findIndex(
        (item: any) => item.name === "Estimated Price"
      );
      let estimatedPrice = option.pjcc[estimatedPriceIndex].totalAmount;
      if (estimatedPrice > 0) {
        let title = option.optionInfo.title;
        total += Math.round(estimatedPrice);
        rows.push({
          title,
          estimatedPrice: `$${estimatedPrice.toLocaleString("en-US", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}`,
        });
      }
    });

    let discountsArray: { title: string; estimatedPrice: string }[] = [];
    items[currentProject]?.projectInfo?.discounts.map((item: any) => {
      let discountedValue = 0;
      let description = "";
      if (item.amountType === "percentage") {
        discountedValue = total * (item.amount / 100);
        total -= Math.round(discountedValue);
        description = item.description;
      } else if (item.amountType === "dollar") {
        discountedValue = item.amount;
        total -= Math.round(discountedValue);
        description = item.description;
      } else if (item.amountType === "expression") {
        const expressionDiscount = calculateExpressionDiscount();
        discountedValue = expressionDiscount;
        total += Number(expressionDiscount);
        description = item.description;
      }
      discountsArray.push({
        title: description,
        estimatedPrice:
          item.amountType === "expression"
            ? `-$${Math.abs(discountedValue).toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`
            : `-$${discountedValue.toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}`,
      });
    });

    rows.push(...discountsArray);

    rows.push({
      title: "Total",
      estimatedPrice: `$${total.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}`,
    });
    setTableData(rows);
    props.setOptionsTotalPrice(total);

    setSubTotal(
      props?.optionsPjcc?.reduce((initial: any, value: any) => {
        return value.amount + initial;
      }, 0)
    );

    setDiscount(items[currentProject]?.projectInfo?.discounts);
  }, [props]);

  const findPrice = (data: any) => {
    const option = props.optionsPjcc.find(
      (item: any) => item.option === data._id
    );

    return option?.totalAmount ? option.totalAmount : null;
  };
  const columns = [
    {
      title: "Options",
      dataIndex: "title",
      width: "80%",
      render: (text: any) =>
        text === "Total" ? <strong>{text}</strong> : text,
    },
    {
      title: "Price",
      dataIndex: "estimatedPrice",
      width: "20%",
      render: (text: any, record: any) => {
        const content =
          record.title === "Total" ? <strong>{text}</strong> : text;
        return <div style={{ textAlign: "right" }}>{content}</div>;
      },
    },
  ];
  if (props?.options?.length === 0) {
    return null;
  }
  return (
    <>
      {isTabletOrMobile ? (
        <div style={{ padding: "50px 25px 0px 20px", width: "850px" }}>
          <h2 className="content-title" style={{ marginBottom: 0 }}>
            PRICE SUMMARY
          </h2>
          <Divider
            type="horizontal"
            style={{
              marginTop: 0,
              marginRight: 50,
              borderColor: "#FDB913",
              borderWidth: 5,
            }}
          ></Divider>
          <div className="pjcc--listing">
            {props?.options?.length > 0 && (
              <div className="table-title">
                <Table
                  rowKey="_id"
                  columns={columns}
                  dataSource={tableData}
                  pagination={false}
                  showHeader={false}
                  rowClassName={(record: any) =>
                    record.title === "Total" ? "highlight-top-border" : ""
                  }
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div style={{ padding: "50px 25px 0px 30px" }}>
          <h2 className="content-title" style={{ marginBottom: 0 }}>
            PRICE SUMMARY
          </h2>
          <Divider
            type="horizontal"
            style={{
              marginTop: 0,
              marginRight: 50,
              borderColor: "#FDB913",
              borderWidth: 5,
            }}
          ></Divider>
          <div className="pjcc--listing">
            {props?.options?.length > 0 && (
              <div className="table-title">
                <Table
                  rowKey="_id"
                  columns={columns}
                  dataSource={tableData}
                  pagination={false}
                  showHeader={false}
                  rowClassName={(record: any) =>
                    record.title === "Total" ? "highlight-top-border" : ""
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}{" "}
    </>
  );
};

export default PriceSummary;
