import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  List,
  Modal,
  Row,
  Space,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import { SearchIcon } from '../../utils/icons';
import './EmailRecipients.less';
import { RootStateOrAny, useSelector } from 'react-redux';

// Define types for props and users
interface User {
  fullName: string;
  email: string;
  _id: string;
  checked: boolean;
  sendBidReview: boolean;
}

interface EmailRecipientsProps {
  users: any[];
  showRecipientsModal: boolean;
  setShowRecipientsModal: (show: boolean) => void;
  handelRecipients: (uniqueUsers: any) => void;
  handleRevisions: any;
  managerSubmitRevision?: boolean;
}

const EmailRecipients: React.FC<EmailRecipientsProps> = ({
  users,
  showRecipientsModal,
  setShowRecipientsModal,
  handelRecipients,
  handleRevisions,
  managerSubmitRevision,
}) => {
  const [limit, setLimit] = useState(false);
  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );
  const [defaultUsers, setDefaultUsers] = useState<User[]>([]);
  const [checklistUsers, setChecklistUsers] = useState<User[]>([]);
  const [isDefaultChecked, setIsDefaultChecked] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState(''); // New state for search term

  useEffect(() => {
    if (users.length > 0) {
      const filteredUsers = users.filter(
        (user: any) => user?.sendBidReview === true,
      );
      let defaultUsersIds: string[] = [];
      let defaultUsers: any[] = [];

      if (managerSubmitRevision) {
        defaultUsersIds = [
          items[currentProject].projectInfo?.salesAssociate,
          items[currentProject].projectInfo?.estimator,
        ]
          .filter(Boolean)
          .map((user: any) => user._id);

        defaultUsers = [
          items[currentProject].projectInfo?.salesAssociate,
          items[currentProject].projectInfo?.estimator,
        ]
          .filter(Boolean)
          .map((el: any) => ({
            fullName: el.fullName,
            email: el.email,
            _id: el._id,
            sendBidReview: el.sendBidReview,
            checked: true,
          }));
        setIsDefaultChecked(false);
      } else {
        defaultUsersIds = filteredUsers.map((user: any) => user._id);
        defaultUsers = filteredUsers.map((el: any) => ({
          fullName: el.fullName,
          email: el.email,
          _id: el._id,
          sendBidReview: el.sendBidReview,
          checked: true,
        }));
      }

      const listUsers = users.map((user: any) => ({
        fullName: user.fullName,
        email: user.email,
        _id: user._id,
        sendBidReview: user.sendBidReview,
        checked: defaultUsersIds.includes(user._id) ? true : false,
      }));

      setChecklistUsers(listUsers);
      setDefaultUsers(defaultUsers);
      setSelectedUsers(defaultUsers);
    }
  }, [users, managerSubmitRevision]);

  const handleCancel = () => {
    setShowRecipientsModal(false);
    if (managerSubmitRevision) {
      setIsDefaultChecked(false);
    } else {
      setIsDefaultChecked(true);
    }
  };

  const handleOk = () => {
    setShowRecipientsModal(false);
    handelRecipients(selectedUsers);
    handleRevisions();
  };

  const handleCheckbox = (e: CheckboxChangeEvent, userId: string) => {
    const isChecked = e.target.checked;

    const updatedUsers = checklistUsers.map((user) =>
      user._id === userId ? { ...user, checked: isChecked } : user,
    );
    setChecklistUsers(updatedUsers);

    if (isChecked) {
      const selectedUser = updatedUsers.find((user) => user._id === userId);
      if (selectedUser) {
        setSelectedUsers((prev) => [
          ...prev,
          {
            ...selectedUser,
            checked: true,
          },
        ]);
      }
    } else {
      setSelectedUsers((prev) => prev.filter((user) => user._id !== userId));
    }
    setIsDefaultChecked(false);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleSeeMore = () => {
    setLimit(true);
  };

  // Filter users based on search term
  const filteredUsers = checklistUsers.filter((user) =>
    user.fullName.toLowerCase().includes(searchTerm),
  );

  const handleDefaultChecked = (e: any) => {
    let checked = e.target.checked;
    const defaultUserIds = defaultUsers.map((item: any) => item._id);
    const listUsers = users.map((user: any) => ({
      fullName: user.fullName,
      email: user.email,
      _id: user._id,
      sendBidReview: user.sendBidReview,
      checked: defaultUserIds.includes(user._id) ? checked : false,
    }));
    setChecklistUsers(listUsers);

    if (checked) {
      setSelectedUsers(defaultUsers);
    } else {
      setSelectedUsers(
        selectedUsers.filter((item: any) => !defaultUserIds.includes(item._id)),
      );
    }
    setIsDefaultChecked(checked);
  };

  return (
    <Modal
      title={
        <h2 style={{ marginTop: 10, fontWeight: 'bold' }}>Select Recipients</h2>
      }
      destroyOnClose={true}
      visible={showRecipientsModal}
      footer={null}
      onCancel={handleCancel}
      width={'40%'}
    >
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        {!managerSubmitRevision && (
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Col span={22}>
              <Checkbox
                checked={isDefaultChecked}
                onChange={(e) => {
                  handleDefaultChecked(e);
                }}
              >
                <span className="surface-checkbox-text">{'Defaults'}</span>
              </Checkbox>
            </Col>
          </Row>
        )}
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Col span={22}>
            <div className="surface-container">
              <Card className="checkbox-card">
                <Input
                  className="search-surface-input"
                  prefix={<SearchIcon />}
                  placeholder="Search users"
                  onChange={handleSearchChange}
                />

                <div className="checkbox-scroll-bar">
                  <List
                    bordered
                    style={{ height: 120 }}
                    dataSource={filteredUsers.sort(
                      (a: any, b: any) => b.checked - a.checked,
                    )} // Use filtered users
                    rowKey="_id"
                    renderItem={(item: any) => (
                      <List.Item className="surface-list-container">
                        <Checkbox
                          checked={item?.checked}
                          onChange={(e) => handleCheckbox(e, item._id)}
                        >
                          <span className="surface-checkbox-text">
                            {item.fullName}
                          </span>
                        </Checkbox>
                      </List.Item>
                    )}
                  />
                </div>
              </Card>
              <Row gutter={20}>
                {selectedUsers
                  .slice(0, !limit ? 5 : selectedUsers.length)
                  .map((data: any) => {
                    return (
                      <Col key={data._id} className="selected-checkbox">
                        <h2 className="checkbox-tag-text">{data.fullName}</h2>
                      </Col>
                    );
                  })}
                {!limit && selectedUsers.length > 5 && (
                  <div onClick={handleSeeMore} className="see-more-container">
                    <p className="show-more-text">
                      +{selectedUsers.length - 5} more
                    </p>
                  </div>
                )}
              </Row>
            </div>
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            <Button
              htmlType="submit"
              type="primary"
              onClick={handleOk}
              disabled={selectedUsers.length === 0 ? true : false}
              style={{
                margin: '0px 10px 0px 5px',
                fontWeight: 'bold',
                fontSize: '15px',
                lineHeight: '18px',
                height: '40px',
                letterSpacing: '0.055em',
                width: '120px',
                color: '#1f1f1f ',
                borderRadius: '5px ',
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Space>
    </Modal>
  );
};

export default EmailRecipients;
