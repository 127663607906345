import {
  Checkbox,
  Row,
  Col,
  Button,
  Modal,
  Select,
  DatePicker,
  message,
} from 'antd';
import './EstimateChecklistsC.less';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import {
  sendMail,
  updateEstimateChecklist,
  setProjectDefaultsUpdated,
  updateProjectDefaults,
} from '../../redux/project/action';
import api from '../../utils/api';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import ProjectMaterialsC from '../project-materials/ProjectMaterialsC';
import EmailModal from '../email-template-modal/EmailModal';
import { paintOrderPDF } from '../../utils/paintOrderPDF';
import Notes from '../notes/Notes';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { API_BASE } from '../../constant';
import _, { cloneDeep } from 'lodash';

const { Option } = Select;

type Props = {
  path?: string;
};

const EstimateChecklistsC = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width:1150px)' });

  const { id } = useParams<any>();

  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );
  const [check, setChecks] = useState<any>(
    items[currentProject].projectInfo?.estimateChecklist
      ? items[currentProject].projectInfo?.estimateChecklist
      : [],
  );
  const [checkProjectDefaults, setCheckProjectDefaults] = useState<any[]>(
    items[currentProject]?.projectDefaults?.estimateChecklist
      ? items[currentProject].projectDefaults.estimateChecklist
      : [],
  );
  const [updatedCheckedItem, setUpdatedCheckedItem] = useState<any>([]);
  const [profilePhoto, setProfilePhoto] = useState<any>();
  const [checkRequest, setCheckRequest] = useState<any>(false);
  const [materialModal, setMaterialModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<string>('');

  const [showModal, setShowModal] = useState<any>(false);
  const [emailSubject, setEmailSubject] = useState<string>('');
  const [emailBody, setEmailBody] = useState<string>('');
  const [emailRecipients, setEmailRecipients] = useState<string>('');

  const dispatch = useDispatch();
  const history = useHistory();
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const { closestStoreData } = useSelector(
    (state: RootStateOrAny) => state.closestStoreData,
  );

  useEffect(() => {
    if (online) {
      api
        .get(
          `user/photo/${items[currentProject].projectInfo?.salesAssociate?._id}`,
        )
        .then((resp: any) => {
          setProfilePhoto(resp);
        });
    }
  }, [online]);

  const handleCheckbox = (e: CheckboxChangeEvent, item: any) => {
    if (e.target.checked) {
      setChecks([...check, item]);
    } else {
      const unCheckIndex = check.findIndex((temp: any) => temp === item._id);
      const newData = JSON.parse(JSON.stringify(check));
      newData.splice(unCheckIndex, 1);
      setChecks(newData);
    }
  };

  const handleCheckboxProjectDefaults = (e: CheckboxChangeEvent, item: any) => {
    const updatedItem = { ...item, checked: e.target.checked };
    const updatedCheckProjectDefaults = checkProjectDefaults.map(
      (innerItem: any) =>
        innerItem._id === item._id
          ? { ...innerItem, checked: e.target.checked }
          : innerItem,
    );
    setUpdatedCheckedItem([...updatedCheckedItem, updatedItem]);
    setCheckProjectDefaults(updatedCheckProjectDefaults);
  };

  const handleDate = (value: any) => {
    setSelectedDate(value);
  };

  const handleCheckRequest = () => {
    setCheckRequest(true);
  };

  const handleOk = async () => {
    if (selectedValue && selectedDate) {
      setCheckRequest(false);
      const selectedVendor = adminDefaults?.vendorDefaults.find(
        (item: any) => item._id === selectedValue,
      );
      setEmailSubject(
        `Quote Needed - ${items[currentProject]?.projectInfo?.jobName}`,
      );
      setEmailBody(
        `${selectedVendor?.repName},\n\nWe are in bid phase for a new project. Please see attached material list (attached) for information. Please send back your project quote by:${moment(
          selectedDate,
        ).format(
          'MMMM DD,YYYY',
        )}\n\nThank you,\n\nRegards,\n${user?.fullName} `,
      );
      setEmailRecipients(`${selectedVendor?.repContact}`);
      setShowModal(true);
    } else {
      message.warn('Please Select');
    }
  };

  const handleCancel = () => {
    setCheckRequest(false);
  };

  const handleChange = (value: any) => {
    setSelectedValue(value);
  };

  const handleNext = () => {
    const payload = { estimateChecklist: check, id };
    dispatch(
      setProjectDefaultsUpdated({
        projectId: id,
        updates: { ...items[currentProject]?.projectDefaults, estimateChecklist: checkProjectDefaults },
      }),
    );
    if (updatedCheckedItem.length > 0) {
      dispatch(updateProjectDefaults(updatedCheckedItem))
    }
    const equalEstimatorChecklist = _.isEqual(items[currentProject].projectInfo.estimateChecklist, payload.estimateChecklist)
    if (payload.estimateChecklist.length > 0 && !equalEstimatorChecklist) {
      dispatch(updateEstimateChecklist(payload));
    }
    if (props.path) {
      history.push(`/${props.path}/${items[currentProject].projectId}`);
    } else {
      history.push(`/proposal-setup/${items[currentProject].projectId}`);
    }
  };

  const handleValue = (item: any) => {
    const found = check?.find((check: any) => check._id === item._id);
    return found ? true : false;
  };

  const handleValueProjectDefaults = (item: any) => item.checked ?? false;

  const toggleMaterial = () => {
    materialModal ? setMaterialModal(false) : setMaterialModal(true);
  };

  const handleSendEmail = async (body: any) => {
    const selectedVendor = adminDefaults?.vendorDefaults.find(
      (item: any) => item._id === selectedValue,
    );
    let buffer: any = await paintOrderPDF(
      items,
      currentProject,
      adminDefaults,
      closestStoreData,
    );
    let emailBody: any = {
      ...body,
      selectedVendor,
      buffer,
    };
    dispatch(sendMail(emailBody, 'projects/work-order-quote-request'));
    message.success('Email Sent');
    setShowModal(false);
  };

  return (
    <>
      {isTabletOrMobile ? (
        <Row gutter={25}>
          <Col lg={15} sm={24}>
            <ProjectMaterialsC
              showModal={materialModal}
              toggleModal={toggleMaterial}
            />
            <EmailModal
              showModal={showModal}
              setShowModal={setShowModal}
              emailSubject={emailSubject}
              emailBody={emailBody}
              emailRecipients={emailRecipients}
              sendEmail={handleSendEmail}
            />
            <Modal
              className="req-modal"
              title="Request Quote from Paint Vendor"
              visible={checkRequest}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <Button
                  key="cancel"
                  onClick={handleCancel}
                  style={{
                    marginRight: 8,
                    color: '#FDB913',
                    border: '1px solid #fdb913',
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  style={{ color: '#1F1F1F' }}
                  key="submit"
                  type="primary"
                  onClick={handleOk}
                >
                  Submit
                </Button>,
              ]}
            >
              <Row style={{ marginBottom: 20 }} gutter={28}>
                <Col>
                  <h2>Select Vendor</h2>
                </Col>
                <Col>
                  <Select
                    defaultValue="Please Select Vendor"
                    onChange={handleChange}
                  >
                    {adminDefaults?.vendorDefaults.map((item: any) => {
                      return (
                        <Option value={item._id}>{item.vendorName}</Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col>
                  <h2>Response Date</h2>
                </Col>
                <Col>
                  <DatePicker
                    disabledDate={(current) => {
                      return moment().add(-1, 'days') >= current;
                    }}
                    onChange={handleDate}
                  />
                </Col>
              </Row>
            </Modal>
            <div className="estimates-checklist-container">
              <div className="estimate-cheader">
                <h1 className="ec-title">Estimate Checklists</h1>
              </div>
              <Row gutter={20} className="ec-checkbox-req">
                <Col>
                  <Button
                    style={{
                      color: '#FDB913',
                      border: '1px solid #fdb913',
                    }}
                    onClick={handleCheckRequest}
                  >
                    Request Quote from paint vendor
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="paint-pricing"
                    style={{ color: '#1F1F1F' }}
                    type="primary"
                    onClick={() => {
                      setMaterialModal(true);
                    }}
                  >
                    Paint Pricing
                  </Button>
                </Col>
              </Row>

              <hr style={{ border: '1px solid #E8E8E8' }} />
              <div className="ec-checkbox-con">
                {adminDefaults?.estimateChecklist
                  ?.filter((item: any) => item.isDeleted === false)
                  .map((item: any) => {
                    return (
                      <Row key={item._id}>
                        <Checkbox
                          onChange={(e) => handleCheckbox(e, item)}
                          checked={handleValue(item)}
                        >
                          <h1 className="es-checkbox-text">{item.description}</h1>
                        </Checkbox>
                      </Row>
                    );
                  })}
                {items[currentProject].projectDefaults?.estimateChecklist
                  ?.filter((item: any) => item.isDeleted === false)
                  .map((item: any) => {
                    return (
                      <Row key={item._id}>
                        <Checkbox
                          onChange={(e) =>
                            handleCheckboxProjectDefaults(e, item)
                          }
                          checked={handleValueProjectDefaults(item)}
                        >
                          <h1 className="es-checkbox-text">
                            {item.description}
                          </h1>
                        </Checkbox>
                      </Row>
                    );
                  })}
              </div>
            </div>
          </Col>

          {isTabletOrMobile ? (
            <Col
              style={{ marginTop: '20px' }}
              className="gutter-row"
              lg={9}
              sm={24}
            >
              <Notes />
            </Col>
          ) : (
            <Col className="gutter-row" lg={9} sm={24}>
              <Notes />
            </Col>
          )}
        </Row>
      ) : (
        <Row gutter={25}>
          <Col lg={24} sm={24}>
            <ProjectMaterialsC
              showModal={materialModal}
              toggleModal={toggleMaterial}
            />
            <EmailModal
              showModal={showModal}
              setShowModal={setShowModal}
              emailSubject={emailSubject}
              emailBody={emailBody}
              emailRecipients={emailRecipients}
              sendEmail={handleSendEmail}
            />
            <Modal
              className="req-modal"
              title="Request Quote from Paint Vendor"
              visible={checkRequest}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <Button
                  key="cancel"
                  onClick={handleCancel}
                  style={{
                    marginRight: 8,
                    color: '#FDB913',
                    border: '1px solid #fdb913',
                  }}
                >
                  Cancel
                </Button>,
                <Button
                  style={{ color: '#1F1F1F' }}
                  key="submit"
                  type="primary"
                  onClick={handleOk}
                >
                  Submit
                </Button>,
              ]}
            >
              <Row style={{ marginBottom: 20 }} gutter={28}>
                <Col>
                  <h2>Select Vendor</h2>
                </Col>
                <Col>
                  <Select
                    defaultValue="Please Select Vendor"
                    onChange={handleChange}
                  >
                    {adminDefaults?.vendorDefaults.map((item: any) => {
                      return (
                        <Option value={item._id}>{item.vendorName}</Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col>
                  <h2>Response Date</h2>
                </Col>
                <Col>
                  <DatePicker
                    disabledDate={(current) => {
                      return moment().add(-1, 'days') >= current;
                    }}
                    onChange={handleDate}
                  />
                </Col>
              </Row>
            </Modal>
            <div className="estimates-checklist-container">
              <div className="estimate-cheader">
                <h1 className="ec-title">Estimate Checklists</h1>
              </div>
              <Row gutter={20} className="ec-checkbox-req">
                <Col>
                  <Button
                    style={{
                      color: '#FDB913',
                      border: '1px solid #fdb913',
                    }}
                    onClick={handleCheckRequest}
                  >
                    Request Quote from paint vendor
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="paint-pricing"
                    style={{ color: '#1F1F1F' }}
                    type="primary"
                    onClick={() => {
                      setMaterialModal(true);
                    }}
                  >
                    Paint Pricing
                  </Button>
                </Col>
              </Row>

              <hr style={{ border: '1px solid #E8E8E8' }} />
              <div className="ec-checkbox-con">
                {adminDefaults?.estimateChecklist
                  ?.filter((item: any) => item.isDeleted === false)
                  .map((item: any) => {
                    return (
                      <Row key={item._id}>
                        <Checkbox
                          onChange={(e) => handleCheckbox(e, item)}
                          checked={handleValue(item)}
                        >
                          <h1 className="es-checkbox-text">{item.description}</h1>
                        </Checkbox>
                      </Row>
                    );
                  })}
                {items[currentProject].projectDefaults?.estimateChecklist
                  ?.filter((item: any) => item.isDeleted === false)
                  .map((item: any) => {
                    return (
                      <Row key={item._id}>
                        <Checkbox
                          onChange={(e) =>
                            handleCheckboxProjectDefaults(e, item)
                          }
                          checked={handleValueProjectDefaults(item)}
                        >
                          <h1 className="es-checkbox-text">
                            {item.description}
                          </h1>
                        </Checkbox>
                      </Row>
                    );
                  })}
              </div>
            </div>
          </Col>

          {isTabletOrMobile ? (
            <Col
              style={{ marginTop: '20px' }}
              className="gutter-row"
              lg={24}
              sm={24}
            >
              <Notes />
            </Col>
          ) : (
            <Col className="gutter-row" lg={24} sm={24}>
              <Notes />
            </Col>
          )}
        </Row>
      )}
      {isTabletOrMobile ? (
        <Row justify="center" style={{ marginBottom: '20px' }}>
          <Col lg={12}>
            <Button onClick={handleNext} type="primary" className="save-button">
              Next
            </Button>
          </Col>
        </Row>
      ) : (
        <Row justify="center" style={{ marginBottom: '20px' }}>
          <Col lg={8}></Col>
          <Col lg={12}>
            <Button onClick={handleNext} type="primary" className="save-button">
              Next
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default EstimateChecklistsC;
