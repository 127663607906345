import { Modal, Button } from 'antd';
import React from 'react';

type Props = {
  showModal: boolean;
  setShowModal: any;
  message: string;
  title: string;
  handleOK?: any;
  handleCancel?: any;
};

const WarningErrorModal = (props: Props) => {
  return (
    <Modal
      className="notes-modal-container"
      open={props.showModal}
      title={
        <h1 style={{ marginTop: 10 }} className="send-title-text">
          {props.title}
        </h1>
      }
      onCancel={() => {
        props.setShowModal(false);
      }}
      width={'40%'}
      footer={[
        <Button
          style={{ color: '#1F1F1F' }}
          key="submit"
          type="primary"
          onClick={() => {
            if (props.handleOK) {
              props.handleOK();
            }
            props.setShowModal(false);
          }}
        >
          Ok
        </Button>,
        <Button
          style={{ color: '#1F1F1F' }}
          key="submit"
          type="primary"
          onClick={() => {
            if (props.handleCancel) {
              props.handleCancel();
            }
            props.setShowModal(false);
          }}
        >
          Cancel
        </Button>,
      ]}
    >
      <p
        style={{
          textAlign: 'left',
          marginTop: 10,
          padding: '10px 10px 10px 25px',
        }}
      >
        {props.message}
      </p>
    </Modal>
  );
};
export default WarningErrorModal;
