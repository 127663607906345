import { Row, Col, Button, Input } from 'antd';
import "./ProposalDefaultComponent.less";

type Props = {
    // title: string;
    data: any;
    show: boolean;
    onShowEdit: () => void;
    ProposalDefaultCard: any;
    setOpenAddContractDefaultComponent?: any
    type: string
    fetchData: any
    updateTitle: (type: string, newTitle: string) => void
};

const ProposalDefaultComponent = ({
    // title,
    data,
    show,
    onShowEdit,
    ProposalDefaultCard,
    setOpenAddContractDefaultComponent,
    type,
    fetchData,
    updateTitle
}: Props) => {
    const handleInputChange = (e: any) => {
        updateTitle(type, e.target.value);  // Call the function passed as a prop
    };

    return (
        <div>
            <Row
                className="contract-default-header"
                align="middle"
                justify="space-between"
                style={{ marginBottom: "16px" }}
            >
                {show ? (
                    <Row>
                        <Col style={{ padding: "2px 15px 0px 0px" }}>
                            <h1 style={{ fontWeight: "bold" }}>Title: </h1>
                        </Col>
                        <Col>
                            <Input
                                className="default-title-input"
                                type="string"
                                value={data?.title}
                                onChange={handleInputChange}
                            />
                        </Col>
                    </Row>

                ) : (
                    <Col>
                        <h1 style={{ paddingTop: "3px" }}>{data?.title}</h1>
                    </Col>
                )}
                {!show && (
                    <Col>
                        <Button className="contract-add" type="primary" onClick={onShowEdit}>
                            {data?.description ? "Update" : "+ Add"}
                        </Button>
                    </Col>
                )}

                {show ? (
                    <Col xs={24}>
                        <ProposalDefaultCard
                            setShowComponent={setOpenAddContractDefaultComponent}
                            formData={data}
                            onCancel={onShowEdit}
                            title={data?.title}
                            type={type}
                            fetchData={fetchData}
                        />
                    </Col>
                ) : (
                    <Col xs={24} style={{ textAlign: "left", marginTop: "10px" }}>
                        <div
                            style={{
                                fontFamily: "Lato",
                                textAlign: "left",
                                fontSize: "15px",
                                fontStyle: "normal",
                            }}
                            dangerouslySetInnerHTML={{ __html: data?.description }}
                        />
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default ProposalDefaultComponent;
