import {
  Divider,
  Form,
  message,
  Popconfirm,
  Row,
  Table,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import _ from "lodash";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import ActionDropDown from "./ActionDropDown";
import "../LaborRates.less";
import EditPaintRates from "../../../components/pop-ups/EditPaintRates";
import api from "../../../utils/api";
import {
  removeServiceFromProject,
  addServiceToProject,
  addRatesToOptions,
} from "../../../redux/project/action";
import { compareObjects } from "../../../utils/helpers";
import Item from "antd/lib/list/Item";
var crypto = require("crypto");

type Props = {
  rates: any;
  setTableValues: any;
  showExpandRows: any;
};
let paintClone: any;
const PaintSurfaces = (props: Props) => {
  const dispatch = useDispatch<any>();
  const [data, setData] = useState<any>(props?.rates);
  const [form] = Form.useForm();
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [row, setRow] = useState<number>(-1);

  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );
  useEffect(() => {
    const project = _.cloneDeep(items[currentProject]); // Deep copy to avoid mutations
    const hasOptionPaintRates = project.options.some(
      (option: any) => option.rates?.paintRates?.length > 0,
    );
    if (hasOptionPaintRates) {
      const paintLaborRates = [...props.rates];
      let laborRatesNotFoundInOption: string[] = [];

      // Collect all paint rate IDs from all options
      const allPaintRateIds = project.options?.flatMap((option: any) =>
        option?.rates?.paintRates.map((paintRate: any) => {
          if (!paintRate.isDeleted) return paintRate.projectLaborRate;
        }),
      );

      // Filter rates that are not found in any of the paintRates
      laborRatesNotFoundInOption = paintLaborRates
        .filter((rate) => !allPaintRateIds.includes(rate._id))
        .map((rate) => rate._id);

      setData(
        props.rates.filter(
          (item: any) => !laborRatesNotFoundInOption.includes(item._id),
        ),
      );
    } else {
      setData(props.rates);
    }
  }, [props.rates]);
  const isEditing = (record: any) => record._id === editingKey;
  const toggleEditModal = (_: boolean) => {
    !showEditModal ? setShowEditModal(true) : setShowEditModal(false);
  };
  const handleEdit = (record: any) => {
    const index = data.findIndex((item: any) => item._id === record._id);
    setRow(index);
    !showEditModal ? toggleEditModal(true) : toggleEditModal(false);
  };

  const edit = (record: any) => {
    paintClone = { ...record };

    form.setFieldsValue({
      paintLaborRate: "",
      prepLaborRate: "",
      primeLaborRate: "",
      paintSpreadRate: "",
      primerSpreadRate: "",
      paintMaterial: "",
      ...record,
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  // console.log("TEST_DATA", data);

  const save = async (id: string) => {
    try {
      const row = (await form.validateFields()) as any;

      const newData = [...data];

      const index = newData.findIndex((item) => id === item._id);

      if (index > -1) {
        const item = newData[index];
        console.log(row, item);
        if (compareObjects(row, item)) {
          setEditingKey("");
          return;
        }

        const testData: any = { ...item, ...row };
        let updatedObject: any = {};
        for (const key in testData) {
          if (testData[key] !== paintClone[key]) {
            updatedObject[key] = testData[key];
          }
        }

        const body = {
          category: "paint",
          rate: updatedObject,
          project: items[currentProject].projectId,
        };

        api
          .patch(`rates/project/${item._id}`, body)
          .then(() => {})
          .catch((err) => console.error(err));

        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleDelete = (id: string) => {
    let project: any = _.cloneDeep(items[currentProject]);
    let index = project.projectRates.paintRates.findIndex(
      (item: any) => item._id === id,
    );
    project.projectRates.paintRates[index].isDeleted = true;
    // project.projectRates.paintRates.splice(index, 1);
    props.setTableValues({ paintRates: project.projectRates.paintRates });
    for (const singleOption of project.options) {
      let index = singleOption.rates.paintRates.findIndex(
        (item: any) => item.projectLaborRate === id,
      );
      if (index !== -1) {
        singleOption.rates.paintRates[index].isDeleted = true;
      }
      // singleOption.rates.paintRates.splice(index, 1);
    }
    dispatch(removeServiceFromProject(id, "paint", project, adminDefaults));
  };

  const deepEqualForClone = (obj1: any, obj2: any): boolean => {
    // If both are direct values
    console.log("OBJECT 1", obj1, "OBJECT 2", obj2);
    if (obj1 === obj2) {
      return true;
    }

    // If either of them isn't an object or is null
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    // Get the keys of both objects excluding the "_id"
    const keys1 = Object.keys(obj1).filter((key) => !["_id"].includes(key));
    const keys2 = Object.keys(obj2).filter((key) => !["_id"].includes(key));

    // If number of keys is different between the two objects
    if (keys1.length !== keys2.length) {
      console.log("keys length Not equal");
      return false;
    }

    // Check if every key-value pair in obj1 matches that in obj2
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqualForClone(obj1[key], obj2[key])) {
        console.log(
          "Check if every key-value pair in obj1 matches that in obj2",
        );
        return false;
      }
    }

    return true;
  };

  const deepEqualCheckForClone = (editedObject: any, oldObjectArray: any) => {
    const updatedEditedObject = {
      ...editedObject,
      paintMaterial: editedObject.paintMaterial
        ? editedObject.paintMaterial._id
        : null,
      primerMaterial: editedObject.primerMaterial
        ? editedObject.primerMaterial._id
        : null,
    };
    for (let oldObject of oldObjectArray) {
      const updatedOldObject = {
        ...oldObject,
        paintMaterial: oldObject.paintMaterial ? oldObject.paintMaterial : null,
        primerMaterial: oldObject.primerMaterial
          ? oldObject.primerMaterial
          : null,
      };
      if (deepEqualForClone(updatedEditedObject, updatedOldObject)) {
        return true;
      }
    }
    return false;
  };

  const handlePaintClone = (
    cloneData: any,
    index: number,
    surfaceName: string,
  ) => {
    const result = props.rates.some((obj: any) => {
      let name: string = obj.item;
      if (name.replaceAll(" ", "") === surfaceName[index].replaceAll(" ", "")) {
        return true;
      }
    });
    if (result === true) {
      message.error("Name already taken");
    } else {
      const newRates = {
        ...cloneData,
        item: surfaceName[index],
        project: items[currentProject].projectId,
        // paintLevel: 0,
        // prepLevel: 0,
        // primeLevel: 0,
        _id: crypto.randomBytes(12).toString("hex"),
        isCloned: true,
      };
      // delete newRates._id;
      // delete newRates.defaultRate;

      const notDeletedRates = props.rates.filter(
        (item: any) => item.isDeleted === false,
      );
      const isCloned = notDeletedRates.filter(
        (item: any) => item.isCloned === true,
      );
      const rateAlreadyExists = deepEqualCheckForClone(newRates, isCloned);
      if (rateAlreadyExists) {
        message.error("Rate Already Exists");
      } else {
        const body = [
          {
            category: "paint",
            rate: [newRates],
          },
        ];
        const newData = [...data];
        newData.push(body[0].rate[0]);
        setData(newData);
        let project = _.cloneDeep(items[currentProject]);
        dispatch(addServiceToProject(items[currentProject].projectId, body));
        project.projectRates.paintRates = newData;

        let tempPaintNewRates = [];
        for (const item of project.options) {
          let newRate = [];
          for (const element of body[0].rate) {
            let count = 0;
            let coats = 1;
            let color = "TBD";
            let prepHours = count / element.prepLaborRate;
            let primeHours = element.primeHours ? element.primeHours : 0;
            let paintHours = (count * coats) / element.paintLaborRate;
            let primerGallons = element.primerGallons
              ? element.primerGallons
              : 0;
            let primeSelect = element.primeSelect
              ? element.primeSelect
              : "spot";
            let paintGallons = (count * coats) / element.paintSpreadRate;
            const projectLaborRate = element._id;
            let _id = crypto.randomBytes(12).toString("hex");
            newRate.push({
              ...element,
              _id,
              option: item.optionInfo._id,
              projectLaborRate: projectLaborRate,
              count,
              coats,
              color,
              prepHours,
              primeHours,
              paintHours,
              primerGallons,
              primeSelect,
              paintGallons,
              totalPaintHours: prepHours + primeHours + paintHours,
              paintMaterialCost:
                (element.paintMaterial?.priceAfterTax
                  ? element.paintMaterial?.priceAfterTax * paintGallons
                  : 0) +
                (element.primerMaterial?.priceAfterTax
                  ? element.primerMaterial?.priceAfterTax * primerGallons
                  : 0),
            });
          }
          item.rates.paintRates.push(...newRate);
          tempPaintNewRates.push(...newRate);
        }
        let paintRequestBody = [
          {
            category: "paint",
            rate: [...tempPaintNewRates],
          },
        ];
        dispatch(addRatesToOptions(paintRequestBody, project, adminDefaults));
      }
    }

    // api
    //   .post(`rates/project/${items[currentProject].projectId}`, body)
    //   .then((resp: any) => {
    //     const newData = [...data];
    //     newData.push(resp[0]);
    //     setData(newData);
    //   })
    //   .catch((err) => console.error(err));
  };

  const paintColumns = [
    {
      title: "Surface",
      dataIndex: "item",
      // width: '130px',
      editable: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      // width: '80px',
    },
    {
      title: "Paint Labor",
      // width: '130px',
      // dataIndex: "paintLaborRate",
      onCell: () => {
        return {
          style: { paddingBottom: "-6px", paddingTop: "38px" },
        };
      },
      render: (data: any) => {
        let dataMediumValue = data.defaultRate?.paintLaborMedium;
        if (typeof data.defaultRate === "string") {
          const rateIndex = adminDefaults.defaultRates.paintRates.findIndex(
            (item: any) => item._id === data.defaultRate,
          );
          dataMediumValue =
            adminDefaults.defaultRates.paintRates[rateIndex].paintLaborMedium;
        }
        const determineTrackColor = (rateItem: any) => {
          if (!rateItem.defaultRate) {
            return "#d1cec4";
          }
          let mediumValue = rateItem.defaultRate.paintLaborMedium;
          if (typeof rateItem.defaultRate === "string") {
            const rateIndex = adminDefaults.defaultRates.paintRates.findIndex(
              (item: any) => item._id === rateItem.defaultRate,
            );
            mediumValue =
              adminDefaults.defaultRates.paintRates[rateIndex].paintLaborMedium;
          }
          if (rateItem.paintLaborRate < mediumValue) {
            console.log("Red");
            return "#FF0000";
          } else if (rateItem.paintLaborRate === mediumValue) {
            console.log("Yellow");
            return "#FDB913";
          } else if (rateItem.paintLaborRate > mediumValue) {
            console.log("Green");
            return "#00B44F";
          } else {
            console.log("default");
            return "#d1cec4";
          }
        };
        return (
          <div>
            <Row style={{ justifyContent: "center", marginTop: 8 }}>
              {data.paintLaborRate}{" "}
            </Row>

            <Row style={{ marginTop: "-25px", justifyContent: "center" }}>
              {data.paintLaborRate !== dataMediumValue ? (
                <Divider
                  style={{
                    minWidth: 22,
                    width: 22,
                    borderColor: determineTrackColor(data),
                    borderWidth: 4,
                  }}
                />
              ) : (
                <Divider
                  style={{
                    minWidth: 22,
                    width: 22,
                    borderColor: "none",
                    borderWidth: 0,
                    marginTop: 29,
                  }}
                />
              )}
            </Row>
          </div>
        );
      },
      editable: true,
    },
    {
      title: "Prep Labor",
      // dataIndex: "prepLaborRate",
      // width: '130px',
      onCell: () => {
        return {
          style: { paddingBottom: "-6px", paddingTop: "38px" },
        };
      },
      render: (data: any) => {
        let dataMediumValue = data.defaultRate?.prepLaborMedium;
        if (typeof data.defaultRate === "string") {
          const rateIndex = adminDefaults.defaultRates.paintRates.findIndex(
            (item: any) => item._id === data.defaultRate,
          );
          dataMediumValue =
            adminDefaults.defaultRates.paintRates[rateIndex].prepLaborMedium;
        }
        const determineTrackColor = (rateItem: any) => {
          if (!rateItem.defaultRate) {
            return "#d1cec4";
          }
          let mediumValue = rateItem.defaultRate.prepLaborMedium;
          if (typeof rateItem.defaultRate === "string") {
            const rateIndex = adminDefaults.defaultRates.paintRates.findIndex(
              (item: any) => item._id === rateItem.defaultRate,
            );
            mediumValue =
              adminDefaults.defaultRates.paintRates[rateIndex].prepLaborMedium;
          }
          if (rateItem.prepLaborRate < mediumValue) {
            console.log("Red");
            return "#FF0000";
          } else if (rateItem.prepLaborRate === mediumValue) {
            console.log("Yellow");
            return "#FDB913";
          } else if (rateItem.prepLaborRate > mediumValue) {
            console.log("Green");
            return "#00B44F";
          } else {
            console.log("default");
            return "#d1cec4";
          }
        };
        return (
          <div>
            <Row style={{ justifyContent: "center", marginTop: 8 }}>
              {data.prepLaborRate}{" "}
            </Row>
            <Row style={{ marginTop: "-25px", justifyContent: "center" }}>
              {data.prepLaborRate !== dataMediumValue ? (
                <Divider
                  style={{
                    minWidth: 22,
                    width: 22,
                    borderColor: determineTrackColor(data),
                    borderWidth: 4,
                  }}
                />
              ) : (
                <Divider
                  style={{
                    minWidth: 22,
                    width: 22,
                    borderColor: "none",
                    borderWidth: 0,
                    marginTop: 29,
                  }}
                />
              )}
            </Row>
          </div>
        );
      },
      editable: true,
    },
    {
      title: "Prime Labor",
      // dataIndex: "primeLaborRate",
      // width: '130px',
      onCell: () => {
        return {
          style: { paddingBottom: "-6px", paddingTop: "38px" },
        };
      },
      render: (data: any) => {
        let dataMediumValue = data.defaultRate?.primeLaborMedium;
        if (typeof data.defaultRate === "string") {
          const rateIndex = adminDefaults.defaultRates.paintRates.findIndex(
            (item: any) => item._id === data.defaultRate,
          );
          dataMediumValue =
            adminDefaults.defaultRates.paintRates[rateIndex].primeLaborMedium;
        }
        const determineTrackColor = (rateItem: any) => {
          if (!rateItem.defaultRate) {
            return "#d1cec4";
          }
          let mediumValue = rateItem.defaultRate.primeLaborMedium;
          if (typeof rateItem.defaultRate === "string") {
            const rateIndex = adminDefaults.defaultRates.paintRates.findIndex(
              (item: any) => item._id === rateItem.defaultRate,
            );
            mediumValue =
              adminDefaults.defaultRates.paintRates[rateIndex].primeLaborMedium;
          }
          if (rateItem.primeLaborRate < mediumValue) {
            console.log("Red");
            return "#FF0000";
          } else if (rateItem.primeLaborRate === mediumValue) {
            console.log("Yellow");
            return "#FDB913";
          } else if (rateItem.primeLaborRate > mediumValue) {
            console.log("Green");
            return "#00B44F";
          } else {
            console.log("default");
            return "#d1cec4";
          }
        };
        return (
          <div>
            <Row style={{ justifyContent: "center", marginTop: 8 }}>
              {data.primeLaborRate}{" "}
            </Row>
            <Row style={{ marginTop: "-25px", justifyContent: "center" }}>
              {data.primeLaborRate !== dataMediumValue ? (
                <Divider
                  style={{
                    minWidth: 22,
                    width: 22,
                    borderColor: determineTrackColor(data),
                    borderWidth: 4,
                  }}
                />
              ) : (
                <Divider
                  style={{
                    minWidth: 22,
                    width: 22,
                    borderColor: "none",
                    borderWidth: 0,
                    marginTop: 29,
                  }}
                />
              )}
            </Row>
          </div>
        );
      },
      editable: true,
    },
    {
      title: "Paint",
      dataIndex: "paintSpreadRate",
      // width: '20px',
      editable: true,
    },
    {
      title: "Prime",
      dataIndex: "primerSpreadRate",
      // width: '40px',
      editable: true,
    },
    {
      title: "Paint Material",
      // dataIndex: "paintMaterial",
      render: (data: any) => {
        return (
          <>
            {data.customerSuppliedPaint ? (
              <div>Customer Supplied</div>
            ) : (
              data.paintMaterial && (
                <div>
                  {data.paintMaterial?.manufacturer}:
                  {data.paintMaterial?.product}
                </div>
              )
            )}
          </>
        );
      },
      // width: '150px',
      editable: true,
    },
    {
      title: "Primer Material",
      // dataIndex: "primerMaterial",
      render: (data: any) => {
        return (
          <>
            {data.customerSuppliedPrimer ? (
              <div>Customer Supplied</div>
            ) : (
              data.primerMaterial && (
                <div>
                  {data.primerMaterial?.manufacturer}:
                  {data.primerMaterial?.product}
                </div>
              )
            )}
          </>
        );
      },
      // width: '150px',
      editable: true,
    },

    {
      title: "Action",
      // width: '50px',
      render: (_: any, record: any, index: number) => {
        const editable = isEditing(record);
        return !editable ? (
          <>
            <ActionDropDown
              handleClone={handlePaintClone}
              handleDelete={handleDelete}
              handleEdit={() => handleEdit(record)}
              // handleEdit={edit}
              data={record}
              index={index}
              category="paint"
            />
          </>
        ) : (
          <span>
            <Typography.Link
              onClick={() => save(record._id)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const setDataRates = (rate: any) => {
    const newState = data.map((item: any) => {
      if (item._id === rate._id) {
        return rate;
      }
      return item;
    });
    setData(newState);
  };

  const showExpandableRows = () => {
    if (props.showExpandRows) {
      return data.map((row: any) => row._id);
    } else {
      return [];
    }
  };

  const expandedRowContent = (record: any) => {
    const options = items[currentProject].options;
    let listOfOptions = [];
    for (const singleOption of options) {
      const isFound = singleOption.rates.paintRates.some(
        (item: any) => item.projectLaborRate === record._id && !item.isDeleted,
      );
      if (isFound) {
        listOfOptions.push(singleOption.optionInfo.title);
      }
    }
    return (
      <div style={{ width: "79%", margin: "auto" }}>
        {listOfOptions.length === 0 ? (
          <Typography>No Options Created</Typography>
        ) : (
          <Typography>{listOfOptions.join(", ")}</Typography>
        )}
      </div>
    );
  };

  return (
    <>
      <EditPaintRates
        showEditModal={showEditModal}
        toggleEditModal={toggleEditModal}
        row={data[row]}
        setDataRates={setDataRates}
        paintRates={props.rates}
      />
      <Form form={form} component={false}>
        {}
        <Table
          className="no-expand-icon"
          scroll={{ x: 900 }}
          rowKey="_id"
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table_row table-row-light"
              : "table_row table-row-dark"
          }
          loading={!data ? true : false}
          pagination={false}
          columns={paintColumns}
          dataSource={data
            .filter((item: any) => !item.isDeleted)
            .sort((a: any, b: any) => a.item.localeCompare(b.item))}
          expandable={{
            expandedRowRender: (record, index) => expandedRowContent(record),
            expandIcon: () => null,
            expandedRowKeys: showExpandableRows(),
          }}
        />
      </Form>
    </>
  );
};

export default PaintSurfaces;
