import { customToFixed } from "./helpers";

export const totalCostGrossMarginEstimation = (
  marginDefaults: any,
  cost: any
) => {
  //Get all tiered margins below this project cost
  let grossMargin = marginDefaults.filter((item: any) => {
    return cost >= item.cost;
  });
  //Get last index from the filtered grossMargin
  let smallestValue = grossMargin[0]?.margin;
  let smallestIndex = 0;
  for (let i = 1; i < grossMargin.length; i++) {
    if (grossMargin[i]?.margin < smallestValue) {
      smallestValue = grossMargin[i]?.margin;
      smallestIndex = i;
    }
  }
  let index = smallestIndex;

  //TODO: change return object
  //Estimated price
  let costGm = cost / (1 - grossMargin[index]?.margin / 100);
  return { costGm, grossMargin, index };
};

export const grossMarginEstimation = (
  marginDefaults: any,
  cost: any,
  singleOption: any,
  name: string,
  totalCostGrossMargin: any
) => {
  let overRideMargin = singleOption.overRideGrossMargin.filter((item: any) => {
    return item.name === name;
  });
  if (overRideMargin.length === 0) {
    let grossMargin = totalCostGrossMargin.grossMargin;
    let index = totalCostGrossMargin.index;
    // let grossMargin = marginDefaults.filter((item: any) => {
    //   return cost >= item.cost;
    // });
    // let index = grossMargin.length - 1;

    let costGm = cost / (1 - grossMargin[index]?.margin / 100);
    return { costGm, grossMargin, index };
  } else {
    const costGm = cost / (1 - overRideMargin[0].margin / 100);
    let index = 0;
    let grossMargin = overRideMargin;
    return { costGm, grossMargin, index };
  }
};

export const calculateExpressionDiscount = (
  project: any,
  fullBidRevenue: any
) => {
  const sumOfAllOptionsPrices = project.options?.reduce(
    (initial: any, curValue: any) => {
      return initial + parseFloat(curValue.pjcc[15].totalAmount);
    },
    0
  );
  const expressionDiscount = fullBidRevenue.costGm - sumOfAllOptionsPrices;
  return expressionDiscount;
};

// Replace it with google chat code when working on pjcc excel object
export const optionPjccObject = (
  projectId: string,
  singleOption: any,
  cost: any,
  costGm: any,
  name: string
) => {
  if (
    name === "Total Material Cost" ||
    name === "Paint Hours" ||
    name === "Carpentry Hours" ||
    name === "Total Hours" ||
    name === "Total Labor" ||
    name === "Average Wage" ||
    name === "Total Cost"
  ) {
    return {
      project: projectId,
      option: singleOption._id,
      name: name,
      cost: customToFixed(cost, 2),
      amount: customToFixed(costGm, 2),
      totalAmount: customToFixed(
        costGm *
        (singleOption.buildingQuantity ? singleOption.buildingQuantity : 1),
        2
      ),
      grossMargin: customToFixed(
        isNaN(100 * (1 - cost / costGm)) ? 0 : 100 * (1 - cost / costGm),
        2
      ),
    };
  }
  if (name === "Estimated Price" || name === "Gross Margin") {
    return {
      project: projectId,
      option: singleOption._id,
      name: name,
      cost: customToFixed(cost, 2),
      amount: customToFixed(costGm, 2),
      totalAmount: customToFixed(
        costGm *
        (singleOption.buildingQuantity ? singleOption.buildingQuantity : 1),
        2
      ),
      grossMargin: 0,
    };
  } else {
    return {
      project: projectId,
      option: singleOption._id,
      name: name,
      cost: customToFixed(cost, 2),
      amount: customToFixed(costGm.costGm, 2),
      totalAmount: customToFixed(
        costGm.costGm *
        (singleOption.buildingQuantity ? singleOption.buildingQuantity : 1),
        2
      ),
      grossMargin: customToFixed(costGm.grossMargin[costGm.index].margin, 2),
    };
  }
};

export const pricingCalculation = (project: any, adminDefaults: any) => {
  const projectInfo = project.projectInfo;

  let allOptionsPjcc: any = [];
  let paintLaborOptionPjcc = [];
  let carpentryLaborOptionPjcc = [];
  let carpentryHoursOptionPjcc = [];

  if (project.options.length > 0) {
    for (const singleOption of project.options) {
      for (const paintRate of singleOption.rates.paintRates) {
        if (!paintRate.isDeleted) {
          let coatsMultiplier: number = projectInfo.coatsMultiplier;
          //Review: Conert it into simple mathematical formula (1 + coats*multipler/100) (Would make it more readable)
          //Review: let's change the variable name as well
          const adjustedCoatsMultiplier =
            1 + ((paintRate.coats - 1) * coatsMultiplier) / 100;
          paintRate.prepHours = customToFixed(
            paintRate.count / paintRate.prepLaborRate,
            2
          );
          // --> Review: Line can be simplified, Also why is it not a calculation while prep and paint hours is. Let's discuss
          paintRate.primeHours = customToFixed(paintRate.primeHours || 0, 2);
          paintRate.paintHours = customToFixed(
            (paintRate.count * adjustedCoatsMultiplier) /
            paintRate.paintLaborRate,
            2
          );
          //Review: Let's discuss simplification as it looks like its checking primer gallon to be null twice
          //Review: Also this is not a calculation again

          paintRate.primerGallons = singleOption.optionInfo?.roundGallons
            ? Math.ceil(paintRate.primerGallons ?? 0)
            : (paintRate.primerGallons ?? 0);

          // Simplifying paintGallons logic
          paintRate.paintGallons = singleOption.optionInfo?.roundGallons
            ? Math.ceil(
              (paintRate.count * adjustedCoatsMultiplier) /
              paintRate.paintSpreadRate
            )
            : (paintRate.count * adjustedCoatsMultiplier) /
            paintRate.paintSpreadRate;

          paintRate.totalPaintHours = customToFixed(
            paintRate.prepHours + paintRate.primeHours + paintRate.paintHours,
            2
          );

          //Review: Let's check customer supplied before trying to find it in project materials.
          let paintMaterialRate = paintRate.customerSuppliedPaint
            ? 0
            : paintRate?.paintMaterial
              ? project?.projectMaterials?.find(
                (material: any) =>
                  material.defaultMaterialId === paintRate?.paintMaterial?._id
              )?.price || paintRate.paintMaterial?.priceAfterTax
              : 0;

          let primerMaterialRate = paintRate.customerSuppliedPrimer
            ? 0
            : paintRate.primerMaterial
              ? project?.projectMaterials?.find(
                (material: any) =>
                  material.defaultMaterialId === paintRate.primerMaterial._id
              )?.price || paintRate.primerMaterial.priceAfterTax
              : 0;

          //Review: Do we need a ternary operator here as we're already setting it to zero above just in case. Use the formula only
          paintRate.paintMaterialCost = customToFixed(
            paintMaterialRate * paintRate.paintGallons +
            primerMaterialRate * paintRate.primerGallons,
            2
          );
        }
      }

      for (const carpentryRate of singleOption.rates.carpentryRates) {
        if (!carpentryRate.isDeleted) {
          carpentryRate.carpentryMaterial = customToFixed(
            carpentryRate.materialRate * carpentryRate.count,
            2
          );
          carpentryRate.carpentryHours = carpentryRate.carpentryHours =
            customToFixed(
              (carpentryRate.laborRate * carpentryRate.count) /
              projectInfo.carpentryWageRate,
              2
            );
        }
      }

      for (const equipmentRate of singleOption.rates.equipmentRates) {
        if (!equipmentRate.isDeleted) {
          equipmentRate.totalCost = customToFixed(
            equipmentRate.count *
            equipmentRate.periods *
            (equipmentRate.cost +
              equipmentRate.puAndDelivery +
              equipmentRate.surcharges) *
            (1 + equipmentRate.tax / 100),
            2
          );
        }
      }

      for (const miscRate of singleOption.rates.miscellaneousRates) {
        if (!miscRate.isDeleted) {
          miscRate.totalCost = customToFixed(miscRate.count * miscRate.cost, 2);
        }
      }

      for (const travelRate of singleOption.rates.travelRates) {
        if (!travelRate.isDeleted) {
          travelRate.totalCost = customToFixed(
            travelRate.rfmCount * travelRate.dnCount * travelRate.cost,
            2
          );
        }
      }

      let singleOptionPjcc = [];

      let sumOfAllPaintMaterialCost = 0;
      let sumOfAllPaintRateHours = 0;
      //Review: What kind of input produces not a number condition
      //Review: We are reiterating on the same items that we used in loop above. To be revisited later.

      for (const item of singleOption.rates.paintRates) {
        if (!item.isDeleted) {
          sumOfAllPaintMaterialCost += isNaN(item.paintMaterialCost)
            ? 0
            : item.paintMaterialCost;
          sumOfAllPaintRateHours +=
            (item.paintHours || 0) +
            (item.primeHours || 0) +
            (item.prepHours || 0);
        }
      }

      let sumOfAllCarpentryRateHours = 0;
      let sumOfAllCarpentryMaterial = 0;
      for (const item of singleOption.rates.carpentryRates) {
        if (!item.isDeleted) {
          sumOfAllCarpentryRateHours += customToFixed(
            item.carpentryHours || 0,
            2
          );
          sumOfAllCarpentryMaterial += customToFixed(
            item.carpentryMaterial || 0,
            2
          );
        }
      }

      let sumOfAllEquipmentTotalCost = 0;
      for (const item of singleOption.rates.equipmentRates) {
        if (!item.isDeleted) {
          sumOfAllEquipmentTotalCost += customToFixed(item.totalCost || 0, 2);
        }
      }

      let sumOfAllMiscTotalCost = 0;
      for (const item of singleOption.rates.miscellaneousRates) {
        if (!item.isDeleted) {
          sumOfAllMiscTotalCost += item.totalCost || 0;
        }
      }

      let sumOfAllTravelTotalCost = 0;
      for (const item of singleOption.rates.travelRates) {
        if (!item.isDeleted) {
          sumOfAllTravelTotalCost += customToFixed(item.totalCost || 0, 2);
        }
      }
      let washHours = 0;
      if (
        singleOption.rates.washRates.length > 0 &&
        singleOption.optionInfo.washIncluded //Review: let's remove true here
      ) {
        //Review: Include the length check in if condition
        //Review: Ternary operator not needed as washHours is already initialized to 0. Would only contain formula
        washHours =
          singleOption.rates.washRates[0].squareFeet /
          singleOption.rates.washRates[0].rate;
      }

      let setup = 0;

      if (singleOption?.optionInfo?.overRideSetupHours) {
        setup = singleOption?.optionInfo?.overRideSetupHours;
      } else {
        //Review: Custom to fixed can be used
        setup =
          projectInfo.setupConst *
          customToFixed(sumOfAllPaintRateHours + washHours, 2);
      }

      let totalPaintHours = customToFixed(
        sumOfAllPaintRateHours + washHours + setup,
        2
      );

      // Non Price that why make it customToFixed
      let totalCarpentryHours = customToFixed(
        1.08 * sumOfAllCarpentryRateHours,
        2
      );

      let totalPaintAndCarpentryHours = totalPaintHours + totalCarpentryHours;

      // Set all Non Prices and fields used in excel to customToFixed and remain to Math.round
      //PaintCost (Not used in Excel so make it Round)
      let paintCost = Math.round(
        (isNaN(singleOption.optionInfo.spotPrimeMaterial)
          ? 0
          : singleOption.optionInfo.spotPrimeMaterial) +
        sumOfAllPaintMaterialCost
      );
      //SsCost (Not used in Excel so make it Round)
      let ssCost = Math.round(
        Math.round(totalPaintAndCarpentryHours) * projectInfo.ssConst
      );
      // CarpentryMaterial (Not used in Excel so make it Round)
      let carpentryMaterial = Math.round(sumOfAllCarpentryMaterial);

      // TotalMaterialCost
      let totalMaterialCost = paintCost + ssCost + carpentryMaterial;

      // MiscCost (Not used in Excel so make it Round)
      let miscCost = Math.round(sumOfAllMiscTotalCost);

      // PaintLabor (Not used in Excel so make it Round)
      let paintLabor = Math.round(totalPaintHours * projectInfo.paintWageRate);

      // CarpentryLabor (Not used in Excel so make it Round)
      let carpentryLabor = Math.round(
        totalCarpentryHours * projectInfo.carpentryWageRate
      );

      // TotalLabor
      let totalLabor = paintLabor + carpentryLabor;

      // AverageWage
      let averageWage = isNaN(totalLabor / totalPaintAndCarpentryHours)
        ? 0
        : Math.round(totalLabor / totalPaintAndCarpentryHours);
      // TotalCost
      // Previously it was round that why now a point values
      // let totalCost = Math.round(
      //   totalMaterialCost + totalLabor + miscCost + travelCost + equipmentCost
      // );
      let totalCost =
        totalMaterialCost +
        totalLabor +
        miscCost +
        sumOfAllTravelTotalCost +
        sumOfAllEquipmentTotalCost;

      let totalCostGrossMargin = totalCostGrossMarginEstimation(
        projectInfo.tieredMargins,
        totalCost
      );

      let paintCostGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        paintCost,
        singleOption.optionInfo,
        "Paint Cost",
        totalCostGrossMargin
      );

      let ssCostGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        ssCost,
        singleOption.optionInfo,
        "SS Cost",
        totalCostGrossMargin
      );

      let carpentryMaterialGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        carpentryMaterial,
        singleOption.optionInfo,
        "Carpentry Material",
        totalCostGrossMargin
      );

      let totalMaterialCostGm =
        paintCostGm.costGm + ssCostGm.costGm + carpentryMaterialGm.costGm;

      let equipmentCostGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        sumOfAllEquipmentTotalCost,
        singleOption.optionInfo,
        "Equipment Cost",
        totalCostGrossMargin
      );

      let miscCostGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        miscCost,
        singleOption.optionInfo,
        "Miscellaneous Cost",
        totalCostGrossMargin
      );

      let travelCostGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        sumOfAllTravelTotalCost,
        singleOption.optionInfo,
        "Travel Cost",
        totalCostGrossMargin
      );

      let paintLaborGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        paintLabor,
        singleOption.optionInfo,
        "Paint Labor",
        totalCostGrossMargin
      );

      let carpentryLaborGm = grossMarginEstimation(
        projectInfo.tieredMargins,
        carpentryLabor,
        singleOption.optionInfo,
        "Carpentry Labor",
        totalCostGrossMargin
      );

      let totalLaborGm = customToFixed(
        paintLaborGm.costGm + carpentryLaborGm.costGm,
        2
      );
      let averageWageGm = isNaN(totalLaborGm / totalPaintAndCarpentryHours)
        ? 0
        : Math.round(totalLaborGm / totalPaintAndCarpentryHours);

      let totalCostGm = customToFixed(
        totalMaterialCostGm +
        totalLaborGm +
        miscCostGm.costGm +
        travelCostGm.costGm +
        equipmentCostGm.costGm,
        2
      );

      let paintCostObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        paintCost,
        paintCostGm,
        "Paint Cost"
      );

      let ssCostObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        ssCost,
        ssCostGm,
        "SS Cost"
      );
      let carpentryMaterialObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        carpentryMaterial,
        carpentryMaterialGm,
        "Carpentry Material"
      );
      let totalMaterialCostObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        totalMaterialCost,
        totalMaterialCostGm,
        "Total Material Cost"
      );
      let equipmentCostObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        sumOfAllEquipmentTotalCost,
        equipmentCostGm,
        "Equipment Cost"
      );
      let miscCostObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        miscCost,
        miscCostGm,
        "Miscellaneous Cost"
      );
      let travelCostObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        sumOfAllTravelTotalCost,
        travelCostGm,
        "Travel Cost"
      );
      let paintHoursObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        totalPaintHours,
        totalPaintHours,
        "Paint Hours"
      );
      let carpentryHoursObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        totalCarpentryHours,
        totalCarpentryHours,
        "Carpentry Hours"
      );
      carpentryHoursOptionPjcc.push(carpentryHoursObject);
      let totalHoursObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        totalPaintAndCarpentryHours,
        totalPaintAndCarpentryHours,
        "Total Hours"
      );
      let paintLaborObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        paintLabor,
        paintLaborGm,
        "Paint Labor"
      );
      paintLaborOptionPjcc.push(paintLaborObject);
      let carpentryLaborObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        carpentryLabor,
        carpentryLaborGm,
        "Carpentry Labor"
      );
      carpentryLaborOptionPjcc.push(carpentryLaborObject);
      let totalLaborObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        totalLabor,
        totalLaborGm,
        "Total Labor"
      );
      let averageWageObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        averageWage,
        averageWageGm,
        "Average Wage"
      );
      let totalCostObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        totalCost,
        totalCostGm,
        "Total Cost"
      );
      let estimatedPriceObject = optionPjccObject(
        projectInfo._id,
        singleOption.optionInfo,
        totalCost,
        totalCostGm,
        "Estimated Price"
      );
      let grossMarginObject = {
        project: projectInfo._id,
        option: singleOption.optionInfo._id,
        name: "Gross Margin",
        cost: null,
        amount: Math.round(estimatedPriceObject.amount - totalCostObject.cost),
        totalAmount: Math.round(
          estimatedPriceObject.totalAmount -
          totalCostObject.cost * singleOption.optionInfo.buildingQuantity
        ),
      };
      // let grossMarginObject = optionPjccObject(
      //   projectInfo._id,
      //   singleOption.optionInfo,
      //   totalCost,
      //   totalCostGm,
      //   "Gross Margin"
      // );

      singleOptionPjcc.push(paintCostObject);
      singleOptionPjcc.push(ssCostObject);
      singleOptionPjcc.push(carpentryMaterialObject);
      singleOptionPjcc.push(totalMaterialCostObject);
      singleOptionPjcc.push(equipmentCostObject);
      singleOptionPjcc.push(miscCostObject);
      singleOptionPjcc.push(travelCostObject);
      singleOptionPjcc.push(paintHoursObject);
      singleOptionPjcc.push(carpentryHoursObject);
      singleOptionPjcc.push(totalHoursObject);
      singleOptionPjcc.push(paintLaborObject);
      singleOptionPjcc.push(carpentryLaborObject);
      singleOptionPjcc.push(totalLaborObject);
      singleOptionPjcc.push(averageWageObject);
      singleOptionPjcc.push(totalCostObject);
      singleOptionPjcc.push(estimatedPriceObject);
      singleOptionPjcc.push(grossMarginObject);

      //setting Values to Project
      singleOption.pjcc = singleOptionPjcc;

      allOptionsPjcc.push({
        optionId: singleOption.optionInfo._id,
        optionName: singleOption.optionInfo.title,
        included: singleOption.optionInfo.included,
        buildingQuantity: singleOption.optionInfo.buildingQuantity ?? 1,
        pjcc: singleOptionPjcc,
      });
    }

    let fullBidCost = allOptionsPjcc.reduce(
      (accumulator: any, currentObject: any) => {
        let totalProfit = currentObject?.pjcc?.find(
          (item: any) => item.name === "Total Cost"
        );
        return (
          accumulator + totalProfit?.cost * currentObject?.buildingQuantity
        );
      },
      0
    );
    const fullBidRevenue = totalCostGrossMarginEstimation(
      projectInfo.tieredMargins,
      fullBidCost
    );
    const optionsPjccWithIncluded: any = allOptionsPjcc.filter((item: any) => {
      return item.included === "included";
    });

    // if (projectInfo.overRideGrossMargin.length !== 0) {
    //   console.log("One inside override", projectInfo.overRideGrossMargin)
    //   for (const item of projectInfo.overRideGrossMargin) {
    //     switch (item.name) {
    //       case "Paint Cost":
    //       case "SS Cost":
    //       case "Carpentry Material":
    //         {
    //           for (const singleOptionPjcc of allOptionsPjcc) {
    //             let optionBuildingQuantity = singleOptionPjcc.buildingQuantity;
    //             for (const element of singleOptionPjcc.pjcc) {
    //               if (element.name === item.name) {
    //                 let newAmount = element.cost / (1 - item.margin / 100);
    //                 let newTotalAmount = newAmount * optionBuildingQuantity;

    //                 element.amount = newAmount;
    //                 element.totalAmount = newTotalAmount;
    //                 element.grossMargin = item.margin;
    //               }
    //             }
    //             let newArray = singleOptionPjcc.pjcc.filter((entity: any) => {
    //               return (
    //                 entity.name === "Paint Cost" ||
    //                 entity.name === "SS Cost" ||
    //                 entity.name === "Carpentry Material"
    //               );
    //             });

    //             let cost = newArray.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.cost;
    //             }, 0);
    //             let amount = newArray.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.amount;
    //             }, 0);
    //             let totalAmount = newArray.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.totalAmount;
    //             }, 0);
    //             let grossMargin = (1 - cost / totalAmount) * 100;

    //             for (const element of singleOptionPjcc.pjcc) {
    //               if (element.name === "Total Material Cost") {
    //                 element.cost = cost;
    //                 element.amount = amount;
    //                 element.totalAmount = totalAmount;
    //                 element.grossMargin = grossMargin;
    //               }
    //             }
    //             let arrayForTotalCost = singleOptionPjcc.pjcc.filter(
    //               (entity: any) => {
    //                 return (
    //                   entity.name === "Total Material Cost" ||
    //                   entity.name === "Miscellaneous Cost" ||
    //                   entity.name === "Travel Cost" ||
    //                   entity.name === "Equipment Cost" ||
    //                   entity.name === "Total Labor"
    //                 );
    //               }
    //             );

    //             let newCost = arrayForTotalCost.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.cost;
    //             }, 0);
    //             let newAmount = arrayForTotalCost.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.amount;
    //             }, 0);
    //             let newTotalAmount = arrayForTotalCost.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.totalAmount;
    //             }, 0);
    //             let newGrossMargin = (1 - newCost / newTotalAmount) * 100;

    //             for (const element of singleOptionPjcc.pjcc) {
    //               if (
    //                 element.name === "Total Cost" ||
    //                 element.name === "Estimated Price" ||
    //                 element.name === "Gross Margin"
    //               ) {
    //                 element.cost = newCost;
    //                 element.amount = newAmount;
    //                 element.totalAmount = newTotalAmount;
    //                 element.grossMargin = newGrossMargin;
    //               }
    //             }
    //           }
    //         }
    //         break;
    //       case "Paint Labor":
    //       case "Carpentry Labor":
    //         {
    //           for (const singleOptionPjcc of allOptionsPjcc) {
    //             let optionBuildingQuantity = singleOptionPjcc.buildingQuantity;
    //             for (const element of singleOptionPjcc.pjcc) {
    //               if (element.name === item.name) {
    //                 let newAmount = element.cost / (1 - item.margin / 100);
    //                 let newTotalAmount = newAmount * optionBuildingQuantity;

    //                 element.amount = newAmount;
    //                 element.totalAmount = newTotalAmount;
    //                 element.grossMargin = item.margin;
    //               }
    //             }
    //             let newArray = singleOptionPjcc.pjcc.filter((entity: any) => {
    //               return (
    //                 entity.name === "Paint Labor" ||
    //                 entity.name === "Carpentry Labor"
    //               );
    //             });

    //             let cost = newArray.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.cost;
    //             }, 0);
    //             let amount = newArray.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.amount;
    //             }, 0);
    //             let totalAmount = newArray.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.totalAmount;
    //             }, 0);
    //             let grossMargin = (1 - cost / totalAmount) * 100;

    //             for (const element of singleOptionPjcc.pjcc) {
    //               if (element.name === "Total Labor") {
    //                 element.cost = cost;
    //                 element.amount = amount;
    //                 element.totalAmount = totalAmount;
    //                 element.grossMargin = grossMargin;
    //               }
    //             }

    //             let arrayForTotalCost = singleOptionPjcc.pjcc.filter(
    //               (entity: any) => {
    //                 return (
    //                   entity.name === "Total Material Cost" ||
    //                   entity.name === "Miscellaneous Cost" ||
    //                   entity.name === "Travel Cost" ||
    //                   entity.name === "Equipment Cost" ||
    //                   entity.name === "Total Labor"
    //                 );
    //               }
    //             );

    //             let newCost = arrayForTotalCost.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.cost;
    //             }, 0);
    //             let newAmount = arrayForTotalCost.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.amount;
    //             }, 0);
    //             let newTotalAmount = arrayForTotalCost.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.totalAmount;
    //             }, 0);
    //             let newGrossMargin = (1 - newCost / newTotalAmount) * 100;

    //             for (const element of singleOptionPjcc.pjcc) {
    //               if (
    //                 element.name === "Total Cost" ||
    //                 element.name === "Estimated Price" ||
    //                 element.name === "Gross Margin"
    //               ) {
    //                 element.cost = newCost;
    //                 element.amount = newAmount;
    //                 element.totalAmount = newTotalAmount;
    //                 element.grossMargin = newGrossMargin;
    //               }
    //             }
    //           }
    //         }
    //         break;
    //       case "Miscellaneous Cost":
    //       case "Travel Cost":
    //       case "Equipment Cost":
    //         {
    //           for (const singleOptionPjcc of allOptionsPjcc) {
    //             let optionBuildingQuantity = singleOptionPjcc.buildingQuantity;
    //             for (const element of singleOptionPjcc.pjcc) {
    //               if (element.name === item.name) {
    //                 let newAmount = element.cost / (1 - item.margin / 100);
    //                 let newTotalAmount = newAmount * optionBuildingQuantity;

    //                 element.amount = newAmount;
    //                 element.totalAmount = newTotalAmount;
    //                 element.grossMargin = item.margin;
    //               }
    //             }
    //             let arrayForTotalCost = singleOptionPjcc.pjcc.filter(
    //               (entity: any) => {
    //                 return (
    //                   entity.name === "Total Material Cost" ||
    //                   entity.name === "Miscellaneous Cost" ||
    //                   entity.name === "Travel Cost" ||
    //                   entity.name === "Equipment Cost" ||
    //                   entity.name === "Total Labor"
    //                 );
    //               }
    //             );

    //             let newCost = arrayForTotalCost.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.cost;
    //             }, 0);
    //             let newAmount = arrayForTotalCost.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.amount;
    //             }, 0);
    //             let newTotalAmount = arrayForTotalCost.reduce(function (
    //               previousValue: any,
    //               currentValue: any
    //             ) {
    //               return previousValue + currentValue.totalAmount;
    //             }, 0);
    //             let newGrossMargin = (1 - newCost / newTotalAmount) * 100;

    //             for (const element of singleOptionPjcc.pjcc) {
    //               if (
    //                 element.name === "Total Cost" ||
    //                 element.name === "Estimated Price" ||
    //                 element.name === "Gross Margin"
    //               ) {
    //                 element.cost = newCost;
    //                 element.amount = newAmount;
    //                 element.totalAmount = newTotalAmount;
    //                 element.grossMargin = newGrossMargin;
    //               }
    //             }
    //           }
    //         }
    //         break;
    //     }
    //   }
    // }

    let allOptionPjcc: any = [];
    for (const singleOptionPjcc of optionsPjccWithIncluded) {
      allOptionPjcc.push(...singleOptionPjcc.pjcc);
    }

    let projectPjcc: any = [];
    const nameTypes = [
      "Estimated Price",
      "Paint Cost",
      "SS Cost",
      "Carpentry Material",
      "Total Material Cost",
      "Equipment Cost",
      "Miscellaneous Cost",
      "Travel Cost",
      "Paint Hours",
      "Carpentry Hours",
      "Total Hours",
      "Paint Labor",
      "Carpentry Labor",
      "Total Labor",
      "Average Wage",
      "Total Cost",
      "Gross Margin",
    ];
    if (allOptionPjcc.length !== 0) {
      let index: number = 0;
      let maxCount = allOptionPjcc.length / 17;
      let count = 0;
      nameTypes.forEach((nameType) => {
        const pjccOptionByName = allOptionPjcc.filter(
          (item: any) => item.name === nameType
        );
        // Test case can not be covered below if/else because pjccOptionByName always exist
        if (pjccOptionByName.length !== 0) {
          const value: any = {
            cost: 0,
            amount: 0,
            totalAmount: 0,
            grossMargin: 0,
          };
          pjccOptionByName.forEach((singleItem: any) => {
            value.cost +=
              singleItem.cost *
              optionsPjccWithIncluded[count]?.buildingQuantity;
            value.amount +=
              singleItem.amount *
              optionsPjccWithIncluded[count]?.buildingQuantity;
            value.totalAmount += singleItem.totalAmount;
            value.grossMargin += singleItem.grossMargin;
            count++;
            if (count === maxCount) {
              count = 0;
            }
          });
          let itemGrossMargin = 0;
          itemGrossMargin = +customToFixed(
            (1 - value.cost / value.amount) * 100,
            2
          );
          projectPjcc.push({
            project: projectInfo._id,
            name: nameType,
            cost: value.cost,
            amount: value.amount,
            totalAmount: value.totalAmount,
            grossMargin: !isNaN(itemGrossMargin) ? itemGrossMargin : 0,
            index: index++,
            productionTargets: 0,
          });
        }
      });

      // Test case can not covered below if/else because projectPjcc always exist
      if (projectPjcc.length !== 0) {
        const grossMarginIndex = projectPjcc.findIndex(
          (item: any) => item.name === "Gross Margin"
        );
        const totalCostIndex = projectPjcc.findIndex(
          (item: any) => item.name === "Total Cost"
        );
        const pjccProjectTotalHours = projectPjcc.find(
          (item: any) => item.name === "Total Hours"
        );
        const pjccProjectGrossMargin = projectPjcc.find(
          (item: any) => item.name === "Gross Margin"
        );

        const pjccProjectFullBidCosts = projectPjcc.find(
          (item: any) => item.name === "Total Cost"
        );
        const pjccProjectPriceToClient = projectPjcc.find(
          (item: any) => item.name === "Estimated Price"
        );
        const pjccProjectLabor = projectPjcc.find(
          (item: any) => item.name === "Total Labor"
        );
        const pjccProjectwageIndex = projectPjcc.findIndex(
          (item: any) => item.name === "Average Wage"
        );
        let priceToClient = pjccProjectPriceToClient.totalAmount;
        let dollar = 0;

        const expressionDiscount = calculateExpressionDiscount(
          project,
          fullBidRevenue
        );
        if (projectInfo?.discounts?.length) {
          for (const item of projectInfo.discounts) {
            if (item.amountType === "percentage") {
              priceToClient -= priceToClient * (item.amount / 100);
            }
            if (item.amountType === "dollar") {
              priceToClient -= item.amount;
            }
          }
        }

        // priceToClient = priceToClient - dollar;
        projectPjcc[pjccProjectwageIndex].cost = customToFixed(
          pjccProjectLabor.cost / pjccProjectTotalHours.cost,
          2
        );
        projectPjcc[pjccProjectwageIndex].totalAmount = customToFixed(
          pjccProjectLabor.amount / pjccProjectTotalHours.amount,
          2
        );
        projectPjcc[grossMarginIndex].cost = customToFixed(
          priceToClient - projectPjcc[totalCostIndex].cost,
          2
        );
        projectPjcc[grossMarginIndex].amount = customToFixed(
          priceToClient - projectPjcc[totalCostIndex].cost,
          2
        );
        projectPjcc[grossMarginIndex].totalAmount = customToFixed(
          priceToClient - projectPjcc[totalCostIndex].cost,
          2
        );

        const projectMargin =
          (1 - pjccProjectFullBidCosts.cost / priceToClient) * 100;

        let grossProfit =
          pjccProjectGrossMargin?.totalAmount /
          pjccProjectTotalHours?.totalAmount;
        grossProfit = isFinite(grossProfit) ? grossProfit : 0;

        projectPjcc.push(
          {
            project: projectInfo._id,
            name: "Gross Profit $ p/hr",
            totalAmount: customToFixed(
              pjccProjectGrossMargin.totalAmount === 0
                ? 0
                : !isNaN(grossProfit)
                  ? (grossProfit ?? 0)
                  : 0,
              2
            ),
            index: index++,
          },
          {
            project: projectInfo._id,
            name: "Project Costs",
            cost: pjccProjectFullBidCosts.cost,
            index: index++,
          },
          {
            project: projectInfo._id,
            name: "Project Margin",
            totalAmount: customToFixed(
              pjccProjectFullBidCosts.cost === 0
                ? 0
                : !isNaN(projectMargin)
                  ? (projectMargin ?? 0)
                  : 0,
              2
            ),
            index: index++,
          },
          {
            project: projectInfo._id,
            name: "Price to Client",
            totalAmount: customToFixed(priceToClient, 2),
            index: index++,
          },
          {
            project: projectInfo._id,
            name: "Full Bid Revenue",
            cost: fullBidCost,
            amount: customToFixed(fullBidRevenue?.costGm, 2),
            totalAmount: customToFixed(fullBidRevenue?.costGm, 2),
            margin: customToFixed(
              fullBidRevenue.grossMargin[fullBidRevenue.index]?.margin,
              2
            ),
          }
        );
      }
    }
    if (projectInfo.productionPjcc?.length > 0) {
      for (const item of projectPjcc) {
        let productionPjccEntity = projectInfo.productionPjcc.find(
          (entity: any) => entity.name === item.name
        );
        if (productionPjccEntity) {
          //Not covered in test case because productionPjccEntity.productionTargets will be 0 on option create and cannot be null
          item.productionTargets =
            productionPjccEntity.productionTargets === null
              ? item.productionTargets
              : productionPjccEntity.productionTargets;
          item.workOrder = productionPjccEntity.appearOnWorkOrder;
        }
      }
    }

    //setting Values to Project

    const updatedProjectDto = projectPjcc.map((item: any) => {
      return {
        ...item,
        cost: customToFixed(item.cost, 2),
        amount: customToFixed(item.amount, 2),
        totalAmount: customToFixed(item.totalAmount, 2),
        productionTargets: item.productionTargets,
      };
    });
    project.pjcc = updatedProjectDto;

    const projectPjccPaintLabor = projectPjcc.filter((item: any) => {
      return item.name === "Paint Labor";
    });

    let paintWorkOrderArray = [];

    for (const singleOption of [...project.options]?.sort(
      (a: any, b: any) => a.optionInfo.position - b.optionInfo.position
    )) {
      // const optionWashRates: any = washRates.filter((item: any) => {
      //   return item.option === singleOption.id;
      // });
      if (singleOption?.optionInfo?.included === "included") {
        let totalWashHours = 0;
        if (
          singleOption.rates.washRates.length > 0 &&
          singleOption.optionInfo.washIncluded === true
        ) {
          totalWashHours = singleOption.rates.washRates[0].totalHours;
        }

        // let singleOptionPaintRates = paintRates.filter((item: any) => {
        //   return item.option == singleOption.id;
        // });

        const singleOptionPaintRates = singleOption.rates.paintRates;

        //paintRateId
        let singleOptionPaintRateId: any = [];

        let prepHoursSum = 0;
        let paintHoursSum = 0;
        let primeHoursSum = 0;
        for (const item of singleOptionPaintRates) {
          if (!item.isDeleted) {
            prepHoursSum = prepHoursSum + item?.prepHours;
            paintHoursSum = paintHoursSum + item?.paintHours;
            primeHoursSum = primeHoursSum + item?.primeHours;
            let paintManufacturer = item.paintMaterial?.manufacturer ?? "";
            let paintProduct = item.paintMaterial?.product ?? "";
            let paintSheen = item.paintMaterial?.sheen ?? "";

            let paintMsg = "";
            if (paintManufacturer || paintProduct) {
              paintMsg = `${paintManufacturer}:${paintProduct}:${paintSheen}`;
            }
            let primerManufacturer = item.primerMaterial?.manufacturer ?? "";
            let primerProduct = item.primerMaterial?.product ?? "";

            let primerSheen = item.primerMaterial?.sheen ?? "";
            let primerMsg = "";
            if (primerManufacturer || primerProduct) {
              primerMsg = `${primerManufacturer}:${primerProduct}:${primerSheen}`;
            }
            singleOptionPaintRateId.push({
              item: item?.item,
              showLaborRates: item?.showLaborRates,
              paintMaterial: paintMsg,
              color: item?.color,
              coats: item?.coats,
              paintGallons: isNaN(item.paintGallons) ? 0 : item.paintGallons,
              primerMaterial: primerMsg,
              primerGallons: isNaN(item.primerGallons) ? 0 : item.primerGallons,
              primerCoats: item?.primeSelect,
            });
          }
        }

        let productionTargetsDivideByCost =
          projectPjccPaintLabor[0].productionTargets /
          projectPjccPaintLabor[0].cost;

        //Wash Hours
        let singleOptionWashHours =
          totalWashHours *
          productionTargetsDivideByCost *
          singleOption.optionInfo.buildingQuantity;
        //Setup
        let setup = (paintHoursSum + totalWashHours) * projectInfo.setupConst;
        if (singleOption?.optionInfo?.overRideSetupHours) {
          setup = singleOption.optionInfo.overRideSetupHours;
        }
        //Prep Hours
        let singleOptionPrepHours =
          (prepHoursSum + setup) *
          singleOption.optionInfo.buildingQuantity *
          (projectPjccPaintLabor[0].productionTargets /
            projectPjccPaintLabor[0].cost);
        //Paint Hours
        let singleOptionPaintHours =
          (paintHoursSum + primeHoursSum) *
          (projectPjccPaintLabor[0].productionTargets /
            projectPjccPaintLabor[0].cost) *
          singleOption.optionInfo.buildingQuantity;
        //Paint Labor
        let optionPjccPaintLabor = paintLaborOptionPjcc.filter((item) => {
          return item.option === singleOption.optionInfo._id;
        });

        let singleOptionPaintLabor =
          (optionPjccPaintLabor[0].cost / projectPjccPaintLabor[0].cost) *
          projectPjccPaintLabor[0].productionTargets;

        let paintWorkOrder = {
          // _id: crypto.randomBytes(12).toString("hex"),
          project: projectInfo._id,
          item: singleOption.optionInfo.title,
          showLaborRates: singleOption.optionInfo?.showLaborRates,
          address: singleOption.optionInfo.address,
          workOrderNotes: singleOption.optionInfo.workOrderPaintNotes,
          washHours: isNaN(singleOptionWashHours) ? 0 : +singleOptionWashHours,
          prepHours: isNaN(singleOptionPrepHours) ? 0 : +singleOptionPrepHours,
          paintHours: isNaN(singleOptionPaintHours)
            ? 0
            : +singleOptionPaintHours,
          paintLabor: isNaN(singleOptionPaintLabor)
            ? 0
            : +singleOptionPaintLabor,
          paintRateId: isNaN(singleOptionPaintLabor)
            ? []
            : singleOptionPaintRateId,
          optionCreatedAt: singleOption.optionInfo.createdAt,
        };
        paintWorkOrderArray.push(paintWorkOrder);
      }
    }

    //setting Values to Project
    project.paintWorkOrder = paintWorkOrderArray;

    const projectPjccCarpentryLabor = projectPjcc.filter((item: any) => {
      return item.name === "Carpentry Labor";
    });

    let carpentryWorkOrderArray = [];
    for (const singleOption of [...project.options]?.sort(
      (a: any, b: any) => a.optionInfo.position - b.optionInfo.position
    )) {
      // let singleOptionCarpentryRates = carpentryRates.filter((item: any) => {
      //   return item.option == singleOption.id;
      // });

      if (singleOption?.optionInfo?.included === "included") {
        let singleOptionCarpentryRates = singleOption.rates.carpentryRates;
        let optionPjccCarpentryLabor = carpentryLaborOptionPjcc.filter(
          (item) => {
            return item.option === singleOption.optionInfo._id;
          }
        );
        let optionPjccCarpentryHours = carpentryHoursOptionPjcc.filter(
          (item) => {
            return item.option === singleOption.optionInfo._id;
          }
        );
        let singleOptionCarpentryHours =
          optionPjccCarpentryHours[0].totalAmount *
          (projectPjccCarpentryLabor[0].productionTargets /
            projectPjccCarpentryLabor[0].cost) *
          singleOption.optionInfo.buildingQuantity;

        let singleOptionCarpentryLabor =
          optionPjccCarpentryLabor[0].cost *
          (projectPjccCarpentryLabor[0].productionTargets /
            projectPjccCarpentryLabor[0].cost);

        let singleOptionCarpentryRateId = [];
        for (const item of singleOptionCarpentryRates) {
          if (item.count !== 0) {
            singleOptionCarpentryRateId.push({
              item: item.item,
              count: item.count,
            });
          }
        }

        let carpentryWorkOrder = {
          // _id: crypto.randomBytes(12).toString("hex"),
          project: projectInfo._id,
          buildingName: singleOption.optionInfo.title,
          numberOfType: isNaN(singleOption.optionInfo.buildingQuantity)
            ? 1
            : singleOption.optionInfo.buildingQuantity,
          carpentryHours: isNaN(singleOptionCarpentryHours)
            ? 0
            : +singleOptionCarpentryHours,
          carpentryLabor: isNaN(singleOptionCarpentryLabor)
            ? 0
            : +singleOptionCarpentryLabor,
          carpentryRateId: isNaN(singleOptionCarpentryLabor)
            ? []
            : singleOptionCarpentryRateId,
          optionCreatedAt: singleOption.optionInfo.createdAt,
          workOrderNotes: singleOption.optionInfo.workOrderCarpentryNotes,
        };
        carpentryWorkOrderArray.push(carpentryWorkOrder);
      }
    }

    //setting Values to Project
    project.carpentryWorkOrder = carpentryWorkOrderArray;

    const address = `${projectInfo.clientName}, ${projectInfo.streetAddressOne
      }, ${projectInfo.streetAddressTwo ? projectInfo.streetAddressTwo + ", " : ""
      }${projectInfo.city}, ${projectInfo.state}, ${projectInfo.zip}, ${projectInfo.country
      }`;

    let paintMaterials: any = {};
    let primerMaterials: any = {};
    for (const option of paintWorkOrderArray) {
      for (const paintRate of option.paintRateId) {
        let paintKey = `${paintRate.paintMaterial},${paintRate.color}`;
        if (paintMaterials[paintKey]) {
          paintMaterials[paintKey] += paintRate.paintGallons;
        } else {
          paintMaterials[paintKey] = paintRate.paintGallons;
        }
        let primerKey = `${paintRate.primerMaterial}`;
        if (primerMaterials[primerKey]) {
          primerMaterials[primerKey] += paintRate.primerGallons;
        } else {
          primerMaterials[primerKey] = paintRate.primerGallons;
        }
      }
    }

    let paintInfo = [];
    let paintKeys: any = Object.entries(paintMaterials);
    for (const item of paintKeys) {
      let newKeys = item[0].split(",");
      if (newKeys[0] !== "") {
        paintInfo.push({
          product: newKeys[0],
          color: newKeys[1],
          paintGallons: Math.ceil(item[1]),
          orderPaintGallons: 0,
        });
      }
    }

    let vendorName = paintInfo[0]?.product?.split(":")[0];
    let foundObj = adminDefaults.vendorDefaults.find((obj: any) => {
      return obj.prefix.includes(vendorName);
    });
    // let vendor;
    // if (vendorName === "SW") {
    //   vendor = adminDefaults.vendorDefaults.find(
    //     (item: any) => item.vendorName === "SW"
    //   );
    // } else {
    //   vendor = adminDefaults.vendorDefaults.find(
    //     (item: any) => item.vendorName === "PPG"
    //   );
    // }

    let primerInfo = [];
    let primerKeys: any = Object.entries(primerMaterials);
    for (const item of primerKeys) {
      if (item[0] !== "") {
        primerInfo.push({
          primer: item[0],
          primeGallons: Math.ceil(item[1]),
          orderPrimeGallons: 0,
        });
      }
    }

    const paintOrder = {
      project: projectInfo._id,
      accountNumber: foundObj?.accountNumber,
      jobSiteAddress: address,
      // prc: prePresentationChecklist?.activePrcDescription || '',
      paintInfo: paintInfo,
      primerInfo: primerInfo,
    };

    //setting Values to Project
    project.paintOrder = paintOrder;

    let carpentryMaterial: any = {};
    for (const option of carpentryWorkOrderArray) {
      for (const carpentryRate of option.carpentryRateId) {
        let carpentryKey = `${carpentryRate.item}`;
        if (carpentryMaterial[carpentryKey]) {
          carpentryMaterial[carpentryKey] += carpentryRate.count;
        } else {
          carpentryMaterial[carpentryKey] = carpentryRate.count;
        }
      }
    }

    let productInfo = [];
    let keys = Object.entries(carpentryMaterial);
    for (const item of keys) {
      productInfo.push({ product: item[0], totalLfOrSf: item[1] });
    }

    const carpentryOrder = {
      project: projectInfo._id,
      productInfo: productInfo,
    };

    //setting Values to Project
    project.woodOrder = carpentryOrder;
    return project;
  }

  project.pjcc = [];
  project.paintWorkOrder = [];
  project.carpentryWorkOrder = [];
  project.paintOrder = {};
  project.woodOrder = {};
  project.projectInfo.productionPjcc = [];
  project.projectInfo.discounts = [];
  // project.projectInfo.overRideGrossMargin = [];

  return project;
};
