import moment from "moment";

export const columns = [
  {
    title: "Contractor",
    dataIndex: "contractorCompanyName",
    sorter: (a: any, b: any) => a?.contractorCompanyName?.localeCompare(b?.contractorCompanyName),
  },
  {
    title: "Contact Name",
    dataIndex: "fullName",
    sorter: (a: any, b: any) => a?.fullName?.localeCompare(b?.fullName),
  },
  {
    title: "Contact Email",
    dataIndex: "email",
  },
  {
    title: "Contact Number",
    dataIndex: "phone",
  },
  {
    title: "Subagreement Signed Date",
    dataIndex: "date",
    render: (data: any) => {
      return <div>{moment(data).format("MMMM YYYY")}</div>;
    },
  },
  {
    title: "Contract Uploaded",
    render: (data: any) => {
      return <>{data?.agreementKey ? "Yes" : "No"}</>;
    },
  },
];

export const contractorHeader = [
  {
    label: "contractorCompanyName",
    key: "contractorCompanyName"
  },
  {
    label: "fullName",
    key: "fullName",
  },
  {
    label: "email",
    key: "email",
  },
  {
    label: "phone",
    key: "phone",
  },
  {
    label: "date",
    key: "date",
  },
];
