import ProposalDefaultContainer from "../../containers/proposal-default-container/ProposalDefaultContainer";

function ProposalDefault () {
  return (
    <div>
      <ProposalDefaultContainer />
    </div>
  );
}

export default ProposalDefault ;
