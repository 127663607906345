import React, { useEffect, useState } from "react";
import { Table, Row, Col, Button, Input, message, Modal, Tooltip } from "antd";
import { paintOrderColumns } from "./columns";
import "../WorkOrderPaintC.less";
import { InvalidMediaIcon } from "../../../utils/icons";
import Description from "./Description";
import SelectDeliveryDate from "../../../components/data-time-card/SelectDeliveryDate";
import DeliveryNotes from "../../../components/delivery-notes/DeliveryNotes";
import DeliveryContact from "../../../components/delivery-contact/DeliveryContact";
import moment from "moment";
import api from "../../../utils/api";
import { useHistory, useParams } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import EmailModal from "../../email-template-modal/EmailModal";
import { LatoRegular } from "../../../assets/fonts/LatoRegular";
import { LatoBold } from "../../../assets/fonts/LatoBold";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { getAuthUser, getSelectedTenant } from "../../../utils/authToken";
import { API_BASE } from "../../../constant";
import authService from "../../../services/auth.service";
import { isNull } from "lodash";
import WarningErrorModal from "../../../components/warning-error-modal/WarningErrorModal";
import { setEditsFoundInPaintOrderBool } from "../../../redux/project/action";
var crypto = require("crypto");

type Props = {
  handleToggle: () => void;
  paintOrder: any;
  orderNo: number;
  paintMaterials: any;
  primerMaterials: any;
  closestStore?: any;
  jobSiteLocation?: any;
  handleUpdateState?: any;
  productionAssociateEmail?: any;
  setClosestStore?: any;
  users: any[];
  comments: string;
  setComments: any;
  deliveryNotes: string;
  setDeliveryNotes: any;
  oldDeliveryContact: any;
  oldOrderContact: any;
};

const OrderAmountCheck = (props: Props) => {
  const [deliveryAddress, setDeliveryAddress] = useState<any>("");
  const [role, setRole] = useState<any>("");
  const [paint, setPaint] = useState<any>([]);
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [deliveryOption, setDeliveryOption] = useState("delivery");
  const [vendor, setVendor] = useState<any>({});
  const [primer, setPrimer] = useState<any>([]);
  const [warningModelOpen, setWarningModelOpen] = useState(false);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [accountNumber, setAccountNumber] = useState<any>("");

  const [deliveryContactName, setDeliveryContactName] = useState<string>("");
  const [deliveryContactPhone, setDeliveryContactPhone] = useState<string>("");
  const [deliveryContactEmail, setDeliveryContactEmail] = useState<string>("");
  const [orderContactName, setOrderContactName] = useState<string>("");
  const [orderContactPhone, setOrderContactPhone] = useState<string>("");
  const [orderContactEmail, setOrderContactEmail] = useState<string>("");

  const [selectedStore, setSelectedStore] = useState<any>({});
  const [showModal, setShowModal] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailBody, setEmailBody] = useState<string>("");
  const [emailRecipients, setEmailRecipients] = useState<string>("");
  const [placeOrderButtonLoading, setPlaceOrderButtonLoading] =
    useState<boolean>(false);

  const { items, currentProject, adminDefaults, warningModalIsOpen } =
    useSelector((state: RootStateOrAny) => state.offlineData);
  const [editsFound, setEditsFound] = useState(false);
  const [pdfForPPGEmail, setPdfForPPGEmail] = useState<any>();
  const user = getAuthUser();
  const dispatch = useDispatch();

  useEffect(() => {
    setPaint(props.paintMaterials ? props.paintMaterials : []);
    setPrimer(props.primerMaterials ? props.primerMaterials : []);
  }, [props.paintMaterials, props.primerMaterials]);

  useEffect(() => {
    setAccountNumber(props?.paintOrder?.accountNumber);
    setSelectedStore(props?.closestStore);
    const selectedVendor = adminDefaults.vendorDefaults?.find(
      (item: any) => item.accountNumber === props?.paintOrder?.accountNumber,
    );
    setVendor(selectedVendor);
    setDeliveryAddress({
      clientName: items[currentProject]?.projectInfo?.clientName,
      streetAddressOne: items[currentProject]?.projectInfo?.streetAddressOne,
      streetAddressTwo: items[currentProject]?.projectInfo?.streetAddressTwo,
      city: items[currentProject]?.projectInfo?.city,
      state: items[currentProject]?.projectInfo?.state,
      zip: items[currentProject]?.projectInfo?.zip,
      country: items[currentProject]?.projectInfo?.country,
    });
  }, []);

  useEffect(() => {
    let filterPaint = paint?.filter((item: any) => {
      return item.orderPaintGallons > 0;
    });
    let filterPrimer = primer?.filter((item: any) => {
      return item.orderPrimeGallons > 0;
    });
    if (
      filterPaint?.length > 0 ||
      filterPrimer?.length > 0 ||
      props.deliveryNotes !== "" ||
      deliveryContactName !== "" ||
      deliveryContactPhone !== "" ||
      deliveryContactEmail !== "" ||
      orderContactName !== "" ||
      orderContactPhone !== "" ||
      orderContactEmail !== "" ||
      date !== undefined ||
      time !== undefined
    ) {
      setEditsFound(true);
      dispatch(setEditsFoundInPaintOrderBool(true));
    }
  }, [
    paint,
    primer,
    props.deliveryNotes,
    deliveryContactName,
    deliveryContactPhone,
    deliveryContactEmail,
    orderContactName,
    orderContactPhone,
    orderContactEmail,
    date,
    time,
  ]);

  const addDelivery = (data: any) => {
    props.setDeliveryNotes(data);
  };

  const addDeliveryContactName = (data: any) => {
    setDeliveryContactName(data);
  };

  const addDeliveryPhone = (data: any) => {
    setDeliveryContactPhone(data);
  };

  const addDeliveryEmail = (data: any) => {
    setDeliveryContactEmail(data);
  };

  const addOrderContact = (data: any) => {
    setOrderContactName(data);
  };

  const handleClosestStoreChange = (val: any) => {
    props.setClosestStore(val);
  };

  const addOrderPhone = (data: any) => {
    setOrderContactPhone(data);
  };

  const addOrderEmail = (data: any) => {
    setOrderContactEmail(data);
  };

  const onDateChange = (date: any) => {
    setDate(date);
  };
  const onTimeChange = (time: any) => {
    setTime(time);
  };

  const onPickUpChange = (e: any) => {
    e.target.value === "pickUp"
      ? setDeliveryAddress({})
      : setDeliveryAddress({
          clientName: items[currentProject]?.projectInfo?.clientName,
          streetAddressOne:
            items[currentProject]?.projectInfo?.streetAddressOne,
          streetAddressTwo:
            items[currentProject]?.projectInfo?.streetAddressTwo,
          city: items[currentProject]?.projectInfo?.city,
          state: items[currentProject]?.projectInfo?.state,
          zip: items[currentProject]?.projectInfo?.zip,
          country: items[currentProject]?.projectInfo?.country,
        });
    setDeliveryOption(e.target.value);
  };

  const handleDeleteNotes = (index: number) => {
    const data = [...props.deliveryNotes];
    data.splice(index, 1);
    props.setDeliveryNotes(data);
  };
  const handleDeleteComments = (index: number) => {
    const data = [...props.comments];
    data.splice(index, 1);
    props.setComments(data);
  };

  const addComments = (data: any) => {
    props.setComments(data);
  };

  const handlePaintGallons = (e: any, index: number) => {
    let newData: any = [...paint?.filter((item: any) => item.paintGallons)];
    newData[index].orderPaintGallons = +e.target.value;
    setPaint(newData);
  };

  const handlePrimerGallons = (e: any, index: number) => {
    const newData: any = [...primer?.filter((item: any) => item.primeGallons)];
    newData[index].orderPrimeGallons = +e.target.value;
    setPrimer(newData);
  };

  const generateOrderPdf = async () => {
    const horizontalMargin = 30;
    const primerColumns = [
      { header: "Primer", dataKey: "primer" },
      { header: "Ordered/Estimated Gallons", dataKey: "orderedActualGallons" },
      { header: "Order Gallons", dataKey: "orderPrimeGallons" },
    ];
    const paintColumns = [
      { header: "Product", dataKey: "product" },
      { header: "Color", dataKey: "color" },
      { header: "Ordered/Estimated Gallons", dataKey: "orderedActualGallons" },
      { header: "Order Gallons", dataKey: "orderPaintGallons" },
    ];

    const filteredPrimerData = primer.filter((item: any) => {
      const productName = item.primer.split(":")[0].trim();
      if (vendor.vendorName === "other") {
        return item.orderPrimeGallons > 0;
      } else {
        return (
          vendor.prefix.some((prefix: string) =>
            productName.startsWith(prefix),
          ) && item.orderPrimeGallons > 0
        );
      }
    });

    const primerTableDataPdf = filteredPrimerData.map((item: any) => {
      return {
        ...item,
        orderedActualGallons: `${item.orderedGallons}/${item.primeGallons}`,
      };
    });

    const filteredPaintData = paint.filter((item: any) => {
      const productName = item.product.split(":")[0].trim();
      if (vendor.vendorName === "other") {
        return item.orderPaintGallons > 0;
      } else {
        return (
          vendor.prefix.some((prefix: string) =>
            productName.startsWith(prefix),
          ) && item.orderPaintGallons > 0
        );
      }
    });

    const paintTableDataPdf = filteredPaintData.map((item: any) => {
      let colorObj = adminDefaults.colors.find(
        (adminColor: any) => adminColor.name === item.color,
      );
      return {
        ...item,
        orderedActualGallons: `${item.orderedGallons}/${item.paintGallons}`,
        colorCode: colorObj?.hexCode,
        color: `      ${item.color}`,
      };
    });

    var doc = new jsPDF("p", "pt");

    doc.addFileToVFS("Lato-Regular.ttf", LatoRegular);
    doc.addFont("Lato-Regular.ttf", "LatoRegular", "normal");
    doc.addFileToVFS("Lato-Bold.ttf", LatoBold);
    doc.addFont("Lato-Bold.ttf", "LatoRegular", "bold");

    let image = new Image();
    image.src = adminDefaults?.companyInfo.companyLogo;

    const imageAspectRatio = image.naturalWidth / image.naturalHeight;
    const opWidth = 200;
    doc.addImage(image, "png", 365, 25, opWidth, opWidth / 2.8, "logo", "FAST");
    doc.setFontSize(30);
    doc.text(`Paint Order ${props?.orderNo + 1}`, horizontalMargin, 60);
    doc.setTextColor("black");

    doc.setFont("LatoRegular", "normal");
    doc.setFontSize(8);

    let tableEndYPos = 120;
    const defaultDrawColor = doc.getDrawColor();
    doc.setDrawColor("#E5E4E2");
    doc.rect(horizontalMargin, tableEndYPos, 370, 100);

    //Date and Time Rect
    doc.rect(horizontalMargin + 380, tableEndYPos, 155, 100);
    let deliveryDateTimeRectStart = horizontalMargin + 380;
    let deliveryDateAndTimeBoxEndPos = tableEndYPos;
    deliveryDateAndTimeBoxEndPos += 30;
    doc.setFontSize(10);
    doc.text(
      `${deliveryOption === "delivery" ? "Delivery" : "Pick-Up"} Date & Time`,
      deliveryDateTimeRectStart + 30,
      deliveryDateAndTimeBoxEndPos,
    );
    deliveryDateAndTimeBoxEndPos += 10;
    doc.text(
      moment(date).format("MM-DD-YYYY"),
      deliveryDateTimeRectStart + 23,
      deliveryDateAndTimeBoxEndPos + 11,
    );
    doc.text(
      moment(time).format("hh:mm A"),
      deliveryDateTimeRectStart + 88,
      deliveryDateAndTimeBoxEndPos + 11,
    );
    deliveryDateAndTimeBoxEndPos += 20;
    doc.setFontSize(8);

    tableEndYPos += 20;

    doc.setDrawColor(defaultDrawColor);

    // doc.text("Building Name", horizontalMargin, tableEndYPos);
    // doc.text(option?.buildingName, 150 + horizontalMargin, tableEndYPos);
    doc.text("Account Number:", horizontalMargin + 10, tableEndYPos);
    doc.text(accountNumber, 100 + horizontalMargin, tableEndYPos);
    doc.text("Store Phone:", horizontalMargin + 220, tableEndYPos);
    doc.text(
      items[currentProject]?.projectInfo?.jobNumber,
      290 + horizontalMargin,
      tableEndYPos,
    );
    tableEndYPos += 10;
    doc.text("PO:", horizontalMargin + 10, tableEndYPos);
    doc.text(
      items[currentProject]?.projectInfo?.jobNumber,
      100 + horizontalMargin,
      tableEndYPos,
    );
    doc.text("PRC:", horizontalMargin + 220, tableEndYPos);
    doc.text(
      props.paintOrder?.activePrcDescription || " ",
      290 + horizontalMargin,
      tableEndYPos,
    );
    tableEndYPos += 15;
    if (deliveryOption === "delivery") {
      const deliveryAddressNew = [
        deliveryAddress?.clientName,
        deliveryAddress?.streetAddressOne,
        deliveryAddress?.city,
        deliveryAddress?.state,
        deliveryAddress?.zip,
        deliveryAddress?.country,
      ]
        .filter(Boolean)
        .join(", ");
      const splitDeliveryAddress = doc.splitTextToSize(deliveryAddressNew, 250);
      doc.text("Delivery Address:", horizontalMargin + 10, tableEndYPos);
      doc.text(splitDeliveryAddress, 100 + horizontalMargin, tableEndYPos);
      tableEndYPos += splitDeliveryAddress.length * 10;
    }
    doc.text("Closest Store Address:", horizontalMargin + 10, tableEndYPos);
    doc.text(
      Object.keys(props.closestStore).length > 0 ? props.closestStore.name : "",
      100 + horizontalMargin,
      tableEndYPos,
    );
    tableEndYPos += 10;
    doc.text("Store Number:", horizontalMargin + 10, tableEndYPos);
    doc.text(
      selectedStore?.number ? selectedStore?.number?.toString() : "",
      100 + horizontalMargin,
      tableEndYPos,
    );
    tableEndYPos += 10;
    doc.text("Vendor:", horizontalMargin + 10, tableEndYPos);
    doc.text(vendor?.vendorName, 100 + horizontalMargin, tableEndYPos);
    tableEndYPos += 10;

    tableEndYPos = 250;

    if (primerTableDataPdf.length > 0) {
      autoTable(doc, {
        body: primerTableDataPdf,
        columns: primerColumns,
        startY: tableEndYPos,
        margin: { horizontal: horizontalMargin },
        styles: {
          lineColor: "#585858",
          lineWidth: 0,
        },
        headStyles: {
          fillColor: "#FDB913",
          fontSize: 8,
          textColor: "black",
        },
        bodyStyles: { fontSize: 8, textColor: "black" },
        columnStyles: {
          1: {
            halign: "center",
            cellWidth: 80,
          },
          2: {
            halign: "center",
            cellWidth: 50,
          },
        },
        didDrawPage: (data) => {
          tableEndYPos = data.cursor?.y || tableEndYPos;
        },
        didDrawCell: (data) => {
          if (data.row.section !== "head") {
            if (data.row.index % 2 === 0) {
              data.cell.styles.fillColor = "#E2E2E2";
            } else {
              data.cell.styles.fillColor = "white";
            }
          }
        },
      });
      tableEndYPos += 20;
    }
    autoTable(doc, {
      body: paintTableDataPdf,
      columns: paintColumns,
      startY: tableEndYPos,
      margin: { horizontal: horizontalMargin },
      styles: {
        lineColor: "#585858",
        lineWidth: 0,
      },
      headStyles: {
        fillColor: "#FDB913",
        fontSize: 8,
        textColor: "black",
      },
      bodyStyles: { fillColor: "#FAF9F9", fontSize: 8, textColor: "black" },
      columnStyles: {
        1: {
          cellWidth: 185,
        },
        2: {
          halign: "center",
          cellWidth: 80,
        },
        3: {
          halign: "center",
          cellWidth: 50,
        },
      },
      // eslint-disable-next-line no-loop-func
      didDrawPage: (data) => {
        tableEndYPos = data.cursor?.y || tableEndYPos;
      },
      didDrawCell: (data) => {
        if (data.row.section !== "head") {
          if (data.row.index % 2 === 0) {
            data.cell.styles.fillColor = "#E2E2E2";
          } else {
            data.cell.styles.fillColor = "#FFFFFF";
          }
        }
        if (data.row.section !== "head") {
          if (data.row.index >= 0) {
            let xPos = data.cursor ? data.cursor.x : 0;
            let yPos = data.cursor ? data.cursor.y : 0;
            let startYColor = 5;
            if (paintTableDataPdf[data.row.index].lines >= 2) {
              startYColor = 14;
            }
            if (
              data.column.dataKey === "color" &&
              paintTableDataPdf[data.row.index]?.colorCode
            ) {
              let fillColor = doc.getFillColor();
              let drawColor = doc.getDrawColor();
              doc.setDrawColor(0, 0, 0);
              doc.setFillColor(paintTableDataPdf[data.row.index].colorCode);
              doc.rect(xPos + 5, yPos + startYColor, 9, 9, "F");
              doc.setFillColor(fillColor);
              doc.setDrawColor(drawColor);
            }
          }
        }
      },
    });
    tableEndYPos += 20;
    doc.setDrawColor("#E5E4E2");

    //Delivery Information
    if (deliveryOption === "delivery") {
      let deliveryRectStartY = tableEndYPos;
      let deliveryRectStartX = horizontalMargin;
      const splitDeliveryNotes = doc.splitTextToSize(
        props.deliveryNotes || "",
        240,
      );
      let deliveryBoxHeight = 80;
      if (splitDeliveryNotes.length > 4) {
        //Because 80 suits the as box size for Contact information.
        //Also it looks good if there are 4 lines of comments
        deliveryBoxHeight += (splitDeliveryNotes.length - 4) * 10;
      }
      doc.rect(horizontalMargin, tableEndYPos, 260, deliveryBoxHeight);
      doc.setFont("LatoRegular", "bold");
      doc.setFontSize(10);
      doc.text(
        "Delivery Notes",
        deliveryRectStartX + 10,
        deliveryRectStartY + 17,
      );
      doc.setFont("LatoRegular", "normal");
      doc.setFontSize(8);
      doc.text(
        splitDeliveryNotes,
        deliveryRectStartX + 10,
        deliveryRectStartY + 35,
      );

      doc.rect(horizontalMargin + 275, tableEndYPos, 260, 80);
      let deliveryContactRectStartX = horizontalMargin + 275;
      let deliveryContactRectStartY = tableEndYPos;
      doc.setFont("LatoRegular", "bold");
      doc.setFontSize(10);
      doc.text(
        "Delivery Contact",
        deliveryContactRectStartX + 10,
        deliveryContactRectStartY + 17,
      );
      doc.setFont("LatoRegular", "normal");
      doc.setFontSize(8);
      doc.text(
        "Delivery Contact:",
        deliveryContactRectStartX + 10,
        deliveryContactRectStartY + 35,
      );
      doc.text(
        deliveryContactName,
        deliveryContactRectStartX + 130,
        deliveryContactRectStartY + 35,
      );
      doc.text(
        "Phone Number:",
        deliveryContactRectStartX + 10,
        deliveryContactRectStartY + 50,
      );
      doc.text(
        deliveryContactPhone,
        deliveryContactRectStartX + 130,
        deliveryContactRectStartY + 50,
      );
      doc.text(
        "Email Address:",
        deliveryContactRectStartX + 10,
        deliveryContactRectStartY + 65,
      );
      doc.text(
        deliveryContactEmail,
        deliveryContactRectStartX + 130,
        deliveryContactRectStartY + 65,
      );

      tableEndYPos += deliveryBoxHeight + 20;
    }
    //Order Contact Information and Comments
    let commentsRectStartY = tableEndYPos;
    let commentsRectStartX = horizontalMargin;
    const splitComments = doc.splitTextToSize(props.comments || "", 240);
    let commentsBoxHeigt = 80;
    if (splitComments.length > 4) {
      //Because 80 is appropriate box size for Contact information.
      //Also it accommodates 4 lines of comments
      commentsBoxHeigt += (splitComments.length - 4) * 10;
    }
    doc.rect(horizontalMargin, tableEndYPos, 260, commentsBoxHeigt);
    doc.setFont("LatoRegular", "bold");
    doc.setFontSize(10);
    doc.text("Comments", commentsRectStartX + 10, commentsRectStartY + 17);
    doc.setFont("LatoRegular", "normal");
    doc.setFontSize(8);
    doc.text(splitComments, commentsRectStartX + 10, commentsRectStartY + 35);

    doc.rect(horizontalMargin + 275, tableEndYPos, 260, 80);
    let orderContactRectStartX = horizontalMargin + 275;
    let orderContactRectStartY = tableEndYPos;
    doc.setFont("LatoRegular", "bold");
    doc.setFontSize(10);
    doc.text(
      "Order Contact",
      orderContactRectStartX + 10,
      orderContactRectStartY + 17,
    );
    doc.setFont("LatoRegular", "normal");
    doc.setFontSize(8);
    doc.text(
      "Order Contact:",
      orderContactRectStartX + 10,
      orderContactRectStartY + 35,
    );
    doc.text(
      orderContactName,
      orderContactRectStartX + 130,
      orderContactRectStartY + 35,
    );
    doc.text(
      "Phone Number:",
      orderContactRectStartX + 10,
      orderContactRectStartY + 50,
    );
    doc.text(
      orderContactPhone,
      orderContactRectStartX + 130,
      orderContactRectStartY + 50,
    );
    doc.text(
      "Email Address:",
      orderContactRectStartX + 10,
      orderContactRectStartY + 65,
    );
    doc.text(
      orderContactEmail,
      orderContactRectStartX + 130,
      orderContactRectStartY + 65,
    );

    doc.setDrawColor(defaultDrawColor);
    // doc.save("Paint Order.pdf");
    let blob = doc.output();
    return blob;
  };

  const submitOrder = async (data: any) => {
    let newPaint = [...paint];
    let newPrimer = [...primer];
    const filteredPaintData = newPaint.filter((item: any) => {
      const productName = item.product.split(":")[0].trim();
      if (vendor.vendorName === "other") {
        return item.orderPaintGallons > 0;
      } else {
        return (
          vendor.prefix.some((prefix: string) =>
            productName.startsWith(prefix),
          ) && item.orderPaintGallons > 0
        );
      }
    });
    const filteredPrimerData = newPrimer.filter((item: any) => {
      const productName = item.primer.split(":")[0].trim();
      if (vendor.vendorName === "other") {
        return item.orderPrimeGallons > 0;
      } else {
        return (
          vendor.prefix.some((prefix: string) =>
            productName.startsWith(prefix),
          ) && item.orderPrimeGallons > 0
        );
      }
    });
    let newItems = filterPaintOrderArr(filteredPaintData, filteredPrimerData);
    setPlaceOrderButtonLoading(true);
    let orderDate: any;
    if (date) {
      orderDate = moment(date).format("MMMM DD,YYYY");
    }
    if (time) {
      orderDate = orderDate + moment(time).format(", h:mm A");
    }

    let paintOrderBlob = await generateOrderPdf();
    setPdfForPPGEmail(paintOrderBlob);
    const dateObj = new Date(orderDate);
    const orderNumber = props.orderNo + 1;

    var placeOrderId = crypto.randomBytes(12).toString("hex");
    let body = {
      _id: placeOrderId,
      project: id,
      deliveryDateTime: dateObj ? moment(dateObj) : "",
      paintInfo: filteredPaintData,
      primerInfo: filteredPrimerData,
      orderNo: orderNumber,
      deliveryNotes: props.deliveryNotes,
      deliveryAddress: deliveryAddress,
      pickup: deliveryOption === "delivery" ? false : true,
      comments: props.comments,
      deliveryContact: {
        name: deliveryContactName,
        phoneNumber: deliveryContactPhone,
        emailAddress: deliveryContactEmail,
      },
      orderContact: {
        name: orderContactName,
        phoneNumber: orderContactPhone,
        emailAddress: orderContactEmail,
      },
      store: selectedStore,
      accountNumber: accountNumber,
      storeNumber: selectedStore?.number,
      poNumber: items[currentProject]?.projectInfo?.jobNumber,
      jobName: items[currentProject]?.projectInfo?.jobName,
      completeAddress: {
        Name: deliveryAddress?.clientName,
        StreetAddress1: deliveryAddress?.streetAddressOne,
        StreetAddress2: deliveryAddress?.streetAddressTwo,
        City: deliveryAddress?.city,
        State: deliveryAddress?.state,
        ZIP: deliveryAddress?.zip,
        Country: deliveryAddress?.country,
      },
      items: newItems,
      isDelivery: deliveryOption === "delivery" ? true : false,
      vendor: vendor ? vendor.vendorName : "",
      paintOrderBlob,
      emailBody: data?.emailBody,
      emailRecipients: data?.emailRecipients,
      emailSubject: data?.emailSubject,
      projectName: items[currentProject]?.projectInfo?.jobName,
      createdAt: moment(Date.now()),
    };

    const user = getAuthUser();
    const tenantId = getSelectedTenant();

    axios
      .post(`${API_BASE}place-order/create-order/${id}`, body, {
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "tenant-id": !isNull(tenantId) ? tenantId : "",
        },
      })
      .then((response: any) => {
        setPaint([]);
        setPrimer([]);
        message.success("Order Placed");
        props.handleUpdateState(response.data);
      })
      .catch((err: any) => {
        // message.error(err.response.data.message);
        setErrorMessage(err?.response?.data?.message);
        setShowErrorModal(true);
      })
      .finally(() => {
        setPlaceOrderButtonLoading(false);
      });
  };

  const handleSendEmail = (body: any) => {
    // submitOrderTest();
    submitOrder(body);
    setShowModal(false);
    setShowEmailModal(false);
  };

  const handleSendPPGEmail = async (data: any) => {
    let paintOrderBlob = await pdfForPPGEmail;
    const orderNumber = props.orderNo + 1;
    const body = {
      ...data,
      orderNo: orderNumber,
      projectName: items[currentProject]?.projectInfo?.jobName,
      paintOrderBlob,
    };
    await api.post("place-order/send-ppg-order-mail", body);
    setRole("");
    if (placeOrderButtonLoading === false) {
      setShowModal(false);
      setShowEmailModal(false);
      dispatch(setEditsFoundInPaintOrderBool(false));
      props.handleToggle();
      setPdfForPPGEmail(null);
    }
  };

  const openEmailModal = () => {
    if (vendor?.vendorName.startsWith("PPG")) {
      // submitOrderTest();
      submitOrder({});

      setEmailSubject(
        `Paint Order - ${items[currentProject]?.projectInfo?.jobName}`,
      );
      setEmailBody(
        `Hey Team,\n\nI have just submitted this order through your Point of Sales System. Let me know if there are any questions. 
        \n\nThanks,\n${user.fullName}`,
      );
      setEmailRecipients(
        vendor?.repContact
          ? `${vendor?.repContact};${selectedStore?.email};${props?.productionAssociateEmail !== undefined ? props?.productionAssociateEmail : ""}`
          : "Enter Recipient Email",
      );
      setRole("PPG");
      setShowEmailModal(true);
    } else if (vendor?.vendorName === "other") {
      setEmailSubject(
        `Paint Order - ${items[currentProject]?.projectInfo?.jobName}`,
      );
      setEmailBody(
        `Sir,\n \nDelivery Date: ${moment(
          items[currentProject]?.projectInfo?.estimateScheduledDate,
        ).format(
          "MMMM DD,YYYY",
        )}. For more information please visit https://app.pro-driven.com/manager-preview-profile/${
          items[currentProject].projectInfo._id
        }. \n \nThanks, \n${user.fullName}`,
      );

      setEmailRecipients(
        `${props?.productionAssociateEmail !== undefined ? props?.productionAssociateEmail : ""}`,
      );
      setShowEmailModal(true);
    } else {
      setEmailSubject(
        `Paint Order - ${items[currentProject]?.projectInfo?.jobName}`,
      );
      const repName = vendor?.repName ? vendor?.repName : "";
      setEmailBody(
        `${repName},\n \nDelivery Date: ${moment(
          items[currentProject]?.projectInfo?.estimateScheduledDate,
        ).format(
          "MMMM DD,YYYY",
        )}. For more information please visit https://app.pro-driven.com/manager-preview-profile/${
          items[currentProject].projectInfo._id
        }.. \n \nThanks, \n${user.fullName}`,
      );

      setEmailRecipients(
        vendor?.repContact
          ? `${vendor?.repContact}; ${selectedStore?.email}; ${props?.productionAssociateEmail !== undefined ? props?.productionAssociateEmail : ""}`
          : "Enter Recipient Email",
      );
      setShowEmailModal(true);
    }
    setShowModal(false);
  };

  const handleOrder = () => {
    if (!date || !time) {
      message.error("Please Select Date and Time");
    } else if (
      deliveryOption === "delivery" &&
      (deliveryContactName === "" ||
        deliveryContactEmail === "" ||
        deliveryContactPhone === "")
    ) {
      message.error("Enter Complete Delivery Contact Info");
    } else if (
      orderContactName === "" ||
      orderContactEmail === "" ||
      orderContactPhone === ""
    ) {
      message.error("Enter Complete Order Contact Info");
    } else {
      let materialsObject = {
        paint: [...paint],
        primer: [...primer],
      };
      let itemsArray = [...paint];
      let paintLength = paint.length;
      let primerLength = primer.length;
      let min = paintLength < primerLength ? paintLength : primerLength;
      let max = paintLength > primerLength ? paintLength : primerLength;
      let paintGreater = paintLength < primerLength ? false : true;

      for (let i = 0; i < min; i++) {
        itemsArray.push({
          ...paint[i],
          ...primer[i],
        });
      }

      let orderGallons = 0;
      for (const element of materialsObject.paint) {
        orderGallons += isNaN(element.orderPaintGallons)
          ? 0
          : element.orderPaintGallons;
      }
      for (const element of materialsObject.primer) {
        orderGallons += isNaN(element.orderPrimeGallons)
          ? 0
          : element.orderPrimeGallons;
      }

      if (orderGallons === 0) {
        message.error("Please Add Order Gallons to Proceed");
      } else {
        setShowModal(true);
      }
    }
  };

  const filterPaintOrderArr = (paint: any, primer: any) => {
    let materialsObject = {
      paint: [...paint],
      primer: [...primer],
    };
    let itemsArray = [...paint];
    let paintLength = paint.length;
    let primerLength = primer.length;
    let min = paintLength < primerLength ? paintLength : primerLength;
    let max = paintLength > primerLength ? paintLength : primerLength;
    let paintGreater = paintLength < primerLength ? false : true;

    for (let i = 0; i < min; i++) {
      itemsArray.push({
        ...paint[i],
        ...primer[i],
      });
    }

    let orderGallons = 0;
    for (const element of materialsObject.paint) {
      orderGallons += isNaN(element.orderPaintGallons)
        ? 0
        : element.orderPaintGallons;
    }
    for (const element of materialsObject.primer) {
      orderGallons += isNaN(element.orderPrimeGallons)
        ? 0
        : element.orderPrimeGallons;
    }
    return materialsObject;
  };

  const newPaintColumns: any = [...paintOrderColumns];

  const newColumn = [
    paintOrderColumns[0],
    {
      title: (
        <>
          <Row>
            <Col>
              <div
                style={{
                  height: 20,
                  width: 20,
                  // marginTop:8,
                  marginRight: 20,
                }}
              />
            </Col>
            <Col>Color</Col>
          </Row>
        </>
      ),
      dataIndex: "color",
      width: "30%",
      render: (data: string) => {
        let response = adminDefaults.colors.find(
          (item: any) => item.name === data,
        );
        return (
          <>
            <Row style={{ marginTop: 15 }}>
              <Col>
                <div
                  style={{
                    background: response?.hexCode,
                    height: 20,
                    width: 20,
                    marginRight: 20,
                  }}
                />
              </Col>
              <Col>
                <p>{data}</p>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Ordered/Estimated Gallons",
      align: "center",
      width: "25%",
      render: (data: any) => {
        return (
          <p style={{ marginRight: 20, marginTop: 15 }}>
            {data?.orderedGallons}/{data?.paintGallons}
          </p>
        );
      },
    },
    {
      title: "Order Gallons",
      dataIndex: "paintGallons",
      width: "15%",
      render: (data: any, _: any, index: any) => {
        return (
          <Input
            type="number"
            onChange={(e: any) => handlePaintGallons(e, index)}
            defaultValue={0}
            style={{
              width: "80px",
            }}
          />
        );
      },
    },
    {
      title: "Warning",
      align: "center",
      width: "15%",
      render: (data: any) => {
        let message = "";
        if (data?.color === "TBD") {
          message = "Color is not selected";
        } else if (
          data?.orderPaintGallons >
          data?.paintGallons - data?.orderedGallons
        ) {
          message = "Order gallons are greater than remaining";
        }
        if (
          data?.color === "TBD" &&
          data?.orderPaintGallons > data?.paintGallons - data?.orderedGallons
        ) {
          message =
            "Color is not selected and order gallons are greater than remaining";
        }

        return (
          <>
            {message && (
              <>
                <Tooltip arrowPointAtCenter placement="topLeft" title={message}>
                  <InvalidMediaIcon
                    style={{
                      color: "#FDB913",
                      cursor: "pointer",
                      fontSize: 17,
                    }}
                  />
                </Tooltip>
              </>
            )}
          </>
        );
      },
    },
  ];

  newPaintColumns.splice(2, 1, {
    title: "Gallons",
    dataIndex: "paintGallons",
    render: (data: any, _: any, index: any) => {
      return (
        <Input
          type="number"
          onChange={(e: any) => handlePaintGallons(e, index)}
          value={data}
        />
      );
    },
  });

  const newPrimerColumns: any = [
    {
      title: "Primer",
      dataIndex: "primer",
      width: "60%",
      render: (data: string) => {
        return <p style={{ marginTop: 15 }}>{data}</p>;
      },
    },
    {
      title: "Ordered/Estimated Gallons",
      align: "center",
      width: "25%",
      render: (data: any) => {
        return (
          <p style={{ marginRight: 20, marginTop: 15 }}>
            {data?.orderedGallons}/{data?.primeGallons}
          </p>
        );
      },
    },
    {
      title: "Order Gallons",
      dataIndex: "primeGallons",
      width: "15%",
      render: (data: any, _: any, index: any) => {
        return (
          <Input
            type="number"
            onChange={(e: any) => handlePrimerGallons(e, index)}
            defaultValue={0}
            style={{
              width: "80px",
            }}
          />
        );
      },
    },
    {
      title: "Warning",
      align: "center",
      width: "15%",
      render: (data: any) => {
        let message = "";

        if (
          data?.orderPrimeGallons >
          data?.primeGallons - data?.orderedGallons
        ) {
          message = "Order gallons are greater than remaining";
        }

        return (
          <>
            {message && (
              <>
                <Tooltip arrowPointAtCenter placement="topLeft" title={message}>
                  <InvalidMediaIcon
                    style={{
                      color: "#FDB913",
                      cursor: "pointer",
                      fontSize: 17,
                    }}
                  />
                </Tooltip>
              </>
            )}
          </>
        );
      },
    },
  ];

  const newHeaderPaint: any = [
    {
      title: "Product",
      dataIndex: "product",
      width: "35%",
      render: (data: string) => {
        return <p style={{ marginTop: 15 }}>{data}</p>;
      },
    },
    {
      title: (
        <>
          <Row>
            <Col>
              <div
                style={{
                  height: 20,
                  width: 20,
                  // marginTop:8,
                  marginRight: 20,
                }}
              />
            </Col>
            <Col>Color</Col>
          </Row>
        </>
      ),
      dataIndex: "color",
      width: "40%",
      render: (data: string) => {
        let response = adminDefaults.colors.find(
          (item: any) => item.name === data,
        );
        return (
          <>
            <Row style={{ marginTop: 15 }}>
              <Col>
                <div
                  style={{
                    background: response?.hexCode,
                    height: 20,
                    width: 20,
                    marginRight: 20,
                  }}
                />
              </Col>
              <Col>
                <p>{data}</p>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: (
        <>
          <div>Estimated Gallons</div>
        </>
      ),
      dataIndex: "paintGallons",
      align: "center",
      width: "12%",
      render: (data: number) => {
        return (
          <p style={{ marginRight: 20, marginTop: 15 }}>{Math.ceil(data)}</p>
        );
      },
    },
    {
      title: "Order Gallons",
      align: "center",
      width: "12%",
      render: (data: any) => {
        return (
          <p style={{ marginRight: 20, marginTop: 15 }}>
            {data?.orderPaintGallons}
          </p>
        );
      },
    },
  ];

  const newHeaderPrimer: any = [
    {
      title: "Primer",
      dataIndex: "primer",
      with: "20%",
      render: (data: string) => {
        return <p style={{ marginTop: 15 }}>{data}</p>;
      },
    },
    {
      title: (
        <>
          <div>Estimated Gallons</div>
        </>
      ),
      dataIndex: "primeGallons",
      align: "center",
      width: "12%",
      render: (data: number) => {
        return <p style={{ marginRight: 20, marginTop: 15 }}>{data}</p>;
      },
    },
    {
      title: "Order Gallons",
      align: "center",
      width: "12%",
      render: (data: any) => {
        return (
          <p style={{ marginRight: 20, marginTop: 15 }}>
            {data?.orderPrimeGallons}
          </p>
        );
      },
    },
  ];
  const handleSetShowEmailModal = (cancel: boolean) => {
    setShowEmailModal(cancel);
    history.push(`/wood-order/${id}`);
  };

  return (
    <div>
      <WarningErrorModal
        title="Error Logs"
        showModal={showErrorModal}
        setShowModal={setShowErrorModal}
        message={errorMessage}
      />
      <EmailModal
        showModal={showEmailModal}
        setShowModal={handleSetShowEmailModal}
        emailSubject={emailSubject}
        emailBody={emailBody}
        emailRecipients={emailRecipients}
        sendEmail={handleSendEmail}
        sendPPGEmail={handleSendPPGEmail}
        role={role}
        loading={placeOrderButtonLoading}
      />
      <Modal
        className="notes-modal-container"
        visible={showModal}
        title={
          <h1 style={{ marginTop: 10 }} className="send-title-text">
            Place Order
          </h1>
        }
        onCancel={() => {
          setShowModal(false);
        }}
        width={"80%"}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setShowModal(false);
            }}
            style={{
              marginRight: 8,
              color: "#FDB913",
              border: "1px solid #fdb913",
            }}
          >
            Cancel
          </Button>,
          <Button
            style={{ color: "#1F1F1F" }}
            key="submit"
            type="primary"
            onClick={openEmailModal}
          >
            Ok
          </Button>,
        ]}
      >
        <div className="place-order-hover">
          <div>
            {primer.filter((item: any) => item.orderPrimeGallons > 0).length >
              0 && (
              <Table
                rowKey="_id"
                rowClassName={(record, index) => {
                  return vendor?.vendorName === "other"
                    ? ""
                    : vendor?.prefix?.some((str: string) =>
                          record.primer.startsWith(str),
                        ) && record?.orderPrimeGallons > 0
                      ? ""
                      : "table-row-red";
                }}
                columns={newHeaderPrimer}
                dataSource={primer.filter(
                  (item: any) => item.orderPrimeGallons > 0,
                )}
                pagination={false}
              />
            )}
          </div>
          <div>
            {paint.filter((item: any) => item.orderPaintGallons > 0).length >
              0 && (
              <Table
                rowKey="_id"
                rowClassName={(record, index) => {
                  return vendor?.vendorName === "other"
                    ? ""
                    : vendor?.prefix?.some((str: string) =>
                          record.product.startsWith(str),
                        ) && record?.orderPaintGallons > 0
                      ? ""
                      : "table-row-red";
                }}
                columns={newHeaderPaint}
                dataSource={paint.filter(
                  (item: any) => item.orderPaintGallons > 0,
                )}
                pagination={false}
              />
            )}
          </div>
        </div>
      </Modal>
      <div className="wo-paint-root">
        <h1 className="wop-title wop-header">Product Order</h1>
        <hr style={{ border: "1px solid #E8E8E8" }} />
        <Row gutter={15} style={{ padding: "30px 15px" }}>
          <Col span={17}>
            <Description
              deliveryAddress={deliveryAddress}
              paintOrder={props.paintOrder}
              setDeliveryAddress={setDeliveryAddress}
              pickUp={deliveryOption === "delivery" ? false : true}
              closestStore={props?.closestStore}
              selectedStore={selectedStore}
              setClosestStore={setSelectedStore}
              jobSiteLocation={props?.jobSiteLocation}
              accountNumber={accountNumber}
              setAccountNumber={setAccountNumber}
              setVendor={setVendor}
              closetStoreSetter={handleClosestStoreChange}
            />
          </Col>
          <Col span={7}>
            <SelectDeliveryDate
              onDateChange={onDateChange}
              onTimeChange={onTimeChange}
              pickUp={deliveryOption}
              onPickUpChange={onPickUpChange}
            />
          </Col>
        </Row>
        <div className="wo-paint-table">
          <div>
            <Table
              rowKey="_id"
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? "table_row table-row-light"
                  : "table_row table-row-dark"
              }
              columns={newPrimerColumns}
              dataSource={primer?.filter((item: any) => item.primeGallons)}
              pagination={false}
            />
          </div>
          <div>
            <Table
              scroll={{ x: 900 }}
              rowKey="_id"
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? "table_row table-row-light"
                  : "table_row table-row-dark"
              }
              columns={newColumn}
              dataSource={paint?.filter((item: any) => item.paintGallons)}
              pagination={false}
            />
          </div>
        </div>
      </div>
      {deliveryOption === "delivery" && (
        <Row gutter={40} style={{ marginTop: "40px" }}>
          <Col span={12}>
            <DeliveryNotes
              title="Delivery Notes"
              notes={props.deliveryNotes}
              addNotes={addDelivery}
              // handleDelete={handleDeleteNotes}
            />
          </Col>
          <Col span={12}>
            <DeliveryContact
              title="Delivery Contact"
              addContactName={addDeliveryContactName}
              addPhone={addDeliveryPhone}
              addEmail={addDeliveryEmail}
              users={props?.users}
              oldContact={props.oldDeliveryContact}
            />
          </Col>
        </Row>
      )}
      <Row gutter={40} style={{ marginTop: "40px" }}>
        <Col span={12}>
          <DeliveryNotes
            title="Comments"
            notes={props.comments}
            addNotes={addComments}
            // handleDelete={handleDeleteComments}
          />
        </Col>
        <Col span={12}>
          <DeliveryContact
            title="Order Contact"
            addContactName={addOrderContact}
            addPhone={addOrderPhone}
            addEmail={addOrderEmail}
            users={props?.users}
            oldContact={props.oldOrderContact}
          />
        </Col>
      </Row>
      <WarningErrorModal
        title="Warning!"
        showModal={warningModelOpen}
        setShowModal={setWarningModelOpen}
        message={`Are you sure you want to leave page? \nThe changes you have made to process order will not save.`}
        handleOK={() => {
          props.handleToggle();
          dispatch(setEditsFoundInPaintOrderBool(false));
          setWarningModelOpen(false);
        }}
      />
      <Row justify="center" style={{ marginTop: "50px" }}>
        <Col>
          <Row gutter={20} style={{ marginBottom: 20 }} justify="center">
            <Col>
              <Button
                onClick={() => {
                  if (editsFound) {
                    setWarningModelOpen(true);
                  } else {
                    props.handleToggle();
                  }
                }}
                type="primary"
                className="next-btn-po"
              >
                Back
              </Button>
            </Col>
            <Col>
              <Button
                onClick={handleOrder}
                className="initial-product-btn"
                type="primary"
                loading={placeOrderButtonLoading}
              >
                Place Order
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default OrderAmountCheck;
