import { Form, message, Popconfirm, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import ActionDropDown from './ActionDropDown';
import CarpentryEditable from './CarpentryEditable';
import { compareObjects } from '../../../utils/helpers';
import {
  removeServiceFromProject,
  bulkUpdateRates,
  addServiceToProject,
  addRatesToOptions,
  projectRateDeleteAndOptionRatesUpdate,
} from '../../../redux/project/action';
import _ from 'lodash';
import {
  deepEqualCheck,
  deepEqualCheckForClone,
} from '../../../utils/objectCheckers';
var crypto = require('crypto');

type Props = {
  rates: any;
  setTableValues: any;
  showExpandRows: any;
};
let carpentryClone: any;
const CarpentrySurfaces = (props: Props) => {
  const dispatch = useDispatch<any>();
  const [data, setData] = useState(props?.rates);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );
  useEffect(() => {
    const project = _.cloneDeep(items[currentProject]); // Deep copy to avoid mutations
    const hasOptionCarpentryRates = project.options.some(
      (option: any) => option.rates?.carpentryRates?.length > 0,
    );
    if (hasOptionCarpentryRates) {
      const carpentryLaborRates = [...props.rates];
      let laborRatesNotFoundInOption: string[] = [];

      // Collect all carpentry rate IDs from all options
      const allCarpentryRateIds = project.options?.flatMap((option: any) =>
        option?.rates?.carpentryRates?.map((carpentryRate: any) => {
          if (!carpentryRate.isDeleted) return carpentryRate.projectLaborRate;
        }),
      );

      // Filter rates that are not found in any of the carpentryRates
      laborRatesNotFoundInOption = carpentryLaborRates
        .filter((rate) => !allCarpentryRateIds.includes(rate._id))
        .map((rate) => rate._id);

      setData(
        props.rates.filter(
          (item: any) => !laborRatesNotFoundInOption.includes(item._id),
        ),
      );
    } else {
      setData(props.rates);
    }
  }, [props.rates]);
  const isEditing = (record: any) => record._id === editingKey;

  const cancel = () => {
    setEditingKey('');
  };

  const edit = (record: any) => {
    carpentryClone = { ...record };
    form.setFieldsValue({
      item: '',
      laborRate: '',
      materialRate: '',
      ...record,
    });
    setEditingKey(record._id);
  };

  const handleDelete = (id: string) => {
    let project: any = _.cloneDeep(items[currentProject]);
    let index = project.projectRates.carpentryRates.findIndex(
      (item: any) => item._id === id,
    );
    project.projectRates.carpentryRates[index].isDeleted = true;
    props.setTableValues({
      carpentryRates: project.projectRates.carpentryRates,
    });
    for (const singleOption of project.options) {
      let index = singleOption.rates.carpentryRates.findIndex(
        (item: any) => item.projectLaborRate === id,
      );
      if (index !== -1) {
        singleOption.rates.carpentryRates[index].isDeleted = true;
      }
      // singleOption.rates.carpentryRates.splice(index, 1);
    }
    dispatch(removeServiceFromProject(id, 'carpentry', project, adminDefaults));
    // api
    //   .delete(`rates/project/carpentry/${id}`)
    //   .then(() => {
    //     let newData = [...data];
    //     let index = newData.findIndex((item: any) => item._id === id);
    //     newData.splice(index, 1);
    //     props.setTableValues({ carpentryRates: newData });
    //   })
    //   .catch((err) => console.log(err));
  };

  const save = async (id: React.Key) => {
    try {
      const row = (await form.validateFields()) as any;

      const newData = [...data];

      const index = newData.findIndex((item) => id === item._id);

      if (index > -1) {
        const item = newData[index];

        const testData: any = { ...item, ...row };
        let updatedObject: any = {};
        for (const key in testData) {
          if (testData[key] !== carpentryClone[key]) {
            updatedObject[key] = testData[key];
          }
        }

        const body = {
          category: 'carpentry',
          rate: { ...updatedObject, modified: true },
          project: items[currentProject].projectId,
        };

        let project: any = _.cloneDeep(items[currentProject]);
        let projectCarpentryIndex =
          project.projectRates.carpentryRates.findIndex(
            (element: any) => element._id === item._id,
          );
        for (var key in updatedObject) {
          if (
            typeof project.projectRates.carpentryRates[projectCarpentryIndex][
              key
            ] === 'number'
          ) {
            project.projectRates.carpentryRates[projectCarpentryIndex][key] =
              +updatedObject[key];
          } else {
            project.projectRates.carpentryRates[projectCarpentryIndex][key] =
              updatedObject[key];
          }
        }
        project.projectRates.carpentryRates[projectCarpentryIndex].modified =
          true;
        console.log(
          'CHECKING UPDATE',
          project.projectRates.carpentryRates[projectCarpentryIndex].modified,
        );
        //some calculations will be required
        for (const singleOption of project.options) {
          let optionCarpentryIndex =
            singleOption.rates.carpentryRates.findIndex(
              (element: any) => element.projectLaborRate === item._id,
            );

          console.log(optionCarpentryIndex);

          for (var key in updatedObject) {
            if (optionCarpentryIndex > -1) {
              if (
                typeof singleOption.rates.carpentryRates[projectCarpentryIndex][
                  key
                ] === 'number'
              ) {
                singleOption.rates.carpentryRates[optionCarpentryIndex][key] =
                  +updatedObject[key];
              } else {
                singleOption.rates.carpentryRates[optionCarpentryIndex][key] =
                  updatedObject[key];
              }
            }
          }
          if (optionCarpentryIndex > -1) {
            singleOption.rates.carpentryRates[optionCarpentryIndex].modified =
              true;
          }
        }
        dispatch(bulkUpdateRates(body, item._id, project, adminDefaults));

        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleWhenEditedRateAlreadyExits = (
    rateThatExits: any,
    editedRate: any,
  ) => {
    console.log(rateThatExits, editedRate);
    let reduxObj = [];
    let project: any = _.cloneDeep(items[currentProject]);
    let optionRatesToBeDeletedIdArray = [];
    for (const singleOption of project.options) {
      const foundRateIndex = singleOption.rates.carpentryRates.findIndex(
        (item: any) => item.projectLaborRate === rateThatExits._id,
      );
      const foundEditedRateIndex = singleOption.rates.carpentryRates.findIndex(
        (item: any) => item.projectLaborRate === editedRate._id,
      );
      if (foundEditedRateIndex !== -1) {
        console.log(singleOption.rates.carpentryRates[foundEditedRateIndex]);
        singleOption.rates.carpentryRates[foundEditedRateIndex].isDeleted =
          true;
        optionRatesToBeDeletedIdArray.push(
          singleOption.rates.carpentryRates[foundEditedRateIndex]._id,
        );
      }
      if (foundRateIndex === -1) {
        let carpentryLaborRateConst = adminDefaults.wageDefaults.find(
          (item: any) => {
            return item.title === 'Carpentry Wage Rate';
          },
        );
        let count = 0;
        let carpentryMaterial = count * rateThatExits.materialRate;
        let carpentryHours =
          (1.08 * count * rateThatExits.laborRate) /
          carpentryLaborRateConst.wageValue;
        const projectLaborRate = rateThatExits._id;
        let _id = crypto.randomBytes(12).toString('hex');
        let newObj = {
          ...rateThatExits,
          _id,
          option: singleOption.optionInfo._id,
          projectLaborRate: projectLaborRate,
          count,
          carpentryMaterial,
          carpentryHours,
        };
        reduxObj.push(newObj);
        singleOption.rates.carpentryRates.push(...reduxObj);
      }
    }
    const editedRateIndexInProject =
      project.projectRates.carpentryRates.findIndex(
        (item: any) => item._id === editedRate._id,
      );
    console.log(editedRateIndexInProject);
    project.projectRates.carpentryRates[editedRateIndexInProject].isDeleted =
      true;

    // project.projectRates.carpentryRates.splice(editedRateIndexInProject, 1);
    const body = {
      category: 'carpentry',
      projectRateToBeDeletedId: editedRate._id,
      optionRate: reduxObj,
      optionRatesToBeDeletedIdArray,
      project: project.projectId,
    };
    console.log(body, project);
    dispatch(
      projectRateDeleteAndOptionRatesUpdate(
        body,
        project.projectId,
        project,
        adminDefaults,
      ),
    );
  };

  const newOnSave = async (id: string) => {
    const row = (await form.validateFields()) as any;
    const newData = [...data];
    const index = newData.findIndex((item) => id === item._id);
    if (index > -1) {
      const item = newData[index];
      console.log(item);
      let updatedObject: any = { ...item, ...row };
      if (compareObjects(row, item)) {
        setEditingKey('');
        return;
      }
      updatedObject.modified = true;
      console.log(updatedObject);
      const notDeletedRates = props.rates.filter(
        (item: any) => item.isDeleted === false,
      );
      console.log(notDeletedRates, props.rates);
      let rateAlreadyExists: any;
      if (updatedObject.isCloned) {
        const isCloned = notDeletedRates.filter(
          (item: any) => item.isCloned === true,
        );
        console.log(isCloned);
        rateAlreadyExists = deepEqualCheckForClone(updatedObject, isCloned);
        console.log(rateAlreadyExists);
      } else {
        const isNotCloned = notDeletedRates.filter(
          (item: any) => item.isCloned === false,
        );
        console.log(isNotCloned);
        const temp = isNotCloned.filter(
          (item: any) => item.defaultRate._id === updatedObject.defaultRate._id,
        );
        console.log(temp);
        rateAlreadyExists = deepEqualCheck(updatedObject, temp);
        console.log(rateAlreadyExists);
      }

      if (rateAlreadyExists.result) {
        console.log('all things Done');
        handleWhenEditedRateAlreadyExits(rateAlreadyExists.rate, updatedObject);
        setEditingKey('');
        // props.toggleEditModal(false);
      } else {
        save(id);
        // props.toggleEditModal(false);
      }
    }
  };

  const handleCarpentryClone = (
    cloneData: any,
    index: number,
    surfaceName: string,
  ) => {
    const result = props.rates.some((obj: any) => {
      let name: string = obj.item;
      if (name.replaceAll(' ', '') === surfaceName[index].replaceAll(' ', '')) {
        return true;
      }
    });
    if (result === true) {
      message.error('Name already taken');
    } else {
      const newRates = {
        ...cloneData,
        item: surfaceName[index],
        project: items[currentProject].projectId,
        _id: crypto.randomBytes(12).toString('hex'),
        isCloned: true,
      };
      // delete newRates._id;
      // delete newRates.defaultRate;

      const notDeletedRates = props.rates.filter(
        (item: any) => item.isDeleted === false,
      );
      const isCloned = notDeletedRates.filter(
        (item: any) => item.isCloned === true,
      );
      console.log(isCloned);
      const rateAlreadyExists = deepEqualCheckForClone(newRates, isCloned);
      console.log(rateAlreadyExists);
      if (rateAlreadyExists) {
        message.error('Rate Already Exists');
      } else {
        const body = [
          {
            category: 'carpentry',
            rate: [newRates],
          },
        ];
        const newData = [...data];
        newData.push(body[0].rate[0]);
        setData(newData);

        let project = _.cloneDeep(items[currentProject]);
        dispatch(addServiceToProject(items[currentProject].projectId, body));
        project.projectRates.carpentryRates = newData;

        let tempCarpentryNewRates = [];
        for (const item of project.options) {
          let newRate = [];
          for (const element of body[0].rate) {
            let carpentryLaborRateConst = adminDefaults.wageDefaults.find(
              (item: any) => {
                return item.title === 'Carpentry Wage Rate';
              },
            );

            let count = 0;
            let carpentryMaterial = count * element.materialRate;
            let carpentryHours =
              (1.08 * count * element.laborRate) /
              carpentryLaborRateConst.wageValue;
            const projectLaborRate = element._id;
            let _id = crypto.randomBytes(12).toString('hex');
            newRate.push({
              ...element,
              _id,
              option: item.optionInfo._id,
              projectLaborRate: projectLaborRate,
              count,
              carpentryMaterial,
              carpentryHours,
            });
          }

          item.rates.carpentryRates.push(...newRate);
          tempCarpentryNewRates.push(...newRate);
        }
        let carpentryRequestBody = [
          {
            category: 'carpentry',
            rate: [...tempCarpentryNewRates],
          },
        ];
        dispatch(
          addRatesToOptions(carpentryRequestBody, project, adminDefaults),
        );
      }
    }
    // api
    //   .post(`rates/project/${items[currentProject].projectId}`, body)
    //   .then((resp: any) => {
    //     const newData = [...data];
    //     newData.push(resp[0]);
    //     setData(newData);
    //   })
    //   .catch((err) => console.error(err));
  };

  const carpentryColumns = [
    {
      title: 'Carpentry Item',
      dataIndex: 'item',
      width: '200px',
      editable: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '100px',
    },
    {
      title: 'Labor/Unit',
      dataIndex: 'laborRate',
      editable: true,
      render: (data: any) => {
        return (
          <>
            {data && (
              <>
                {parseFloat(data)?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  style: 'currency',
                  currency: 'USD',
                })}
              </>
            )}
          </>
        );
      },
      width: '120px',
    },
    {
      title: 'Material/Unit',
      dataIndex: 'materialRate',
      render: (data: any) => {
        return (
          <>
            {data && (
              <>
                {parseFloat(data)?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  style: 'currency',
                  currency: 'USD',
                })}
              </>
            )}
          </>
        );
      },
      editable: true,
    },
    {
      title: 'Action',
      width: '80px',

      render: (data: any, record: any, index: number) => {
        const editable = isEditing(record);
        return !editable ? (
          <>
            <ActionDropDown
              handleEdit={edit}
              data={data}
              index={index}
              category="carpentry"
              handleDelete={handleDelete}
              handleClone={handleCarpentryClone}
            />
          </>
        ) : (
          <span>
            <Typography.Link
              onClick={() => newOnSave(record._id)}
              style={{ marginRight: 8 }}
            >
              <CheckOutlined />
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>
                <CloseOutlined />
              </a>
            </Popconfirm>
          </span>
        );
      },
    },
  ];
  const mergedColumns = carpentryColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === 'item' ? 'text' : 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const showExpandableRows = () => {
    if (props.showExpandRows) {
      return data.map((row: any) => row._id);
    } else {
      return [];
    }
  };

  const expandedRowContent = (record: any) => {
    console.log(record);
    const options = items[currentProject].options;
    let listOfOptions = [];
    for (const singleOption of options) {
      const isFound = singleOption.rates.carpentryRates.some(
        (item: any) => item.projectLaborRate === record._id && !item.isDeleted,
      );
      if (isFound) {
        listOfOptions.push(singleOption.optionInfo.title);
      }
    }
    console.log(listOfOptions);
    return (
      <div style={{ width: '79%', margin: 'auto' }}>
        {listOfOptions.length === 0 ? (
          <Typography>No Options Created</Typography>
        ) : (
          <Typography>{listOfOptions.join(', ')}</Typography>
        )}
      </div>
    );
  };

  return (
    <Form form={form} component={false}>
      <Table
        scroll={{ x: 900 }}
        rowKey="_id"
        components={{
          body: {
            cell: CarpentryEditable,
          },
        }}
        rowClassName={(_, index) =>
          index % 2 === 0
            ? 'table_row table-row-light'
            : 'table_row table-row-dark'
        }
        loading={!data ? true : false}
        pagination={false}
        columns={mergedColumns}
        dataSource={data
          .filter((item: any) => !item.isDeleted)
          .sort((a: any, b: any) => a.item.localeCompare(b.item))}
        expandable={{
          expandedRowRender: (record, index) => expandedRowContent(record),
          expandIcon: () => null,
          expandedRowKeys: showExpandableRows(),
        }}
      />
    </Form>
  );
};

export default CarpentrySurfaces;
