import React, { useEffect, useState } from 'react';
import {
  Select,
  Modal,
  Space,
  AutoComplete,
  List,
  Checkbox,
  Input,
  message,
} from 'antd';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { customToFixed } from '../../utils/helpers';
import _ from 'lodash';
import '../../components/pop-ups/SendRequest.less';

type Props = {
  isBulkEditModalOpen: boolean;
  setIsBulkEditModalOpen: any;
  paintRates: any;
  roundGallons: boolean;
  optionId: string;
};

const BulkEditModal = (props: Props) => {
  const { Option } = Select;
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );
  const dispatch = useDispatch();
  const [filteredColorOptions, setFilteredColorOptions] = useState<any>([]);
  const [paintColor, setPaintColor] = useState<string>('');
  const [paintCoats, setPaintCoats] = useState<number>();
  const [paintPrime, setPaintPrime] = useState<string>('');
  const [listData, setListData] = useState<any[]>([]);
  const [checked, setChecked] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);

  useEffect(() => {
    const listData = props.paintRates
      ?.filter((item: any) => !item.isDeleted)
      .map((item: any) => {
        return { ...item, checked: true };
      })
      .sort((a: any, b: any) => a.item.localeCompare(b.item));
    let checkeditemList = listData.map((item: any) => {
      return { id: item._id, checked: true };
    });
    setListData(listData);
    setChecked(checkeditemList);
    setCheckAll(true);
    let defaultCoats = getCommonValue(props.paintRates, 'coats');
    setPaintCoats(defaultCoats !== null ? defaultCoats : null);
    let defaultPrimer = getCommonValue(props.paintRates, 'primeSelect');
    setPaintPrime(defaultPrimer !== '' ? defaultPrimer : '');
    let defaultColor = getCommonValue(props.paintRates, 'color');
    setPaintColor(defaultColor !== '' ? defaultColor : '');
  }, [props.paintRates]);

  useEffect(() => {
    if (checked.length === listData.length) {
      const notChecked = checked.filter((item: any) => item.checked === false);
      if (checked.length - notChecked.length < checked.length) {
        setCheckAll(false);
      } else {
        setCheckAll(true);
      }
    }
  }, [checked]);

  const handleSetAllChecked = (e: any) => {
    const checked = e.target.checked;
    let markedList = listData.map((item: any) => {
      return { ...item, checked: checked };
    });
    let checkeditemList = listData.map((item: any) => {
      return { id: item._id, checked: checked };
    });
    setCheckAll(checked);
    setListData(markedList);
    setChecked(checkeditemList);
  };

  const getCommonValue = (items: any, label: string) => {
    if (!items || items.length === 0) {
      return label !== 'coats' ? '' : null;
    }

    const firstCoat = items[0][label];
    const allSame = items.every((item: any) => item[label] === firstCoat);

    if (label !== 'coats') {
      return allSame ? firstCoat : '';
    } else {
      return allSame ? firstCoat : null;
    }
  };

  const findMaterial = (material: any) => {
    const findFromProject = items[currentProject]?.projectMaterials?.find(
      (item: any) => item?.defaultMaterialId === material?._id,
    );
    if (findFromProject) {
      return findFromProject.price;
    }
    return material?.priceAfterTax;
  };

  const calculatePrimeHours = (newData: any) => {
    if (newData.primeSelect === 'full') {
      newData.primeHours = newData.count / newData.primeLaborRate;

      newData.primeHours = customToFixed(newData.primeHours, 2);
      calculateTotalPaintHours(newData);
    }
  };

  const calculatePaintHours = (newData: any) => {
    let coatsMultiplier: number =
      items[currentProject].projectInfo.coatsMultiplier;
    const newCoats =
      newData.coats === 2
        ? 1 + coatsMultiplier / 100
        : newData.coats === 3
          ? 1 + coatsMultiplier / 50
          : 1;
    newData.paintHours = (newData.count * newCoats) / newData.paintLaborRate;

    newData.paintHours = customToFixed(newData.paintHours, 2);
    calculateTotalPaintHours(newData);
  };

  const calculateTotalPaintHours = (newData: any) => {
    newData.totalPaintHours =
      (newData.prepHours ? parseFloat(newData.prepHours) : 0) +
      (newData.primeHours ? parseFloat(newData.primeHours) : 0) +
      (newData.paintHours ? parseFloat(newData.paintHours) : 0);
    newData.totalPaintHours = customToFixed(newData.totalPaintHours, 2);
  };

  const calculatePrimerGallons = (newData?: any) => {
    if (newData === undefined) {
      newData = [...props.paintRates];
    }
    if (newData.primeSelect === 'full') {
      newData.primerGallons = newData.count / newData.primerSpreadRate;
      if (props.roundGallons) {
        newData.primerGallons = Math.ceil(newData.primerGallons);
      }
      calculatePaintMaterialCost(newData);
    }
  };

  const calculatePaintGallons = (newData?: any) => {
    if (newData === undefined) {
      newData = [...props.paintRates];
    }
    let coatsMultiplier: number =
      items[currentProject].projectInfo.coatsMultiplier;
    const newCoats =
      newData.coats === 2
        ? 1 + coatsMultiplier / 100
        : newData.coats === 3
          ? 1 + coatsMultiplier / 50
          : 1;
    newData.paintGallons = (newCoats * newData.count) / newData.paintSpreadRate;
    if (props.roundGallons) {
      newData.paintGallons = Math.ceil(newData.paintGallons);
    }
    calculatePaintMaterialCost(newData);
  };

  const calculatePaintMaterialCost = (newData: any) => {
    let updatedPaintMaterialPrice = findMaterial(newData.paintMaterial);
    let updatedPrimerMaterialPrice = findMaterial(newData.primerMaterial);

    newData.paintMaterialCost =
      (newData.paintGallons ? newData.paintGallons : 0) *
        (newData.customerSuppliedPaint
          ? 0
          : updatedPaintMaterialPrice
            ? updatedPaintMaterialPrice
            : newData.paintMaterial
              ? newData.paintMaterial.priceAfterTax
              : 0) +
      (newData.customerSuppliedPrimer
        ? 0
        : updatedPrimerMaterialPrice
          ? (newData.primerGallons ? newData.primerGallons : 0) *
            updatedPrimerMaterialPrice
          : (newData.primerGallons ? newData.primerGallons : 0) *
            (newData.primerMaterial
              ? newData.primerMaterial.priceAfterTax
              : 0));

    newData.paintMaterialCost = customToFixed(newData.paintMaterialCost, 2);
  };

  const handlePrime = (updatedItem: any) => {
    if (updatedItem.primeSelect === 'full') {
      calculatePrimeHours(updatedItem);
      calculatePrimerGallons(updatedItem);
    } else if (updatedItem.primeSelect === 'spot') {
      updatedItem.primeHours = 0;
      updatedItem.primerGallons = 0;
      calculateTotalPaintHours(updatedItem);
      calculatePaintMaterialCost(updatedItem);
    }
  };

  const handleCoats = (updatedItem: any) => {
    calculatePaintHours(updatedItem);
    if (updatedItem.primeSelect === 'full') {
      calculatePrimerGallons(updatedItem);
    }
    calculatePaintGallons(updatedItem);
  };

  const bulkEditPrimeCoatsColor = () => {
    const data = {
      coats: paintCoats,
      primeSelect: paintPrime,
      color: paintColor,
    };
    const dataToUpdate = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== ''),
    );
    const itemsToUpdate = checked.filter((item: any) => item.checked === true);
    const patchBody = [];

    for (let item of itemsToUpdate) {
      const OrgItem = props.paintRates.find((ele: any) => ele._id === item.id);
      const foundItem = { ...OrgItem };

      if (foundItem) {
        const updatedKeys: string[] = [];
        for (const key of Object.keys(dataToUpdate)) {
          if (foundItem[key] !== dataToUpdate?.[key]) {
            updatedKeys.push(key);
            foundItem[key] = dataToUpdate?.[key];
          }
        }

        if (updatedKeys.length > 0) {
          // Process the updated keys here if needed
          for (const key of updatedKeys) {
            if (key === 'primeSelect') {
              handlePrime(foundItem);
            } else if (key === 'coats') {
              handleCoats(foundItem);
            } else {
              continue;
            }
          }
        }
        patchBody.push(foundItem);
      }
    }
    let updatedProjectForRedux = _.cloneDeep(items[currentProject]);
    const optionIndex = updatedProjectForRedux?.options?.findIndex(
      (item: any) => item.optionInfo._id === props.optionId,
    );
    const updatedOptionPainRates = _.cloneDeep(props.paintRates);

    for (const item of patchBody) {
      const rateIndex = updatedOptionPainRates.findIndex(
        (ele: any) => ele._id === item._id,
      );
      updatedOptionPainRates[rateIndex] = item;
    }
    updatedProjectForRedux.options[optionIndex].rates.paintRates =
      updatedOptionPainRates;
    if (patchBody.length > 0) {
      for (const item of patchBody) {
        const rateIndex = props.paintRates.findIndex(
          (ele: any) => ele._id === item._id,
        );
        if (rateIndex !== -1) {
          props.paintRates[rateIndex] = item;
        }
      }
    }
    setChecked([]);
    setCheckAll(false);
    props.setIsBulkEditModalOpen(false);
  };

  const handleCheckboxChange = (e: any, item: any, index: number) => {
    const isChecked = e.target.checked;
    const updatedItem = { ...item, checked: isChecked };

    const updatedList = listData.map((listItem, i) =>
      i === index ? updatedItem : listItem,
    );

    setListData(updatedList);
    setChecked((prevChecked) => {
      const existingIndex = prevChecked.findIndex(
        (item) => item.id === updatedItem._id,
      );

      if (existingIndex > -1) {
        const updatedChecked = [...prevChecked];
        updatedChecked[existingIndex].checked = isChecked;
        return updatedChecked;
      } else {
        return [...prevChecked, { id: updatedItem._id, checked: isChecked }];
      }
    });
  };
  const capitalizeFirstLetter = (str: string) => {
    return str ? str[0]?.toUpperCase() + str?.slice(1) : '';
  };

  const handleColorOptions = (e: any) => {
    if (e.length > 3) {
      let newOptions = adminDefaults?.colors.filter((item: any) =>
        item.name.toLowerCase().includes(e.toLowerCase()),
      );

      setFilteredColorOptions(
        newOptions.map((color: any) => {
          return {
            label: (
              <>
                <div
                  style={{
                    background: color.hexCode,
                    height: 20,
                    width: 20,
                    marginRight: 10,
                  }}
                ></div>
                <div>{color.name}</div>
              </>
            ),
            value: color.name,
          };
        }),
      );
    } else {
      setFilteredColorOptions([]);
    }
    setPaintColor(e);
  };
  return (
    <Modal
      className="surface-modal"
      destroyOnClose={true}
      title={<Space style={{ fontWeight: 'bold' }}>Bulk Edit</Space>}
      open={props.isBulkEditModalOpen}
      onCancel={() => props.setIsBulkEditModalOpen(false)}
      onOk={() => {
        const notAllCheckedFalse = checked.filter(
          (item: any) => item.checked === true,
        );
        if (notAllCheckedFalse.length > 0) {
          bulkEditPrimeCoatsColor();
        } else {
          message.error('Please Check the Items to be Updated');
        }
      }}
    >
      <div>
        <div
          style={{
            marginLeft: 225,
            marginBottom: 8,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <label style={{ margin: 0, fontWeight: 'bold' }}>Prime</label>
          <label style={{ marginLeft: 75, fontWeight: 'bold' }}>Coats</label>
          <label style={{ marginLeft: 150, fontWeight: 'bold' }}>Color</label>
        </div>
        <div
          style={{
            marginLeft: 205,
            marginBottom: 8,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Select
            onChange={(value: string) => setPaintPrime(value)}
            style={{ width: 75, marginLeft: 5 }}
            value={paintPrime}
          >
            <Option value="spot">Spot</Option>
            <Option value="full">Full</Option>
          </Select>

          <Select
            onChange={(value: number) => setPaintCoats(value)}
            style={{ width: '55px', marginLeft: 45 }}
            value={paintCoats}
          >
            <Option value={1}>1</Option>
            <Option value={2}>2</Option>
            <Option value={3}>3</Option>
          </Select>

          <>
            <div
              style={{
                background: adminDefaults?.colors.find(
                  (item: any) => item.name === paintColor,
                )?.hexCode,
                height: 32,
                width: 30,
                marginLeft: 13,
              }}
            />
            <AutoComplete
              options={filteredColorOptions.map((option: any) => ({
                value: option.value,
                label: (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: 16,
                    }}
                  >
                    <span
                      style={{
                        display: 'inline-block',
                        width: 32,
                        height: 32,
                        backgroundColor: option.color,
                      }}
                    />
                    {option.label}
                  </div>
                ),
              }))}
              onChange={(e: any) => handleColorOptions(e)}
              defaultValue={paintColor}
              style={{ marginLeft: 5, width: 210 }}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: 320 }}
            />
          </>
        </div>
        <Checkbox
          checked={checkAll}
          onChange={(e) => handleSetAllChecked(e)}
          style={{ marginRight: 25, width: 180, textAlign: 'center' }}
        >
          <span style={{ marginLeft: 40, fontWeight: 'bold' }}>Select All</span>
        </Checkbox>
        <div
          className="checkbox-scroll-bar"
          style={{
            maxHeight: 500,
            maxWidth: 700,
            overflowY: 'auto',
          }}
        >
          <List
            itemLayout="horizontal"
            className="surface-list-container"
            dataSource={listData}
            renderItem={(item: any, index: number) => (
              <List.Item>
                <Checkbox
                  checked={item.checked}
                  onChange={(e) => handleCheckboxChange(e, item, index)}
                />
                <label style={{ width: 100 }}>{item.item}</label>
                <span style={{ width: 75, textAlign: 'center' }}>
                  {capitalizeFirstLetter(item.primeSelect)}
                </span>
                <span style={{ width: 55, textAlign: 'center' }}>
                  {item?.coats === null ? 1 : item?.coats}
                </span>
                <span style={{ width: 215, paddingLeft: 10 }}>
                  {item.color}
                </span>
              </List.Item>
            )}
          />
        </div>
      </div>
    </Modal>
  );
};

export default BulkEditModal;
