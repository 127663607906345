import React, { useEffect, useState } from "react";
import { Row, Col, Input, Select, AutoComplete } from "antd";
import "./DeliveryContact.less";
import { useMediaQuery } from "react-responsive";

const { Option } = Select;

type Props = {
  title: string;
  addContactName: (data: any) => void;
  addPhone: (data: any) => void;
  addEmail: (data: any) => void;
  users: any;
  oldContact: any;
};

const DeliveryContact = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:1200px)" });

  const [emailError, setEmailError] = useState<string>("");
  const [contactNumberError, setContactNumberError] = useState<string>("");

  const [deliveryContact, setDeliveryContact] = useState<any>(
    props.oldContact
      ? {
          fullName: props.oldContact.name,
          phone: props.oldContact.phoneNumber,
          email: props.oldContact.emailAddress,
        }
      : {},
  );
  const [searchString, setSearchString] = useState<any>(
    props.oldContact ? props.oldContact.name : "",
  );
  const [filteredUsers, setFilteredUsers] = useState<any>([]);

  useEffect(() => {
    if (props.oldContact) {
      props.addContactName(props.oldContact?.name);
      props.addEmail(props.oldContact?.emailAddress);
      props.addPhone(props.oldContact?.phoneNumber);
    }
  }, []);

  const formatPhoneNumber = (input: string) => {
    // Remove all non-digit characters
    const cleaned = input.replace(/\D/g, "");

    // Limit to 10 digits
    const trimmed = cleaned.slice(0, 10);

    // Format the number
    if (trimmed.length === 0) return "";
    if (trimmed.length <= 3) return `(${trimmed}`;
    if (trimmed.length <= 6)
      return `(${trimmed.slice(0, 3)}) ${trimmed.slice(3)}`;
    return `(${trimmed.slice(0, 3)}) ${trimmed.slice(3, 6)}-${trimmed.slice(6)}`;
  };

  const handleSelect = (value: any) => {
    const selectedUser = props.users.find(
      (user: any) => user.fullName === value,
    );
    if (!selectedUser) return;
    props.addContactName(selectedUser?.fullName);
    props.addPhone(selectedUser?.phone);
    props.addEmail(selectedUser?.email);
    setDeliveryContact(selectedUser);
  };

  const handleContactChange = (e: any) => {
    if (e.length >= 3) {
      props.addContactName(e);
      setDeliveryContact((prevState: any) => ({
        ...(prevState || {}),
        fullName: e,
      }));
    }
  };

  const handleUserSelectOptions = (e: any) => {
    setSearchString(e);
    if (e.length >= 2) {
      const filteredUsers = props.users.filter((user: any) =>
        user.fullName.toLowerCase().includes(e.toLowerCase()),
      );
      setFilteredUsers(filteredUsers);
      if (filteredUsers.length === 0) {
        handleContactChange(e);
      }
    } else {
      setFilteredUsers([]);
      if (e.length >= 2) {
        handleContactChange(e);
      }
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.trim(); // Trim input

    // Format the phone number
    const formattedValue = formatPhoneNumber(value);

    // Validate the formatted phone number
    const isValid = validateContactNumber(formattedValue);

    // Only update state if the phone number is valid
    if (isValid) {
      props.addPhone(formattedValue); // Pass the formatted value to the parent component
      setDeliveryContact((prevState: any) => ({
        ...(prevState || {}),
        phone: formattedValue,
      }));
    } else {
      // If invalid, update the state with the raw input (for user feedback)
      setDeliveryContact((prevState: any) => ({
        ...(prevState || {}),
        phone: value,
      }));
    }
  };

  const validateContactNumber = (value: string): boolean => {
    // Regex for (000) 000-0000 format
    const formattedRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    // Regex for 0123456789 format
    const unformattedRegex = /^\d{10}$/;

    if (formattedRegex.test(value) || unformattedRegex.test(value)) {
      setContactNumberError(""); // Clear error if valid
      return true;
    } else {
      setContactNumberError("Please provide a valid number"); // Set error if invalid
      return false;
    }
  };

  const handleEmailChange = (e: any) => {
    const value = e.target.value;
    validateEmail(value);
    props.addEmail(value);
    setDeliveryContact((prevState: any) => ({
      ...(prevState || {}),
      email: value,
    }));
  };

  const validateEmail = (value: string) => {
    if (value && !/\S+@\S+\.\S+/.test(value)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  const title = props.title.split(" ");

  return (
    <>
      {isTabletOrMobile ? (
        <div className="delivery-notes-root" style={{ height: "310px" }}>
          <h1>{props.title}</h1>

          <div style={{ marginTop: "28px" }}>
            <Row justify={"space-between"}>
              <Col>
                <p>{title[0]} Contact:</p>
              </Col>
              <Col>
                <AutoComplete
                  options={filteredUsers.map((user: any) => ({
                    value: user.fullName,
                    label: (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 16,
                        }}
                      >
                        {user.fullName}
                      </div>
                    ),
                  }))}
                  placeholder={"Search or Type..."}
                  onChange={(e: any) => handleUserSelectOptions(e)}
                  value={searchString}
                  style={{ width: 270, borderRadius: 5, height: 30 }}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: 320 }}
                  onSelect={(e) => handleSelect(e)}
                />
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: 5 }}>
              <Col>
                <p>Phone Number:</p>
              </Col>
              <Col>
                <Input
                  placeholder={"(000) 000-0000"}
                  onChange={handlePhoneChange}
                  style={{ width: 270, borderRadius: 5, height: 30 }}
                  value={deliveryContact.phone || ""}
                />
                {contactNumberError && (
                  <div
                    style={{ color: "red", marginBottom: 0, paddingBottom: 0 }}
                  >
                    {contactNumberError}
                  </div>
                )}
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: 5 }}>
              <Col>
                <p>Email Address:</p>
              </Col>
              <Col>
                <Input
                  onChange={handleEmailChange}
                  style={{ width: 270, borderRadius: 5 }}
                  value={deliveryContact?.email}
                />
                {emailError && (
                  <div
                    style={{ color: "red", marginBottom: 0, paddingBottom: 0 }}
                  >
                    {emailError}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="delivery-notes-root">
          <h1>{props.title}</h1>

          <div style={{ marginTop: "28px" }}>
            <Row justify={"space-between"}>
              <Col>
                <p>{title[0]} Contact:</p>
              </Col>
              <Col>
                <AutoComplete
                  options={filteredUsers.map((user: any) => ({
                    value: user.fullName,
                    label: (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 16,
                        }}
                      >
                        {user.fullName}
                      </div>
                    ),
                  }))}
                  placeholder={"Search or Type..."}
                  onChange={(e: any) => handleUserSelectOptions(e)}
                  value={searchString}
                  style={{ width: 270, borderRadius: 5, height: 30 }}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: 320 }}
                  onSelect={(e) => handleSelect(e)}
                />
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: 5 }}>
              <Col>
                <p>Phone Number:</p>
              </Col>
              <Col>
                <Input
                  placeholder={"(000) 000-0000"}
                  onChange={handlePhoneChange}
                  style={{ width: 270, borderRadius: 5, height: 30 }}
                  value={deliveryContact.phone || ""}
                />
                {contactNumberError && (
                  <div
                    style={{ color: "red", marginBottom: 0, paddingBottom: 0 }}
                  >
                    {contactNumberError}
                  </div>
                )}
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: 5 }}>
              <Col>
                <p>Email Address:</p>
              </Col>
              <Col>
                <Input
                  onChange={handleEmailChange}
                  style={{ width: 270, borderRadius: 5 }}
                  value={deliveryContact?.email}
                />
                {emailError && (
                  <div
                    style={{ color: "red", marginBottom: 0, paddingBottom: 0 }}
                  >
                    {emailError}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

function propsAreEqual(prevProps: any, props: any) {
  return prevProps.title === props.title && prevProps.users === props.users;
}

export default React.memo(DeliveryContact, propsAreEqual);
