import React, { FunctionComponent, SVGProps } from 'react';
import {
  AvailableJobsIcon,
  NotificationsIcon,
  UserProfileIcon,
  SettingsIcon,
  GearIcon,
  DollarLogo,
} from '../utils/icons';

import PreviewProfile from '../pages/sales-associate/PreviewProfile';
import NewProfile from '../pages/sales-associate/NewProfile';
import Pjcc from '../pages/sales-associate/Pjcc';
import Proposal from '../pages/sales-associate/Proposal';
import JobEstimates from '../pages/estimator/JobEstimates';
import Onsite from '../pages/estimator/OnSite';
import Labor from '../pages/estimator/Labor';
import OptionsSetup from '../pages/estimator/option';
import Pricing from '../pages/estimator/Pricing';
import EstimateChecklists from '../pages/estimator/EstimateChecklists';
import ProposalSetup from '../pages/estimator/ProposalSetup';
import EstimatorProposal from '../pages/estimator/Proposal';
import ManagerMyJobs from '../pages/manager/ManagerMyJobs';
import ManagerPreviewProfile from '../pages/manager/ManagerPreviewProfile';
import ManagerChecklists from '../pages/manager/ManagerChecklists';
import ManagerOptionSetup from '../pages/manager/ManagerOptionSetup';
import ManagerLaborRates from '../pages/manager/ManagerLaborRates';
import ManagerPricing from '../pages/manager/ManagerPricing';
import ManagerEstimateChecklist from '../pages/manager/ManagerEstimateChecklist';
import ManagerProposalSetup from '../pages/manager/ManagerProposalSetup';
import ManagerProposal from '../pages/manager/ManagerProposal';
import LandedJobs from '../pages/production-associate/LandedJobs';
import PJCC from '../pages/production-associate/PJCC';
import PreChecklist from '../pages/production-associate/PreChecklist';
import ProposalC from '../pages/job-jacket/ProposalC';
import WorkOrderPaint from '../pages/job-jacket/WorkOrderPaint';
import WorkOrderCarpentry from '../pages/job-jacket/WorkOrderCarpentry';
import PaintOrderCommercial from '../pages/job-jacket/PaintOrderCommercial';
import WoodOrder from '../pages/job-jacket/WoodOrder';
import Contract from '../pages/job-jacket/Contract';
import OptionDetails from '../pages/estimator/OptionDetails';
import ManagerOptionDetails from '../pages/manager/ManagerOptionDetails';
import PaymentTab from '../pages/payment/PaymentTab';
import JobPostingBoard from '../pages/contractor/JobPostingBoard';
import MyJobs from '../pages/contractor/MyJobs';
import PaymentLogs from '../pages/contractor/PaymentLogs';
import PaymentRecord from '../pages/contractor/PaymentRecord';
import UserProfile from '../pages/profile/UserProfile';
import CreateAccount from '../pages/profile/CreateAccount';
import AvailableJobs from '../pages/sales-associate/AvailableJobs';
import AllJobs from '../pages/sales-associate/AllJobs';
import UpdateProfile from '../pages/sales-associate/UpdateProfile';
import Setting from '../pages/profile/Setting';
import ManagerPJCC from '../pages/manager/ManagerPJCC';
import MaterialDefaults from '../pages/administrator/MaterialDefaults';
import ContractorData from '../pages/administrator/ContractorData';
import PayProcessingDefaults from '../pages/administrator/PayProcessingDefaults';
import EquipmentCosts from '../pages/administrator/EquipmentCosts';
import OtherDefaults from '../pages/administrator/OtherDefaults';
import LaborRatesDefaults from '../pages/administrator/LaborRatesDefaults';
import UserProfiles from '../pages/administrator/UserProfiles';
import ProposalDefaultsCom from '../pages/administrator/ProposalDefaultsCom';
import ProposalDefaultsRes from '../pages/administrator/ProposalDefaultsRes';
import SegmentsDefaults from '../pages/administrator/SegmentsDefaults';
import OnSiteDefaults from '../pages/administrator/OnSiteDefaults';
import Integrations from '../pages/administrator/Integrations';
import CheckRequest from '../pages/production-associate/CheckRequest';
import PaymentRequests from '../containers/payment-requests/PaymentRequests';
import PaintCost from '../pages/administrator/PaintCost';
import CarpentryCost from '../pages/administrator/CarpentryCost';
import TravelCost from '../pages/administrator/TravelCost';
import MiscCost from '../pages/administrator/MiscCost';
import ContractorProposal from '../pages/contractor/ContractorProposal';
import ContractorWorkOrderPaint from '../pages/contractor/ContractorWorkOrderPaint';
import ContractorWorkOrderCarpentry from '../pages/contractor/ContractorWorkOrderCarpentry';
import ContractorPaintOrder from '../pages/contractor/ContractorPaintOrder';
import ContractorWoodOrder from '../pages/contractor/ContractorWoodOrder';
import ContractorPreView from '../pages/contractor/ContractorPreView';
import ContractorMedia from '../pages/contractor/ContractorMedia';
import Media from '../pages/job-jacket/Media';
import Users from '../pages/administrator/Users';
import CompanyInfo from '../pages/administrator/CompanyInfo';
import StoreLocations from '../pages/administrator/StoreLocations';
import Vendors from '../pages/administrator/Vendors';
import About from '../pages/administrator/About';
import RedirectPage from '../pages/RedirectPage';
// import ErrorBoundary from "../pages/job-jacket/ErrorBoundary";
import ContractDefault from '../pages/administrator/ContractDefault';
import superAdminUsers from '../pages/super_admin/Users';
import Tenant from '../pages/super_admin/tenant';
import TenantUsers from '../pages/super_admin/TenantUsers';
import TenantProjects from '../pages/super_admin/TenantProjects';
import ColorsDefault from '../pages/administrator/ColorsDefault';
import EstimatorChecklistDefaults from '../pages/administrator/EstimatorChecklistDefaults';
import ProposalDefault from '../pages/administrator/ProposalDefault ';

export type RouteType = {
  Component: React.FC;
  exact?: boolean;
  path: string;
  roles?: string[];
  title?: string;
  visible?: boolean;
  icon?: any;
  children?: any;
};

export const routes: RouteType[] = [
  {
    path: '/',
    exact: true,
    Component: AvailableJobs,
    title: 'My Jobs',
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
    visible: true,
    icon: AvailableJobsIcon,
  },

  {
    path: '/all-jobs',
    exact: true,
    Component: AllJobs,
    title: 'All Jobs',
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
    visible: true,
    icon: AvailableJobsIcon,
  },

  {
    path: '/factur',
    exact: true,
    Component: AllJobs,
    title: 'Factur',
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
    visible: true,
    icon: AvailableJobsIcon,
  },
  {
    path: '/new-profile',
    exact: true,
    Component: NewProfile,
    // roles: ['sales_associate'],
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },

  // {
  //   path: "/update-profile/:id",
  //   exact: true,
  //   Component: UpdateProfile,
  //   roles: ["sales_associate", "admin"],
  // },
  // {
  //   path: "/preview-profile/:id",
  //   exact: true,
  //   Component: PreviewProfile,
  //   // roles: ['sales_associate'],
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //     "manager",
  //     "admin",
  //   ],
  // },
  // {
  //   path: "/pjcc/:id",
  //   exact: true,
  //   Component: Pjcc,
  //   // roles: ['sales_associate'],
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //     "manager",
  //     "admin",
  //   ],
  // },
  // {
  //   path: "/proposal/:id",
  //   exact: true,
  //   Component: Proposal,
  //   // roles: ['sales_associate'],
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //     "manager",
  //     "admin",
  //   ],
  // },

  // {
  //   path: "/job-estimates",
  //   exact: true,
  //   Component: JobEstimates,
  //   // roles: ['estimator' , 'sales_associate'],
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //     "manager",
  //     "admin",
  //   ],
  //   title: "My Jobs",
  //   visible: false,
  //   icon: AvailableJobsIcon,
  // },
  // {
  //   path: "/on-site-checklist/:id",
  //   exact: true,
  //   Component: Onsite,
  //   // roles: ['estimator'],
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //     "manager",
  //     "admin",
  //   ],
  // },
  // {
  //   path: "/option-setup/:id",
  //   exact: true,
  //   Component: OptionsSetup,
  //   // roles: ['estimator'],
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //     "manager",
  //     "admin",
  //   ],
  // },
  // {
  //   path: "/labor-rates/:id",
  //   exact: true,
  //   Component: Labor,
  //   // roles: ['estimator'],
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //     "manager",
  //     "admin",
  //   ],
  // },

  // {
  //   path: "/option-details/:id",
  //   exact: true,
  //   Component: OptionDetails,
  //   // roles: ['estimator'],
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //     "manager",
  //     "admin",
  //   ],
  // },
  // {
  //   path: "/pricing",
  //   exact: true,
  //   Component: Pricing,
  //   // roles: ['estimator'],
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //     "manager",
  //     "admin",
  //   ],
  // },
  // {
  //   path: "/estimate-checklists/:id",
  //   exact: true,
  //   Component: EstimateChecklists,
  //   // roles: ['estimator'],
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //     "manager",
  //     "admin",
  //   ],
  // },
  // {
  //   path: "/proposal-setup/:id",
  //   exact: true,
  //   Component: ProposalSetup,
  //   // roles: ['estimator'],
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //     "manager",
  //     "admin",
  //   ],
  // },
  // {
  //   path: "/estimator-proposal/:id",
  //   exact: true,
  //   Component: EstimatorProposal,
  //   // roles: ['estimator'],
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //     "manager",
  //     "admin",
  //   ],
  // },
  // {
  //   path: "/manager-my-jobs",
  //   exact: true,
  //   Component: ManagerMyJobs,
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //     "manager",
  //     "admin",
  //   ],
  // },
  // {
  //   path: "/redirect",
  //   exact: true,
  //   Component: RedirectPage,
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //     "manager",
  //     "admin",
  //   ],
  //   visible: true,
  // },
  {
    path: '/manager-preview-profile/:id',
    exact: true,
    Component: ManagerPreviewProfile,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/manager-on-site-checklists/:id',
    exact: true,
    Component: ManagerChecklists,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/manager-options-setup/:id',
    exact: true,
    Component: ManagerOptionSetup,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/manager-labor-rates/:id',
    exact: true,
    Component: ManagerLaborRates,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/manager-option-details/:id',
    exact: true,
    Component: ManagerOptionDetails,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/manager-pjcc/:id',
    exact: true,
    Component: ManagerPJCC,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/manager-pricing',
    exact: true,
    Component: ManagerPricing,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/manager-estimate-checklist/:id',
    exact: true,
    Component: ManagerEstimateChecklist,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },

  {
    path: '/manager-proposal-setup/:id',
    exact: true,
    Component: ManagerProposalSetup,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/manager-proposal/:id',
    exact: true,
    Component: ManagerProposal,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/landed-jobs',
    exact: true,
    Component: LandedJobs,
  },
  {
    path: '/production-pjcc/:id',
    exact: true,
    Component: PJCC,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/pre-checklist/:id',
    exact: true,
    Component: PreChecklist,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },

  {
    path: '/production-associate-check-request/:id',
    exact: true,
    Component: CheckRequest,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/proposal-c/:id',
    exact: true,
    Component: ProposalC,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/work-order-paint/:id',
    exact: true,
    Component: WorkOrderPaint,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/work-order-carpentry/:id',
    exact: true,
    Component: WorkOrderCarpentry,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  // {
  //   path: "/paint-order-residential",
  //   exact: true,
  //   Component: PaintOrderResidential,
  //   roles: [
  //     "sales_associate",
  //     "production_associate",
  //     "estimator",
  //
  //     "manager",
  //     "admin",
  //   ],
  // },
  {
    path: '/paint-order/:id',
    exact: true,
    Component: PaintOrderCommercial,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/wood-order/:id',
    exact: true,
    Component: WoodOrder,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/job-jacket-contract/:id',
    exact: true,
    Component: Contract,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/job-jacket-media/:id',
    exact: true,
    Component: Media,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'manager',
      'admin',
    ],
  },
  {
    path: '/admin-defaults-estimator-checklist',
    exact: true,
    roles: ['admin'],
    Component: EstimatorChecklistDefaults,
  },
  {
    path: '/payment-tab',
    exact: true,
    roles: ['contractor'],
    Component: PaymentTab,
  },
  {
    path: '/contractor-job-board',
    exact: true,
    roles: ['contractor'],
    title: 'Available Jobs',
    visible: true,
    icon: AvailableJobsIcon,
    Component: JobPostingBoard,
  },
  {
    path: '/contractor-my-jobs',
    exact: true,
    roles: ['contractor'],
    title: 'My Jobs',
    visible: true,
    icon: AvailableJobsIcon,
    Component: MyJobs,
  },
  {
    path: '/contractor-proposal/:id',
    exact: true,
    roles: ['contractor'],
    Component: ContractorProposal,
  },
  {
    path: '/contractor-work-order-paint/:id',
    exact: true,
    roles: ['contractor'],
    Component: ContractorWorkOrderPaint,
  },
  {
    path: '/contractor-paint-order/:id',
    exact: true,
    roles: ['contractor'],
    Component: ContractorPaintOrder,
  },
  {
    path: '/contractor-wood-order/:id',
    exact: true,
    roles: ['contractor'],
    Component: ContractorWoodOrder,
  },
  {
    path: '/contractor-pre-view/:id',
    exact: true,
    roles: ['contractor'],
    Component: ContractorPreView,
  },
  {
    path: '/super_admin/tenant',
    title: 'Tenant',
    exact: true,
    roles: ['super_admin'],
    visible: true,
    icon: AvailableJobsIcon,
    Component: Tenant,
  },
  {
    path: '/super_admin/users',
    title: 'Users',
    exact: true,
    roles: ['super_admin'],
    Component: superAdminUsers,
    visible: true,
    icon: AvailableJobsIcon,
  },
  {
    path: '/super_admin/TenantUsers/:id',
    title: 'Tenant Users',
    exact: true,
    roles: ['super_admin'],
    Component: TenantUsers,
    icon: AvailableJobsIcon,
  },
  {
    path: '/super_admin/TenantProjects/:id',
    title: 'Tenants Projects',
    exact: true,
    roles: ['super_admin'],
    Component: TenantProjects,
    icon: AvailableJobsIcon,
  },
  {
    path: '/super-admin-material-default',
    exact: true,
    Component: MaterialDefaults,
    title: 'Defaults',
    visible: true,
    roles: ['super_admin'],
    icon: GearIcon,
    children: [
      {
        key: 0,
        label: 'Contract Defaults',
        path: '/super-admin-contract-default',
      },
      {
        key: 1,
        label: 'Colors',
        path: '/super-admin-colors-default',
      },
      {
        key: 2,
        label: 'Carpentry Labor Rates',
        path: '/super-admin-carpentry-costs',
      },
      {
        key: 3,
        label: 'Estimate Checklist',
        path: '/super-admin-defaults-estimate-checklist',
      },
      {
        key: 4,
        label: 'Equipment Labor Rates',
        path: '/super-admin-equipment-costs',
      },
      {
        key: 5,
        label: 'Material defaults',
        path: '/super-admin-material-default',
      },
      {
        key: 6,
        label: 'Misc Labor Rates',
        path: '/super-admin-misc-costs',
      },
      {
        key: 7,
        label: 'On-Site defaults',
        path: '/super-admin-onsite-checklist-defaults',
      },
      {
        key: 8,
        label: 'Paint Labor Rates',
        path: '/super-admin-paint-costs',
      },
      {
        key: 9,
        label: 'Paint Store Locations',
        path: '/super-admin-default-store-locations',
      },
      {
        key: 10,
        label: 'PJCC defaults',
        path: '/super-admin-other-defaults',
      },
      {
        key: 11,
        label: 'Proposal (Commercial)',
        path: '/super-admin-defaults-commercial',
      },
      {
        key: 12,
        label: 'Proposal (Residential)',
        path: '/super-admin-defaults-residential',
      },
      {
        key: 13,
        label: 'Proposal Defaults',
        path: '/super-admin-proposal-defaults',
      },
      {
        key: 14,
        label: 'Segments',
        path: '/super-admin-defaults-segments',
      },
      {
        key: 15,
        label: 'Travel Labor Rates',
        path: '/super-admin-travel-costs',
      },
      {
        key: 16,
        label: 'Vendors',
        path: '/super-admin-default-vendor',
      },
      {
        key: 17,
        label: 'About',
        path: '/super-admin-default-about',
      },
    ],
  },
  {
    path: '/contractor-media/:id',
    exact: true,
    roles: ['contractor'],
    Component: ContractorMedia,
  },
  {
    path: '/contractor-work-order-carpentry/:id',
    exact: true,
    roles: ['contractor'],
    Component: ContractorWorkOrderCarpentry,
  },
  {
    path: '/contractor-payment-logs',
    exact: true,
    roles: ['contractor'],
    title: 'Payment',
    visible: true,
    icon: DollarLogo,
    Component: PaymentLogs,
  },
  {
    path: '/contractor-payment-record/:id',
    exact: true,
    roles: ['contractor'],
    Component: PaymentRecord,
  },

  {
    path: '/admin-material-default',
    exact: true,
    Component: MaterialDefaults,
    title: 'Defaults',
    visible: true,
    roles: ['admin'],
    icon: GearIcon,
    children: [
      {
        key: 0,
        label: 'Company Info',
        path: '/admin-default-company-info',
      },
      {
        key: 1,
        label: 'Contractor data',
        path: '/admin-contractor-data',
      },
      {
        key: 2,
        label: 'Contract Defaults',
        path: '/admin-contract-default',
      },
      {
        key: 3,
        label: 'Carpentry Labor Rates',
        path: '/admin-carpentry-costs',
      },
      {
        key: 4,
        label: 'Estimator Checklist',
        path: '/admin-defaults-estimator-checklist',
      },

      {
        key: 5,
        label: 'Equipment Labor Rates',
        path: '/admin-equipment-costs',
      },
      {
        key: 6,
        label: 'Material defaults',
        path: '/admin-material-default',
      },
      {
        key: 7,
        label: 'Misc Labor Rates',
        path: '/admin-misc-costs',
      },
      {
        key: 8,
        label: 'On-Site defaults',
        path: '/admin-onsite-checklist-defaults',
      },
      {
        key: 9,
        label: 'Paint Labor Rates',
        path: '/admin-paint-costs',
      },
      {
        key: 10,
        label: 'Paint Store Locations',
        path: '/admin-default-store-locations',
      },
      {
        key: 11,
        label: 'Pay Processing',
        path: '/admin-pay-processing',
      },
      {
        key: 12,
        label: 'PJCC defaults',
        path: '/admin-other-defaults',
      },
      {
        key: 14,
        label: 'Proposal (Commercial)',
        path: '/admin-defaults-commercial',
      },
      {
        key: 15,
        label: 'Proposal (Residential)',
        path: '/admin-defaults-residential',
      },
      {
        key: 16,
        label: 'Proposal Defaults',
        path: '/admin-proposal-defaults',
      },
      {
        key: 17,
        label: 'Travel Labor Rates',
        path: '/admin-travel-costs',
      },
      {
        key: 18,
        label: 'Users',
        path: '/admin-default-users',
      },
      {
        key: 19,
        label: 'Vendors',
        path: '/admin-default-vendor',
      },
      {
        key: 20,
        label: 'About',
        path: '/admin-default-about',
      },
    ],
  },
  {
    path: '/user-profile',
    exact: true,
    Component: UserProfile,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'contractor',
      'manager',
      'admin',
    ],
    title: 'User Profile',
    icon: UserProfileIcon,
    visible: true,
  },
  {
    path: '/Setting',
    exact: true,
    Component: Setting,
    title: 'Settings',
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'contractor',
      'manager',
      'admin',
    ],
    icon: SettingsIcon,
    visible: true,
  },
  {
    path: '/admin-contractor-data',
    exact: true,

    Component: ContractorData,
    title: 'Contractor data',
    visible: false,
    roles: ['admin'],
  },
  {
    path: '/admin-pay-processing',
    exact: true,
    Component: PayProcessingDefaults,
    title: 'Pay Processing',
    visible: false,
    roles: ['admin'],
  },
  {
    path: '/admin-paint-costs',
    exact: true,
    title: 'Paint Cost',
    visible: false,
    roles: ['admin'],
    Component: PaintCost,
  },
  {
    path: '/admin-carpentry-costs',
    exact: true,
    title: 'Carpentry Cost',
    visible: false,
    roles: ['admin'],
    Component: CarpentryCost,
  },
  {
    path: '/admin-equipment-costs',
    exact: true,
    title: 'Equipment Cost',
    visible: false,
    roles: ['admin'],
    Component: EquipmentCosts,
  },
  {
    path: '/admin-travel-costs',
    exact: true,
    title: 'Travel Cost',
    visible: false,
    roles: ['admin'],
    Component: TravelCost,
  },
  {
    path: '/admin-misc-costs',
    exact: true,
    title: 'Miscellaneous Cost',
    visible: false,
    roles: ['admin'],
    Component: MiscCost,
  },
  {
    path: '/admin-other-defaults',
    exact: true,
    roles: ['admin'],
    Component: OtherDefaults,
  },
  {
    path: '/admin-labor-rates-defaults',
    exact: true,
    roles: ['sales_associate', 'production_associate', 'estimator', 'manager'],
    Component: LaborRatesDefaults,
  },
  {
    path: '/admin-user-profiles',
    exact: true,
    roles: ['sales_associate', 'production_associate', 'estimator', 'manager'],
    Component: UserProfiles,
  },
  {
    path: '/admin-defaults-commercial',
    exact: true,
    roles: ['admin'],
    Component: ProposalDefaultsCom,
  },
  {
    path: '/admin-defaults-residential',
    exact: true,
    roles: ['admin'],
    Component: ProposalDefaultsRes,
  },
  {
    path: '/admin-proposal-defaults',
    exact: true,
    roles: ['admin'],
    Component: ProposalDefault,
  },
  {
    path: '/admin-default-users',
    exact: true,
    roles: ['admin'],
    Component: Users,
  },
  {
    path: '/admin-default-company-info',
    exact: true,
    roles: ['admin'],
    Component: CompanyInfo,
  },
  {
    path: '/admin-default-store-locations',
    exact: true,
    roles: ['admin'],
    Component: StoreLocations,
  },
  {
    path: '/admin-default-vendor',
    exact: true,
    roles: ['admin'],
    Component: Vendors,
  },
  {
    path: '/admin-default-about',
    exact: true,
    roles: ['admin'],
    Component: About,
  },
  {
    path: '/admin-contract-default',
    exact: true,
    roles: ['admin'],
    Component: ContractDefault,
  },
  {
    path: '/admin-onsite-checklist-defaults',
    exact: true,
    roles: ['admin'],
    Component: OnSiteDefaults,
  },

  {
    path: '/admin-integrations',
    exact: true,
    roles: ['sales_associate', 'production_associate', 'estimator', 'manager'],
    Component: Integrations,
  },
  {
    path: '/payment-requests',
    exact: true,
    roles: ['sales_associate', 'production_associate', 'estimator', 'manager'],
    Component: PaymentRequests,
  },
  {
    path: '/create-account',
    exact: true,
    roles: [
      'sales_associate',
      'production_associate',
      'estimator',
      'contractor',
      'manager',
      'admin',
    ],
    Component: CreateAccount,
  },
  //super admin defaults routes

  {
    path: '/super-admin-colors-default',
    exact: true,
    title: 'Colors',
    visible: false,
    roles: ['super_admin'],
    Component: ColorsDefault,
  },
  {
    path: '/super-admin-paint-costs',
    exact: true,
    title: 'Paint Cost',
    visible: false,
    roles: ['super_admin'],
    Component: PaintCost,
  },
  {
    path: '/super-admin-carpentry-costs',
    exact: true,
    title: 'Carpentry Cost',
    visible: false,
    roles: ['super_admin'],
    Component: CarpentryCost,
  },
  {
    path: '/super-admin-equipment-costs',
    exact: true,
    title: 'Equipment Cost',
    visible: false,
    roles: ['super_admin'],
    Component: EquipmentCosts,
  },
  {
    path: '/super-admin-travel-costs',
    exact: true,
    title: 'Travel Cost',
    visible: false,
    roles: ['super_admin'],
    Component: TravelCost,
  },
  {
    path: '/super-admin-misc-costs',
    exact: true,
    title: 'Miscellaneous Cost',
    visible: false,
    roles: ['super_admin'],
    Component: MiscCost,
  },
  {
    path: '/super-admin-other-defaults',
    exact: true,
    roles: ['super_admin'],
    Component: OtherDefaults,
  },
  {
    path: '/super-admin-defaults-commercial',
    exact: true,
    roles: ['super_admin'],
    Component: ProposalDefaultsCom,
  },
  {
    path: '/super-admin-defaults-residential',
    exact: true,
    roles: ['super_admin'],
    Component: ProposalDefaultsRes,
  },
  {
    path: '/super-admin-defaults-segments',
    exact: true,
    roles: ['super_admin'],
    Component: SegmentsDefaults,
  },
  {
    path: '/super-admin-defaults-estimate-checklist',
    exact: true,
    roles: ['super_admin'],
    Component: EstimatorChecklistDefaults,
  },
  {
    path: '/super-admin-default-store-locations',
    exact: true,
    roles: ['super_admin'],
    Component: StoreLocations,
  },
  {
    path: '/super-admin-default-vendor',
    exact: true,
    roles: ['super_admin'],
    Component: Vendors,
  },
  {
    path: '/super-admin-default-about',
    exact: true,
    roles: ['super_admin'],
    Component: About,
  },
  {
    path: '/super-admin-proposal-defaults',
    exact: true,
    roles: ['super_admin'],
    Component: ProposalDefault,
  },
  {
    path: '/super-admin-contract-default',
    exact: true,
    roles: ['super_admin'],
    Component: ContractDefault,
  },
  {
    path: '/super-admin-onsite-checklist-defaults',
    exact: true,
    roles: ['super_admin'],
    Component: OnSiteDefaults,
  },
];
