import {
  Row,
  Col,
  Table,
  Spin,
  Button,
  message,
  Form,
  Modal,
  Input,
  Select,
  Card,
  List,
  Checkbox,
  Tooltip,
  Avatar,
  InputRef,
  Space,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import api from "../../utils/api";
import { columns } from "./config";
import { useParams } from "react-router-dom";
import { EyeIcon, SearchIcon } from "../../utils/icons";
import React from "react";
import TenantSteps from "./TenantSteps";
import { RootStateOrAny, useSelector } from "react-redux";
import axios from "axios";
import { getAuthUser } from "../../utils/authToken";
import { isNull } from "lodash";
import { API_BASE } from "../../constant";
import {
  FilterConfirmProps,
  TableRowSelection,
} from "antd/lib/table/interface";
import FilterColumn from "../../components/filter-columns/FilterColumn";
import ProjectFiltersModal from "../../components/project-filters-modal/ProjectFiltersModal";
import TenantProjectHeader from "../../components/tenant-project-header/TenantProjectHeader";
import MigrateProjectModal from "../../components/migrate-project-modal/MigrateProjectModal";

interface FilterItem {
  text: string;
  value: string;
  checked: boolean;
}

interface Segment {
  _id: string;
  segmentName: string;
  segmentIcon: string;
  __v: number;
  subSegments: string[];
}

interface Project {
  _id: string;
  jobName: string;
  jobNumber: string;
  projectType: string;
  projectSubtype: string;
  streetAddressOne: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  primaryContactName: string;
  primaryContactEmail: string;
  primaryContactAddress: string;
  projectStatus: string;
  segment: Segment;
  subSegment: string;
  [key: string]: any; // For other properties that might exist
}

const TenantProjects = () => {
  const offline = useSelector((state: RootStateOrAny) => state.offline);
  const { online } = offline;
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  const { id } = useParams<{ id: string }>();
  const [tenants, setTenants] = useState<any[]>([]);
  const [tenantProjects, setTenantProjects] = useState<Project[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalProjectsCount, setTotalProjectsCount] = useState<number>(0);

  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);
  const [filteredProjectsCount, setFilteredProjectsCount] = useState<number>(0);
  const [showPagination, setShowPagination] = useState<boolean>(true);
  //filtersStates
  const searchInput = useRef<InputRef>(null);
  const [filters, setFilters] = useState<{ [key: string]: FilterItem[] }>({});
  const [filteredLists, setFilteredLists] = useState<{
    [key: string]: FilterItem[];
  }>({});

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  //Filter Modal States:
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [isMigrateModalVisible, setIsMigrateModalVisible] = useState(false);

  const showFilterModal = () => {
    setIsFilterModalVisible(true);
  };

  const hideFilterModal = () => {
    setIsFilterModalVisible(false);
  };

  const showMigrateModal = () => {
    setIsMigrateModalVisible(true);
  };

  const hideMigrateModal = () => {
    setLoading(true);
    fetchTenantsAllPopulatedProjects();

    setTimeout(() => {
      setIsMigrateModalVisible(false);
    }, 2000);
  };

  const filterKeysList = [
    "jobName",
    "jobNumber",
    "projectType",
    "projectSubtype",
    "segment",
    "subSegment",
    "streetAddressOne",
    "city",
    "state",
    "zip",
    "country",
    "primaryContactName",
    "primaryContactEmail",
    "primaryContactAddress",
    "projectStatus",
  ];

  useEffect(() => {
    if (online) {
      fetchTenantsAllPopulatedProjects();
      axios
        .get(`${API_BASE}tenants`, {
          headers: { Authorization: "Bearer " + user.accessToken },
        })
        .then((response: any) => {
          console.log(response.data);
          setTenants(response.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, [online]);

  const fetchTenantsAllPopulatedProjects = () => {
    const user = getAuthUser();
    axios({
      method: "GET",
      url: `${API_BASE}projects/tenant/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: user.accessToken ? "Bearer " + user.accessToken : "",
        "tenant-id": !isNull(id) ? id : "",
      },
    })
      .then((response: any) => {
        const filtersList = filterKeysList.reduce(
          (acc, filterKey) => {
            acc[filterKey] = createFiltersArray(
              filterKey,
              response.data.projects
            );
            return acc;
          },
          {} as { [key: string]: FilterItem[] }
        );

        setFilteredLists(filtersList);
        setFilters(filtersList);
        setLoading(false);
        setFilteredProjects(response.data.projects);
        setTenantProjects(response.data.projects);
        setTotalProjectsCount(response.data.projectsCount);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log(err);
      });
  };

  const createFiltersArray = (
    keyName: string,
    tenantAllProjects: Project[]
  ): FilterItem[] => {
    const uniqueKeys = new Set<string>();

    return tenantAllProjects
      .map((project) => {
        let key, text, value;
        if (keyName === "segment") {
          key = project.segment?.segmentName;
          text = key;
          value = key;
        } else {
          key = project[keyName];
          text = key;
          value = key;
        }

        if (key && !uniqueKeys.has(key)) {
          uniqueKeys.add(key);
          return { text, value, checked: false };
        }
        return null; // Return null for duplicate or undefined values
      })
      .filter((item): item is FilterItem => item !== null); // Filter out null values
  };

  const clearSelectedRows = () => {
    setSelectedRowKeys([]);
    setSelectAll(false);
  };

  const handleApplyFilters = (selectedFilters: any) => {
    setIsFilterModalVisible(false);
    setLoading(true);

    const filtered = tenantProjects.filter((project) => {
      for (let filterKey in selectedFilters) {
        if (selectedFilters[filterKey].length > 0) {
          const filterValues = selectedFilters[filterKey].map(
            (item: any) => item.text
          );

          switch (filterKey) {
            case "jobName":
            case "jobNumber":
            case "projectType":
            case "projectSubtype":
            case "streetAddressOne":
            case "city":
            case "state":
            case "zip":
            case "country":
            case "primaryContactName":
            case "primaryContactEmail":
            case "primaryContactAddress":
            case "projectStatus":
              if (!filterValues.includes(project[filterKey])) {
                return false;
              }
              break;

            case "segment":
              if (!filterValues.includes(project?.segment?.segmentName)) {
                return false;
              }
              break;

            case "subSegment":
              if (!filterValues.includes(project.subSegment)) {
                return false;
              }
              break;

            default:
              break;
          }
        }
      }
      return true;
    });

    console.log(filtered);
    if (filtered.length === totalProjectsCount) {
      setFilteredProjectsCount(0);
      setShowPagination(true);
    } else {
      setFilteredProjectsCount(filtered.length);
      setShowPagination(false);
    }

    setFilteredProjects(filtered);
    setLoading(false);
  };

  const updatedHandleSearch = (
    selectedKeys: string[],
    confirm: (param?: any) => void,
    dataIndex: string,
    filteredLists: {
      [key: string]: FilterItem[];
    }
  ) => {
    clearSelectedRows();

    const selectedFilters = filteredLists[dataIndex]
      .filter((item) => item.checked)
      .map((item) => item.value);

    if (selectedFilters.length !== 0) {
      setFilteredProjects(
        tenantProjects.filter((project) =>
          selectedFilters.includes(project[dataIndex])
        )
      );
      confirm();
    }
  };

  const updatedHandleReset = (
    clearFilters: () => void,
    dataIndex: string,
    filters: {
      [key: string]: FilterItem[];
    }
  ) => {
    clearSelectedRows();
    clearFilters();
    setFilteredLists((prevFilteredLists) => ({
      ...prevFilteredLists,
      [dataIndex]: filters[dataIndex].map((item) => ({
        ...item,
        checked: false,
      })),
    }));
    setFilteredProjects(tenantProjects);
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <FilterColumn
        dataIndex={dataIndex}
        selectedKeys={selectedKeys}
        setSelectedKeys={setSelectedKeys}
        clearFilters={clearFilters}
        confirm={confirm}
        filters={filters}
        filteredLists={filteredLists}
        handleSearch={updatedHandleSearch}
        handleReset={updatedHandleReset}
      />
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const allRowKeys = filteredProjects.map((project) => project._id);
      setSelectedRowKeys(allRowKeys);
    } else {
      setSelectedRowKeys([]);
    }
    setSelectAll(checked);
  };

  const handleRowSelection = (id: React.Key) => {
    setSelectedRowKeys((prevSelectedRowKeys) => {
      const newSelectedRowKeys = prevSelectedRowKeys.includes(id)
        ? prevSelectedRowKeys.filter((key) => key !== id)
        : [...prevSelectedRowKeys, id];
      setSelectAll(newSelectedRowKeys.length === filteredProjects.length);
      return newSelectedRowKeys;
    });
  };

  const updatedColumns = [
    {
      title: (
        <Checkbox
          checked={selectAll}
          onChange={(e) => handleSelectAll(e.target.checked)}
        />
      ),
      dataIndex: "select",
      render: (_: any, record: Project) => (
        <Checkbox
          checked={selectedRowKeys.includes(record._id)}
          onChange={() => handleRowSelection(record._id)}
        />
      ),
    },
    {
      title: "Project Name",
      render: (data: any) => {
        console.log("render");
        return (
          <>
            <div
              style={{
                width: "200px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ paddingTop: "7px" }}>
                {data?.segment?.segmentIcon && (
                  <img
                    src={`data:image/svg+xml;base64,${Buffer.from(
                      data?.segment?.segmentIcon
                    ).toString("base64")}`}
                    alt="project"
                    width="39"
                    height="39"
                  />
                )}
              </div>

              <div style={{ paddingLeft: "15px" }}>
                <p
                  style={{
                    // margin: "0",
                    marginTop: "10px",
                    color: " #1F1F1F",
                    fontSize: "16px",
                    fontStyle: "normal",
                  }}
                >
                  {data.jobName}
                </p>
                {/* <p
                  style={{
                    fontSize: "12px",
                    color: "rgba(51, 51, 51, 0.7)",
                  }}
                >
                  {data.jobNumber}
                </p> */}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "Job Number",
      dataIndex: "jobNumber",
      render: (data: any) => {
        return <div style={{ width: "105px" }}>{data}</div>;
      },
    },
    {
      title: "Job Type",
      render: (data: any) => {
        return (
          <div style={{ width: "100px" }}>
            <p style={{ fontSize: "16px", margin: 0 }}>{data?.projectType}</p>
          </div>
        );
      },
    },
    {
      title: "Job Sub-Type",
      dataIndex: "projectSubtype",
      render: (data: any) => {
        return <div style={{ width: "105px" }}>{data}</div>;
      },
    },
    {
      title: "Segment",
      render: (data: any) => {
        return (
          <div style={{ width: "100px" }}>
            <p style={{ fontSize: "16px", margin: 0 }}>
              {data?.segment?.segmentName}
            </p>
            <p
              style={{
                color: "rgba(51, 51, 51, 0.7)",
                fontSize: "12px",
              }}
            >
              {data?.subSegment}
            </p>
          </div>
        );
      },
    },
    // {
    //   title: "Job Site Address",
    //   render: (data: any) => {
    //     const jobSiteAddress = `${
    //       data.clientName ? data.clientName + "," : ""
    //     } ${data.streetAddressOne}, ${
    //       data.streetAddressTwo ? data.streetAddressTwo + ", " : ""
    //     } ${data.city}, ${data.state}, ${data.zip}`;
    //     return <div style={{ width: "300px" }}>{jobSiteAddress}</div>;
    //   },
    // },
    {
      title: "Street Address",
      render: (data: any) => {
        const jobSiteAddress = `${data.streetAddressOne}, ${data.streetAddressTwo ? data.streetAddressTwo : ""
          }`;
        return <div style={{ width: "150px" }}>{jobSiteAddress}</div>;
      },
    },
    {
      title: "City",
      dataIndex: "city",
      render: (data: any) => {
        return <div style={{ width: "100px" }}>{data}</div>;
      },
    },
    {
      title: "State",
      dataIndex: "state",
      render: (data: any) => {
        return <div style={{ width: "60px" }}>{data}</div>;
      },
    },
    {
      title: "Zip",
      dataIndex: "zip",
      render: (data: any) => {
        return <div style={{ width: "45px" }}>{data}</div>;
      },
    },
    {
      title: "Country",
      dataIndex: "country",
    },
    {
      title: "Client Name",
      dataIndex: "primaryContactName",
      render: (data: any) => {
        return <div style={{ width: "100px" }}>{data}</div>;
      },
    },
    {
      title: "Client Email",
      dataIndex: "primaryContactEmail",
      render: (data: any) => {
        return <div style={{ width: "100px" }}>{data}</div>;
      },
    },
    {
      title: "Client Address",
      dataIndex: "primaryContactAddress",
      render: (data: any) => {
        return <div style={{ width: "100px" }}>{data}</div>;
      },
    },
    {
      title: "Users",
      render: (data: any) => {
        const getInitials = (name: string) => {
          try {
            const nameArray = name.split(" ");
            const firstName = nameArray[0];
            const lastName = nameArray[nameArray.length - 1];
            if (nameArray.length === 1) {
              const initials = firstName.charAt(0);
              return initials.toUpperCase();
            } else {
              const initials = firstName.charAt(0) + lastName.charAt(0);
              return initials.toUpperCase();
            }
          } catch (err) {
            return "";
          }
        };

        const associateInitials = getInitials(data?.salesAssociate?.fullName);
        const estimatorInitials = getInitials(data?.estimator?.fullName);
        const productionInitials = getInitials(
          data?.productionAssociate?.fullName
        );
        return (
          <div>
            <Avatar.Group>
              {data.salesAssociate && (
                <Tooltip title={data.salesAssociate.fullName}>
                  {data.salesAssociate?.picture && online ? (
                    <Avatar src={data.salesAssociate?.picture} />
                  ) : (
                    <Avatar>{associateInitials}</Avatar>
                  )}
                </Tooltip>
              )}
              {data.estimator && (
                <Tooltip title={data.estimator.fullName}>
                  {data.estimator?.picture && online ? (
                    <Avatar src={data.estimator?.picture} />
                  ) : (
                    <Avatar>{estimatorInitials}</Avatar>
                  )}
                </Tooltip>
              )}
              {data.productionAssociate && (
                <Tooltip title={data.productionAssociate.fullName}>
                  {data.productionAssociate?.picture && online ? (
                    <Avatar src={data.productionAssociate?.picture} />
                  ) : (
                    <Avatar>{productionInitials}</Avatar>
                  )}
                </Tooltip>
              )}
            </Avatar.Group>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "projectStatus",
    },
  ];

  return (
    <>
      <TenantSteps current={1} />
      <Row style={{ justifyContent: "space-between" }}>
        <Col span={17}>
          <h1 style={{ fontWeight: "bold", fontSize: "30px" }}>Projects</h1>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{
              color: "black",
              paddingInline: "50px",
              borderRadius: "8px",
              height: "40px",
            }}
            onClick={showFilterModal}
          >
            Filters{" "}
            {filteredProjectsCount === 0 ? "" : `(${filteredProjectsCount})`}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            style={{
              color: "black",
              paddingInline: "50px",
              borderRadius: "8px",
              height: "40px",
            }}
            onClick={showMigrateModal}
          >
            Migrate Project
          </Button>
        </Col>
      </Row>
      <ProjectFiltersModal
        visible={isFilterModalVisible}
        onClose={hideFilterModal}
        filterList={filteredLists}
        handleApplyFilters={handleApplyFilters}
      />
      <MigrateProjectModal
        selectedProjectList={tenantProjects.filter((project: any) =>
          selectedRowKeys.includes(project._id)
        )}
        tenants={tenants}
        fetchTenantProjects={fetchTenantsAllPopulatedProjects}
        isMigrateModalVisible={isMigrateModalVisible}
        setIsMigrateModalVisible={setIsMigrateModalVisible}
      />
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <hr style={{ border: "1px solid #E8E8E8" }} />
          <div className="pp-default-table">
            <Table
              columns={updatedColumns}
              dataSource={filteredProjects}
              scroll={{ x: 1000 }}
              loading={{
                indicator: (
                  <div>
                    <Spin />
                  </div>
                ),
                spinning: loading,
              }}
              pagination={
                showPagination
                  ? {
                    showSizeChanger: true,
                    defaultPageSize: 20,
                    total: totalProjectsCount,
                    pageSizeOptions: [20],
                    onChange: (page) => {
                      setPage(page);
                    },
                    current: page,
                  }
                  : false
              }
              rowKey="id"
            />
          </div>
        </>
      )}
    </>
  );
};

export default TenantProjects;
