import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Menu, MenuProps } from "antd";
import {
  Form,
  Input,
  Button,
  Select,
  TimePicker,
  Radio,
  Checkbox,
  Card,
  DatePicker,
  Row,
  Col,
  Space,
  message,
  Dropdown,
} from "antd";
import { OfficeIcon, ResidentialIcon } from "../../utils/icons";
import moment from "moment";
import "./CreateForm.less";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import ClientContact from "./ClientContact";
import AddMedia from "../project-media/AddMedia";
import { addProject, createProposal } from "../../redux/project/action";
import { MaskedInput } from "antd-mask-input";

import { usStates, canadaStates } from "../../constant/index";
import AssociatePeopleForm from "./AssociatePeopleForm";

var crypto = require("crypto");

const { Option } = Select;

const CreateForm: React.FC = () => {
  const dispatch = useDispatch<any>();
  const options1 = ["Commercial", "Residential"];
  const options2 = ["Interior", "Exterior"];
  const [subSegments, setSubSegments] = useState([]);
  const [states, setStates] = useState<any>([...usStates, ...canadaStates]);

  const [form] = Form.useForm();
  const { adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const history = useHistory();

  const [isOnsite, setIsOnsite] = useState(false);
  const [isMaint, setIsMaint] = useState(false);
  const [projectType, setProjectType] = useState(options1[0]);
  const [secondaryType, setSecondaryType] = useState(options2[0]);
  const associatePeople = useSelector(
    (state: RootStateOrAny) => state.associatePeople
  );

  const {
    selectedSalesAssociate,
    selectedEstimators,
    selectedProductionAssociate,
  } = associatePeople;

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }
      return Promise.resolve();
    },
  };

  const checkJobNumber = {
    validator(_: any, value: string) {
      if (value) {
        if (value.endsWith("_")) {
          return Promise.reject(new Error("Please enter 8 digit number"));
        }
      }

      return Promise.resolve();
    },
  };

  const onProjectChange = (e: any) => {
    setProjectType(e.target.value);
  };
  const onSecondaryChange = (e: any) => {
    setSecondaryType(e.target.value);
  };

  const onCheckSiteChange = (e: any) => {
    if (e.target.checked) {
      setIsOnsite(true);
    } else {
      setIsOnsite(false);
    }
  };
  const onCheckMainChange = (e: any) => {
    if (e.target.checked) {
      setIsMaint(true);
    } else {
      setIsMaint(false);
    }
  };

  const onFinish = (values: any) => {
    var id = crypto.randomBytes(12).toString("hex");
    var proposalId = crypto.randomBytes(12).toString("hex");
    let estimateDate = moment();

    if (values.estimateDate && values.estimateTime) {
      estimateDate = estimateDate
        .startOf("day")
        .add(values.estimateTime.hours(), "hours")
        .add(values.estimateTime.minutes(), "minutes");
    }

    const findSegment = adminDefaults.marketSegments.find(
      (item: any) => item._id === values.segment
    );
    const data = {
      ...values,
      _id: id,
      primaryContactName: values.PrimaryContactName,
      primaryContactTelephone: values.PrimaryContactTelephone,
      primaryContactCell: values.PrimaryContactCell,
      primaryContactEmail: values.PrimaryContactEmail,
      primaryContactAddress: values.PrimaryContactAddress,
      hasMaintenanceContact: isMaint,
      maintenanceContactName: values.MaintenanceContactName,
      maintenanceContactTelephone: values.MaintenanceContactTelephone,
      maintenanceContactAddress: values.MaintenanceContactAddress,
      maintenanceContactCell: values.MaintenanceContactCell,
      maintenanceContactEmail: values.MaintenanceContactEmail,
      onSiteContactName: values["On-siteContactName"],
      onSiteContactTelephone: values["On-siteContactTelephone"],
      onSiteContactAddress: values["On-siteContactAddress"],
      onSiteContactCell: values["On-siteContactCell"],
      onSiteContactEmail: values["On-siteContactEmail"],
      hasOnSiteContact: isOnsite,
      projectType: projectType,
      projectSubtype: secondaryType,
      salesAssociate:
        Object.keys(selectedSalesAssociate).length > 0
          ? selectedSalesAssociate
          : undefined,
      estimator:
        Object.keys(selectedEstimators).length > 0
          ? selectedEstimators
          : undefined,
      productionAssociate:
        Object.keys(selectedProductionAssociate).length > 0
          ? selectedProductionAssociate
          : undefined,

      segment: findSegment,
      subSegment: values.subSegment,
      estimateScheduledDate: estimateDate,
      leadDate: values.leadDate.valueOf(),
      createdAt: moment().unix(),
      updatedAt: moment().unix(),
      projectState: 1,
      paintWageRate: adminDefaults.wageDefaults.find(
        (item: any) => item.title === "Painting Wage Rate"
      )?.wageValue,
      carpentryWageRate: adminDefaults.wageDefaults.find(
        (item: any) => item.title === "Carpentry Wage Rate"
      )?.wageValue,
      ssConst: adminDefaults.wageDefaults.find(
        (item: any) => item.title === "SS"
      )?.wageValue,
      setupConst: adminDefaults.wageDefaults.find(
        (item: any) => item.title === "Setup"
      )?.wageValue,
      coatsMultiplier: adminDefaults.wageDefaults.find(
        (item: any) => item.title === "Additional Coats Percentage"
      )?.wageValue,
      tieredMargins: adminDefaults.marginDefaults,
      checkRequestRetainerPercent:
        adminDefaults?.contractPaymentDefault?.retainerPercentage,
      projectStatus: "New Lead",
    };
    const proposalDefaultObj = {
      _id: proposalId,
      setup: [],
      surface: [],
      termsAndConditions: [],
      optionImagesPerRow: 3,
      addOptionMedia: true,
    }
    if (
      Object.keys(selectedSalesAssociate).length === 0 ||
      Object.keys(selectedEstimators).length === 0
    ) {
      message.warn("Please select associates");
    } else {
      dispatch(addProject(data));
      dispatch(createProposal(id, proposalDefaultObj));
      history.push(`/manager-preview-profile/${id}`);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "+" || e.key === "-" || e.key === "." || e.key === "e") {
      e.preventDefault();
    }
  };

  const handleSegment = (value: any) => {
    const segment = adminDefaults?.marketSegments?.find(
      (item: any) => item._id === value
    );

    if (segment) {
      setSubSegments(segment.subSegments);
      form.setFieldsValue({
        subSegment: null,
      });
    }
  };

  const handleCountry = (value: any) => {
    if (usStates.includes(value)) {
      form.setFieldsValue({
        country: "United States",
      });
    }
    if (canadaStates.includes(value)) {
      form.setFieldsValue({
        country: "Canada",
      });
    }
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        layout="vertical"
      >
        <Row gutter={24}>
          <Col lg={15} sm={24}>
            <div
              style={{ boxShadow: "0px 0px 13px 2px rgba(5, 21, 63, 0.04) " }}
            >
              <Card className="root-container">
                <div className="title-container">
                  <h1 className="form-title">Create Profile</h1>
                </div>
                <hr style={{ border: "1px solid #E8E8E8" }} />

                <div className="form-container ant-select-container">
                  <div className="project-type-container">
                    <h1 className="project-type"> Select Project Type</h1>
                    <Form.Item>
                      <div className="radio-btn-container">
                        <Radio.Group
                          onChange={onProjectChange}
                          value={projectType}
                          optionType="button"
                          buttonStyle="solid"
                        >
                          <Radio.Button value="Commercial">
                            <div>
                              <div>
                                <OfficeIcon />
                              </div>
                              <div>{options1[0]} </div>
                            </div>
                          </Radio.Button>
                          <Radio.Button value="Residential">
                            <div>
                              <ResidentialIcon />
                            </div>
                            <div>{options1[1]} </div>
                          </Radio.Button>
                        </Radio.Group>
                      </div>
                      <div className="radio-btn-container-2">
                        <Radio.Group
                          onChange={onSecondaryChange}
                          value={secondaryType}
                          optionType="button"
                          buttonStyle="solid"
                        >
                          <Radio.Button value="Interior">
                            {options2[0]}
                          </Radio.Button>
                          <Radio.Button value="Exterior">
                            {options2[1]}
                          </Radio.Button>
                        </Radio.Group>
                      </div>
                    </Form.Item>
                  </div>
                  <div className="job-container">
                    <h1 className="form-subtitle">Job information</h1>
                    <div className="job-form" style={{ marginTop: 23 }}>
                      <Row gutter={17}>
                        <Col span={12}>
                          <Form.Item
                            hasFeedback
                            name="jobName"
                            label={<h1 className="form-text">Job Name</h1>}
                            rules={[
                              {
                                required: true,
                                message: "Please enter the Job Name!",
                              },
                              checkEmpty,
                            ]}
                          >
                            <Input className="form-input" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            hasFeedback
                            name="clientName"
                            label={<h1 className="form-text">Client's Name</h1>}
                            rules={[
                              {
                                required: true,
                                message: "Please enter the Client Name!",
                              },
                              checkEmpty,
                            ]}
                          >
                            <Input className="form-input" />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={17}>
                        <Col span={12}>
                          <Form.Item
                            hasFeedback
                            name="streetAddressOne"
                            label={
                              <h1 className="form-text">Street Address 1</h1>
                            }
                            rules={[
                              {
                                required: true,
                                message: "Please enter the Street Address!",
                              },
                              checkEmpty,
                            ]}
                          >
                            <Input className="form-input" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <div className="address-display">
                            <Form.Item
                              name="streetAddressTwo"
                              label={
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <h1 className="form-text">
                                    Street Address 2
                                  </h1>
                                  <p
                                    style={{
                                      color: "#CACACA",
                                      margin: 0,
                                      padding: 0,
                                    }}
                                  >
                                    (optional)
                                  </p>
                                </div>
                              }
                              rules={[checkEmpty]}
                            >
                              <Input className="form-input" />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>

                      <Row gutter={17}>
                        <Col span={12}>
                          <Form.Item
                            hasFeedback
                            name="city"
                            label={<h1 className="form-text">City</h1>}
                            rules={[
                              {
                                required: true,
                                message: "Please enter the City!",
                              },
                              checkEmpty,
                            ]}
                          >
                            <Input className="form-input" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <div className="createForm--state-dropdown">
                            <Form.Item
                              hasFeedback
                              name="state"
                              label={<h1 className="form-text">State</h1>}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter the State!",
                                },
                                checkEmpty,
                              ]}
                            >
                              <Select
                                showSearch
                                onChange={handleCountry}
                                className="select-field"
                                placeholder="State"
                                filterOption={(input: any, option: any) =>
                                  (option?.children?.toString() ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {states
                                  ?.sort()
                                  .map((item: any, index: number) => {
                                    return (
                                      <option key={index} value={item}>
                                        {item}
                                      </option>
                                    );
                                  })}
                              </Select>
                              {/* <Input className="form-input" /> */}
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>

                      <Row gutter={17}>
                        <Col span={12}>
                          <div>
                            <Form.Item
                              hasFeedback
                              name="zip"
                              label={<h1 className="form-text">Zip</h1>}
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter the Zip code!",
                                },
                                {
                                  max: 5,
                                  message: "Code cannot exceed 5 digits",
                                },
                                checkEmpty,
                              ]}
                            >
                              <Input
                                type="number"
                                min={0}
                                className="form-input"
                                onKeyDown={handleKeyDown}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            hasFeedback
                            name="country"
                            label={<h1 className="form-text">Country</h1>}
                          >
                            {/* <Select
                          onChange={handleCountry}
                          className="select-field"
                          placeholder="Country"
                        >
                          <Option value={"Canada"}>Canada</Option>
                          <Option value={"United States"}>United States</Option>
                        </Select> */}
                            <Input className="form-input" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <h1 className="form-subtitle">Client's contact</h1>
                    <div>
                      <div className="checkbox-container">
                        <Checkbox checked disabled>
                          Primary
                        </Checkbox>
                        <Checkbox
                          checked={isOnsite}
                          onChange={onCheckSiteChange}
                        >
                          On-site
                        </Checkbox>
                        <Checkbox
                          checked={isMaint}
                          onChange={onCheckMainChange}
                        >
                          Invoice
                        </Checkbox>
                      </div>
                      <ClientContact form={form} title={"Primary"} />
                      {isOnsite ? (
                        <ClientContact form={form} title={"On-site"} />
                      ) : null}
                      {isMaint ? (
                        <ClientContact form={form} title={"Maintenance"} />
                      ) : null}
                    </div>
                  </div>

                  <h1 className="form-subtitle">Tracking</h1>
                  <Row gutter={17}>
                    <Col span={12}>
                      <div>
                        <Form.Item
                          rules={[
                            { required: true, message: "Please select!" },
                          ]}
                          name="segment"
                          label={<h1 className="form-text">Segment</h1>}
                        >
                          <Select
                            onChange={handleSegment}
                            className="select-field"
                            placeholder="Segment"
                          >
                            {adminDefaults?.marketSegments?.map((item: any) => {
                              return (
                                <Option key={item._id} value={item._id}>
                                  {item.segmentName}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div>
                        <Form.Item
                          name="subSegment"
                          label={<h1 className="form-text">Sub Segment</h1>}
                        >
                          <Select
                            className="select-field"
                            placeholder="Sub Segment"
                          >
                            {subSegments?.map((item: any, index: number) => {
                              return (
                                <Option key={index} value={item}>
                                  {item}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row gutter={17}>
                    <Col className="estimate-container" span={12}>
                      <Row>
                        <Col>
                          <h1 className="form-text">Estimate scheduled</h1>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 8 }} gutter={20}>
                        <Col span={12}>
                          <Form.Item name="estimateDate">
                            <DatePicker
                              disabledDate={(current) => {
                                return moment().add(-1, "days") >= current;
                              }}
                              className="estimate-date"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="estimateTime">
                            <TimePicker
                              className="estimate-date"
                              use12Hours
                              format="h:mm A"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <div className="date-container">
                        <Form.Item
                          name="leadDate"
                          label={<h1 className="form-text">Lead Date</h1>}
                          rules={[
                            { required: true, message: "Please select!" },
                          ]}
                        >
                          <DatePicker className="lead-date" />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <div>
                        <Form.Item
                          name="jobNumber"
                          label={<h1 className="form-text">C1 Job Number</h1>}
                          rules={[
                            {
                              required: true,
                              message: "Please enter the number!",
                            },
                            checkJobNumber,
                          ]}
                          hasFeedback
                        >
                          <MaskedInput
                            mask={"JOB-0000-0000"}
                            className="form-input"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <AddMedia />
                </div>
              </Card>
            </div>
          </Col>
          <Col className="gutter-row" lg={9} sm={24}>
            <AssociatePeopleForm />
          </Col>
        </Row>
        <div>
          <Row>
            <Col lg={5} md={9}></Col>
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="save-button"
                >
                  Save and Preview
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default CreateForm;
