import HTMLParser from "html-to-json-parser";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import { LatoBold } from "../assets/fonts/LatoBold";
import { LatoRegular } from "../assets/fonts/LatoRegular";
import dot from "../assets/images/pdfdot.jpg";
// import { content } from "html2canvas/dist/types/css/property-descriptors/content";

function drawDivider(
  document: jsPDF,
  position: number,
  x1: number,
  x2: number
) {
  document.setLineWidth(3);
  document.setDrawColor("#fdb913");
  document.line(x1, position, x2, position);
}

const getColorFromHtmlStyleString = (
  styleText: string
): [number, number, number] => {
  let startIndex = styleText.indexOf("(");
  let endIndex = styleText.indexOf(")");
  let rgbString = styleText.substring(startIndex + 1, endIndex);
  let rgbValuesArray = rgbString
    .split(",")
    .map((s) => s.trim())
    .map((rgbValueString) => parseInt(rgbValueString));
  return [rgbValuesArray[0], rgbValuesArray[1], rgbValuesArray[2]];
};

const printWordsWithWrapping = (
  doc: jsPDF,
  text: string,
  xPos: number,
  yPos: number,
  lineWidth: number,
  horizontalMargin: number
) => {
  let splitText = doc.splitTextToSize(text, lineWidth - xPos);
  for (const singleTextString of splitText) {
    let words = singleTextString.split(" ");
    if (yPos > 800) {
      doc.addPage();
      yPos = 50;
    }
    for (let word of words) {
      word = word.trim();
      if (xPos + doc.getTextWidth(word) > lineWidth) {
        yPos += 10;
        xPos = horizontalMargin; //change it to horizontalMargin after discussion
      }
      let wordWithSpace = `${word} `;
      if (word.length < 1) {
        continue;
      }
      doc.text(wordWithSpace, xPos, yPos);
      xPos += doc.getTextWidth(wordWithSpace);
    }
  }
  return { xPos, yPos };
};

const hasLargerFontSize = async (
  proposalDoc: jsPDF,
  pTagWithMultipleElements: any
) => {
  let fontSize = 0;
  for (const element of pTagWithMultipleElements) {
    if (
      typeof element === "object" &&
      element.type === "span" &&
      element.attributes.class
    ) {
      if (element.attributes.class === "ql-size-large") {
        if (fontSize !== 2) fontSize = 1;
      } else if (element.attributes.class === "ql-size-huge") {
        fontSize = 2;
      }
    }
  }
  return fontSize;
};

const addSvgImageToPdf = async (
  svgString: string,
  contractDoc: any,
  x: number,
  y: number
) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Failed to get canvas context");
  }

  const img = new Image();

  // Must return a promise that resolves when the image is loaded
  await new Promise((resolve, reject) => {
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const imgData = canvas.toDataURL("image/png");

      contractDoc.addImage(imgData, "PNG", x, y, 18, 18);
      resolve(null);
    };

    img.onerror = reject;
    img.src = svgString;
  });
};

// async function processParsedHtml(
//   contractDoc: any,
//   parsedHtml: any,
//   horizontalMargin: any,
//   docEndPosition: any,
//   lineWidth: number
// ) {
//   let cursorPosition = {
//     xPos: horizontalMargin,
//     yPos: docEndPosition,
//   };

//   const defaultFillColor = contractDoc.getFillColor();
//   const defaultTextColor = contractDoc.getTextColor();
//   const defaultFontSize = contractDoc.getFontSize();
//   for (const element of parsedHtml.content) {
//     if (element.type === "p") {
//       if (element.content.length > 1) {
//         const containsLargerFontSize = await hasLargerFontSize(
//           contractDoc,
//           element.content
//         );
//         if (containsLargerFontSize === 1) {
//           docEndPosition += 10;
//         } else if (containsLargerFontSize === 2) {
//           docEndPosition += 5;
//         }
//         let xPos = horizontalMargin;
//         element.content.forEach((currentElement: any) => {
//           if (typeof currentElement === "string") {
//             cursorPosition = printWordsWithWrapping(
//               contractDoc,
//               currentElement,
//               xPos,
//               docEndPosition,
//               lineWidth
//             );
//             xPos = cursorPosition.xPos;
//             docEndPosition = cursorPosition.yPos;
//           } else if (currentElement.type === "br") {
//             docEndPosition += 10;
//           } else if (typeof currentElement === "object") {
//             switch (currentElement.type) {
//               case "strong":
//                 contractDoc.setFont("LatoRegular", "bold");
//                 cursorPosition = printWordsWithWrapping(
//                   contractDoc,
//                   currentElement.content[0],
//                   xPos,
//                   docEndPosition,
//                   lineWidth
//                 );
//                 docEndPosition = cursorPosition.yPos;
//                 xPos = cursorPosition.xPos;
//                 contractDoc.setFont("LatoRegular", "normal");
//                 break;
//               case "em":
//                 contractDoc.setFont("LatoRegular", "italic");
//                 cursorPosition = printWordsWithWrapping(
//                   contractDoc,
//                   currentElement.content[0],
//                   xPos,
//                   docEndPosition,
//                   lineWidth
//                 );
//                 docEndPosition = cursorPosition.yPos;
//                 xPos = cursorPosition.xPos;
//                 contractDoc.setFont("LatoRegular", "normal");
//                 break;
//               case "u": {
//                 const textWidth = contractDoc.getTextWidth(
//                   currentElement.content[0]
//                 );
//                 contractDoc.line(
//                   xPos,
//                   docEndPosition + 1,
//                   textWidth + xPos,
//                   docEndPosition + 1
//                 );
//                 cursorPosition = printWordsWithWrapping(
//                   contractDoc,
//                   currentElement.content[0],
//                   xPos,
//                   docEndPosition,
//                   lineWidth
//                 );
//                 docEndPosition = cursorPosition.yPos;
//                 xPos = cursorPosition.xPos;
//                 break;
//               }
//               case "s": {
//                 const textWidth = contractDoc.getTextWidth(
//                   currentElement.content[0]
//                 );
//                 contractDoc.line(
//                   xPos,
//                   docEndPosition - 3,
//                   textWidth + xPos,
//                   docEndPosition - 3,
//                   "FD"
//                 );
//                 cursorPosition = printWordsWithWrapping(
//                   contractDoc,
//                   currentElement.content[0],
//                   xPos,
//                   docEndPosition,
//                   lineWidth
//                 );
//                 docEndPosition = cursorPosition.yPos;
//                 xPos = cursorPosition.xPos;
//                 break;
//               }
//               case "a":
//                 contractDoc.setTextColor("blue");
//                 if (
//                   xPos + contractDoc.getTextWidth(currentElement.content[0]) >
//                   565
//                 ) {
//                   xPos = horizontalMargin;
//                   docEndPosition += 10;
//                 }
//                 contractDoc.textWithLink(
//                   currentElement.content[0],
//                   xPos,
//                   docEndPosition,
//                   { url: currentElement.attributes.href }
//                 );
//                 xPos += contractDoc.getTextWidth(currentElement.content[0]);
//                 contractDoc.setTextColor(defaultTextColor);
//                 break;
//               case "span":
//                 let contentStyle = currentElement.attributes.style;
//                 if (contentStyle) {
//                   if (contentStyle.startsWith("background-color")) {
//                     let colorArry = getColorFromHtmlStyleString(contentStyle);
//                     const textWidth = contractDoc.getTextWidth(
//                       currentElement.content[0]
//                     );
//                     contractDoc.setFillColor(
//                       colorArry[0],
//                       colorArry[1],
//                       colorArry[2]
//                     );
//                     contractDoc.rect(
//                       xPos,
//                       docEndPosition - 8,
//                       textWidth,
//                       10,
//                       "F"
//                     );
//                     cursorPosition = printWordsWithWrapping(
//                       contractDoc,
//                       currentElement.content[0],
//                       xPos,
//                       docEndPosition,
//                       lineWidth
//                     );
//                     docEndPosition = cursorPosition.yPos;
//                     xPos = cursorPosition.xPos;
//                     contractDoc.setFillColor(defaultFillColor);
//                   } else if (contentStyle.startsWith("color")) {
//                     let colorArry = getColorFromHtmlStyleString(contentStyle);
//                     contractDoc.setTextColor(
//                       colorArry[0],
//                       colorArry[1],
//                       colorArry[2]
//                     );
//                     cursorPosition = printWordsWithWrapping(
//                       contractDoc,
//                       currentElement.content[0],
//                       xPos,
//                       docEndPosition,
//                       lineWidth
//                     );
//                     docEndPosition = cursorPosition.yPos;
//                     xPos = cursorPosition.xPos;
//                     contractDoc.setTextColor(defaultTextColor);
//                   }
//                 } else {
//                   contentStyle = currentElement.attributes.class;
//                   switch (contentStyle) {
//                     case "ql-size-huge":
//                       contractDoc.setFontSize(18);
//                       cursorPosition = printWordsWithWrapping(
//                         contractDoc,
//                         currentElement.content[0],
//                         xPos,
//                         docEndPosition,
//                         lineWidth
//                       );
//                       docEndPosition = cursorPosition.yPos;
//                       xPos = cursorPosition.xPos;
//                       contractDoc.setFontSize(defaultFontSize);
//                       break;
//                     case "ql-size-large":
//                       contractDoc.setFontSize(12);
//                       cursorPosition = printWordsWithWrapping(
//                         contractDoc,
//                         currentElement.content[0],
//                         xPos,
//                         docEndPosition,
//                         lineWidth
//                       );
//                       docEndPosition = cursorPosition.yPos;
//                       xPos = cursorPosition.xPos;
//                       contractDoc.setFontSize(defaultFontSize);
//                       break;
//                     case "ql-size-small":
//                       contractDoc.setFontSize(6);
//                       cursorPosition = printWordsWithWrapping(
//                         contractDoc,
//                         currentElement.content[0],
//                         xPos,
//                         docEndPosition,
//                         lineWidth
//                       );
//                       docEndPosition = cursorPosition.yPos;
//                       xPos = cursorPosition.xPos;
//                       contractDoc.setFontSize(defaultFontSize);
//                       break;
//                     default:
//                       break;
//                   }
//                 }
//                 break;
//               default:
//                 console.log("default case: ", currentElement);
//             }
//             if (xPos >= 565) {
//               docEndPosition += 10;
//               xPos = horizontalMargin;
//             }
//           }
//         });
//         docEndPosition += 10;
//       } else {
//         let isLink = false;
//         const content = element.content[0];
//         if (typeof content === "string") {
//           let splitText = contractDoc.splitTextToSize(
//             content,
//             565 - horizontalMargin
//           );
//           contractDoc.text(splitText, horizontalMargin, docEndPosition);
//           docEndPosition += 10 * splitText.length;
//         } else {
//           switch (content.type) {
//             case "strong":
//               contractDoc.setFont("LatoRegular", "bold");
//               break;
//             case "em":
//               contractDoc.setFont("LatoRegular", "italic");
//               break;
//             case "u": {
//               const textWidth = contractDoc.getTextWidth(content.content[0]);
//               contractDoc.line(
//                 horizontalMargin,
//                 docEndPosition + 1,
//                 textWidth + horizontalMargin,
//                 docEndPosition + 1
//               );
//               break;
//             }
//             case "s": {
//               const textWidth = contractDoc.getTextWidth(content.content[0]);
//               contractDoc.line(
//                 horizontalMargin,
//                 docEndPosition - 3,
//                 textWidth + horizontalMargin,
//                 docEndPosition - 3,
//                 "FD"
//               );
//               break;
//             }
//             case "a":
//               contractDoc.setTextColor("blue");
//               contractDoc.textWithLink(
//                 content.content[0],
//                 horizontalMargin,
//                 docEndPosition,
//                 { url: content.attributes.href }
//               );
//               docEndPosition += 10;
//               isLink = true;
//               break;
//             case "span":
//               let contentStyle = content.attributes.style;
//               if (contentStyle) {
//                 if (contentStyle.startsWith("background-color")) {
//                   let colorArry = getColorFromHtmlStyleString(contentStyle);
//                   const textWidth = contractDoc.getTextWidth(
//                     content.content[0]
//                   );
//                   contractDoc.setFillColor(
//                     colorArry[0],
//                     colorArry[1],
//                     colorArry[2]
//                   );
//                   contractDoc.rect(
//                     horizontalMargin,
//                     docEndPosition - 8,
//                     textWidth,
//                     10,
//                     "F"
//                   );
//                 } else if (contentStyle.startsWith("color")) {
//                   let colorArry = getColorFromHtmlStyleString(contentStyle);
//                   contractDoc.setTextColor(
//                     colorArry[0],
//                     colorArry[1],
//                     colorArry[2]
//                   );
//                 }
//               } else {
//                 contentStyle = content.attributes.class;
//                 switch (contentStyle) {
//                   case "ql-size-huge":
//                     contractDoc.setFontSize(18);
//                     docEndPosition += 10;
//                     break;
//                   case "ql-size-large":
//                     contractDoc.setFontSize(12);
//                     docEndPosition += 5;
//                     break;
//                   case "ql-size-small":
//                     contractDoc.setFontSize(6);
//                     break;
//                   default:
//                     break;
//                 }
//               }
//               break;
//             case "br":
//               docEndPosition += 10;
//               break;

//             default:
//               console.log("default case: ", content);
//           }
//           if (!isLink) {
//             let text = content.content ? content.content[0] : " ";
//             let splitText = contractDoc.splitTextToSize(
//               text,
//               lineWidth - horizontalMargin
//             );
//             for (const singleTextString of splitText) {
//               if (docEndPosition > 800) {
//                 contractDoc.addPage();
//                 docEndPosition = 50;
//               }
//               contractDoc.text(
//                 singleTextString,
//                 horizontalMargin,
//                 docEndPosition
//               );
//               docEndPosition += 11;
//             }
//             // contractDoc.text(splitText, horizontalMargin, docEndPosition);
//             // docEndPosition += splitText.length * 10;
//           }
//           contractDoc.setTextColor(defaultTextColor);
//           contractDoc.setFontSize(defaultFontSize);
//           contractDoc.setFont("LatoRegular", "normal");
//         }
//       }
//     } else if (element.type === "u") {
//       if (element.content.length === 1 && element.content.type === "li") {
//         const dotImage = new Image();
//         dotImage.src = dot;

//         contractDoc.addImage(
//           dotImage,
//           "jpg",
//           horizontalMargin,
//           docEndPosition,
//           3,
//           3
//         );
//         docEndPosition += 5;
//         contractDoc.setFontSize(defaultFontSize);
//         contractDoc.setFont("LatoRegular", "normal");
//         let splitText = contractDoc.splitTextToSize(
//           `${element.content[0].content}`,
//           530 - horizontalMargin
//         );

//         contractDoc.text(splitText, horizontalMargin + 10, docEndPosition);
//         docEndPosition += 10 * splitText.length;
//       } else if (typeof element.content === "object") {
//         // switch (currentElement.type) {
//         //   case "strong":
//         //     contractDoc.setFont("LatoRegular", "bold");
//         //     cursorPosition = printWordsWithWrapping(
//         //       contractDoc,
//         //       currentElement.content[0],
//         //       xPos,
//         //       docEndPosition,
//         //       lineWidth
//         //     );
//         //     docEndPosition = cursorPosition.yPos;
//         //     xPos = cursorPosition.xPos;
//         //     contractDoc.setFont("LatoRegular", "normal");
//         //     break;
//         //   case "em":
//         //     contractDoc.setFont("LatoRegular", "italic");
//         //     cursorPosition = printWordsWithWrapping(
//         //       contractDoc,
//         //       currentElement.content[0],
//         //       xPos,
//         //       docEndPosition,
//         //       lineWidth
//         //     );
//         //     docEndPosition = cursorPosition.yPos;
//         //     xPos = cursorPosition.xPos;
//         //     contractDoc.setFont("LatoRegular", "normal");
//         //     break;
//         //   case "u": {
//         //     const textWidth = contractDoc.getTextWidth(
//         //       currentElement.content[0]
//         //     );
//         //     contractDoc.line(
//         //       xPos,
//         //       docEndPosition + 1,
//         //       textWidth + xPos,
//         //       docEndPosition + 1
//         //     );
//         //     cursorPosition = printWordsWithWrapping(
//         //       contractDoc,
//         //       currentElement.content[0],
//         //       xPos,
//         //       docEndPosition,
//         //       lineWidth
//         //     );
//         //     docEndPosition = cursorPosition.yPos;
//         //     xPos = cursorPosition.xPos;
//         //     break;
//         //   }
//         //   case "s": {
//         //     const textWidth = contractDoc.getTextWidth(
//         //       currentElement.content[0]
//         //     );
//         //     contractDoc.line(
//         //       xPos,
//         //       docEndPosition - 3,
//         //       textWidth + xPos,
//         //       docEndPosition - 3,
//         //       "FD"
//         //     );
//         //     cursorPosition = printWordsWithWrapping(
//         //       contractDoc,
//         //       currentElement.content[0],
//         //       xPos,
//         //       docEndPosition,
//         //       lineWidth
//         //     );
//         //     docEndPosition = cursorPosition.yPos;
//         //     xPos = cursorPosition.xPos;
//         //     break;
//         //   }
//         //   case "a":
//         //     contractDoc.setTextColor("blue");
//         //     if (
//         //       xPos + contractDoc.getTextWidth(currentElement.content[0]) >
//         //       565
//         //     ) {
//         //       xPos = horizontalMargin;
//         //       docEndPosition += 10;
//         //     }
//         //     contractDoc.textWithLink(
//         //       currentElement.content[0],
//         //       xPos,
//         //       docEndPosition,
//         //       { url: currentElement.attributes.href }
//         //     );
//         //     xPos += contractDoc.getTextWidth(currentElement.content[0]);
//         //     contractDoc.setTextColor(defaultTextColor);
//         //     break;
//         //   case "span":
//         //     let contentStyle = currentElement.attributes.style;
//         //     if (contentStyle) {
//         //       if (contentStyle.startsWith("background-color")) {
//         //         let colorArry = getColorFromHtmlStyleString(contentStyle);
//         //         const textWidth = contractDoc.getTextWidth(
//         //           currentElement.content[0]
//         //         );
//         //         contractDoc.setFillColor(
//         //           colorArry[0],
//         //           colorArry[1],
//         //           colorArry[2]
//         //         );
//         //         contractDoc.rect(
//         //           xPos,
//         //           docEndPosition - 8,
//         //           textWidth,
//         //           10,
//         //           "F"
//         //         );
//         //         cursorPosition = printWordsWithWrapping(
//         //           contractDoc,
//         //           currentElement.content[0],
//         //           xPos,
//         //           docEndPosition,
//         //           lineWidth
//         //         );
//         //         docEndPosition = cursorPosition.yPos;
//         //         xPos = cursorPosition.xPos;
//         //         contractDoc.setFillColor(defaultFillColor);
//         //       } else if (contentStyle.startsWith("color")) {
//         //         let colorArry = getColorFromHtmlStyleString(contentStyle);
//         //         contractDoc.setTextColor(
//         //           colorArry[0],
//         //           colorArry[1],
//         //           colorArry[2]
//         //         );
//         //         cursorPosition = printWordsWithWrapping(
//         //           contractDoc,
//         //           currentElement.content[0],
//         //           xPos,
//         //           docEndPosition,
//         //           lineWidth
//         //         );
//         //         docEndPosition = cursorPosition.yPos;
//         //         xPos = cursorPosition.xPos;
//         //         contractDoc.setTextColor(defaultTextColor);
//         //       }
//         //     } else {
//         //       contentStyle = currentElement.attributes.class;
//         //       switch (contentStyle) {
//         //         case "ql-size-huge":
//         //           contractDoc.setFontSize(18);
//         //           cursorPosition = printWordsWithWrapping(
//         //             contractDoc,
//         //             currentElement.content[0],
//         //             xPos,
//         //             docEndPosition,
//         //             lineWidth
//         //           );
//         //           docEndPosition = cursorPosition.yPos;
//         //           xPos = cursorPosition.xPos;
//         //           contractDoc.setFontSize(defaultFontSize);
//         //           break;
//         //         case "ql-size-large":
//         //           contractDoc.setFontSize(12);
//         //           cursorPosition = printWordsWithWrapping(
//         //             contractDoc,
//         //             currentElement.content[0],
//         //             xPos,
//         //             docEndPosition,
//         //             lineWidth
//         //           );
//         //           docEndPosition = cursorPosition.yPos;
//         //           xPos = cursorPosition.xPos;
//         //           contractDoc.setFontSize(defaultFontSize);
//         //           break;
//         //         case "ql-size-small":
//         //           contractDoc.setFontSize(6);
//         //           cursorPosition = printWordsWithWrapping(
//         //             contractDoc,
//         //             currentElement.content[0],
//         //             xPos,
//         //             docEndPosition,
//         //             lineWidth
//         //           );
//         //           docEndPosition = cursorPosition.yPos;
//         //           xPos = cursorPosition.xPos;
//         //           contractDoc.setFontSize(defaultFontSize);
//         //           break;
//         //         default:
//         //           break;
//         //       }
//         //     }
//         //     break;
//         //   default:
//         //     console.log("default case: ", currentElement);
//         // }
//         // if (xPos >= 565) {
//         //   docEndPosition += 10;
//         //   xPos = horizontalMargin;
//         // }
//       }
//       //   if (element.content.length > 1 && Array.isArray(element.content)) {
//       //     element.content.forEach((item:any) => {
//       //         switch (item.type) {
//       //             case "li":
//       //                 const dotImage = new Image();
//       //                 dotImage.src = dot;

//       //                 dotImage.onload = function() {
//       //                     contractDoc.addImage(
//       //                         dotImage,
//       //                         "jpg",
//       //                         horizontalMargin,
//       //                         docEndPosition,
//       //                         3,
//       //                         3
//       //                     );
//       //                     docEndPosition += 5;
//       //                     contractDoc.setFontSize(defaultFontSize);
//       //                     contractDoc.setFont("LatoRegular", "normal");
//       //                     let splitText = contractDoc.splitTextToSize(
//       //                         `${item.content}`,
//       //                         530 - horizontalMargin
//       //                     );

//       //                     contractDoc.text(
//       //                         splitText,
//       //                         horizontalMargin + 10,
//       //                         docEndPosition
//       //                     );

//       //                     docEndPosition += 10 * splitText.length;
//       //                 };
//       //                 break;

//       //             case "strong":
//       //                 contractDoc.setFont("LatoRegular", "bold");
//       //                 break;

//       //             case "em":
//       //                 contractDoc.setFont("LatoRegular", "italic");
//       //                 break;

//       //             case "u":
//       //               {
//       //                 const textWidth:any = contractDoc.getTextWidth(item.content);
//       //                 contractDoc.line(
//       //                     horizontalMargin,
//       //                     docEndPosition + 1,
//       //                     textWidth + horizontalMargin,
//       //                     docEndPosition + 1
//       //                 );
//       //               }
//       //                 break;

//       //             case "s":
//       //                 const textWidth = contractDoc.getTextWidth(item.content);
//       //                 contractDoc.line(
//       //                     horizontalMargin,
//       //                     docEndPosition - 3,
//       //                     textWidth + horizontalMargin,
//       //                     docEndPosition - 3,
//       //                     "FD"
//       //                 );
//       //                 break;

//       //             case "a":
//       //                 contractDoc.setTextColor("blue");
//       //                 contractDoc.textWithLink(
//       //                     item.content,
//       //                     horizontalMargin,
//       //                     docEndPosition,
//       //                     { url: item.attributes.href }
//       //                 );
//       //                 docEndPosition += 10;
//       //                 break;

//       //             case "span":
//       //                 let contentStyle = item.attributes.style;
//       //                 if (contentStyle) {
//       //                     if (contentStyle.startsWith("background-color")) {
//       //                         let colorArry = getColorFromHtmlStyleString(contentStyle);
//       //                         const textWidth = contractDoc.getTextWidth(
//       //                             item.content[0]
//       //                         );
//       //                         contractDoc.setFillColor(
//       //                             colorArry[0],
//       //                             colorArry[1],
//       //                             colorArry[2]
//       //                         );
//       //                         contractDoc.rect(
//       //                             horizontalMargin,
//       //                             docEndPosition - 8,
//       //                             textWidth,
//       //                             10,
//       //                             "F"
//       //                         );
//       //                     } else if (contentStyle.startsWith("color")) {
//       //                         let colorArry = getColorFromHtmlStyleString(contentStyle);
//       //                         contractDoc.setTextColor(
//       //                             colorArry[0],
//       //                             colorArry[1],
//       //                             colorArry[2]
//       //                         );
//       //                     }
//       //                 } else {
//       //                     contentStyle = item.attributes.class;
//       //                     switch (contentStyle) {
//       //                         case "ql-size-huge":
//       //                             contractDoc.setFontSize(18);
//       //                             docEndPosition += 10;
//       //                             break;
//       //                         case "ql-size-large":
//       //                             contractDoc.setFontSize(12);
//       //                             docEndPosition += 5;
//       //                             break;
//       //                         case "ql-size-small":
//       //                             contractDoc.setFontSize(6);
//       //                             break;
//       //                         default:
//       //                             break;
//       //                     }
//       //                 }
//       //                 break;

//       //             case "br":
//       //                 docEndPosition += 10;
//       //                 break;

//       //             default:
//       //                 break;
//       //         }

//       //         if (item.type !== "li") {
//       //             let text = item.content ? item.content[0] : " ";
//       //             let splitText = contractDoc.splitTextToSize(
//       //                 text,
//       //                 lineWidth - horizontalMargin
//       //             );
//       //             contractDoc.text(splitText, horizontalMargin, docEndPosition);
//       //             docEndPosition += splitText.length * 10;

//       //             contractDoc.setTextColor(defaultTextColor);
//       //             contractDoc.setFontSize(defaultFontSize);
//       //             contractDoc.setFont("LatoRegular", "normal");
//       //         }
//       //     });
//       // }
//     }
//   }
//   return docEndPosition;
// }

function extractStyles(styleString: string) {
  const styles = styleString
    .split(";")
    .reduce((acc: Record<string, string>, curr: string) => {
      const [key, value] = curr.split(":").map((item) => item.trim());
      if (key && value) {
        acc[key] = value;
      }
      return acc;
    }, {});

  return {
    backgroundColor: styles["background-color"],
    color: styles["color"],
  };
}

function returnCorrectConfigurationForJsPDF(
  contractDoc: jsPDF,
  currentElement: any,
  horizontalMargin: any,
  docEndPosition: any,
  lineWidth: number,
  strongTrue: boolean
) {
  let splitText = [];
  if (currentElement.type === "br" && !currentElement.content) {
    docEndPosition += 10;
  } else {
    if (typeof currentElement.content[0] === "string") {
      splitText = contractDoc.splitTextToSize(
        currentElement.content[0],
        lineWidth - horizontalMargin
      );
    } else {
      splitText = contractDoc.splitTextToSize(
        currentElement.content[0].content[0],
        lineWidth - horizontalMargin
      );
    }

    switch (currentElement.type) {
      case "strong":
        contractDoc.setFont("LatoBold", "bold");
        strongTrue = true;
        break;
      case "em":
        if (strongTrue) {
          contractDoc.setFont("LatoBold", "italic", "bold");
          strongTrue = false;
        } else {
          contractDoc.setFont("LatoRegular", "italic");
        }
        break;
      case "s":
        for (const singleTextString of splitText) {
          const textWidth = contractDoc.getTextWidth(singleTextString);
          // if (docEndPosition > 800) {
          //   contractDoc.addPage();
          //   docEndPosition = 50;
          // }
          contractDoc.line(
            horizontalMargin,
            docEndPosition - 3,
            textWidth + horizontalMargin,
            docEndPosition - 3,
            "FD"
          );
          docEndPosition += 10;
        }

        break;
      case "u":
        for (const singleTextString of splitText) {
          const textWidth = contractDoc.getTextWidth(singleTextString);
          // if (docEndPosition > 800) {
          //   contractDoc.addPage();
          //   docEndPosition = 50;
          // }
          contractDoc.line(
            horizontalMargin,
            docEndPosition + 1,
            textWidth + horizontalMargin,
            docEndPosition
          );
          docEndPosition += 10;
        }

        break;
    }
  }

  return strongTrue;
}

type NestedContent = {
  type?: string;
  content?: NestedContent[] | string;
  attributes?: any;
};

function processTypes(
  nestedObject: NestedContent | NestedContent[] | string,
  contractDoc: jsPDF,
  horizontalMargin: number,
  docEndPosition: number,
  lineWidth: number,
  defaultTextColor: any,
  defaultFontSize: any,
  isTextStrong: boolean
) {
  // let cursorPosition = {
  //   xPos: horizontalMargin,
  //   yPos: docEndPosition,
  // };
  let currentXPos = horizontalMargin;
  let currentYPos = docEndPosition;
  let xPos = horizontalMargin;
  // let updatedHorizontalMargin = horizontalMargin;
  if (typeof nestedObject === "string") {
    const strCursorPosition = printWordsWithWrapping(
      contractDoc,
      nestedObject,
      currentXPos,
      currentYPos,
      lineWidth,
      horizontalMargin
    );
    currentXPos = strCursorPosition.xPos;
    currentYPos = strCursorPosition.yPos + 16;

    contractDoc.setTextColor(defaultTextColor);
    contractDoc.setFontSize(defaultFontSize);
    contractDoc.setFont("LatoRegular", "normal");
  } else if (Array.isArray(nestedObject)) {
    // It's an array
    nestedObject.forEach((element, index) => {
      const result = processTypes(
        element,
        contractDoc,
        currentXPos,
        currentYPos,
        lineWidth,
        defaultTextColor,
        defaultFontSize,
        isTextStrong
      );
      currentXPos = result.xPos;
      currentYPos = result.yPos;
    });
  } else {
    // It's an object
    if (nestedObject.type) {
      switch (nestedObject.type) {
        case "span":
          let contentStyle = nestedObject.attributes.style;
          let contentClass = nestedObject.attributes.class;
          if (contentStyle) {
            const { backgroundColor, color } = extractStyles(contentStyle);
            if (backgroundColor) {
              const backgroundColorRBG =
                getColorFromHtmlStyleString(backgroundColor);
              if (
                Array.isArray(nestedObject.content) &&
                typeof nestedObject.content[0] === "string"
              ) {
                const textWidth = contractDoc.getTextWidth(
                  nestedObject.content[0]
                );
                contractDoc.setFillColor(...backgroundColorRBG);
                contractDoc.rect(
                  horizontalMargin,
                  docEndPosition - 8,
                  textWidth,
                  10,
                  "F"
                );
              }
            }
            if (color) {
              const colorRBG = getColorFromHtmlStyleString(color);
              contractDoc.setTextColor(...colorRBG);
            }
          }
          if (contentClass) {
            switch (contentClass) {
              case "ql-size-huge":
                contractDoc.setFontSize(18);
                docEndPosition += 10;
                break;
              case "ql-size-large":
                contractDoc.setFontSize(12);
                docEndPosition += 5;
                break;
              case "ql-size-small":
                contractDoc.setFontSize(6);
                break;
              default:
                break;
            }
          }

          break;

        default:
          const result = returnCorrectConfigurationForJsPDF(
            contractDoc,
            nestedObject,
            currentXPos,
            currentYPos,
            lineWidth,
            isTextStrong
          );
          isTextStrong = result;
          break;
      }
    }

    if (nestedObject.content && typeof nestedObject.content !== "string") {
      const result = processTypes(
        nestedObject.content,
        contractDoc,
        currentXPos,
        currentYPos,
        lineWidth,
        defaultTextColor,
        defaultFontSize,
        isTextStrong
      );
      currentXPos = result.xPos;
      currentYPos = result.yPos;
    }
  }
  return { xPos: currentXPos, yPos: currentYPos };
}

function processTypesV2(
  nestedObject: NestedContent | NestedContent[] | string,
  contractDoc: jsPDF,
  horizontalMargin: number,
  docEndPosition: number,
  lineWidth: number,
  defaultTextColor: any,
  defaultFontSize: any,
  isTextStrong: boolean
) {
  // let cursorPosition = {
  //   xPos: horizontalMargin,
  //   yPos: docEndPosition,
  // };
  let currentXPos = horizontalMargin;
  let currentYPos = docEndPosition;

  if (typeof nestedObject === "string") {
    //string
    const cursorPosition = printWordsWithWrapping(
      contractDoc,
      nestedObject,
      currentXPos,
      currentYPos,
      lineWidth,
      horizontalMargin
    );
    currentXPos = cursorPosition.xPos;
    currentYPos = cursorPosition.yPos + 20;

    contractDoc.setTextColor(defaultTextColor);
    contractDoc.setFontSize(defaultFontSize);
    contractDoc.setFont("LatoRegular", "normal");
  } else if (!Array.isArray(nestedObject)) {
    //object
    if (nestedObject.type) {
      switch (nestedObject.type) {
        case "span":
          let contentStyle = nestedObject.attributes.style;
          let contentClass = nestedObject.attributes.class;
          if (contentStyle) {
            const { backgroundColor, color } = extractStyles(contentStyle);
            if (backgroundColor) {
              const backgroundColorRBG =
                getColorFromHtmlStyleString(backgroundColor);
              if (
                Array.isArray(nestedObject.content) &&
                typeof nestedObject.content[0] === "string"
              ) {
                const textWidth = contractDoc.getTextWidth(
                  nestedObject.content[0]
                );
                contractDoc.setFillColor(...backgroundColorRBG);
                contractDoc.rect(
                  horizontalMargin,
                  docEndPosition - 8,
                  textWidth,
                  10,
                  "F"
                );
              }
            }
            if (color) {
              const colorRBG = getColorFromHtmlStyleString(color);
              contractDoc.setTextColor(...colorRBG);
            }
          }
          if (contentClass) {
            switch (contentClass) {
              case "ql-size-huge":
                contractDoc.setFontSize(18);
                docEndPosition += 10;
                break;
              case "ql-size-large":
                contractDoc.setFontSize(12);
                docEndPosition += 5;
                break;
              case "ql-size-small":
                contractDoc.setFontSize(6);
                break;
              default:
                break;
            }
          }

          break;

        default:
          const result = returnCorrectConfigurationForJsPDF(
            contractDoc,
            nestedObject,
            horizontalMargin,
            docEndPosition,
            lineWidth,
            isTextStrong
          );
          isTextStrong = result;
          break;
      }
    }

    if (nestedObject.content && typeof nestedObject.content !== "string") {
      const result = processTypesV2(
        nestedObject.content[0],
        contractDoc,
        currentXPos, // Pass the updated X position
        currentYPos, // Pass the updated Y position
        lineWidth,
        defaultTextColor,
        defaultFontSize,
        isTextStrong
      );
      // horizontalMargin = result.xPos;
      // cursorPosition.yPos = result.yPos;
      currentXPos = result.xPos;
      currentYPos = result.yPos;
    }
  }
  return { xPos: currentXPos, yPos: currentYPos };
}

async function improvedProcessParsedHtml(
  contractDoc: jsPDF,
  parsedHtml: any,
  horizontalMargin: any,
  docEndPosition: any,
  lineWidth: number
) {
  // let cursorPosition = {
  //   xPos: horizontalMargin,
  //   yPos: docEndPosition,
  // };

  const defaultFillColor = contractDoc.getFillColor();
  const defaultTextColor = contractDoc.getTextColor();
  const defaultFontSize = contractDoc.getFontSize();

  for (const element of parsedHtml.content) {
    let isTextStrong = false;
    switch (element.type) {
      case "p":
        let pTagHorizontalMargin = horizontalMargin;
        element.content.forEach(
          (currentElement: any, index: number, array: any[]) => {
            const cursorPosition = processTypes(
              currentElement,
              contractDoc,
              pTagHorizontalMargin,
              docEndPosition,
              lineWidth,
              defaultTextColor,
              defaultFontSize,
              isTextStrong
            );

            if (index < array.length - 1) {
              pTagHorizontalMargin = cursorPosition.xPos;
            }

            if (index === array.length - 1) {
              docEndPosition = cursorPosition.yPos + 10;
            }
            contractDoc.setTextColor(defaultTextColor);
            contractDoc.setFontSize(defaultFontSize);
            contractDoc.setFont("LatoRegular", "normal");

            if (docEndPosition >= 800) {
              contractDoc.addPage();
              docEndPosition = 50;
            }
            // horizontalMargin = cursorPosition.xPos + 5;

            // docEndPosition = cursorPosition.yPos + 10;
          }
        );
        break;
      case "ol":
        let olTagHorizontalMargin = horizontalMargin;
        let orderedList = element.content;
        orderedList.forEach((item: any, index: any) => {
          if (index < 9) {
            contractDoc.text(
              `        ${index + 1}.`,
              horizontalMargin,
              docEndPosition
            );
          } else {
            contractDoc.text(
              `     ${index + 1}.`,
              horizontalMargin,
              docEndPosition
            );
          }
          var cursorPosition = {
            xPos: horizontalMargin,
            yPos: docEndPosition,
          };
          olTagHorizontalMargin += 28;
          item.content.forEach(
            (listElement: any, index: number, array: any[]) => {
              cursorPosition = processTypes(
                listElement,
                contractDoc,
                olTagHorizontalMargin,
                docEndPosition,
                lineWidth,
                defaultTextColor,
                defaultFontSize,
                isTextStrong
              );

              if (index < array.length - 1) {
                olTagHorizontalMargin = cursorPosition.xPos;
              }
              contractDoc.setTextColor(defaultTextColor);
              contractDoc.setFontSize(defaultFontSize);
              contractDoc.setFont("LatoRegular", "normal");

              if (docEndPosition >= 800) {
                contractDoc.addPage();
                docEndPosition = 50;
              }
            }
          );
          olTagHorizontalMargin = horizontalMargin;
          docEndPosition = cursorPosition.yPos;

          if (docEndPosition >= 800) {
            contractDoc.addPage();
            docEndPosition = 50;
          }
        });
        break;
      case "ul":
        let ulTagHorizontalMargin = horizontalMargin;
        let itemsList = element.content;
        itemsList.forEach((item: any) => {
          contractDoc.text(`        \u2022 `, horizontalMargin, docEndPosition);
          var cursorPosition = {
            xPos: horizontalMargin,
            yPos: docEndPosition,
          };
          ulTagHorizontalMargin += 28;
          item.content.forEach(
            (listElement: any, index: number, array: any[]) => {
              cursorPosition = processTypes(
                listElement,
                contractDoc,
                ulTagHorizontalMargin,
                docEndPosition,
                lineWidth,
                defaultTextColor,
                defaultFontSize,
                isTextStrong
              );

              if (index < array.length - 1) {
                ulTagHorizontalMargin = cursorPosition.xPos;
              }
              contractDoc.setTextColor(defaultTextColor);
              contractDoc.setFontSize(defaultFontSize);
              contractDoc.setFont("LatoRegular", "normal");

              if (docEndPosition >= 800) {
                contractDoc.addPage();
                docEndPosition = 50;
              }
            }
          );
          ulTagHorizontalMargin = horizontalMargin;
          docEndPosition = cursorPosition.yPos;

          if (docEndPosition >= 800) {
            contractDoc.addPage();
            docEndPosition = 50;
          }
        });
        break;
      default:
        break;
    }
  }
  return docEndPosition;
}

export const contractPdf = async (
  companyInfo: any,
  contractDefaults: any,
  currentProject: any,
  compensationData: any,
  totalSum: any,
  equipmentNames: any
) => {
  const horizontalMargin = 30;
  const regTextFontSize = 10;

  const {
    companyStreetAddressOne,
    companyStreetAddressTwo,
    companyCity,
    companyStateProvince,
    companyPostalCode,
    companyWebsiteUrl,
    officePhone
  } = companyInfo || {};

  const addressParts = [
    companyStreetAddressOne,
    companyStreetAddressTwo,
  ].filter(Boolean);

  const headerCityParts = [companyCity, companyStateProvince, companyPostalCode].filter(Boolean);
  const headerAddress = addressParts.length > 0 ? `Address: ${addressParts.join(', ')}` : "Address: No address available";
  const headerCity = headerCityParts.length > 0 ? `${headerCityParts.join(', ')}` : '';
  const headerWeb = companyWebsiteUrl ? `Website: ${companyWebsiteUrl}` : '';
  const headerTelephone = officePhone ? `Telephone: ${officePhone}` : '';

  let image = new Image(200, 40);
  image.src = companyInfo.companyLogo;
  const imageAspectRatio = image.naturalWidth / image.naturalHeight;

  let contractEndPosition = 100;
  var contractDoc = new jsPDF("p", "pt");

  contractDoc.addFileToVFS("Lato-Regular.ttf", LatoRegular);
  contractDoc.addFont("Lato-Regular.ttf", "LatoRegular", "normal");
  contractDoc.addFileToVFS("Lato-Bold.ttf", LatoBold);
  contractDoc.addFont("Lato-Bold.ttf", "LatoRegular", "bold");

  contractDoc.setFont("LatoRegular", "normal");
  contractDoc.setFontSize(20);

  const opWidth = 160;
  contractDoc.addImage(
    image,
    "png",
    horizontalMargin - 8,
    10,
    opWidth,
    opWidth / imageAspectRatio,
    "logo",
    "FAST"
  );
  contractDoc.setFont("LatoRegular", "bold");
  contractDoc.text("Contract", horizontalMargin, contractEndPosition);
  contractDoc.setFont("LatoRegular", "normal");
  contractDoc.setFontSize(regTextFontSize);
  contractDoc.text(`${companyInfo?.companyName}`, horizontalMargin + 390, 40);
  contractDoc.text(
    headerAddress,
    horizontalMargin + 390,
    50
  );
  contractDoc.text(
    headerCity,
    horizontalMargin + 390,
    60
  );
  contractDoc.text(headerTelephone, horizontalMargin + 390, 70);
  contractDoc.text(
    headerWeb,
    horizontalMargin + 390,
    80
  );
  let currentDrawColor = contractDoc.getDrawColor();
  contractDoc.setDrawColor("#909090");
  contractDoc.line(0, 120, 600, 120);
  contractDoc.setDrawColor(currentDrawColor);
  contractDoc.setFontSize(14);
  contractEndPosition += 40;

  for (const singleContractDefault of contractDefaults) {
    let docEndPosition = contractEndPosition;

    switch (singleContractDefault.title) {
      case "Description":
        if (contractEndPosition >= 800) {
          contractDoc.addPage();
          contractEndPosition = 50;
        }
        contractDoc.setFontSize(14);
        contractEndPosition += 40;

        contractDoc.setFont("LatoRegular", "bold");
        contractDoc.text(
          singleContractDefault.title,
          horizontalMargin,
          contractEndPosition
        );

        const descriptionText = (
          await HTMLParser(
            "<div>" + singleContractDefault.description + "</div>",
            true
          )
        ).toString();

        contractDoc.setFont("LatoRegular", "normal");
        contractDoc.setFontSize(10);
        const descriptionEndposition = await improvedProcessParsedHtml(
          contractDoc,
          JSON.parse(descriptionText),
          horizontalMargin,
          docEndPosition + 60,
          565
        );
        contractEndPosition = descriptionEndposition;

        break;
      case "Project":
        if (contractEndPosition >= 800) {
          contractDoc.addPage();
          contractEndPosition = 50;
        }
        await addSvgImageToPdf(
          singleContractDefault.svgString,
          contractDoc,
          horizontalMargin,
          contractEndPosition
        );
        contractDoc.setFontSize(14);
        contractDoc.setFont("LatoRegular", "bold");
        contractDoc.text(
          singleContractDefault.title,
          horizontalMargin + 25,
          contractEndPosition + 13
        );

        const project = (
          await HTMLParser(
            "<div>" + singleContractDefault.description + "</div>",
            true
          )
        ).toString();

        contractDoc.setFont("LatoRegular", "normal");
        contractDoc.setFontSize(10);

        const projectEndPosition = await improvedProcessParsedHtml(
          contractDoc,
          JSON.parse(project),
          horizontalMargin,
          docEndPosition + 40,
          400
        );

        contractDoc.setLineWidth(1);
        contractDoc.setFont("LatoRegular", "normal");
        contractDoc.setFontSize(10);
        const jobName = currentProject?.projectInfo?.jobName ?? "";

        let splitJobName = contractDoc.splitTextToSize(jobName, 135);

        contractDoc.text(
          splitJobName,
          horizontalMargin + 400,
          contractEndPosition + 40
        );

        contractEndPosition = projectEndPosition;
        break;
      case "Subcontractor's Scope of Work":
        if (contractEndPosition >= 800) {
          contractDoc.addPage();
          contractEndPosition = 50;
        }
        await addSvgImageToPdf(
          singleContractDefault.svgString,
          contractDoc,
          horizontalMargin,
          contractEndPosition
        );
        contractDoc.setFontSize(14);

        contractDoc.setFont("LatoRegular", "bold");
        contractDoc.text(
          singleContractDefault.title,
          horizontalMargin + 25,
          contractEndPosition + 13
        );

        const scopeOfWork = (
          await HTMLParser(
            "<div>" + singleContractDefault.description + "</div>",
            true
          )
        ).toString();

        contractDoc.setFont("LatoRegular", "normal");
        contractDoc.setFontSize(10);
        const subcontractorEndPostion = await improvedProcessParsedHtml(
          contractDoc,
          JSON.parse(scopeOfWork),
          horizontalMargin,
          docEndPosition + 40,
          565
        );

        contractEndPosition = subcontractorEndPostion;
        break;
      case "Compensation":
        if (contractEndPosition >= 800) {
          contractDoc.addPage();
          contractEndPosition = 50;
        }
        await addSvgImageToPdf(
          singleContractDefault.svgString,
          contractDoc,
          horizontalMargin,
          contractEndPosition
        );
        contractDoc.setFontSize(14);

        contractDoc.setFont("LatoRegular", "bold");
        contractDoc.text(
          singleContractDefault.title,
          horizontalMargin + 25,
          contractEndPosition + 13
        );
        const text =
          "Subcontractor shall be paid for satisfactory performance of the Work the total lump sum amount shown below.";
        contractDoc.setFont("LatoRegular", "normal");
        contractDoc.setFontSize(10);
        contractDoc.text(text, horizontalMargin, contractEndPosition + 40);

        let compensationTableData = [];
        if (compensationData) {
          compensationTableData = compensationData?.map((row: any) => {
            if (!row.productionTargets) {
              return {
                ...row,
                productionTargets: 0,
              };
            }
            return row;
          });
        }

        let tableBody = [
          ...compensationTableData,
          {
            name: "Total Contract Price",
            productionTargets: totalSum ? totalSum : 0,
          },
        ];
        const compensationColumns = [
          { header: "Description", dataKey: "name" },
          { header: "Amount", dataKey: "productionTargets" },
        ];
        contractEndPosition += 20;
        autoTable(contractDoc, {
          body: tableBody,
          columns: compensationColumns,
          startY: contractEndPosition + 40,
          margin: { horizontal: horizontalMargin },
          styles: {
            lineColor: "#585858",
            lineWidth: 1,
          },
          headStyles: {
            fillColor: "#FDB913",
            halign: "center",
            textColor: "black",
          },

          bodyStyles: { fillColor: "#FAF9F9", fontSize: 8, textColor: "black" },
          columnStyles: {
            1: {
              halign: "right",
            },
          },

          didDrawPage: (data) => {
            contractEndPosition = data.cursor?.y || contractEndPosition;
          },
          didParseCell: (data) => {
            var rows = data.table.body;
            if (data.row.index === rows.length - 1) {
              data.cell.styles.fontStyle = "bold";
            }
          },
        });
        const compensation = (
          await HTMLParser(
            "<div>" + singleContractDefault.description + "</div>",
            true
          )
        ).toString();

        contractDoc.setFont("LatoRegular", "normal");
        contractDoc.setFontSize(10);

        const compensationEndPosition = await improvedProcessParsedHtml(
          contractDoc,
          JSON.parse(compensation),
          horizontalMargin,
          contractEndPosition + 30,
          565
        );

        const dotImage = new Image();
        dotImage.src = dot;
        if (
          currentProject?.prePresentationChecklist?.paymentTermsException
            ?.length
        ) {
          contractEndPosition = compensationEndPosition + 10;
          contractDoc.addImage(
            dotImage,
            "jpg",
            horizontalMargin,
            contractEndPosition,
            3,
            3
          );
          contractDoc.setFont("LatoRegular", "normal");
          contractDoc.setFontSize(10);
          contractDoc.text(
            `${currentProject?.prePresentationChecklist?.paymentTermsException}`,
            horizontalMargin + 15,
            contractEndPosition + 5,
            { maxWidth: 450 }
          );
          const eqEndPosition = contractDoc.getTextDimensions(
            `${currentProject?.prePresentationChecklist?.paymentTermsException}`,
            {
              maxWidth: 450,
            }
          );
          contractEndPosition += eqEndPosition.h + 10;
        } else {
          contractEndPosition = compensationEndPosition + 10;
        }

        break;
      case "Correction Period":
        if (contractEndPosition >= 800) {
          contractDoc.addPage();
          contractEndPosition = 50;
        }
        await addSvgImageToPdf(
          singleContractDefault.svgString,
          contractDoc,
          horizontalMargin,
          contractEndPosition
        );
        contractDoc.setFontSize(14);

        contractDoc.setFont("LatoRegular", "bold");
        contractDoc.text(
          singleContractDefault.title,
          horizontalMargin + 25,
          contractEndPosition + 13
        );

        const CorrectionPeriod = (
          await HTMLParser(
            "<div>" + singleContractDefault.description + "</div>",
            true
          )
        ).toString();

        contractDoc.setFont("LatoRegular", "normal");
        contractDoc.setFontSize(10);
        const correctionEndPosition = await improvedProcessParsedHtml(
          contractDoc,
          JSON.parse(CorrectionPeriod),
          horizontalMargin,
          contractEndPosition + 40,
          565
        );

        if (
          currentProject?.prePresentationChecklist?.correctionPeriod?.length
        ) {
          contractEndPosition = correctionEndPosition;
          const dotImage = new Image();
          dotImage.src = dot;

          contractDoc.addImage(
            dotImage,
            "jpg",
            horizontalMargin,
            contractEndPosition,
            3,
            3
          );
          contractDoc.text(
            `${currentProject?.prePresentationChecklist?.correctionPeriod}`,
            horizontalMargin + 15,
            contractEndPosition + 5,
            { maxWidth: 450 }
          );
          const cpEndPosition = contractDoc.getTextDimensions(
            `${currentProject?.prePresentationChecklist?.correctionPeriod}`,

            {
              maxWidth: 450,
            }
          );
          contractEndPosition += cpEndPosition.h + 15;
        } else {
          contractEndPosition = correctionEndPosition + 15;
        }
        break;

      case "Equipment":
        if (contractEndPosition + 200 >= 800) {
          contractDoc.addPage();
          contractEndPosition = 50;
        }
        console.log(currentProject);

        if (currentProject?.prePresentationChecklist?.showEquipment) {
          await addSvgImageToPdf(
            singleContractDefault.svgString,
            contractDoc,
            horizontalMargin,
            contractEndPosition
          );
          contractDoc.setFontSize(14);
          contractEndPosition += 12;
          contractDoc.setFont("LatoRegular", "bold");
          contractDoc.text(
            singleContractDefault.title,
            horizontalMargin + 25,
            contractEndPosition
          );
          if (contractEndPosition >= 800) {
            contractDoc.addPage();
            contractEndPosition = 50;
          }
          let equipment = (
            await HTMLParser(
              "<div>" + singleContractDefault.description + "</div>",
              true
            )
          ).toString();

          const replacements = {
            contractor:
              currentProject.prePresentationChecklist?.crew?.fullName ??
              "_______________",
            companyName: companyInfo.companyName,
          };

          for (const [placeholder, value] of Object.entries(replacements)) {
            const regex = new RegExp(`{${placeholder}}`, "g");
            equipment = equipment.replace(regex, value);
          }

          contractDoc.setFont("LatoRegular", "normal");
          contractDoc.setFontSize(10);
          const equipmentEndPosition = await improvedProcessParsedHtml(
            contractDoc,
            JSON.parse(equipment),
            horizontalMargin,
            contractEndPosition + 30,
            565
          );
          contractEndPosition = equipmentEndPosition;
          const dotImage = new Image();
          dotImage.src = dot;
          equipmentNames.map((item: any) => {
            if (contractEndPosition >= 800) {
              contractDoc.addPage();
              contractEndPosition = 50;
            }
            contractDoc.addImage(
              dotImage,
              "jpg",
              horizontalMargin,
              contractEndPosition,
              3,
              3
            );
            contractDoc.setFont("LatoRegular", "normal");
            contractDoc.text(
              `${item.item}`,
              horizontalMargin + 10,
              contractEndPosition + 5
            );
            contractEndPosition += 15;
          });
          contractEndPosition += 15;
        }
        break;
      case "Start/Completion Date":
        if (contractEndPosition >= 800) {
          contractDoc.addPage();
          contractEndPosition = 50;
        }
        await addSvgImageToPdf(
          singleContractDefault.svgString,
          contractDoc,
          horizontalMargin,
          contractEndPosition
        );
        contractDoc.setFontSize(14);

        contractEndPosition += 12;
        contractDoc.setFont("LatoRegular", "bold");
        contractDoc.text(
          singleContractDefault.title,
          horizontalMargin + 25,
          contractEndPosition
        );
        contractEndPosition += 25;
        if (contractEndPosition >= 800) {
          contractDoc.addPage();
          contractEndPosition = 50;
        }

        contractDoc.setFont("LatoRegular", "normal");
        contractDoc.setFontSize(regTextFontSize);
        const { startDateFirmString, startDateFlexibleString } =
          singleContractDefault.startAndCompletionDate;
        const startDateText =
          currentProject.prePresentationChecklist?.startDateFirmOrFlexible ===
            "firm"
            ? startDateFirmString
            : startDateFlexibleString;

        contractDoc.text(
          `Start Date:${startDateText}`,
          horizontalMargin,
          contractEndPosition,
          { maxWidth: 430 }
        );

        contractDoc.text(
          currentProject.prePresentationChecklist?.projectStartDate
            ? `${moment(
              currentProject.prePresentationChecklist?.projectStartDate
            ).format("MMM DD,YYYY")}`
            : "",
          horizontalMargin + 450,
          contractEndPosition
        );

        contractDoc.line(
          480,
          contractEndPosition + 3,
          540,
          contractEndPosition + 3
        );

        const startDateEndPosition = contractDoc.getTextDimensions(
          `Start Date: ${startDateText}`,
          {
            maxWidth: 430,
          }
        );

        contractEndPosition += startDateEndPosition.h + 20;

        const { completionDateFirmString, completionDateFlexibleString } =
          singleContractDefault.startAndCompletionDate;
        const completeDateText =
          currentProject.prePresentationChecklist
            ?.completionDateFirmOrFlexible === "firm"
            ? completionDateFirmString
            : completionDateFlexibleString;

        contractDoc.text(
          `Completion Date:${completeDateText}`,
          horizontalMargin,
          contractEndPosition,
          { maxWidth: 430 }
        );
        contractDoc.text(
          currentProject.prePresentationChecklist?.jobCompleteDate
            ? `${moment(
              currentProject.prePresentationChecklist?.jobCompleteDate
            ).format("MMM DD,YYYY")}`
            : "",
          horizontalMargin + 450,
          contractEndPosition
        );

        contractDoc.line(
          480,
          contractEndPosition + 3,
          540,
          contractEndPosition + 3
        );
        const completeDateEndPosition = contractDoc.getTextDimensions(
          `Complete Date: ${completeDateText}`,
          {
            maxWidth: 430,
          }
        );

        contractEndPosition += completeDateEndPosition.h + 15;

        if (contractEndPosition >= 800) {
          contractDoc.addPage();
          contractEndPosition = 50;
        }

        break;

      default:
        {
          if (contractEndPosition >= 800) {
            contractDoc.addPage();
            contractEndPosition = 50;
          }

          await addSvgImageToPdf(
            singleContractDefault.svgString,
            contractDoc,
            horizontalMargin,
            contractEndPosition
          );
          contractDoc.setFontSize(14);

          contractEndPosition += 12;
          contractDoc.setFont("LatoRegular", "bold");
          contractDoc.text(
            singleContractDefault.title,
            horizontalMargin + 25,
            contractEndPosition
          );
          contractEndPosition = contractEndPosition + 20;

          const defaultDescription = (
            await HTMLParser(
              "<div>" + singleContractDefault.description + "</div>",
              true
            )
          ).toString();

          contractDoc.setFont("LatoRegular", "normal");
          contractDoc.setFontSize(10);

          const defaultEndPosition = await improvedProcessParsedHtml(
            contractDoc,
            JSON.parse(defaultDescription),
            horizontalMargin,
            docEndPosition + 40,
            565
          );

          contractEndPosition = defaultEndPosition;
        }
        break;
    }
  }

  contractDoc.setFont("LatoRegular", "bold");
  contractDoc.setFontSize(12);
  contractEndPosition = contractEndPosition + 40;

  if (contractEndPosition >= 800) {
    contractDoc.addPage();
    contractEndPosition = 50;
  }

  contractDoc.text(
    "IN WITNESS WHEREOF, the Parties hereto have executed this Work Order",
    horizontalMargin + 70,
    contractEndPosition
  );
  contractEndPosition += 15;
  contractDoc.text(
    "to be effective upon its full execution.",
    horizontalMargin + 160,
    contractEndPosition
  );

  if (contractEndPosition >= 800) {
    contractDoc.addPage();
    contractEndPosition = 50;
  }

  contractEndPosition += 30;
  contractDoc.text(
    `${companyInfo?.companyName}:`,
    horizontalMargin,
    contractEndPosition
  );

  contractDoc.text(
    "Subcontractor:",
    horizontalMargin + 400,
    contractEndPosition
  );

  if (contractEndPosition >= 800) {
    contractDoc.addPage();
    contractEndPosition = 50;
  }
  contractEndPosition += 30;
  contractDoc.setFont("LatoRegular", "italic");
  contractDoc.setFontSize(regTextFontSize);
  contractDoc.line(
    horizontalMargin,
    contractEndPosition,
    130,
    contractEndPosition
  );
  contractDoc.line(
    horizontalMargin + 400,
    contractEndPosition,
    530,
    contractEndPosition
  );

  contractEndPosition += 12;

  if (contractEndPosition >= 800) {
    contractDoc.addPage();
    contractEndPosition = 50;
  }

  contractDoc.text("Signature", horizontalMargin, contractEndPosition);
  contractDoc.text("Signature", horizontalMargin + 400, contractEndPosition);
  contractEndPosition += 30;
  contractDoc.line(
    horizontalMargin,
    contractEndPosition,
    130,
    contractEndPosition
  );
  contractDoc.line(
    horizontalMargin + 400,
    contractEndPosition,
    530,
    contractEndPosition
  );

  if (contractEndPosition >= 800) {
    contractDoc.addPage();
    contractEndPosition = 50;
  }
  contractEndPosition += 12;

  contractDoc.text("Printed", horizontalMargin, contractEndPosition);
  contractDoc.text("Printed", horizontalMargin + 400, contractEndPosition);

  contractEndPosition += 30;
  contractDoc.line(
    horizontalMargin,
    contractEndPosition,
    130,
    contractEndPosition
  );
  contractDoc.line(
    horizontalMargin + 400,
    contractEndPosition,
    530,
    contractEndPosition
  );
  contractEndPosition += 12;

  contractDoc.text("Title", horizontalMargin, contractEndPosition);
  contractDoc.text("Title", horizontalMargin + 400, contractEndPosition);

  contractEndPosition += 10;
  contractDoc.save("Contract");
  let blob = contractDoc.output();
  return blob;
};
