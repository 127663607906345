import { Button, Col, message, Popconfirm } from 'antd';
import { useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import '../../components/proposal/Proposal.less';
import api from '../../utils/api';
import EmailModal from '../email-template-modal/EmailModal';
import EmailRecipients from '../email-template-modal/EmailRecipients';

type Props = {
  users: any[];
};

const ManagerButtons = (props: Props) => {
  const { id } = useParams<any>();
  const { currentProject, items } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );
  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  const [showModal, setShowModal] = useState<any>(false);
  const [showRecipientsModal, setShowRecipientsModal] = useState<any>(false);
  const [emailSubject, setEmailSubject] = useState<string>('');
  const [emailBody, setEmailBody] = useState<string>('');
  const [emailRecipients, setEmailRecipients] = useState<string>('');
  const [submitProposalRevision, setSubmitProposalRevision] = useState(false);

  const [emailUrl, setEmailUrl] = useState<string>('');

  const handleRevisions = () => {
    setEmailUrl(`user/submit-revisions/${id}`);
    setEmailSubject(
      `Job Review Request - ${items[currentProject]?.projectInfo?.jobName}`,
    );
    setEmailBody(
      `Please review project notes and update the estimate accordingly for the ${items[currentProject]?.projectInfo?.jobName} project. For more information please visit https://app.pro-driven.com/manager-preview-profile/${items[currentProject].projectInfo._id}. \n \nThanks you,\n\n Regards,\n${user.fullName}`,
    );
    // setEmailRecipients(
    //   `${items[currentProject]?.projectInfo?.salesAssociate.email}; ${items[currentProject]?.projectInfo?.estimator.email}`,
    // );
    setShowModal(true);
  };

  const handleApprove = () => {
    setEmailUrl(`user/send-to-sales-associate/${id}`);
    setEmailSubject(
      `Estimate Approved! - ${items[currentProject]?.projectInfo?.jobName}`,
    );
    setEmailBody(
      `Hello ${items[currentProject]?.projectInfo?.salesAssociate.fullName},\n\nThe estimate for ${items[currentProject]?.projectInfo?.jobName} has been reviewed and approved.\n\nFor more information please visit https://app.pro-driven.com/manager-preview-profile/${items[currentProject]?.projectInfo?._id} \n \nThanks you,\n\n Regards,\n${user.fullName}`,
    );
    setEmailRecipients(
      `${items[currentProject]?.projectInfo?.salesAssociate.email}; ${items[currentProject]?.projectInfo?.estimator.email}`,
    );
    setShowModal(true);
  };

  const confirm = () => {
    handleApprove();
  };

  const handleSendEmail = (body: any) => {
    api
      .post(emailUrl, body)
      .then(() => {
        message.success('Email Sent');
      })
      .catch((err) => {
        console.error(err);
      });
    setShowModal(false);
  };

  const fetchRecipients = (recipients: any) => {
    setEmailRecipients(recipients.map((user: any) => user.email).join('; '));
  };

  return (
    <>
      <EmailModal
        showModal={showModal}
        setShowModal={setShowModal}
        emailSubject={emailSubject}
        emailBody={emailBody}
        emailRecipients={emailRecipients}
        sendEmail={handleSendEmail}
      />
      <EmailRecipients
        users={props.users}
        showRecipientsModal={showRecipientsModal}
        setShowRecipientsModal={setShowRecipientsModal}
        handelRecipients={fetchRecipients}
        handleRevisions={handleRevisions}
        managerSubmitRevision={submitProposalRevision}
      />
      <Col>
        <Button
          type="primary"
          style={{
            color: '#1f1f1f',
          }}
          className="notify-btn"
          onClick={() => {
            setShowRecipientsModal(true);
            setSubmitProposalRevision(true);
          }}
        >
          Submit Revisions
        </Button>
      </Col>
      <Col>
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to approve this estimation?"
          onConfirm={confirm}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="primary"
            style={{
              color: '#1f1f1f',
            }}
            className="notify-btn"
          >
            Approve
          </Button>
        </Popconfirm>
      </Col>
    </>
  );
};

export default ManagerButtons;
