import React, { useState, useEffect } from "react";
import { Button, Col, Row, Image, Spin, Popconfirm, Switch } from "antd";
import "./AddMedia.less";
import { RedCrossIcon } from "../../utils/icons";
import AddMedia from "./AddMedia";
import axios from "axios";
import { API_BASE } from "../../constant";
import { RootStateOrAny, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAuthUser, getSelectedTenant } from "../../utils/authToken";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import FilmIcon from "../../assets/images/film.png";
import PdfIcon from "../../assets/images/pdf.png";
import CsvIcon from "../../assets/images/csv.png";
import XlsIcon from "../../assets/images/xls-file.png";
import FileIcon from "../../assets/images/files.png";
import moment from "moment";
import { isNull } from "lodash";
import api from "../../utils/api";
import loadingGif from "../../assets/images/loading.gif";

interface MediaProps {
  loading: boolean;
  setLoading: any;
  fileList: any[];
  setFileList: any;
  pageNo: number;
  setPageNo: any;
  showButton: boolean;
  setShowButton: any;
}

const EditMedia = (props: MediaProps) => {
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const [isEdit, isEditSet] = useState(false);

  const [deleting, setDeleting] = useState("");
  const { id } = useParams<any>();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [previewSrc, setPreviewSrc] = useState("");
  const [imageLoading, setImageLoading] = useState(false);

  const user = getAuthUser();
  const tenantId = getSelectedTenant();

  const handleLoadMore = () => {
    setButtonLoading(true);
    axios
      .get(`${API_BASE}upload-files/media-document/${id}/${props.pageNo}`, {
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "tenant-id": !isNull(tenantId) ? tenantId : "",
        },
      })
      .then((resp) => {
        if (resp.data.length < 10) {
          props.setShowButton(false);
        } else {
          props.setShowButton(true);
        }
        props.setPageNo(props.pageNo + 1);
        props.setFileList([...props.fileList, ...resp.data]);
        setButtonLoading(false);
      })
      .catch((err) => {
        setButtonLoading(false);
        console.log(err);
      });
  };

  const showEditForm = () => {
    isEditSet(true);
  };

  const handleDone = () => {
    isEditSet(false);
  };

  const handleDelete = (id: string) => {
    setDeleting(id);
    axios
      .delete(`${API_BASE}upload-files/${id}`, {
        headers: {
          Authorization: "Bearer " + user.accessToken,
          "tenant-id": !isNull(tenantId) ? tenantId : "",
        },
      })
      .then((resp) => {
        updateFileList(id);
        setDeleting("");
      })
      .catch((err) => console.log(err));
  };

  const updateFileList = (id: string) => {
    props.setFileList(props.fileList.filter((item: any) => id !== item.uid));
  };

  const handleSwitchChange = (checked: boolean, item: any) => {
    axios
      .patch(
        `${API_BASE}upload-files/media/${item.uid}`,
        { addToProject: checked },
        {
          headers: {
            Authorization: "Bearer " + user.accessToken,
            "tenant-id": !isNull(tenantId) ? tenantId : "",
          },
        }
      )
      .then((resp) => {
        props.setFileList(
          props.fileList.map((fileItem: any) => {
            if (fileItem.uid === resp.data._id) {
              return {
                ...fileItem,
                addToProject: resp.data.addToProject,
              };
            }
            return fileItem;
          })
        );
      })
      .catch((err) => console.log(err));
  };

  const openPdfInNewTab = (pdfUrl: string, itemName: string) => {
    if (/iPhone/i.test(navigator.userAgent) || !pdfUrl.includes('data')) {
      const a = document.createElement("a");
      a.href = pdfUrl;
      a.target = "_blank";
      a.click();
    } else {
      fetch(pdfUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const objectUrl = URL.createObjectURL(blob);
          const win = window.open(objectUrl, "_blank");
          if (!win) {
            console.error(
              "Unable to open a new tab. Please check your browser settings."
            );
          } else {
            win.document.title = itemName;
          }
        })
        .catch((error) => {
          console.error("Error loading PDF:", error);
        });
    }
  };

  const onPreview = async (file: any) => {
    setImageLoading(true);
    let src = file.url as string;
    let blob: Blob;

    if (file._doc.key && !file.OrgImageURl) {
      const res: any = await api.get(
        `upload-files/preview-image/${file._doc.key}`
      );
      const OrgImageURl = `data:${file._doc.mimeType};base64,${Buffer.from(res.Body).toString("base64")}`;
      file = { ...file, OrgImageURl };

      const index = props.fileList.findIndex(
        (item: any) => item.uid === file.uid
      );
      const updatedList = [...props.fileList];
      updatedList[index] = { ...file, OrgImageURl };
      props.setFileList(updatedList);
      src = OrgImageURl;
    }
    if (file.OrgImageURl) {
      src = file.OrgImageURl;
    }
    setPreviewSrc(src);
    setImageLoading(false);
  };

  const handleClosePreview = () => {
    setPreviewSrc("");
  };

  return (
    <div>
      {!isEdit ? (
        <div className="profile-content">
          <div>
            <Row justify="space-between">
              <Col>
                <h1 className="form-subtitle">Media</h1>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <p className="edit--text" onClick={showEditForm}>
                  Add
                </p>
              </Col>
            </Row>
          </div>
          {online && (
            <>
              {props.loading ? (
                <Row justify="center">
                  <Spin tip="Loading..." size="large" />
                </Row>
              ) : (
                <>
                  <Row
                    justify="space-between"
                    style={{
                      marginBottom: 10,
                      backgroundColor: "#FFF8E7",
                      padding: "10px 20px",
                    }}
                  >
                    <Col style={{ width: 380 }} span={11}>
                      <h4>File Name</h4>
                    </Col>
                    <Col span={6}>
                      <h4>Generated Time</h4>
                    </Col>
                    <Col span={2}>
                      <h4>Included</h4>
                    </Col>
                    <Col span={1}>
                      <h4>Action</h4>
                    </Col>
                  </Row>
                  {props.fileList
                    .sort(((a: any, b: any) => new Date(b?._doc?.createdAt).getTime() - new Date(a?._doc?.createdAt).getTime()))
                    .map((item: any) => {
                      let formattedDate = moment(item?._doc?.createdAt).format(
                        "YYYY-MM-DD HH:mm:ss"
                      );
                      // let splitString = item.thumbUrl.split(";");
                      return (
                        <div key={item.uid}>
                          {deleting === item.uid ? (
                            <Spin
                              style={{
                                margin: "15px 120px 15px 120px",
                                width: 380,
                              }}
                              tip="Deleting..."
                              size="default"
                            />
                          ) : (
                            <div
                              className="image--holder--media"
                              style={{ marginBottom: 18 }}
                            >
                              <Row align="middle" justify="space-between">
                                <Col style={{ marginLeft: 15 }} span={10}>
                                  <Row align="middle" style={{ width: 380 }}>
                                    {(item?._doc?.mimeType === "image/jpeg" ||
                                      item?._doc?.mimeType === "image/png" ||
                                      item?._doc?.mimeType === "image/gif") && (
                                        <Image
                                          style={{
                                            borderRadius: "5px",
                                          }}
                                          width={50}
                                          src={item.thumbUrl}
                                          preview={{
                                            src:
                                              previewSrc !== ""
                                                ? previewSrc
                                                : loadingGif,
                                            onVisibleChange: (visible) => {
                                              if (!visible) {
                                                handleClosePreview();
                                              }
                                            },
                                          }}
                                          onClick={() => {
                                            onPreview(item);
                                          }}
                                        />
                                      )}
                                    {item?._doc?.mimeType === "video/mp4" && (
                                      <Image
                                        style={{
                                          borderRadius: "5px",
                                        }}
                                        width={50}
                                        src={FilmIcon}
                                        preview={false}
                                      />
                                    )}
                                    {item?._doc?.mimeType ===
                                      "application/pdf" && (
                                        <Image
                                          style={{
                                            borderRadius: "5px",
                                          }}
                                          width={50}
                                          src={PdfIcon}
                                          preview={false}
                                        />
                                      )}
                                    {item?._doc?.mimeType ===
                                      "application/vnd.ms-excel" && (
                                        <Image
                                          style={{
                                            borderRadius: "5px",
                                          }}
                                          width={50}
                                          src={XlsIcon}
                                          preview={false}
                                        />
                                      )}
                                    {item?._doc?.mimeType === "text/csv" && (
                                      <Image
                                        style={{
                                          borderRadius: "5px",
                                        }}
                                        width={50}
                                        src={CsvIcon}
                                        preview={false}
                                      />
                                    )}
                                    {item?._doc?.mimeType !== "image/jpeg" &&
                                      item?._doc?.mimeType !== "image/png" &&
                                      item?._doc?.mimeType !== "video/mp4" &&
                                      item?._doc?.mimeType !== "application/pdf" &&
                                      item?._doc?.mimeType !==
                                      "application/vnd.ms-excel" &&
                                      item?._doc?.mimeType !== "text/csv" &&
                                      item?._doc?.mimeType !== "image/gif" && (
                                        <Image
                                          style={{
                                            borderRadius: "5px",
                                          }}
                                          width={50}
                                          src={FileIcon}
                                          preview={false}
                                        />
                                      )}
                                    <a
                                      style={{ width: "150px" }}
                                      href={item.thumbUrl}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        openPdfInNewTab(
                                          item?.thumbUrl,
                                          item?.name
                                        );
                                      }}
                                    >
                                      <p
                                        style={{
                                          display: "flex",
                                          alignItems: "end",
                                          marginLeft: 12,
                                        }}
                                      >
                                        {item?._doc?.mimeType === "application/pdf"
                                          ? item.name.split(":")[0]
                                          : item.name}
                                      </p>
                                    </a>
                                  </Row>
                                </Col>
                                <Col span={8}>
                                  <p>{formattedDate}</p>
                                </Col>
                                <Col span={1}>
                                  <Switch
                                    style={{
                                      transform: "scaleX(0.8) scaleY(0.8)",
                                    }}
                                    onChange={(checked) =>
                                      handleSwitchChange(checked, item)
                                    }
                                    checked={item.addToProject}
                                  />
                                </Col>
                                <Col style={{ marginRight: 35 }} span={1}>
                                  <Popconfirm
                                    title="Are you sure to delete this file?"
                                    onConfirm={() => handleDelete(item.uid)}
                                    // onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <RedCrossIcon
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: 10,
                                      }}
                                    // onClick={() => handleDelete(item.uid)}
                                    />
                                  </Popconfirm>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  {props.showButton ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        onClick={handleLoadMore}
                        loading={buttonLoading}
                        style={{ color: "#1F1F1F", borderRadius: 5 }}
                        type="primary"
                      >
                        Load More
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <AddMedia
          updateFileList={updateFileList}
          images={props.fileList}
          setFileList={props.setFileList}
        />
      )}
      {isEdit && (
        <Row style={{ marginTop: 20 }} justify="center">
          <Button
            onClick={handleDone}
            style={{ color: "#1F1F1F" }}
            type="primary"
          >
            Done
          </Button>
        </Row>
      )}
    </div>
  );
};

export default EditMedia;
