import React, { useEffect, useState } from "react";
import DescriptionCard from "../../../components/work-order-description/DescriptionCard";
import { Table, Button, Row, Col, Menu, Input, Dropdown, Spin } from "antd";
import { columns } from "./columns";
import "../WorkOrderPaintC.less";
import { useParams } from "react-router-dom";
import api from "../../../utils/api";
import { SearchIcon } from "../../../utils/icons";
import { RootStateOrAny, useSelector } from "react-redux";

type Props = {};

const WorkOrderCarpentryC = (props: Props) => {
  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );
  const [count, setCount] = useState(0);
  const [optionSearched, setOptionSearched] = useState("");
  const [workOrderCarpentry, setWorkOrderCarpentry] = useState(
    items[currentProject]?.carpentryWorkOrder?.filter(
      (item: any) => item?.carpentryLabor
    )
  );
  const { id } = useParams<{ id: string }>();

  const findOption = workOrderCarpentry?.filter((option: any) => {
    if (!optionSearched) {
      return true;
    }
    return option.buildingName
      .toLowerCase()
      .includes(optionSearched.toLowerCase());
  });

  const handleOptionSelect = (entity: any) => {
    const index = workOrderCarpentry?.findIndex(
      (item: any) => item.buildingName === entity.buildingName
    );
    if (index >= 0) {
      setCount(index);
    }
  };
  const menu = (
    <div>
      <div className="option-input-container">
        <Input
          className="buildings-search"
          placeholder="Search"
          onChange={(e) => {
            setOptionSearched(e.target.value);
          }}
          prefix={<SearchIcon />}
        />
      </div>
      <Menu>
        {findOption?.map((item: any) => (
          <Menu.Item
            onClick={() => handleOptionSelect(item)}
            style={{ height: 38 }}
            key={item._id}
          >
            <p className="jmp-search-text">{item.buildingName}</p>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
  const dummyData = [
    {
      title: "Building Name",
      description: workOrderCarpentry[count]?.buildingName,
    },
    {
      title: "Carpentry Hours",
      description: workOrderCarpentry[count]?.carpentryHours.toFixed(2),
    },

    {
      title: "Carpentry Labor",
      description: workOrderCarpentry[count]?.carpentryLabor.toFixed(2),
    },
  ];
  return (
    <div className="wo-paint-root">
      <Row className="wop-header" justify="space-between">
        <Col>
          <h1 className="wop-title">Work Order- Carpentry</h1>
        </Col>
        <Col>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button className="wo-paint-jump">Jump to Option</Button>
          </Dropdown>
        </Col>
      </Row>
      <hr style={{ border: "1px solid #E8E8E8" }} />
      <DescriptionCard
        dummy={workOrderCarpentry[count]?.carpentryLabor ? dummyData : []}
        notes={findOption[count]?.workOrderNotes}
      />
      {/* <div>
        {findOption[count]?.workOrderCarpentryNotes && (
          <>
            <h2
              style={{
                fontWeight: "bold",
                padding: "2px 20px 2px 30px",
              }}
            >
              Notes
            </h2>
            <p
              style={{
                fontSize: "16px",
                padding: "2px 20px 2px 30px",
              }}
            >
              {findOption[count]?.workOrderCarpentryNotes}
            </p>
          </>
        )}
      </div> */}

      <div className="wo-paint-table">
        <Table
          rowKey={(_: any, index: any) => index.toString()}
          pagination={false}
          rowClassName={(record, index) =>
            index % 2 === 0
              ? "table_row table-row-light"
              : "table_row table-row-dark"
          }
          columns={columns}
          dataSource={workOrderCarpentry[count]?.carpentryRateId}
        />
      </div>
      <Row gutter={10} justify="end" style={{ padding: "20px" }}>
        <Col>
          <Button
            className="wo-paint-prev"
            onClick={() => {
              count > 0 && setCount(count - 1);
            }}
          >
            Prev Option
          </Button>
        </Col>
        <Col>
          <Button
            className="wo-paint-next"
            type="primary"
            onClick={() => {
              count < workOrderCarpentry.length - 1 && setCount(count + 1);
            }}
          >
            Next Option
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default WorkOrderCarpentryC;
