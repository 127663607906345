import { Button, Col, Image, Input, Row, message } from "antd";
import "./ProposalDefaultCard.less";
import ReactQuill from "react-quill";
import { useState } from "react";
import api from "../../utils/api";

type Props = {
  setShowComponent?: (value: boolean) => void;
  setShowEditComponent?: (dataId: string) => void;
  formData?: any;
  onCancel?: any;
  title?: string
  type: string
  fetchData?: any
};

function ProposalDefaultCard({
  setShowComponent,
  setShowEditComponent,
  formData,
  onCancel,
  title,
  type,
  fetchData
}: Props) {
  const [contractDescription, setContractDescription] = useState(
    formData?.description ? formData?.description : ""
  );
  const [contractTitle, setContractTitle] = useState(
    formData?.title ? formData?.title : ""
  );

  const handleTextEditorChange = (e: string) => {
    setContractDescription(e);
  };

  const isDataUnchanged = () => {
    const originalTitle = formData?.title || "";
    const currentTitle = contractTitle || "";
    const originalDescription = formData?.description || "";
    const currentDescription = contractDescription || "";
    return originalTitle === currentTitle && originalDescription === currentDescription;
  };

  const stripHtmlTags = (html: string) => {
    return html.replace(/<\/?[^>]+(>|$)/g, "").trim(); // Remove HTML tags and trim spaces
  };

  const handleSaveOrUpdate = async () => {
    if (!title?.trim() || !stripHtmlTags(contractDescription).trim()) {
      message.warning("Title and description are required");
      return;
    }

    if (formData?.title && formData?.description) {
      if (isDataUnchanged()) {
        setShowEditComponent?.(formData._id);
        message.warning("No changes to update");
        return;
      }

      const putReqBody = {
        ...formData,
        title: title,
        description: contractDescription,
      };

      try {
        await api.patch(`proposal/proposal-default/${formData?._id}`, putReqBody);
        fetchData();
        onCancel();
        message.success("Data updated successfully");
      } catch (error: any) {
        console.error("Update failed:", error);
        message.error("Failed to update data");
      }
    } else {
      // Handle create case
      const postReqBody = {
        title: title,
        description: contractDescription,
        type: type,
      };

      try {
        await api.post("proposal/proposal-default", postReqBody);
        fetchData();
        onCancel();
        message.success("Data created successfully");
      } catch (error: any) {
        console.error("Creation failed:", error);
        message.error("Failed to create data");
      }
    }
  };

  const handleCancelButton = () => {
    onCancel();
  };

  return (
    <div style={{ marginTop: "5px" }}>
      <div>
        <ReactQuill
          style={{ padding: "15px 0px 25px 0px" }}
          theme="snow"
          modules={{
            toolbar: [
              ["bold", "italic", "underline", "strike"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ 'size': ['small', false, 'large', 'huge'] }], // Added size option
            ],
            clipboard: {
              matchVisual: false,
            },
          }}
          formats={[
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            "list", // Include list to support bullets
            "bullet", // Unordered list
            "indent", // For indentation (sub-bullets)
            "size", // Add size format to handle font size changes
          ]}
          placeholder="Write Something..."
          onChange={handleTextEditorChange}
          value={contractDescription}
        />
      </div>

      <Row justify={"end"} style={{ paddingBottom: "20px" }}>
        <Col style={{ paddingRight: "15px" }}>
          <Button
            className="contract-save-cancel-button"
            type="primary"
            onClick={handleSaveOrUpdate}
          >
            {formData?.description ? <>Update</> : <>Save</>}
          </Button>
        </Col>
        <Col>
          <Button
            className="contract-save-cancel-button"
            type="primary"
            onClick={handleCancelButton}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );

}

export default ProposalDefaultCard;
