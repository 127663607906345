import React, { useEffect, useState } from "react";
import { Table, Button, Row, Spin } from "antd";
import "../PaymentContractor.less";
import DescriptionCard from "../../../components/work-order-description/DescriptionCard";
import api from "../../../utils/api";
import { useParams } from "react-router-dom";
import { numberWithCommas } from "../../../utils/helpers";
import { columns } from "./config";
import { RootStateOrAny, useSelector } from "react-redux";

type Props = {};

const PaymentRecordC = (props: Props) => {
  const [checkRequest, setCheckRequest] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [bonusPayment, setBonusPayment] = useState(0);
  const [contractedPrice, setContractedPrice] = useState(0);
  const { id } = useParams<{ id: string }>();
  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  useEffect(() => {
    api
      .get(`check-request/project/${id}`)
      .then((resp) => {
        setCheckRequest(resp);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setContractedPrice(parseFloat(checkRequest?.totalContractPrice?.toFixed(2)));
    setBonusPayment(
      items[currentProject]?.prePresentationChecklist?.bonusPayment
        ? items[currentProject]?.prePresentationChecklist?.bonusPayment
        : 0
    );
  }, [checkRequest])

  const description = [
    {
      title: "Project",
      description: `${checkRequest?.projectName}`,
    },
    {
      title: "Contracted Price",
      description: `$${numberWithCommas(+contractedPrice)}`,
    },
    {
      title: "Crew",
      description: `${checkRequest?.crew?.fullName}`,
    },
    {
      title: "Bonus Payment",
      description: `$${bonusPayment}`,
    },
  ];

  return (
    <div className="contractor-payment-root">
      <div className="contractor-payment-title-padding">
        <h1 className="payment-record-title">Payment Record</h1>
      </div>
      {loading ? (
        <Row style={{ padding: "80px 0px" }} justify="center">
          <Spin size="large" />
        </Row>
      ) : (
        <>
          <hr style={{ border: "1px solid #E8E8E8" }} />

          <DescriptionCard dummy={description} />

          <div className="payment-table-content " style={{ marginTop: "40px" }}>
            <Table
              scroll={{ x: 900 }}
              rowKey="_id"
              rowClassName={(record, index) =>
                index % 2 === 0
                  ? "table_row table-row-light"
                  : "table_row table-row-dark"
              }
              columns={columns}
              dataSource={checkRequest?.checkRequest}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PaymentRecordC;
