import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button, Table, message, Spin, Input, Modal } from "antd";
import "./CheckRequestC.less";
import AddNewModal from "./AddNewModal";
import api from "../../utils/api";
import { useParams } from "react-router-dom";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { numberWithCommas } from "../../utils/helpers";
import moment from "moment";
import { columns } from "./config";
import { TrashIcon } from "../../utils/icons";
import { useReactToPrint } from "react-to-print";
import CheckRequestDescription from "./CheckRequestDescription";
import axios from "axios";
import { API_BASE } from "../../constant";
import {
  postCheckRequest,
  updateCheckRequest,
  updatePrePresentationChecklist,
  sendCheckRequestEmail,
  updateProjectCheckRequestRetainerPercentByProjectId,
} from "../../redux/project/action";
import logo from "../../assets/images/Pro-Driven-Edit.jpg";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import EmailModal from "../email-template-modal/EmailModal";
import { LatoRegular } from "../../assets/fonts/LatoRegular";
import { LatoBold } from "../../assets/fonts/LatoBold";
var toBuffer = require("blob-to-buffer");
var crypto = require("crypto");

type Props = {};

const CheckRequestC = (props: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [showSourceLocationModal, setShowSourceLocationModalModal] =
    useState(false);
  const { id } = useParams<{ id: string }>();
  const [bonusPayment, setBonusPayment] = useState(0);
  const [contractedPrice, setContractedPrice] = useState(0);

  const [showEmailModal, setShowEmailModal] = useState<any>(false);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailBody, setEmailBody] = useState<string>("");
  const [emailRecipients, setEmailRecipients] = useState<string>("");

  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const [checkRequestRetainerPercent, setCheckRequestRetainerPercent] =
    useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    const productionItems = items[
      currentProject
    ].projectInfo?.productionPjcc?.filter((item: any) => {
      return item.appearOnWorkOrder;
    });
    const sum = productionItems?.reduce((initial: any, curValue: any) => {
      return initial + parseFloat(curValue.productionTargets);
    }, 0);
    setContractedPrice(
      items[currentProject].checkRequests.length < 1
        ? parseFloat(sum?.toFixed(2))
        : parseFloat(items[currentProject].checkRequests[0].amount) + parseFloat(items[currentProject].checkRequests[0].balanceDue)
    );
    setBonusPayment(
      items[currentProject]?.prePresentationChecklist?.bonusPayment
    );
    setCheckRequestRetainerPercent(
      items[currentProject]?.projectInfo?.checkRequestRetainerPercent ||
      adminDefaults.contractPaymentDefault.retainerPercentage
    );
  }, []);

  const toggleSourceLocationModal = (collapse: boolean) => {
    !showSourceLocationModal
      ? setShowSourceLocationModalModal(true)
      : setShowSourceLocationModalModal(false);
  };

  const handleAdd = () => {
    if (
      items[currentProject].prePresentationChecklist?.crew?._id &&
      items[currentProject].projectInfo.productionAssociate
    ) {
      !showSourceLocationModal
        ? setShowSourceLocationModalModal(true)
        : setShowSourceLocationModalModal(false);
    } else {
      if (
        items[currentProject].prePresentationChecklist?.crew?._id &&
        !items[currentProject].projectInfo.productionAssociate
      ) {
        message.error("Please select the production associate to add amount");
      } else if (
        !items[currentProject].prePresentationChecklist?.crew?._id &&
        items[currentProject].projectInfo.productionAssociate
      ) {
        message.error("Please select the crew to add amount");
      } else {
        message.error(
          "Please select production associate and crew to add amount"
        );
      }
    }
  };
  const handleNotesChange = (notes: string) => {
    console.log("Notes from child:", notes);
  };

  const handleOk = async (notes: string) => {
    let balanceDue = 0;
    let lastBalance =
      items[currentProject].checkRequests[
        items[currentProject].checkRequests.length - 1
      ]?.balanceDue;
    let paymentType = "Misc";
    if (!isNaN(bonusPayment)) {
      if (items[currentProject].checkRequests?.length > 0 && bonusPayment > 0) {
        // length = items[currentProject].checkRequests.length;
        balanceDue = lastBalance + Math.abs(bonusPayment);
        paymentType = "Misc";
      } else if (
        items[currentProject].checkRequests?.length > 0 &&
        bonusPayment < 0
      ) {
        // length = items[currentProject].checkRequests.length;
        balanceDue = lastBalance - Math.abs(bonusPayment);
        paymentType = "Misc";
      } else {
        balanceDue = contractedPrice + +bonusPayment;
        paymentType = "Misc ";
      }
      const body = {
        _id: crypto.randomBytes(12).toString("hex"),
        project: id,
        date: moment(),
        amount: bonusPayment,
        retainer: 0,
        depositAmount: 0,
        balanceDue: balanceDue,
        bonusPayment: bonusPayment,
        paymentType: "Misc",
        projectName: items[currentProject].projectInfo.jobName,
        payor: adminDefaults?.payProcessingDefaults[0]?.user
          ? adminDefaults?.payProcessingDefaults[0]?.user
          : "",
        crew: items[currentProject].prePresentationChecklist?.crew?._id,
        description: notes,
      };
      dispatch(postCheckRequest(body));
      setShowModal(false);
    } else {
      message.error("Your input must be a number");
    }
  };

  const payorRecipients = (users: any[]) => {
    let recipientsString: string = "";
    for (let i = 0; i < users.length; i++) {
      recipientsString += users[i].email;
    }
    return recipientsString;
  };

  const handleAddAmount = async (item: any) => {
    console.log(item);
    const retainerPercentage = parseFloat(item.retainerPercentage) / 100;
    const amount = +item.amount;
    let balanceDue = 0;
    let length = 0;
    let paymentType = "Draw";
    let onlyCheckRequests = items[currentProject].checkRequests.filter(
      (x: any) => x.retainer > 0
    );
    if (items[currentProject].checkRequests?.length > 0) {
      length = items[currentProject].checkRequests.length;
      balanceDue =
        items[currentProject].checkRequests[length - 1].balanceDue - amount;
      paymentType = "Draw";
    } else {
      balanceDue = contractedPrice - amount;
      paymentType = "Draw";
    }
    const remaining = items[currentProject].checkRequests[length - 1]
      ? items[currentProject].checkRequests[length - 1]?.balanceDue
      : contractedPrice;

    if (amount > remaining) {
      message.error("Amount cannot be greater than balance due");
    } else {
      // const _id = crypto.randomBytes(12).toString("hex");
      const body = {
        _id: crypto.randomBytes(12).toString("hex"),
        project: id,
        drawNumber: onlyCheckRequests.length + 1,
        date: moment(),
        amount: amount,
        retainer: amount * retainerPercentage,
        depositAmount: amount - amount * retainerPercentage,
        balanceDue: balanceDue,
        paymentType: paymentType,
        projectName: items[currentProject].projectInfo.jobName,
        payor: adminDefaults?.payProcessingDefaults[0]?.user ?? "",
        crew: items[currentProject].prePresentationChecklist?.crew?._id,
        description: item.description,
      };

      setEmailSubject(
        `${items[currentProject]?.projectInfo?.jobName}-Crew Payment! `
      );
      setEmailBody(
        `${body.payor[0]?.fullName ? body.payor[0]?.fullName : ""
        }, \nCan you please process the attached payment for ${items[currentProject].prePresentationChecklist?.crew?.fullName
        }\n\nThank you,\n\n${user?.fullName}`
      );

      const payorEmails =
        body.payor.length > 0 ? payorRecipients(body.payor) : "";
      const crewEmail =
        items[currentProject].prePresentationChecklist?.crew?.email ?? "";
      const productionAssociateEmail =
        items[currentProject].projectInfo.productionAssociate?.email ?? "";

      const emailArray = [
        payorEmails,
        crewEmail,
        productionAssociateEmail,
      ].filter((email) => email !== "");

      const emailRecipients = emailArray.join("; ");

      setEmailRecipients(emailRecipients);

      toggleSourceLocationModal(false);
      setShowEmailModal(true);

      dispatch(postCheckRequest(body));
      let binaryString = generateCheckReqPdf(body, "email");
      api.post(`upload-files/single/pdf/${id}`, {
        checkReqBlob: binaryString,
        checkRequestNo: body.drawNumber,
        projectName: body.projectName,
      });
      // message.success("Email Sent");
    }
  };

  const handleBonusPayment = (e: any) => {
    setBonusPayment(e.target.value);
  };

  const handleRetainerPercentageChange = (e: any) => {
    const newValue = parseFloat(e.target.value);
    setCheckRequestRetainerPercent(newValue);
  };

  const handleResetOnRetainerPercentage = () => {
    setCheckRequestRetainerPercent(
      items[currentProject]?.projectInfo?.checkRequestRetainerPercent ||
      adminDefaults.contractPaymentDefault.retainerPercentage
    );
  };

  const handleRetainerPercentageSave = () => {
    if (
      items[currentProject]?.projectInfo?.checkRequestRetainerPercent !==
      checkRequestRetainerPercent
    ) {
      dispatch(
        updateProjectCheckRequestRetainerPercentByProjectId({
          projectId: id,
          checkRequestRetainerPercent: checkRequestRetainerPercent,
        })
      );
      message.success("Retainer Percentage Successfully Updated");
    }
  };

  const generateCheckReqPdf = (body: any, str?: string) => {
    let onlyCheckRequests = items[currentProject].checkRequests.filter(
      (x: any) => x.retainer > 0
    );
    let newBody;
    if (body.hasOwnProperty("_id")) {
      newBody = [...items[currentProject]?.checkRequests, body];
    } else {
      newBody = [...items[currentProject]?.checkRequests];
    }

    const horizontalMargin = 30;
    const pageLimit = 800;

    let image = new Image();
    image.src = logo;

    let contractEndPosition = 100;
    var checkReqDoc = new jsPDF("p", "pt");
    checkReqDoc.addFileToVFS("Lato-Regular.ttf", LatoRegular);
    checkReqDoc.addFont("Lato-Regular.ttf", "LatoRegular", "normal");
    checkReqDoc.addFileToVFS("Lato-Bold.ttf", LatoBold);
    checkReqDoc.addFont("Lato-Bold.ttf", "LatoRegular", "bold");
    checkReqDoc.setFont("LatoRegular");
    checkReqDoc.setFontSize(20);

    checkReqDoc.addImage(image, "png", 20, 10, 150, 75);
    checkReqDoc.setFont("LatoRegular", "normal");
    checkReqDoc.setFontSize(10);
    checkReqDoc.text(
      `${adminDefaults?.companyInfo?.companyName ? adminDefaults?.companyInfo?.companyName : ""}`,
      horizontalMargin + 380,
      40
    );
    checkReqDoc.text(
      `Address: ${adminDefaults?.companyInfo?.companyStreetAddressOne ? adminDefaults?.companyInfo?.companyStreetAddressOne : ""}`,
      horizontalMargin + 380,
      52
    );
    checkReqDoc.text(`Telephone: ${adminDefaults?.companyInfo?.officePhone ? adminDefaults?.companyInfo?.officePhone : ""}`, horizontalMargin + 380, 64);
    checkReqDoc.text(
      `Website: ${adminDefaults?.companyInfo?.companyWebsiteUrl ? adminDefaults?.companyInfo?.companyWebsiteUrl : ""}`,
      horizontalMargin + 380,
      76
    );

    checkReqDoc.setFont("LatoRegular", "bold");
    checkReqDoc.setFontSize(24);
    checkReqDoc.text(
      `Check Request - ${onlyCheckRequests.length} `,
      horizontalMargin + 170,
      contractEndPosition
    );
    checkReqDoc.line(0, 120, 600, 120);

    contractEndPosition += 70;

    checkReqDoc.setFontSize(10);
    checkReqDoc.text("Project", horizontalMargin, contractEndPosition);
    checkReqDoc.text(
      "Contracted Price",
      horizontalMargin + 330,
      contractEndPosition
    );

    checkReqDoc.setFont("LatoRegular", "normal");
    checkReqDoc.text(
      `${items[currentProject]?.projectInfo.jobName}`,
      horizontalMargin + 80,
      contractEndPosition
    );
    checkReqDoc.text(
      `$${contractedPrice ? contractedPrice.toLocaleString("en-US") : 0}`,
      horizontalMargin + 450,
      contractEndPosition
    );

    contractEndPosition += 20;

    checkReqDoc.setFont("LatoRegular", "bold");
    checkReqDoc.text("Job Number", horizontalMargin, contractEndPosition);
    if (bonusPayment > 0) {
      checkReqDoc.text(
        "Bonus Payment",
        horizontalMargin + 330,
        contractEndPosition
      );
    } else {
      checkReqDoc.text("Crew", horizontalMargin + 330, contractEndPosition);
    }

    checkReqDoc.setFont("LatoRegular", "normal");
    checkReqDoc.text(
      `${items[currentProject].projectInfo.jobNumber}`,
      horizontalMargin + 80,
      contractEndPosition
    );
    if (bonusPayment > 0) {
      checkReqDoc.text(
        `$${bonusPayment ? bonusPayment.toLocaleString("en-US") : 0}`,
        horizontalMargin + 450,
        contractEndPosition
      );
      checkReqDoc.setFont("LatoRegular", "bold");
      checkReqDoc.text("Crew", horizontalMargin, contractEndPosition + 20);
      checkReqDoc.setFont("LatoRegular", "normal");
      checkReqDoc.text(
        `${items[currentProject].prePresentationChecklist?.crew?.fullName}`,
        horizontalMargin + 80,
        contractEndPosition + 20
      );
      contractEndPosition += 20;
    } else {
      checkReqDoc.text(
        `${items[currentProject].prePresentationChecklist?.crew?.fullName}`,
        horizontalMargin + 450,
        contractEndPosition
      );
    }

    const columns = [
      { header: "Draw ", dataKey: "drawNumber" },
      { header: "Date", dataKey: "date" },
      { header: "Amount", dataKey: "amount" },
      { header: "Retainer", dataKey: "retainer" },
      { header: "Deposit ", dataKey: "depositAmount" },
      { header: "Balance", dataKey: "balanceDue" },
      { header: "Type", dataKey: "paymentType" },
      { header: "Description", dataKey: "description" },
    ];

    let rows = newBody.map((item: any) => {
      let formattedDate = moment(item.date).format("MM-DD-YYYY");

      return {
        ...item,
        amount: `$${item.amount.toLocaleString("en-US")}`,
        retainer: `$${item.retainer.toLocaleString("en-US")}`,
        depositAmount: `$${item.depositAmount.toLocaleString("en-US")}`,
        balanceDue: `$${item.balanceDue.toLocaleString("en-US")}`,
        date: formattedDate,
        paymentType: item.paymentType,
      };
    });

    autoTable(checkReqDoc, {
      body: rows,
      columns: columns,
      startY: contractEndPosition + 20,
      margin: { horizontal: 30 },
      styles: {
        lineColor: "#D3D3D3",
        lineWidth: 1,
      },
      headStyles: {
        fillColor: "#FDB913",
        halign: "center",
        textColor: "black",
      },
      bodyStyles: { fillColor: "#FAF9F9", fontSize: 8, halign: "center" },
      columnStyles: {
        0: {
          halign: "center",
        },
      },
      // eslint-disable-next-line no-loop-func
      didDrawPage: (data) => {
        contractEndPosition = data.cursor?.y || contractEndPosition;
        // tableEndYPos = data.table.finalY || 0;
      },
      // didParseCell: (data) => {
      //   if (data.row.index === rows.length - 1) {
      //     data.cell.styles.fontStyle = "bold";
      //   }
      // },
    });

    if (str === "email") {
      let blob = checkReqDoc.output();
      return blob;
    } else {
      const checRequestNumber = newBody.filter(
        (item: any) => item.drawNumber
      ).length;
      checkReqDoc.save(
        `Check Request ${checRequestNumber} - ${items[currentProject].projectInfo?.jobName}`
      );
    }
  };

  // const handleDelete = (data: any) => {
  //   api.delete(`check-request/${data._id}`).then(() => {
  //     setChecklist({
  //       ...checklist,
  //       checkRequest: checklist.checkRequest.filter(
  //         (item: any) => item._id !== data._id
  //       ),
  //     });
  //   });
  // };

  const save = () => {
    if (items[currentProject].prePresentationChecklist?._id) {
      const body = {
        ...items[currentProject].prePresentationChecklist,
        bonusPayment: bonusPayment,
      };
    } else {
      message.warn("Please Create Pre-Presentation Checklist");
    }
    // dispatch(updatePrePresentationChecklist(body));
  };

  const handleSendEmail = (body: any) => {
    let binaryString = generateCheckReqPdf(body, "email");

    dispatch(sendCheckRequestEmail({ ...body, binaryString }));
    message.success("Email Sent");
    setShowEmailModal(false);
  };

  const handleSaveOutlinedClick = () => {
    setShowModal(true);
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  return (
    <div className="check-request-root">
      <EmailModal
        showModal={showEmailModal}
        setShowModal={setShowEmailModal}
        emailSubject={emailSubject}
        emailBody={emailBody}
        emailRecipients={emailRecipients}
        sendEmail={handleSendEmail}
      />
      <AddNewModal
        toggleSourceLocationModal={toggleSourceLocationModal}
        showSourceLocationModal={showSourceLocationModal}
        handleAddAmount={handleAddAmount}
        checkRequestRetainerPercent={checkRequestRetainerPercent}
      />
      <Row className="check-request-title-header" justify="space-between">
        <Col>
          <h1>Check Request</h1>
        </Col>
        <Col>
          <Button
            style={{
              color: "#FDB913",
              border: "1px solid #fdb913",
              marginRight: 15,
            }}
            onClick={handleAdd}
            className="check-request-add-new-btn"
          >
            Add New
          </Button>
          <>
            {items[currentProject]?.checkRequests.length > 0 ? (
              <Button
                type="primary"
                onClick={generateCheckReqPdf}
                className="check-request-add-new-btn"
                style={{ color: "#1f1f1f" }}
              >
                Download Pdf
              </Button>
            ) : (
              <Button
                type="primary"
                className="check-request-add-new-btn"
                style={{ color: "#1f1f1f" }}
                disabled
              >
                Download Pdf
              </Button>
            )}
          </>
        </Col>
      </Row>

      <CheckRequestDescription
        bonusPayment={bonusPayment}
        handleBonusPayment={handleBonusPayment}
        handleSaveOutlinedClick={handleSaveOutlinedClick}
        showModal={showModal}
        handleModalCancel={handleModalCancel}
        handleOk={handleOk}
        crewName={
          items[currentProject].prePresentationChecklist?.crew?.fullName || ""
        }
        projectName={items[currentProject]?.projectInfo.jobName || ""}
        contractedPrice={contractedPrice || 0}
        handleSave={save}
        jobNumber={items[currentProject]?.projectInfo.jobNumber || ""}
        handleNotesChange={handleNotesChange}
        checkRequestRetainerPercent={checkRequestRetainerPercent}
        handleRetainerPercentageChange={handleRetainerPercentageChange}
        handleRetainerPercentageSave={handleRetainerPercentageSave}
        handleResetOnRetainerPercentage={handleResetOnRetainerPercentage}
      />

      <hr style={{ border: "1px solid #E8E8E8" }} />

      <Table
        scroll={{ x: 900 }}
        style={{ paddingBottom: 20 }}
        rowKey="_id"
        dataSource={items[currentProject].checkRequests}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default CheckRequestC;
