import { Button, Col, Form, Input, message, Modal, Row, Space } from "antd";
import React, { useEffect } from "react";

type Props = {
  showModal: boolean;
  setShowModal: any;
  emailSubject: string;
  emailBody: string;
  emailRecipients?: string;
  role?: string;
  sendEmail: (body: {
    emailSubject: string;
    emailBody: string;
    emailRecipients?: string;
  }) => void;
  sendPPGEmail?: (body: {
    emailSubject: string;
    emailBody: string;
    emailRecipients?: string;
  }) => void;
  loading?: boolean;
};

const EmailModal = (props: Props) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      emailSubject: props.emailSubject,
      emailBody: props.emailBody,
      emailRecipients: props?.emailRecipients,
    });
  }, [
    props.emailSubject,
    props.emailBody,
    props?.emailRecipients,
    props.showModal,
  ]);

  const checkEmpty = {
    validator(_: any, value: string) {
      if (value) {
        if (value.startsWith(" ")) {
          return Promise.reject(new Error("Invalid"));
        }
      }

      return Promise.resolve();
    },
  };

  var stringToHTML = (str: string) => {
    const htmlText = str.replace(/\n/g, "<br>");
    return `<p>${htmlText}</p>`;
  };

  const handleOk = async (values: any) => {
    let htmlString = stringToHTML(values.emailBody);
    if (props.role === "PPG" && props.emailRecipients) {
      let trimmedString = values.emailRecipients.replace(/\s/g, "");
      let recipientsArray = trimmedString.split(";");
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      for (let i = 0; i < recipientsArray.length; i++) {
        const email = recipientsArray[i];
        if (!emailRegex.test(email)) {
          message.error(`Invalid email: ${email}`);
          return;
        }
      }
      let body = {
        emailSubject: values.emailSubject,
        emailBody: htmlString,
        emailRecipients: recipientsArray,
      };
      if (props.sendPPGEmail) {
        props.sendPPGEmail(body);
      }
    } else if (props.emailRecipients) {
      let trimmedString = values.emailRecipients.replace(/\s/g, "");
      let recipientsArray = trimmedString.split(";");
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      for (let i = 0; i < recipientsArray.length; i++) {
        const email = recipientsArray[i];
        if (!emailRegex.test(email)) {
          message.error(`Invalid email: ${email}`);
          return;
        }
      }
      let body = {
        emailSubject: values.emailSubject,
        emailBody: htmlString,
        emailRecipients: recipientsArray,
      };
      props.sendEmail(body);
    } else {
      let body = {
        emailSubject: values.emailSubject,
        emailBody: htmlString,
      };
      props.sendEmail(body);
    }
  };

  const handleCancel = () => {
    props.setShowModal(false);
  };
  return (
    <>
      <Modal
        title={
          <h2 style={{ marginTop: 10, fontWeight: "bold" }}>Email Template</h2>
        }
        visible={props.showModal}
        footer={false}
        onCancel={handleCancel}
        width={"50%"}
      >
        <Space direction="vertical" size={"middle"} style={{ display: "flex" }}>
          <Form
            form={form}
            onFinish={handleOk}
            scrollToFirstError
            layout="vertical"
            style={{ padding: 20 }}
            autoComplete="off"
          >
            <Row>
              <Col span={6}>
                <h2 style={{ fontWeight: "bold" }}>Subject: </h2>
              </Col>
              <Col span={18}>
                <Form.Item
                  // initialValue={props.emailSubject}
                  hasFeedback
                  name="emailSubject"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the Email Subject!",
                    },
                    checkEmpty,
                  ]}
                >
                  <Input className="form-input" />
                </Form.Item>
              </Col>
            </Row>
            {props.emailRecipients && (
              <>
                <Row>
                  <Col span={6}>
                    <h2 style={{ fontWeight: "bold" }}>Recipients: </h2>
                  </Col>
                  <Col span={18}>
                    <Form.Item
                      // initialValue={props.emailBody}
                      name="emailRecipients"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Recipients!",
                        },
                        checkEmpty,
                      ]}
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 2, maxRows: 2 }}
                        style={{
                          borderRadius: "5px",
                          border: "1px solid #e8e8e8",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Row>
              <Col span={6}>
                <h2 style={{ fontWeight: "bold" }}>Email Content: </h2>
              </Col>
              <Col span={18}>
                <Form.Item
                  // initialValue={props.emailBody}
                  name="emailBody"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Email Content!",
                    },
                    checkEmpty,
                  ]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 10, maxRows: 18 }}
                    style={{
                      borderRadius: "5px",
                      border: "1px solid #e8e8e8",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Col>
                <Button
                  key="back"
                  onClick={handleCancel}
                  style={{
                    fontWeight: "bold",
                    fontSize: "15px",
                    lineHeight: "18px",
                    letterSpacing: "0.055em",
                    height: "40px ",
                    color: "#FDB913",
                    border: "1px solid #fdb913",
                    borderRadius: "5px ",
                    width: "120px",
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  htmlType="submit"
                  disabled={props.loading}
                  type="primary"
                  style={{
                    margin: "0px 10px 0px 5px",
                    fontWeight: "bold",
                    fontSize: "15px",
                    lineHeight: "18px",
                    height: "40px",
                    letterSpacing: "0.055em",
                    width: "150px",
                    /* Grey 1 */

                    color: "#1f1f1f ",
                    borderRadius: "5px ",
                  }}
                  loading={props.loading}
                >
                  {props.loading ? "Order Placing" : "Send Email"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Space>
      </Modal>
    </>
  );
};

export default EmailModal;
