import React, { useEffect, useState } from "react";
import {
  Select,
  Modal,
  Space,
  AutoComplete,
  List,
  Checkbox,
  Input,
  message,
} from "antd";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { customToFixed } from "../../../utils/helpers";
import _, { cloneDeep } from "lodash";
import "../../../components/pop-ups/SendRequest.less";
import { bulkUpdateProjectPaintOrPrimerMaterial } from "../../../redux/project/action";

type Props = {
  isBulkEditModalOpen: boolean;
  setIsBulkEditModalOpen: any;
  projectPaintSurfaces: any;
};

const BulkEditPaintLaborRateModal = (props: Props) => {
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );
  const dispatch = useDispatch();
  const [listData, setListData] = useState<any[]>([]);
  const [checked, setChecked] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [selectedPaintMaterial, setSelectedPaintMaterial] =
    useState<string>("");
  const [selectedPrimerMaterial, setSelectedPrimerMaterial] =
    useState<string>("");

  useEffect(() => {
    initializePaintSurfaceListAndStates();
  }, [props.projectPaintSurfaces]);

  useEffect(() => {
    if (checked.length === listData.length) {
      const notChecked = checked.filter((item: any) => item.checked === false);
      if (checked.length - notChecked.length < checked.length) {
        setCheckAll(false);
      } else {
        setCheckAll(true);
      }
    }
  }, [checked]);

  const initializePaintSurfaceListAndStates = () => {
    let sortedListData: any;
    const project = _.cloneDeep(items[currentProject]); // Deep copy to avoid mutations
    const hasOptionPaintRates = project.options.some(
      (option: any) => option.rates?.paintRates?.length > 0,
    );
    if (hasOptionPaintRates) {
      const paintLaborRates = [...props.projectPaintSurfaces];
      let laborRatesNotFoundInOption: string[] = [];

      // Collect all paint rate IDs from all options
      const allPaintRateIds = project.options?.flatMap((option: any) =>
        option?.rates?.paintRates.map((paintRate: any) => {
          if (!paintRate.isDeleted) return paintRate.projectLaborRate;
        }),
      );

      // Filter rates that are not found in any of the paintRates
      laborRatesNotFoundInOption = paintLaborRates
        .filter((rate) => !allPaintRateIds.includes(rate._id))
        .map((rate) => rate._id);

      sortedListData = props.projectPaintSurfaces
        .filter((item: any) => !laborRatesNotFoundInOption.includes(item._id))
        .filter((item: any) => !item.isDeleted)
        .map((item: any) => {
          return { ...item, checked: true };
        })
        .sort((a: any, b: any) => a.item.localeCompare(b.item));
    } else {
      sortedListData = props.projectPaintSurfaces
        .filter((item: any) => !item.isDeleted)
        .map((item: any) => {
          return { ...item, checked: true };
        })
        .sort((a: any, b: any) => a.item.localeCompare(b.item));
    }
    setListData(sortedListData);
    setCheckAll(true);
    let checkeditemList = sortedListData.map((item: any) => {
      return { id: item._id, checked: true };
    });
    setChecked(checkeditemList);
  };

  const handleSetAllChecked = (e: any) => {
    const checked = e.target.checked;
    let markedList = listData.map((item: any) => {
      return { ...item, checked: checked };
    });
    let checkeditemList = listData.map((item: any) => {
      return { id: item._id, checked: checked };
    });
    setCheckAll(checked);
    setListData(markedList);
    setChecked(checkeditemList);
  };

  const handleCheckboxChange = (e: any, item: any, index: number) => {
    const isChecked = e.target.checked;
    const updatedItem = { ...item, checked: isChecked };

    const updatedList = listData.map((listItem, i) =>
      i === index ? updatedItem : listItem,
    );

    setListData(updatedList);
    setChecked((prevChecked) => {
      const existingIndex = prevChecked.findIndex(
        (item) => item.id === updatedItem._id,
      );

      if (existingIndex > -1) {
        const updatedChecked = [...prevChecked];
        updatedChecked[existingIndex].checked = isChecked;
        return updatedChecked;
      } else {
        return [...prevChecked, { id: updatedItem._id, checked: isChecked }];
      }
    });
  };

  const updateMaterialsInRates = (
    rate: any,
    materialToUpdate: any,
    materialType: string,
  ) => {
    if (materialType === "paintMaterial") {
      rate.paintMaterial = materialToUpdate;
    } else if (materialType === "primerMaterial") {
      rate.primerMaterial = materialToUpdate;
    }
  };

  const handleBulkUpdatePaintMaterial = () => {
    const data = {
      paintMaterial: selectedPaintMaterial,
      primerMaterial: selectedPrimerMaterial,
    };

    console.log("DATA>>", data);
    const dataToUpdate = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value !== ""),
    );

    const itemsToUpdate = checked.filter((item: any) => item.checked === true);

    const project = _.cloneDeep(items[currentProject]);
    const optionIds = project.options.map((item: any) => item.optionInfo._id);

    const paintMaterialToUpdate =
      dataToUpdate?.paintMaterial === "customerSupplied"
        ? "customerSupplied"
        : adminDefaults.materialDefaults.paints.find(
            (item: any) => item._id === dataToUpdate?.paintMaterial,
          );
    const primerMaterialToUpdate =
      dataToUpdate?.primerMaterial === "customerSupplied"
        ? "customerSupplied"
        : adminDefaults.materialDefaults.primers.find(
            (item: any) => item._id === dataToUpdate?.primerMaterial,
          );

    // Patch call body
    const patchBody = [];

    for (const item of itemsToUpdate) {
      const projectRateIndex = project.projectRates.paintRates.findIndex(
        (ele: any) => ele._id === item.id,
      );

      if (projectRateIndex >= 0) {
        const projectRate = project.projectRates.paintRates[projectRateIndex];
        const updates: any = {};

        // Update top-level rates
        if (dataToUpdate.hasOwnProperty("paintMaterial")) {
          updateMaterialsInRates(
            projectRate,
            paintMaterialToUpdate,
            "paintMaterial",
          );
          updates.paintMaterial = paintMaterialToUpdate;
        }
        if (dataToUpdate.hasOwnProperty("primerMaterial")) {
          updateMaterialsInRates(
            projectRate,
            primerMaterialToUpdate,
            "primerMaterial",
          );
          updates.primerMaterial = primerMaterialToUpdate;
        }

        // Gather associated option rate IDs
        const optionRateIds = [];
        if (optionIds.length > 0) {
          for (const id of optionIds) {
            const optionIndex = project.options.findIndex(
              (opt: any) => opt.optionInfo._id === id,
            );

            if (optionIndex >= 0) {
              const rates = project.options[optionIndex].rates.paintRates;
              const rateIndex = rates.findIndex(
                (rate: any) => rate.projectLaborRate === item.id,
              );

              if (rateIndex >= 0) {
                const nestedRate = rates[rateIndex];
                if (dataToUpdate.hasOwnProperty("paintMaterial")) {
                  updateMaterialsInRates(
                    nestedRate,
                    paintMaterialToUpdate,
                    "paintMaterial",
                  );
                }
                if (dataToUpdate.hasOwnProperty("primerMaterial")) {
                  updateMaterialsInRates(
                    nestedRate,
                    primerMaterialToUpdate,
                    "primerMaterial",
                  );
                }
                console.log("OptionRateId: ", item.id, rates[rateIndex]._id);
                optionRateIds.push(rates[rateIndex]._id);
              }
            }
          }
        }

        // Construct payload for this projectRate
        patchBody.push({
          projectRateId: item.id,
          optionRateIds,
          updates,
        });
      }
    }

    console.log("Patch body:", JSON.stringify(patchBody, null, 2));
    console.log("ReduxBody:", project);
    dispatch(bulkUpdateProjectPaintOrPrimerMaterial(patchBody, project));
    setSelectedPaintMaterial("");
    setSelectedPrimerMaterial("");
    setChecked([]);
    setCheckAll(false);
    props.setIsBulkEditModalOpen(false);
  };

  return (
    <Modal
      className="surface-modal"
      destroyOnClose={true}
      title={<Space style={{ fontWeight: "bold" }}>Bulk Edit</Space>}
      open={props.isBulkEditModalOpen}
      onCancel={() => props.setIsBulkEditModalOpen(false)}
      onOk={() => {
        const notAllCheckedFalse = checked.filter(
          (item: any) => item.checked === true,
        );
        const hasSelectedMaterial =
          selectedPaintMaterial !== "" || selectedPrimerMaterial !== "";
        if (notAllCheckedFalse.length > 0 && hasSelectedMaterial) {
          handleBulkUpdatePaintMaterial();
        } else if (notAllCheckedFalse.length === 0) {
          message.error("No items are selected. Please check items to update.");
        } else if (!hasSelectedMaterial) {
          message.error(
            "No material is selected. Please provide a paint or primer material.",
          );
        }
      }}
    >
      <>
        <div
          style={{
            marginLeft: 205,
            marginBottom: 8,
            display: "flex",
            alignItems: "center",
          }}
        >
          <label style={{ margin: 0, fontWeight: "bold" }}>
            Paint Material
          </label>
          <label style={{ marginLeft: 155, fontWeight: "bold" }}>
            Primer Material
          </label>
        </div>
        <div
          style={{
            marginLeft: 205,
            marginBottom: 8,
            display: "flex",
            alignItems: "center",
          }}
        >
          {/*Paint Material*/}
          <Select
            showSearch
            dropdownMatchSelectWidth={false}
            style={{ caretColor: "transparent", width: 200 }}
            filterOption={(input, option) =>
              (option?.children?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={(value: string) => setSelectedPaintMaterial(value)}
          >
            {adminDefaults.materialDefaults.paints.map(
              (material: any, index: any) => (
                <Select.Option key={index} value={material["_id"]}>
                  {`${material.manufacturer}: ${material.product}: ${material.sheen}`}
                </Select.Option>
              ),
            )}
            <Select.Option key="customerSupplied" value="customerSupplied">
              Customer Supplied
            </Select.Option>
          </Select>
          {/*Primer Material*/}
          <Select
            showSearch
            filterOption={(input, option) =>
              (option?.children?.toString() ?? "")
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            dropdownMatchSelectWidth={false}
            style={{ caretColor: "transparent", width: 200, marginLeft: 40 }}
            onChange={(value: string) => setSelectedPrimerMaterial(value)}
          >
            {adminDefaults.materialDefaults.primers.map(
              (material: any, index: any) => (
                <Select.Option key={index} value={material["_id"]}>
                  {`${material.manufacturer}: ${material.product}: ${material.sheen}`}
                </Select.Option>
              ),
            )}
            <Select.Option key="customerSupplied" value="customerSupplied">
              Customer Supplied
            </Select.Option>
          </Select>
        </div>

        <div
          className="checkbox-scroll-bar"
          style={{
            maxHeight: 500,
            maxWidth: 700,
            overflowY: "auto",
          }}
        >
          <Checkbox
            checked={checkAll}
            onChange={(e) => handleSetAllChecked(e)}
            style={{ marginRight: 25, width: 180, textAlign: "center" }}
          >
            <span style={{ marginLeft: 40, fontWeight: "bold" }}>
              Select All
            </span>
          </Checkbox>
          <List
            itemLayout="horizontal"
            className="surface-list-container"
            dataSource={listData}
            renderItem={(item: any, index: number) => {
              console.log("Item to show", item);
              return (
                <List.Item>
                  <Checkbox
                    checked={item.checked}
                    onChange={(e) => handleCheckboxChange(e, item, index)}
                  />
                  <label style={{ width: 100 }}>{item.item}</label>
                  <span style={{ width: 200, textAlign: "left" }}>
                    {item?.customerSuppliedPaint === true
                      ? "Customer Supplied"
                      : item?.paintMaterial?.product}
                  </span>
                  <span style={{ width: 200, textAlign: "left" }}>
                    {item?.customerSuppliedPrimer === true
                      ? "Customer Supplied"
                      : item?.primerMaterial?.product}
                  </span>
                </List.Item>
              );
            }}
          />
        </div>
      </>
    </Modal>
  );
};

export default BulkEditPaintLaborRateModal;
