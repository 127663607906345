import { useEffect, useState } from 'react';
import { Col, Row, Button, Table, Form, Select } from 'antd';
import {
  MessageOutlined,
  UpSquareOutlined,
  DownSquareOutlined,
} from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import './OptionSetup.less';
import AddOption from '../../components/pop-ups/AddOption';
import AddNotesModal from '../../components/pop-ups/AddNotesModal';

import { TrashIcon } from '../../utils/icons';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import {
  createOption,
  deleteOptionById,
  fetchOptions,
  fetchOptionsPjcc,
  setCurrentOption,
  updateOption,
} from '../../redux/options/action';
import axios from 'axios';
import { API_BASE } from '../../constant';
import { getAuthUser } from '../../utils/authToken';
import api from '../../utils/api';
import _, { filter } from 'lodash';
import {
  setActiveProject,
  addOptionToProject,
  deleteOptionFromProject,
  updateOptionFromProject,
  updateCurrentProject,
  updateProjectOnNewOption,
  updateProjectById,
  updateOptionPostions,
} from '../../redux/project/action';
import { pricingCalculation } from '../../utils/priceCalculation';
var crypto = require('crypto');

const { Option } = Select;

type Props = {
  manager?: boolean;
};

const OptionSetup = (props: Props) => {
  const dispatch = useDispatch<any>();

  const [itemsCount, setItemsCount] = useState(0);
  const [itemsSelected, setItemsSelected] = useState([]);
  const [tableData, setTableData] = useState<any>([]);
  const [oldTableState, setOldTableState] = useState<any>([]);
  const [form] = Form.useForm();
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showModalForEstimate, setShowModalForEstimate] = useState(false);
  const [rearrangeOptions, setRearrangeOptions] = useState(false);

  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    let project: any = _.cloneDeep(items[currentProject]);
    setTableData(
      project?.options.map((option: any) => {
        return option.optionInfo;
      }).sort(
        (a: any, b: any) => a.position - b.position,
      ),
    );
    setOldTableState(project?.options.map((option: any) => {
      return option.optionInfo;
    }).sort(
      (a: any, b: any) => a.position - b.position,
    ),)
  }, [items[currentProject]]);

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setItemsCount(selectedRows.length);
      setItemsSelected(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const toggleNotesModal = (collapse: boolean) => {
    !showNotesModal ? setShowNotesModal(true) : setShowNotesModal(false);
  };
  const handleNotesModal = () => {
    !showNotesModal ? toggleNotesModal(true) : toggleNotesModal(false);
  };

  const toggleModal = (_: boolean) => {
    !showModalForEstimate
      ? setShowModalForEstimate(true)
      : setShowModalForEstimate(false);
  };
  const handleAddSurface = () => {
    !showModalForEstimate ? toggleModal(true) : toggleModal(false);
  };

  const handleAddAnother = (values: any) => {
    let _id = crypto.randomBytes(12).toString('hex');
    let position = items[currentProject].options.length;
    const body = {
      title: values.title,
      address: values.address,
      _id: _id,
      project: items[currentProject].projectId,
      traversed: false,
      included: 'included',
      position: position + 1,
      washIncluded:
        items[currentProject].projectInfo.projectSubtype == 'Exterior'
          ? true
          : false,
      buildingQuantity: 1,
      overRideGrossMargin: [],
    };

    let newProject: any = _.cloneDeep(items[currentProject]);

    //PaintRates
    let paintRates: any = [];
    newProject.projectRates.paintRates?.forEach((projectRate: any) => {
      let rateRefId = projectRate._id;
      // delete projectRate._id;

      let count = 0;
      let coats = 1;
      let color = 'TBD';
      let prepHours = count / projectRate.prepLaborRate;
      let primeHours = 0;
      let paintHours = (count * coats) / projectRate.paintLaborRate;
      let primerGallons = 0;
      let primeSelect = 'spot';
      let paintGallons = (count * coats) / projectRate.paintSpreadRate;
      let paintRateId = crypto.randomBytes(12).toString('hex');

      const optionRate = {
        ...projectRate,
        _id: paintRateId,
        projectLaborRate: rateRefId,
        option: _id,
        count,
        coats,
        color,
        prepHours,
        primeHours,
        paintHours,
        primerGallons,
        primeSelect,
        paintGallons,
        totalPaintHours: prepHours + primeHours + paintHours,
        paintMaterialCost:
          projectRate.paintMaterial?.priceAfterTax * paintGallons +
          projectRate.primerMaterial?.priceAfterTax * primerGallons,
      };

      paintRates.push(optionRate);
    });
    console.log(paintRates);

    //Carpentry Rates
    let carpentryRates: any = [];
    let carpentryLaborRateConst = adminDefaults.wageDefaults.find(
      (item: any) => {
        return item.title === 'Carpentry Wage Rate';
      },
    );
    newProject.projectRates.carpentryRates?.forEach((projectRate: any) => {
      const projectRateObj = projectRate;
      let rateRefId = projectRate._id;
      // delete projectRateObj._id;

      let count = 0;
      let carpentryMaterial = count * projectRate.materialRate;
      let carpentryHours =
        (1.08 * count * projectRate.laborRate) /
        carpentryLaborRateConst.wageValue;
      let carpentryRateId = crypto.randomBytes(12).toString('hex');

      const optionRate = {
        ...projectRateObj,
        _id: carpentryRateId,
        projectLaborRate: rateRefId,
        option: _id,
        count,
        carpentryMaterial,
        carpentryHours,
      };
      carpentryRates.push(optionRate);
    });

    //Equipment Rates
    let equipmentRates: any = [];
    newProject.projectRates.equipmentRates?.forEach((projectRate: any) => {
      const projectRateObj = projectRate;
      let rateRefId = projectRate._id;
      // delete projectRateObj._id;

      let count = 0;
      let periods = 1;
      let tax = projectRate.tax;
      let cost = projectRate.cost;
      let totalCost =
        count *
        periods *
        (cost +
          projectRate.defaultRate?.surcharges +
          projectRate.defaultRate?.puAndDelivery) *
        (1 + tax / 100);
      let equipmentRateId = crypto.randomBytes(12).toString('hex');

      const optionRate = {
        ...projectRateObj,
        _id: equipmentRateId,
        projectLaborRate: rateRefId,
        option: _id,
        totalCost,
        count,
        periods,
        surcharges: projectRate.defaultRate?.surcharges,
        puAndDelivery: projectRate.defaultRate?.puAndDelivery,
        hasIntervals: projectRate.defaultRate?.hasIntervals,
        monthlyCost: projectRate.defaultRate?.monthlyCost,
        weeklyCost: projectRate.defaultRate?.weeklyCost,
        dailyCost: projectRate.defaultRate?.dailyCost,
      };

      equipmentRates.push(optionRate);
    });

    //Travel Rates
    let travelRates: any = [];
    newProject.projectRates.travelRates?.forEach((projectRate: any) => {
      const projectRateObj = projectRate;
      let rateRefId = projectRate._id;
      // delete projectRateObj._id;

      let rfmCount = 0;
      let dnCount = 0;
      let cost = projectRate.cost;
      let totalCost = cost * rfmCount * dnCount;
      let travelRateId = crypto.randomBytes(12).toString('hex');

      const optionRate = {
        ...projectRateObj,
        _id: travelRateId,
        projectLaborRate: rateRefId,
        option: _id,
        totalCost,
        rfmCount,
        dnCount,
      };
      travelRates.push(optionRate);
    });

    //Misc Rates
    let miscellaneousRates: any = [];
    newProject.projectRates.miscellaneousRates?.forEach((projectRate: any) => {
      const projectRateObj = projectRate;
      let rateRefId = projectRate._id;
      // delete projectRateObj._id;

      let count = 0;
      let cost = projectRate.cost;
      let totalCost = cost * count;
      let miscRateId = crypto.randomBytes(12).toString('hex');

      const optionRate = {
        ...projectRateObj,
        _id: miscRateId,
        projectLaborRate: rateRefId,
        option: _id,
        totalCost,
        count,
      };
      miscellaneousRates.push(optionRate);
    });

    let washRates: any = [];
    let squareFeet = 0;
    for (const item of paintRates) {
      squareFeet += item.count;
    }
    let defaultWashRate = adminDefaults.defaultRates.washRates[0];
    washRates.push({
      rate: defaultWashRate.medium,
      diffLevel: 2,
      squareFeet,
      totalHours: squareFeet / defaultWashRate.medium,
      option: _id,
      project: items[currentProject].projectId,
      defaultRate: defaultWashRate._id,
      _id: crypto.randomBytes(12).toString('hex'),
    });

    let newBody = {
      optionInfo: body,
      pjcc: [],
      rates: {
        paintRates,
        carpentryRates,
        equipmentRates,
        travelRates,
        miscellaneousRates,
        washRates,
      },
    };

    newProject.options.push(newBody);
    // var updatedProject = pricingCalculation(newProject, adminDefaults);

    // dispatch(updateCurrentProject(updatedProject));
    newProject.projectInfo.overRideGrossMargin = [];
    let updateProjectBody = newProject.projectInfo;
    dispatch(addOptionToProject(newBody, newProject, adminDefaults));
    dispatch(updateProjectOnNewOption(updateProjectBody));
    form.resetFields();
    let newTableData = [...tableData, newBody.optionInfo];
    newTableData = newTableData.sort((a, b) => a.position - b.position);
    setTableData(newTableData);
    if (!values.toggle) {
      !showModalForEstimate ? toggleModal(true) : toggleModal(false);
    }

    // dispatch(createOption(body))
    //   .then(() => {
    //     form.resetFields();
    //     !showModalForEstimate ? toggleModal(true) : toggleModal(false);
    //   })
    //   .catch((err: Error) => console.log(err));
  };

  const deleteOption = (id: string) => {
    let project: any = _.cloneDeep(items[currentProject]);
    let index = project.options.findIndex(
      (element: any) => element.optionInfo._id === id,
    );
    const optionLength = project.options.length;
    const position = project.options[index].optionInfo.position;

    if (position !== optionLength) {
      let options = _.cloneDeep(project.options).map(
        (option: any) => option.optionInfo,
      );

      // Sort options by position
      const sortedOptions = options.sort(
        (a: any, b: any) => a.position - b.position,
      );

      // Convert position to zero-indexed for splice
      const indexToDelete = position - 1;

      // Remove the item at the calculated index
      sortedOptions.splice(indexToDelete, 1);

      // Update positions for remaining items starting from the deleted item's index
      for (let i = indexToDelete; i < sortedOptions.length; i++) {
        sortedOptions[i].position = i + 1;
      }

      const updatedOptionPostionsBody = sortedOptions.map((item: any) => ({
        _id: item._id,
        position: item.position,
      }));

      dispatch(updateOptionPostions(updatedOptionPostionsBody, id));
    }

    project.options.splice(index, 1);
    dispatch(deleteOptionFromProject(id, project, adminDefaults));
    if (project.options.length === 0) {
      dispatch(
        updateProjectById({
          ...project?.projectInfo,
          productionPjcc: [],
          discounts: [],
          overRideGrossMargin: [],
        }),
      );
    }
    if (itemsSelected) {
      const filterSelected = itemsSelected.filter((option: any) => {
        const findSelected = project.options.find(
          (item: any) => item.optionInfo._id == option._id,
        );
        return findSelected;
      });
      setItemsSelected(filterSelected);
      setItemsCount(filterSelected?.length);
    }
  };

  const handleOptionSelect = (option: any) => {
    const optionIndex = items[currentProject].options.findIndex(
      (item: any) => item.optionInfo._id == option._id,
    );
    dispatch(setCurrentOption(optionIndex));
  };
  const handleSelect = (optionRow: any, value: string, index: number) => {
    const data = [...tableData];
    data[index].included = value;
    setTableData(data);
    let newProject: any = _.cloneDeep(items[currentProject]);
    const itemIndex = newProject?.options.map((option: any) => {
      return option.optionInfo;
    }).findIndex((item: any) => item._id === optionRow._id)
    newProject.options[itemIndex].optionInfo.included = value;

    let body = {
      included: value,
      options: [optionRow],
    };
    dispatch(updateOptionFromProject(body, newProject, adminDefaults));

    // api
    //   .put("options/update/included", { included: value, options: [optionRow] })
    //   .then((resp: any) => {
    //     dispatch(updateOption(resp));
    //   })
    //   .catch((err: Error) => console.error(err));
  };

  const handleInclude = (include: string) => {
    if (itemsSelected.length > 0) {
      let newProject: any = _.cloneDeep(items[currentProject]);
      for (const singleOption of newProject.options) {
        itemsSelected.forEach((element: any) => {
          if (singleOption.optionInfo.title === element.title) {
            singleOption.optionInfo.included = include;
          }
        });
      }
      let body = {
        included: include,
        options: itemsSelected,
      };
      dispatch(updateOptionFromProject(body, newProject, adminDefaults));
    }
  };
  const findPrice = (data: any) => {
    let allOptionsPjcc = [];
    for (const element of items[currentProject].options) {
      let pjcc = element.pjcc;
      allOptionsPjcc.push(...pjcc);
    }
    const option = allOptionsPjcc.find(
      (item: any) =>
        item.option === data._id && item.name === 'Estimated Price',
    );
    return option?.totalAmount ? Math.round(option.totalAmount) : null;
  };

  const findGM = (data: any) => {
    let allOptionsPjcc = [];
    for (const element of items[currentProject].options) {
      let pjcc = element.pjcc;
      allOptionsPjcc.push(...pjcc);
    }
    const option = allOptionsPjcc.filter(
      (item: any) => item.option === data._id,
    );

    const estimatedPrice = option.find(
      (item: any) => item.name === 'Estimated Price',
    );
    const gm = (1 - estimatedPrice?.cost / estimatedPrice?.amount) * 100;
    return gm ? gm.toFixed(2) : null;
  };

  const handleOptionPosition = (dataId: string, step: 'up' | 'down') => {
    // Sort the tableData to ensure it is ordered by position
    const sortedOptions = [...tableData];
    const currentIndex = sortedOptions.findIndex((item) => item._id === dataId);

    if (
      currentIndex === -1 ||
      (step === 'up' && currentIndex === 0) ||
      (step === 'down' && currentIndex === sortedOptions.length - 1)
    ) {
      return; // No operation if out of bounds or not found.
    }

    // Calculate new index based on move direction
    const newIndex = step === 'up' ? currentIndex - 1 : currentIndex + 1;
    const itemToMove = sortedOptions[currentIndex];
    const adjacentItem = sortedOptions[newIndex];

    const rowAnimationClass = step === 'up' ? 'move-up' : 'move-down';

    // Swap positions
    const tempPosition = itemToMove.position;
    itemToMove.position = adjacentItem.position;
    adjacentItem.position = tempPosition;

    const updatedData = sortedOptions.map((item, index) => {
      if (index === currentIndex) {
        return { ...item, animationClass: rowAnimationClass };
      }
      return { ...item, animationClass: '' };
    }).sort(
      (a: any, b: any) => a.position - b.position,
    );

    // Update table data with animation classes
    setTableData(updatedData);

    // Remove animation classes after animation completes
    setTimeout(() => {
      setTableData(updatedData.map((item: any) => ({ ...item, animationClass: '' })));
    }, 900);
  };

  const optionSetupColumns = [
    {
      title: '',
      width: rearrangeOptions ? "80px" : "0px",
      render: (data: any) => {
        const position = tableData.findIndex((item: any) => item._id === data._id) + 1;

        return (
          <>
            {rearrangeOptions && (
              <>
                {' '}
                <UpSquareOutlined
                  onClick={() => handleOptionPosition(data._id, 'up')}
                  disabled={position === 1}
                  style={{
                    color: position === 1 ? '#d6d6d6' : '#FDB913',
                    cursor: 'pointer',
                    fontSize: '20px',
                    marginTop: 5,
                    fontWeight: "normal"
                  }}
                />{' '}
                <DownSquareOutlined
                  onClick={() => handleOptionPosition(data._id, 'down')}
                  disabled={position === tableData.length}
                  style={{
                    color: position === tableData.length ? '#d6d6d6' : '#FDB913',
                    cursor: 'pointer',
                    fontSize: '20px',
                    marginTop: 5,
                    fontWeight: "normal"
                  }}
                />
              </>
            )}
          </>
        );
      },
    },
    {
      title: 'Title',

      render: (data: any) => {
        return (
          <Link
            onClick={() => handleOptionSelect(data)}
            to={
              props.manager
                ? `/manager-option-details/${data._id}`
                : `/option-details/${data._id}`
            }
          >
            <div
              style={{
                color: '#FDB913',
                textDecoration: 'underline',
              }}
            >
              {data.title}
            </div>
          </Link>
        );
      },
    },

    {
      title: 'Address',
      render: (data: any) => {
        return (
          <Link
            to={
              props.manager
                ? `/manager-option-details/${data._id}`
                : `/option-details/${data._id}`
            }
          >
            <div style={{ color: '#333333' }}>{data.address}</div>
          </Link>
        );
      },
    },
    {
      title: 'Included / Excluded',
      render: (data: any, _: any, index: number) => {
        return (
          <Select
            style={{ width: 100 }}
            dropdownMatchSelectWidth={false}
            value={data.included}
            onChange={(value) => handleSelect(data, value, index)}
          >
            <Option value="included">Included</Option>
            <Option value="excluded">Excluded</Option>
            <Option value="optional">Optional</Option>
          </Select>
        );
      },
    },
    {
      title: 'Gross Margin',
      render: (data: any) => {
        return (
          <>
            {findGM(data) && (
              <div style={{ color: '#333333' }}>{findGM(data)}%</div>
            )}
          </>
        );
      },
    },
    {
      title: 'Price',
      render: (data: any) => {
        return (
          <>
            {findPrice(data) && (
              <div style={{ color: '#333333', minWidth: '70px' }}>
                ${findPrice(data)}
              </div>
            )}
          </>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (data: any) => {
        return (
          <TrashIcon
            onClick={() => deleteOption(data._id)}
            style={{ cursor: 'pointer' }}
          />
        );
      },

      width: '80px',
      align: 'center' as const,
    },
  ];
  return (
    <div style={{ paddingBottom: 30 }}>
      <div className="labor-container">
        <AddOption
          handleAddAnother={handleAddAnother}
          showModalForEstimate={showModalForEstimate}
          toggleModal={toggleModal}
          form={form}
        />
        <div className="labor-header-container">
          <Row justify="space-between">
            <Col>
              <h1 className="labor-header-title ">Options setup</h1>
            </Col>
            <Row gutter={10} align="middle">
              <Col>
                <Button
                  type="primary"
                  className="add-surface"
                  onClick={handleAddSurface}
                >
                  Add option
                </Button>
              </Col>
              <Col className="pb--holder">
                <MessageOutlined onClick={handleNotesModal} />
              </Col>
            </Row>
          </Row>
        </div>
        <hr className="labor-divider " />

        <div className="items-selected-container">
          <Row justify="space-between">
            <Row>
              <Col>
                {itemsCount > 0 && (
                  <h1 className="items-selected-text">
                    {itemsCount} items Selected
                  </h1>
                )}
              </Col>
            </Row>
            <Row>
              {!rearrangeOptions ? (
                <Col>
                  <Button
                    style={{
                      color: '#FDB913',
                      border: '1px solid #fdb913',
                    }}
                    onClick={() => {
                      setRearrangeOptions(true);
                    }}
                    className="pricing-btn"
                  >
                    Rearrange Options
                  </Button>
                </Col>
              ) : (
                <>
                  <Col>
                    <Button
                      style={{
                        color: '#FDB913',
                        border: '1px solid #fdb913',
                      }}
                      onClick={() => {
                        setTableData(oldTableState.map((item: any) => ({ ...item, animationClass: '' })))
                        setRearrangeOptions(false);
                      }}
                      className="pricing-btn"
                    >
                      Cancel Rearrange
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{
                        color: '#FDB913',
                        border: '1px solid #fdb913',
                      }}
                      onClick={() => {
                        const sortedOptions = [...tableData]

                        const updatedOptionPostionsBody = sortedOptions.map((item: any) => ({
                          _id: item._id,
                          position: item.position,
                        }));

                        dispatch(updateOptionPostions(updatedOptionPostionsBody, id));
                        setRearrangeOptions(false);
                      }}
                      className="pricing-btn"
                    >
                      Save Rearrange
                    </Button>
                  </Col>
                </>
              )}
              <Col>
                <Button
                  style={{
                    color: '#FDB913',
                    border: '1px solid #fdb913',
                  }}
                  onClick={() => handleInclude('optional')}
                  className="pricing-btn"
                >
                  Optional
                </Button>
              </Col>
              <Col>
                <Button
                  style={{
                    color: '#FDB913',
                    border: '1px solid #fdb913',
                  }}
                  onClick={() => handleInclude('excluded')}
                  className="pricing-btn"
                >
                  Exclude
                </Button>
              </Col>
            </Row>
          </Row>
        </div>
        <div style={{ paddingBottom: 60 }}>
          <div>
            <div className="labor-rates-container"></div>
            <Table
              scroll={{ x: 900 }}
              rowSelection={{
                ...rowSelection,
              }}
              rowClassName={(record) =>
                `table-row-animated ${record.animationClass || ''}`
              }
              pagination={false}
              columns={optionSetupColumns}
              dataSource={tableData}
              loading={tableData ? false : true}
              rowKey="_id"
            />
          </div>
        </div>
      </div>

      <AddNotesModal
        showNotesModal={showNotesModal}
        toggleNotesModal={toggleNotesModal}
      />
    </div>
  );
};

export default OptionSetup;
