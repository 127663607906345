import React, { useEffect, useState } from "react";
import { Button, Col, Input, Radio, Row, Select } from "antd";
import "./Description.less";
import ProjectSummary from "../../../components/proposal/ProjectSummary";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import axios from "axios";
import EditDeliveryAddressModal from "../../edit-delivery-address-modal/EditDeliveryAddressModal";
import { closestStoreData } from "../../../redux/closestStoreData/action";
const { Option } = Select;
const { TextArea } = Input;

type Props = {
  paintOrder: any;
  title?: string;
  deliveryAddress?: any;
  setDeliveryAddress?: any;
  pickUp?: any;
  closestStore?: any;
  setClosestStore?: any;
  jobSiteLocation?: any;
  accountNumber?: any;
  setAccountNumber?: any;
  setVendor?: any;
  selectedStore?: any;
  closetStoreSetter?: any;
};

const Description = (props: Props) => {
  const [deliveryAddressOption, setDeliveryAddressOption] =
    useState<any>("jobsite");

  const [showDeliveryEditModal, setShowDeliveryEditModal] =
    useState<any>(false);

  const [newClosestStore, setNewClosestStore] = useState<any>({});

  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData
  );

  const [vendorSelectedIndex, setVendorSelectedIndex] = useState<number>(0)

  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.closestStore || Object.keys(props.closestStore).length === 0) {
      const storeToDispatch = props.selectedStore || {};
      dispatch(closestStoreData(storeToDispatch));
    }
  }, [props.closestStore, props.selectedStore]);


  useEffect(() => {
    let paintOrderAccount =
      items[currentProject].paintOrder?.accountNumber?.split(":");
    if (paintOrderAccount) {
      const vendorIndex = adminDefaults.vendorDefaults.findIndex(
        (item: any) => item.accountNumber === paintOrderAccount[0]
      )
      setVendorSelectedIndex(vendorIndex)
    }
  }, [])



  const getVendor = (prefix: string, category: string) => {
    return adminDefaults.vendorDefaults.find((item: any) => item.prefix[0] === prefix && item.category === category);
  };

  const handleSelect = (value: any) => {
    const store = adminDefaults?.storeLocations.find((item: any) => item._id === value);

    if (!store) {
      props?.setVendor({ vendorName: "unknown" });
      props?.setAccountNumber("");
      props.closetStoreSetter('')
      props?.setClosestStore(null);
      return;
    }

    const { store: storeName } = store;
    const projectType = items[currentProject].projectInfo.projectType;
    const category = projectType === "Commercial" ? "Commercial" : "Residential";

    if (storeName === "PPG" || storeName === "SW") {
      const vendor = getVendor(storeName, category);

      if (vendor) {
        const vendorIndex = adminDefaults.vendorDefaults.findIndex(
          (item: any) => item.accountNumber === vendor.accountNumber
        );
        setVendorSelectedIndex(vendorIndex);
        props?.setVendor(vendor);
        props?.setAccountNumber(vendor.accountNumber);
      } else {
        props?.setVendor({ vendorName: "unknown" });
        props?.setAccountNumber("");
      }
    } else {
      props?.setVendor({ vendorName: "other" });
      props?.setAccountNumber("");
    }

    props.closetStoreSetter(store)
    props?.setClosestStore(store);
  };

  const handleVendor = (value: any) => {
    if (value === "other") {
      props?.setVendor({ vendorName: "other" });
      props?.setAccountNumber("");
      setNewClosestStore({});
      props.closetStoreSetter({})
      props.setClosestStore({});
    }
    let vendor = adminDefaults.vendorDefaults.filter(
      (item: any) => item._id === value
    );
    if (vendor.length > 0) {
      let valIndex = adminDefaults.vendorDefaults.findIndex((item: any) => item._id === value)
      setVendorSelectedIndex(valIndex);
      props?.setVendor(vendor[0]);
      props?.setAccountNumber(vendor[0].accountNumber);
      let storeLocationsArray = [...adminDefaults?.storeLocations];

      let filteredArray = storeLocationsArray.filter((item: any) =>
        vendor[0].prefix.includes(item.store)
      );
      let updatedArray = filteredArray.map((item: any) => {
        let distanceFromJobSite = getClosestStore(
          props?.jobSiteLocation[0],
          props?.jobSiteLocation[1],
          item.latitude,
          item.longitude
        );
        let mapLink = `https://www.google.com/maps/dir/?api=1&origin=${props?.paintOrder?.jobSiteAddress
          ?.split(" ")
          .join("+")}&destination=${item.address
            ?.split(" ")
            .join("+")}&travelmode=driving`;
        return {
          ...item,
          mapLink,
          distanceFromJobSite,
        };
      });
      if (updatedArray.length > 0) {
        const smallestObj = updatedArray?.reduce((min, obj) =>
          obj.distanceFromJobSite < min.distanceFromJobSite ? obj : min
        );
        setNewClosestStore(smallestObj);
        props.closetStoreSetter(smallestObj)
        props.setClosestStore(smallestObj);
      } else {
        setNewClosestStore({});
        props.closetStoreSetter({})
        props.setClosestStore({});
      }
    }
  };

  // const [storeLocations, setStoreLocations] = useState([
  //   ...adminDefaults?.storeLocations,
  // ]);

  // const [closestStore, setClosestStore] = useState<any>({});

  // console.log(adminDefaults.storeLocations, props.paintOrder);
  // useEffect(() => {
  //   let storeLocationsArray = [...storeLocations];
  //   axios
  //     .get(
  //       `https://script.google.com/macros/s/AKfycbzIjxF2qlxsKT-Spz0BClilOuaOI19gMAvSva9nt6yfRQWZJpQGjmwYKtP_RMIGfOJk/exec?address=${props?.paintOrder?.jobSiteAddress}`
  //     )
  //     .then((resp: any) => {
  //       if (resp?.data?.length > 0) {
  //         let paintOrderAccount = props?.paintOrder?.accountNumber.split(":");
  //         let filteredArray = storeLocationsArray.filter(
  //           (item: any) => item.store === paintOrderAccount[0]
  //         );
  //         let updatedArray = filteredArray.map((item: any) => {
  //           let distanceFromJobSite = getClosestStore(
  //             resp.data[0],
  //             resp.data[1],
  //             item.latitude,
  //             item.longitude
  //           );
  //           let mapLink = `https://www.google.com/maps/dir/?api=1&origin=${props?.paintOrder?.jobSiteAddress
  //             ?.split(" ")
  //             .join("+")}&destination=${item.address
  //             ?.split(" ")
  //             .join("+")}&travelmode=driving`;
  //           return {
  //             ...item,
  //             mapLink,
  //             distanceFromJobSite,
  //           };
  //         });

  //         const smallestObj = updatedArray.reduce((min, obj) =>
  //           obj.distanceFromJobSite < min.distanceFromJobSite ? obj : min
  //         );
  //         setClosestStore(smallestObj);
  //         setStoreLocations(updatedArray);
  //       }
  //     });
  // }, []);

  // console.log(closestStore);

  const getClosestStore = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) => {
    let radiusOfEarth = 6371000; //In Meters
    let degreeLat = ((lat2 - lat1) * Math.PI) / 180;
    let degreeLon = ((lon2 - lon1) * Math.PI) / 180;
    let a =
      Math.sin(degreeLat / 2) * Math.sin(degreeLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(degreeLon / 2) *
      Math.sin(degreeLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = radiusOfEarth * c;

    return Math.round((d / 1000) * 0.621371 * 100) / 100;
  };

  const handleDeliveryButtons = (e: any) => {
    setDeliveryAddressOption(e.target.value);
    if (e.target.value === "office") {
      props.setDeliveryAddress({
        streetAddressOne: adminDefaults?.companyInfo.companyStreetAddressOne,
        streetAddressTwo: adminDefaults?.companyInfo.companyStreetAddressTwo,
        city: adminDefaults?.companyInfo.companyCity,
        state: adminDefaults?.companyInfo.companyStateProvince,
        zip: adminDefaults?.companyInfo.companyPostalCode,
        country: adminDefaults?.companyInfo.companyCountry,
      });
    }
    if (e.target.value === "jobsite") {
      props.setDeliveryAddress({
        clientName: items[currentProject]?.projectInfo?.clientName,
        streetAddressOne: items[currentProject]?.projectInfo?.streetAddressOne,
        streetAddressTwo: items[currentProject]?.projectInfo?.streetAddressTwo,
        city: items[currentProject]?.projectInfo?.city,
        state: items[currentProject]?.projectInfo?.state,
        zip: items[currentProject]?.projectInfo?.zip,
        country: items[currentProject]?.projectInfo?.country,
      });
    }
    if (e.target.value === "other") {
      setShowDeliveryEditModal(true);
    }
  };

  const onModalCancel = () => {
    setShowDeliveryEditModal(false);
    setDeliveryAddressOption("jobsite");
  };

  const onFinish = (values: any) => {
    let completeAddress = {
      Name: values?.clientName,
      StreetAddress1: values?.streetAddressOne,
      StreetAddress2: values?.streetAddressTwo,
      City: values?.city,
      State: values?.state,
      ZIP: values?.zip,
      Country: values?.country,
    };
    props.setDeliveryAddress(values);
    setShowDeliveryEditModal(false);
  };

  return (
    <div className="c-description-root">
      <EditDeliveryAddressModal
        showModal={showDeliveryEditModal}
        onModalCancel={onModalCancel}
        onFinish={onFinish}
      />
      <Row>
        <Col lg={4} md={6} xs={6}>
          <h1>Account Number</h1>
        </Col>
        <Col lg={8} md={18} xs={18}>
          <p>
            {props?.accountNumber
              ? props?.accountNumber
              : props.paintOrder?.accountNumber}
          </p>
        </Col>
        <Col lg={4} md={6} xs={6}>
          <h1>Store Phone</h1>
        </Col>
        <Col lg={8} md={18} xs={18}>
          <p>
            {props?.selectedStore?.phone ? props?.selectedStore?.phone : "NA"}
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6} xs={6}>
          <h1>PO</h1>
        </Col>
        <Col lg={8} md={18} xs={18}>
          <p>{items[currentProject]?.projectInfo?.jobNumber}</p>
        </Col>
        <Col lg={4} md={6} xs={6}>
          <h1>PRC</h1>
        </Col>
        <Col lg={8} md={18} xs={18}>
          <p>{props.paintOrder?.activePrcDescription}</p>
        </Col>
      </Row>
      {props.title ? (
        <Row>
          <Col lg={4} md={6} xs={6}>
            <h1>Delivery Address</h1>
          </Col>
          <Col lg={14} md={18} xs={18}>
            <p>{props.paintOrder?.jobSiteAddress}</p>
          </Col>
        </Row>
      ) : (
        <>
          {!props.pickUp && (
            <>
              <Row>
                <Col lg={4} md={6} xs={6}>
                  <h1>Delivery Address</h1>
                </Col>
                <Col lg={14} md={18} xs={18}>
                  <Radio.Group
                    onChange={handleDeliveryButtons}
                    value={deliveryAddressOption}
                    optionType="button"
                    buttonStyle="solid"
                  >
                    <Row gutter={15}>
                      <Col>
                        <Radio
                          style={{
                            borderRadius: 5,
                          }}
                          value="jobsite"
                          className="delivery--button"
                        >
                          Jobsite Address
                        </Radio>
                      </Col>
                      <Col>
                        <Radio
                          style={{
                            borderRadius: 5,
                          }}
                          value="office"
                          className="pick--up--button"
                        >
                          Office Address
                        </Radio>
                      </Col>

                      <Col>
                        <Radio
                          style={{
                            borderRadius: 5,
                          }}
                          value="other"
                          className="delivery--button"
                        >
                          Other Address
                        </Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={18}>
                  <p style={{ marginTop: 15 }}>{`${props.deliveryAddress?.clientName
                    ? props.deliveryAddress?.clientName + ","
                    : ""
                    } ${props.deliveryAddress?.streetAddressOne}, ${props.deliveryAddress?.streetAddressTwo
                      ? props.deliveryAddress?.streetAddressTwo + ", "
                      : ""
                    } ${props.deliveryAddress?.city}, ${props.deliveryAddress
                      ?.state}, ${props.deliveryAddress?.zip}, ${props
                        .deliveryAddress?.country}`}</p>
                </Col>
              </Row>
            </>
          )}
        </>
      )}
      {/* Hint */}
      <Row align="middle" className="paint-order-com-desc">
        <Col lg={4} md={6} xs={6}>
          <h1>Store Location</h1>
        </Col>
        <Col lg={8} md={18} xs={18}>
          <Select
            style={{ width: "100%" }}
            className="paint-order-com-dropdown"
            value={
              props?.selectedStore?.name
                ? `${props?.selectedStore?.name} : ${props?.selectedStore?.address}`
                : ""
            }
            disabled={!!props.title}
            onChange={(value: any) => handleSelect(value)}
          >
            {adminDefaults?.storeLocations?.map((item: any) => {
              return (
                <>
                  <Option key={item._id} value={item._id}>
                    {item._id === props?.selectedStore?._id ? (
                      <>
                        {item.name} <span style={{ color: 'red' }}>(Closest Store)</span>
                      </>
                    ) : (
                      item.name
                    )}
                  </Option>
                </>
              );
            })}
          </Select>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col lg={4} md={6} xs={6}>
          <h1>Store Number</h1>
        </Col>
        <Col lg={8} md={18} xs={18}>
          {props?.selectedStore?.number ? (
            <p>{props?.selectedStore?.number}</p>
          ) : (
            <p>Store Number is not Added from Admin</p>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={6} xs={6}>
          <h1>Vendor</h1>
        </Col>
        <Col lg={4} md={18} xs={18}>
          <Select
            style={{ width: "100%" }}
            className="paint-order-com-dropdown"
            value={(vendorSelectedIndex >= 0) ? adminDefaults.vendorDefaults[vendorSelectedIndex].vendorName : adminDefaults.vendorDefaults[0].vendorName}
            disabled={!!props.title}
            onChange={(value: any) => handleVendor(value)}
          >
            {adminDefaults.vendorDefaults.map((item: any) => {
              return (
                <>
                  <Option key={item._id} value={item._id}>
                    {item.vendorName}
                  </Option>
                </>
              );
            })}
            <Option key={"other"} value={"other"}>
              Other Vendor
            </Option>
          </Select>
        </Col>
      </Row>
    </div>
  );
};

export default Description;
