import { Button, Col, message } from 'antd';
import React, { useState } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import { useParams } from 'react-router-dom';
import api from '../../utils/api';
import EmailModal from '../email-template-modal/EmailModal';
import EmailRecipients from '../email-template-modal/EmailRecipients';

type Props = {
  users: any[];
};

const EstimatorButtons = (props: Props) => {
  const [emailUrl, setEmailUrl] = useState<string>('');
  const [emailRecipients, setEmailRecipients] = useState<string>('');
  const [proposalBuffer, setProposalBuffer] = useState<any>();

  const { id } = useParams<any>();
  const { user } = useSelector((state: RootStateOrAny) => state.auth);
  const [showRecipientsModal, setShowRecipientsModal] = useState<any>(false);
  const { items, currentProject } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );

  const [showModal, setShowModal] = useState<any>(false);
  const [emailSubject, setEmailSubject] = useState<string>('');
  const [emailBody, setEmailBody] = useState<string>('');

  const notifyManager = () => {
    // console.log(items)
    setEmailSubject(
      `Project Review Request! - ${items[currentProject]?.projectInfo?.jobName}`,
    );
    setEmailBody(
      `Project Estimation is completed for ${items[currentProject]?.projectInfo?.jobName} job! Please review the job. \n\n\
      For more information please visit https://app.pro-driven.com/manager-preview-profile/${items[currentProject]?.projectInfo?._id}\n\n Thanks.\n${user?.fullName}`,
    );
    setShowModal(true);
  };

  const handleSendEmail = (body: any) => {
    api
      .post(`user/notify-manager/${id}`, body)
      .then(() => {
        message.success('Email Sent');
      })
      .catch((err) => {
        console.error(err);
      });
    setShowModal(false);
  };

  const notifyProductionAssociate = () => {
    setEmailUrl(`user/notify-production-associate/${id}`);
    setEmailSubject(
      `Production Ready- ${items[currentProject]?.projectInfo?.jobName}`,
    );
    setEmailBody(
      `Hello ${items[currentProject]?.projectInfo?.productionAssociate.fullName},\n\nThe Project for ${items[currentProject]?.projectInfo?.jobName} is now production ready. Please contact with ${items[currentProject]?.projectInfo?.salesAssociate.fullName} to complete proeject hand off. \n\nfor more information please visit https://app.pro-driven.com/manager-preview-profile/${items[currentProject]?.projectInfo?._id}.\n\nRegards,\n${user.fullName}`,
    );

    setEmailRecipients(
      `${items[currentProject]?.projectInfo?.productionAssociate.email}`,
    );
    setShowModal(true);
    setProposalBuffer('');
  };

  const fetchRecipients = (recipients: any) => {
    setEmailRecipients(recipients.map((user: any) => user.email).join('; '));
  };

  return (
    <>
      <EmailModal
        showModal={showModal}
        setShowModal={setShowModal}
        emailSubject={emailSubject}
        emailBody={emailBody}
        sendEmail={handleSendEmail}
        emailRecipients={emailRecipients}
      />
      <EmailRecipients
        users={props.users}
        showRecipientsModal={showRecipientsModal}
        setShowRecipientsModal={setShowRecipientsModal}
        handelRecipients={fetchRecipients}
        handleRevisions={notifyManager}
      />
      <Col>
        <Button
          type="primary"
          style={{
            color: '#1f1f1f',
          }}
          onClick={() => setShowRecipientsModal(true)}
          className="notify-btn"
        >
          Request Approval
        </Button>
        <Button
          type="primary"
          style={{
            color: '#1f1f1f',
          }}
          className="notify-btn"
          onClick={notifyProductionAssociate}
        >
          Notify Production Associate
        </Button>
      </Col>
    </>
  );
};

export default EstimatorButtons;
