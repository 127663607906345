import { Button, Col, Image, Input, Row, Spin, message } from "antd";
import { PlusSquareFilled } from "@ant-design/icons";
import "./ContractDefaultCard.less";
import ReactQuill from "react-quill";
import { useState } from "react";
import SelectSvgIconModal from "./SelectSvgIconModal";
import api from "../../utils/api";

type ContractDefaultDTO = {
  _id: string;
  svgString: string;
  title: string;
  description: string;
  position: number;
  addToProjects: boolean;
  isDefault: boolean;
  isEditable: boolean;
  edit?: boolean;
};

type Props = {
  setShowComponent?: (value: boolean) => void;
  setShowEditComponent?: (dataId: string) => void;
  dataToBeEdited?: ContractDefaultDTO;
  setContractDefaults: (
    value:
      | ContractDefaultDTO[]
      | ((currentDefaults: ContractDefaultDTO[]) => ContractDefaultDTO[])
  ) => void;
  currentNumberOfContractDefaults: number;
};

function AddEditContractDefaultCard({
  setShowComponent,
  setShowEditComponent,
  dataToBeEdited,
  setContractDefaults,
  currentNumberOfContractDefaults,
}: Props) {
  const [openSelectIconModal, setOpenSelectIconModal] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(
    dataToBeEdited?.svgString ? dataToBeEdited?.svgString : null
  );
  const [contractTitle, setContractTitle] = useState(
    dataToBeEdited?.title ? dataToBeEdited?.title : ""
  );
  const [contractDescription, setContractDescription] = useState(
    dataToBeEdited?.description ? dataToBeEdited?.description : ""
  );
  const [saveClicked, setSaveClicked] = useState(false)

  const handleSelectIconOk = (svgIconString: any) => {
    setSelectedIcon(svgIconString);
    setOpenSelectIconModal(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const charLimit = 50;

    if (inputValue.length <= charLimit) {
      setContractTitle(inputValue);
    } else {
      setContractTitle(inputValue.slice(0, charLimit));
    }
  };

  const handleTextEditorChange = (e: string) => {
    setContractDescription(e);
  };

  const validateInputs = () => {
    let isValid = true;
    if (!selectedIcon) {
      message.error("Select Icon");
      isValid = false;
    }
    if (!contractTitle) {
      message.error("Enter Title");
      isValid = false;
    }
    if (!contractDescription) {
      message.error("Enter Some Description");
      isValid = false;
    }
    return isValid;
  };

  const isDataUnchanged = () => {
    return (
      dataToBeEdited &&
      dataToBeEdited.svgString === selectedIcon &&
      dataToBeEdited.title === contractTitle &&
      dataToBeEdited.description === contractDescription
    );
  };

  const handleSaveOrUpdate = async () => {
    if (!validateInputs()) {
      setSaveClicked(false)
      return;
    }
    else if (dataToBeEdited) {
      if (isDataUnchanged()) {
        setShowEditComponent?.(dataToBeEdited._id);
        return;
      }
      const putReqBody = {
        ...dataToBeEdited,
        svgString: selectedIcon,
        title: contractTitle,
        description: contractDescription,
      };
      console.log("Put Req Body", putReqBody);
      await api
        .put(`contract-defaults/${dataToBeEdited._id}`, putReqBody)
        .then((response: any) => {
          const responseWithType: ContractDefaultDTO = {
            _id: response._id,
            svgString: response.svgString,
            title: response.title,
            description: response.description,
            position: response.position,
            addToProjects: response.addToProjects,
            isDefault: response.isDefault,
            isEditable: response.isEditable,
            edit: false,
          };
          console.log(responseWithType);
          setContractDefaults((currentDefaults: ContractDefaultDTO[]) =>
            currentDefaults.map((item: ContractDefaultDTO) =>
              item._id === responseWithType._id ? responseWithType : item
            )
          );
        })
        .catch((error: any) => {
          console.log(error);
          message.error("Something Went Wrong");
          setShowEditComponent?.(dataToBeEdited._id);
        });
    } else {
      const postReqBody = {
        svgString: selectedIcon,
        title: contractTitle,
        description: contractDescription,
        addToProjects: false,
        position: currentNumberOfContractDefaults + 1,
        isDefault: false,
        isEditable: true,
      };
      console.log("Post Req Body", postReqBody);
      await api
        .post("contract-defaults", postReqBody)
        .then((response: any) => {
          const responseWithType: ContractDefaultDTO = {
            _id: response._id,
            svgString: response.svgString,
            title: response.title,
            description: response.description,
            position: response.position,
            addToProjects: response.addToProjects,
            isDefault: response.isDefault,
            isEditable: response.isEditable,
            edit: false,
          };
          console.log(responseWithType);
          setContractDefaults?.((currentDefaults: ContractDefaultDTO[]) => [
            ...currentDefaults,
            responseWithType,
          ]);
          setShowComponent?.(false);
        })
        .catch((error: any) => {
          console.log(error);
          message.error("Something Went Wrong");
          setShowComponent?.(false);
        });
    }
    setSaveClicked(false)
  };

  const handleCancelButton = () => {
    if (dataToBeEdited) {
      setShowEditComponent?.(dataToBeEdited._id);
    } else {
      setShowComponent?.(false);
    }
  };

  return (
    <div style={{ padding: "30px 25px 0px 25px" }}>
      <SelectSvgIconModal
        openModal={openSelectIconModal}
        setModalState={setOpenSelectIconModal}
        handleSelectIconOk={handleSelectIconOk}
      />
      <h1>{dataToBeEdited ? "Update" : "Create"} Default</h1>
      <Row>
        {selectedIcon && (
          <Col style={{ marginRight: 10, marginTop: 4 }}>
            <Image
              src={selectedIcon}
              style={{ fontSize: "30px" }}
              preview={false}
            />
          </Col>
        )}
        <Col style={{ marginRight: 15 }}>
          {!dataToBeEdited?.isDefault && (
            <PlusSquareFilled
              onClick={() => setOpenSelectIconModal(true)}
              style={{
                color: "#FDB913",
                cursor: "pointer",
                fontSize: "30px",
              }}
            />
          )}
        </Col>
        <Col style={{ padding: "2px 15px 0px 0px" }}>
          <h1 style={{ fontWeight: "bold" }}>Title: </h1>
        </Col>
        <Col>
          <Input
            className="default-title-input"
            type="string"
            value={contractTitle}
            onChange={handleInputChange}
            disabled={dataToBeEdited?.isDefault}
          />
        </Col>
      </Row>
      <div>
        <ReactQuill
          style={{ padding: "15px 0px 25px 0px" }}
          theme="snow"
          modules={{
            toolbar: [
              // [
              //   // { header: "1" }, { header: "2" },
              //   { font: Font.whitelist },
              // ],
              // [{ size: [] }],
              ["bold", "italic", "underline", "strike"],
              // [{ color: [] }, { background: [] }],
              [
                { list: "ordered" },
                { list: "bullet" },
                // { indent: "-1" },
                // { indent: "+1" },
              ],
            ],
            clipboard: {
              matchVisual: false,
            },
          }}
          formats={[
            // "font",
            "size",
            "bold",
            "italic",
            "underline",
            "strike",
            "blockquote",
            // "color",
            // "background",
            "list",
            "bullet",
            "indent",
          ]}
          placeholder="Write Something..."
          onChange={handleTextEditorChange}
          value={contractDescription}
        />
      </div>
      <Row justify={"end"} style={{ paddingBottom: "20px" }}>
        <Col style={{ paddingRight: "15px" }}>
          <Button
            className="contract-save-cancel-button"
            type="primary"
            onClick={() => {
              setSaveClicked(true);
              handleSaveOrUpdate();
            }}
          >
            {saveClicked ? <Spin /> : <>{dataToBeEdited ? <>Update</> : <>Save</>}</>}
          </Button>
        </Col>
        <Col>
          <Button
            className="contract-save-cancel-button"
            type="primary"
            onClick={handleCancelButton}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default AddEditContractDefaultCard;
