import React, { useRef, useEffect, useState } from 'react';
import { Layout, Steps, Button, Row, Col, Card, Divider, Spin } from 'antd';
import { useReactToPrint } from 'react-to-print';
import ProposalHeader from '../../components/proposal/ProposalHeader';
import JobSiteHeader from '../../components/proposal/JobSiteHeader';
import ProjectSummary from '../../components/proposal/ProjectSummary';
import PriceSummary from '../../components/proposal/PriceSummary';
import ProjectDetails from '../../containers/proposal/ProjectDetails';
import Signature from '../../components/proposal/Signature';
import '../../components/proposal/Proposal.less';
import { useParams } from 'react-router-dom';
import ManagerSteps from './ManagerSteps';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import useActiveProject from '../../utils/useActiveProject';
import api from '../../utils/api';
import TermsConditions from '../../containers/proposal/TermsConditions';
import OptionImages from '../../containers/proposal/OptionImages';
import { getAuthUser, getSelectedTenant } from '../../utils/authToken';
import ManagerButtons from '../../containers/proposal-buttons/ManagerButtons';
import SalesAssociateButton from '../../containers/proposal-buttons/SalesAssociateButton';
import EstimatorButtons from '../../containers/proposal-buttons/EstimatorButtons';

import { generateProposalPdf } from '../../utils/proposalPdf';
import _, { isNull } from 'lodash';
import Warranty from '../../containers/proposal/Warranty';
import DefAndConduct from '../../containers/proposal/DefAndConduct';
import ClientContactProposal from '../../components/proposal/ClientContactProposal';
import {
  updateProposalCount,
  uploadProposalFile,
} from '../../redux/project/action';
import AdditionalOptions from '../../components/proposal/AdditionalOptions';
import HTMLParser from 'html-to-json-parser';
import axios from 'axios';
import { API_BASE } from '../../constant';

const { Content } = Layout;
const { Step } = Steps;

const ManagerProposal: React.FC = () => {
  const { id } = useParams<any>();
  const [profilePhoto, setProfilePhoto] = useState();
  const [loggedInUserPhoto, setLoggedInUserPhoto] = useState();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any>();
  useActiveProject();
  const [optionPjcc, setOptionPjcc] = useState<any>();
  const [subTotal, setSubTotal] = useState(0);

  const [optionalOptionInfoArray, setOptionalOptionInfoArray] = useState<any>(
    [],
  );
  const [optionsTotalPrice, setOptionsTotalPrice] = useState<any>(0);

  const [companyInfo, setCompanyInfo] = useState<any>({});
  const [optionsList, setOptionsList] = useState<any>([]);
  const [optionsMediaList, setOptionsMediaList] = useState([]);
  const dispatch = useDispatch<any>();

  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );
  const { online } = useSelector((state: RootStateOrAny) => state.offline);
  const [usersForReciepientEmailModal, setUserForRecipientEmailModal] =
    useState<any[]>([]);
  const user = getAuthUser();
  const tenantId = getSelectedTenant();

  const projectDefaultsTermsAndConditions =
    items[currentProject]?.projectDefaults?.termsAndConditions?.filter(
      (item: any) => item.isDeleted === false && item.checked === true,
    ) || [];

  useEffect(() => {
    if (online) {
      api
        .get('user/users')
        .then((response: any) => {
          console.log(response);
          setUserForRecipientEmailModal(response);
        })
        .catch((err) => {
          console.log('ERROR', err);
        });
      fetchData();
    }
  }, [online]);

  useEffect(() => {
    let optionsForProposal: any[] = [];
    let jobType = items[currentProject]?.projectInfo?.projectType;
    items[currentProject].options.map((option: any) => {
      if (option.optionInfo.included === 'included') {
        let estimatedPrice = option.pjcc.find(
          (item: any) => item.name === 'Estimated Price',
        );
        if (estimatedPrice?.totalAmount !== 0) {
          optionsForProposal.push(option.optionInfo);
        }
      }
    });
    setOptions(optionsForProposal);

    let optionPjccForProposal: any[] = [];
    items[currentProject].options.map((option: any) => {
      if (option.optionInfo.included === 'included') {
        let optionPjcc = option.pjcc.filter(
          (item: any) => item.name === 'Estimated Price',
        );
        optionPjccForProposal.push(...optionPjcc);
      }
    });

    let optionalOptionInfo = items[currentProject]?.options
      .filter((option: any) => option?.optionInfo?.included === 'optional')
      .map((item: any) => {
        let estimatedPriceObj = item?.pjcc.filter(
          (item: any) => item.name === 'Estimated Price',
        );
        return {
          title: item.optionInfo.title,
          totalPrice: estimatedPriceObj[0].totalAmount,
        };
      });
    setOptionalOptionInfoArray(optionalOptionInfo);

    setOptionPjcc(optionPjccForProposal);
    setSubTotal(
      optionPjccForProposal?.reduce((initial: any, value: any) => {
        return value.amount + initial;
      }, 0),
    );

    let optionsArray = [
      ...items[currentProject].options.filter(
        (item: any) => item.optionInfo.included === 'included',
      ),
    ] // Clone the array to avoid modifying the state
      .sort((a: any, b: any) => a.optionInfo.position - b.optionInfo.position) // Sort by position
      .map((item: any) => {
        return item.optionInfo.title;
      });
    setOptionsList(optionsArray);

    setCompanyInfo(adminDefaults.companyInfo);
    window.scrollTo(0, 0);
    window.history.scrollRestoration = 'manual';
  }, []);
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      if (
        items[currentProject].options.length > 0 &&
        items[currentProject]?.projectInfo?.proposal?.addOptionMedia === true
      ) {
        const resp = await axios.get(
          `${API_BASE}upload-files/option-media/${items[currentProject].projectId}`,
          {
            headers: {
              Authorization: 'Bearer ' + user.accessToken,
              'tenant-id': !isNull(tenantId) ? tenantId : '',
            },
          },
        );
        if (resp.data.length > 0) {
          setOptionsMediaList(resp.data);
        }
      }
      // Fetch profile photo
      const profileResp: any = await api.get(
        `user/photo/${items[currentProject].projectInfo?.salesAssociate._id}`,
      );
      if (profileResp) setProfilePhoto(profileResp);
      // Fetch logged-in user photo
      const loggedInUserResp: any = await api.get(`user/photo/${user._id}`);
      if (loggedInUserResp) setLoggedInUserPhoto(loggedInUserResp);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async () => {
    const result = (
      await HTMLParser(
        '<div>' +
          items[currentProject]?.projectInfo?.proposal?.projectSummary +
          '</div>',
        true,
      )
    ).toString();

    const parsedHtml = JSON.parse(result);

    setLoading(true);
    let project = _.cloneDeep(items[currentProject]);
    let defaults = _.cloneDeep(adminDefaults);

    let proposalBlob: any = await generateProposalPdf(
      project,
      defaults,
      companyInfo,
      'save',
      profilePhoto,
      items[currentProject]?.projectInfo?.salesAssociate,
      parsedHtml,
      optionsMediaList,
    );

    await dispatch(
      uploadProposalFile(id, {
        proposalBlob,
        proposalCount: (project?.projectInfo?.proposalCount ?? 0) + 1,
        projectName: project?.projectInfo?.jobName,
      }),
    );
    await dispatch(
      updateProposalCount({
        projectId: id,
        proposalCount: (project?.projectInfo?.proposalCount ?? 0) + 1,
      }),
    );
    setLoading(false);
  };

  return (
    <Content>
      <ManagerSteps current={7} />
      <div className="steps-content">
        <div>
          <div className="button-container">
            <Row gutter={8} justify="end">
              <Col>
                <Button
                  onClick={handleExport}
                  loading={loading}
                  className="export-btn"
                  style={{
                    color: '#FDB913',
                    border: '1px solid #fdb913',
                  }}
                  disabled={loading}
                >
                  Export
                </Button>
              </Col>
              <EstimatorButtons users={usersForReciepientEmailModal} />
              <ManagerButtons users={usersForReciepientEmailModal} />
            </Row>
          </div>

          <div ref={componentRef}>
            <Card
              className="proposal-tables-border-bottom"
              style={{ overflowX: 'scroll' }}
            >
              <ProposalHeader
                jobNumber={items[currentProject]?.projectInfo?.jobNumber}
                date={items[currentProject]?.projectInfo?.proposal?.createdAt}
                companyInfo={adminDefaults?.companyInfo}
                projectSubtype={
                  items[currentProject]?.projectInfo?.projectSubtype
                }
                projectType={items[currentProject]?.projectInfo?.projectType}
              />
              <hr
                style={{
                  border: '1px solid #E8E8E8',
                  margin: 0,
                  borderBottom: '0px',
                }}
              />
              <JobSiteHeader
                city={items[currentProject]?.projectInfo?.city}
                zip={items[currentProject]?.projectInfo?.zip}
                state={items[currentProject]?.projectInfo?.state}
                jobName={items[currentProject]?.projectInfo?.jobName}
                address={items[currentProject]?.projectInfo?.streetAddressOne}
                telephone={
                  items[currentProject]?.projectInfo?.primaryContactTelephone
                }
                email={items[currentProject]?.projectInfo?.primaryContactEmail}
                associatePicture={
                  items[currentProject]?.projectInfo?.salesAssociate?.picture
                }
                associateName={
                  items[currentProject]?.projectInfo?.salesAssociate?.fullName
                }
                associateCell={
                  items[currentProject]?.projectInfo.salesAssociate?.phone
                }
                associateEmail={
                  items[currentProject]?.projectInfo?.salesAssociate?.email
                }
                associateJobTitle={
                  items[currentProject]?.projectInfo?.salesAssociate?.jobTitle
                }
                companyInfo={adminDefaults?.companyInfo}
                preparedBy={items[currentProject]?.projectInfo?.salesAssociate}
              />
              <div style={{ padding: 25 }}>
                <ClientContactProposal
                  pName={items[currentProject]?.projectInfo.primaryContactName}
                  pTelephone={
                    items[currentProject]?.projectInfo.primaryContactTelephone
                  }
                  pAddress={
                    items[currentProject]?.projectInfo.primaryContactAddress
                  }
                  pEmail={
                    items[currentProject]?.projectInfo.primaryContactEmail
                  }
                  pCell={items[currentProject]?.projectInfo.primaryContactCell}
                  osName={items[currentProject]?.projectInfo.onSiteContactName}
                  osTelephone={
                    items[currentProject]?.projectInfo.onSiteContactTelephone
                  }
                  osAddress={
                    items[currentProject]?.projectInfo.onSiteContactAddress
                  }
                  osEmail={
                    items[currentProject]?.projectInfo.onSiteContactEmail
                  }
                  osCell={items[currentProject]?.projectInfo.onSiteContactCell}
                  hasOnSite={
                    items[currentProject]?.projectInfo?.proposal?.onSite
                  }
                  mName={
                    items[currentProject]?.projectInfo.maintenanceContactName
                  }
                  mTelephone={
                    items[currentProject]?.projectInfo
                      .maintenanceContactTelephone
                  }
                  mAddress={
                    items[currentProject]?.projectInfo.maintenanceContactAddress
                  }
                  mEmail={
                    items[currentProject]?.projectInfo.maintenanceContactEmail
                  }
                  mCell={
                    items[currentProject]?.projectInfo.maintenanceContactCell
                  }
                  hasMaintenance={
                    items[currentProject]?.projectInfo?.proposal?.maintenance
                  }
                />
              </div>

              <PriceSummary
                options={options}
                optionsPjcc={optionPjcc}
                setOptionsTotalPrice={setOptionsTotalPrice}
              />
              <ProjectSummary
                summary={
                  items[currentProject]?.projectInfo?.proposal?.projectSummary
                }
                optionsList={optionsList}
              />
              <ProjectDetails />
              <hr
                style={{ border: '1px solid #E8E8E8', borderBottom: '0px' }}
              />
              <TermsConditions
                termsAndConditions={[
                  ...items[currentProject]?.projectInfo?.proposal
                    ?.termsAndConditions,
                  ...projectDefaultsTermsAndConditions,
                ]}
              />
              <Signature />
              {optionalOptionInfoArray.length > 0 && (
                <AdditionalOptions
                  tableData={optionalOptionInfoArray}
                  priceWithOptionalOptions={optionsTotalPrice}
                />
              )}
              {items[currentProject].projectInfo?.proposal?.addOptionMedia && (
                <>
                  {loading ? (
                    <>
                      <div
                        className="terms-container"
                        style={{ marginLeft: '30px', marginRight: '35px' }}
                      >
                        <h2 className="content-title">
                          ADDENDUM - ALL PICTURES
                        </h2>
                        <Divider
                          type="horizontal"
                          style={{
                            borderColor: '#FDB913',
                            borderWidth: 5,
                            marginTop: 0,
                          }}
                        ></Divider>
                        <Spin
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {optionsMediaList.length > 0 ? (
                        <div
                          className="terms-container"
                          style={{ marginLeft: '30px', marginRight: '35px' }}
                        >
                          <h2 className="content-title">
                            ADDENDUM - ALL PICTURES
                          </h2>
                          <Divider
                            type="horizontal"
                            style={{
                              borderColor: '#FDB913',
                              borderWidth: 5,
                              marginTop: 0,
                            }}
                          ></Divider>
                          <OptionImages
                            optionsMediaList={optionsMediaList}
                            setOptionsMediaList={setOptionsMediaList}
                            loading={loading}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </>
              )}
              <DefAndConduct />
              {items[currentProject]?.projectInfo?.proposal?.addWarranty && (
                <Warranty />
              )}
            </Card>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default ManagerProposal;
