import { Divider } from "antd";
import "../../components/proposal/Proposal.less";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import api from "../../utils/api";

type Props = {
  setup?: any;
};

const DefAndConduct = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:850px)" });
  const [legalLanguageData, setLegalLanguageData] = useState({ title: '', type: '', description: '' });

  useEffect(() => {
    (async () => {
      try {
        // setLoading(true)
        const warrentyResponse: any = await api.get("proposal/proposal-default/legalLanguage")
        setLegalLanguageData(warrentyResponse[0])
        // setLoading(false)
      } catch (error: any) {
        throw new Error(error)
      }
    })()
  }, [])
  return (
    <>
      {isTabletOrMobile ? (
        <div
          className="terms-container"
          style={{
            marginTop: 30,
            marginBottom: 50,
            marginLeft: 30,
            marginRight: 30,
            width: "800px",
          }}
        >
          <div className="tasks-wrapper" style={{ marginTop: "50px" }}>
            <h2 className="content-title">
              {legalLanguageData?.title}
            </h2>
            <Divider
              type="horizontal"
              style={{
                marginTop: 0,
                marginRight: 50,
                borderColor: "#FDB913",
                borderWidth: 5,
              }}
            ></Divider>

            {/* <div
              className="terms-text"
              style={{ fontWeight: "normal", marginTop: "25px" }}
            > */}
            <div
              className="terms-text" style={{ fontWeight: "normal", marginTop: "25px" }}
              dangerouslySetInnerHTML={{ __html: legalLanguageData?.description }}
            />
            {/* </div> */}
          </div>
        </div>
      ) : (
        <div
          className="terms-container"
          style={{
            marginTop: 30,
            marginBottom: 50,
            marginLeft: 30,
            marginRight: 30,
          }}
        >
          <div className="tasks-wrapper" style={{ marginTop: "50px" }}>
            <h2 className="content-title">
              {legalLanguageData?.title}
            </h2>
            <Divider
              type="horizontal"
              style={{
                marginTop: 0,
                marginRight: 50,
                borderColor: "#FDB913",
                borderWidth: 5,
              }}
            ></Divider>

            {/* <div
              className="terms-text"
              style={{ fontWeight: "normal", marginTop: "25px" }}
            >
              <p>
                RELATIONSHIP — The individual giving you this proposal is an
                independent contractor licensed by CertaPro Painters® to use its
                systems and trademarks to operate a painting franchise. The work
                will be completed by the independent franchised contractor.
                Please make any check payable to the franchise shown on the
                front of this proposal. <br />
                COLORS — Colors may be chosen by the client prior to
                commencement of work. If, after the job starts, a color change
                is required, the independent Contractor will have to charge for
                time and material expenses incurred on the original color.{" "}
                <br />
                UNFORESEEN CONDITIONS — Should conditions arise which could not
                be determined by visual inspection prior to starting work, the
                client must pay an agreed upon extra for the completion of such
                work.
                <br />
                PROPOSAL — This proposal is valid for 60 days after it was
                written. In addition, the Independent Franchised Contractor
                should be informed of your desire to have the work done and
                receive a signed copy of the proposal before work is to be
                started. <br />
                <br />
                <hr />
              </p>
              <p
                style={{ fontWeight: "bold", marginBottom: 0, color: "black" }}
              >
                ATTENTION CLIENT:
              </p>
              <p style={{ margin: 0 }}>
                YOU, THE BUYER, MAY CANCEL THIS TRANSACTION AT ANY TIME PRIOR TO
                MIDNIGHT OF THE THIRD BUSINESS DAY AFTER THE DATE OF THIS
                TRANSACTION. SEE THE BELOW NOTICE OF CANCELLATION FOR AN
                EXPLANATION OF THIS RIGHT. (SATURDAY IS A LEGAL BUSINESS DAY IN
                CONNECTICUT.) THIS SALE IS SUBJECT TO THE PROVISIONS OF THE HOME
                SOLICITATION SALES ACT AND THE HOME IMPROVEMENT ACT. THIS
                INSTRUMENT IS NOT NEGOTIABLE.
                <br />
                <br />
              </p>
              <p
                style={{ fontWeight: "bold", marginBottom: 0, color: "black" }}
              >
                NOTICE OF CANCELLATION:
              </p>
              <p>
                YOU MAY CANCEL THIS TRANSACTION, WITHOUT ANY PENALTY OR
                OBLIGATION, WITHIN THREE BUSINESS DAYS FROM THE ABOVE DATE. IF
                YOU CANCEL, ANY PROPERTY TRADED IN, ANY PAYMENTS MADE BY YOU
                UNDER THE CONTRACT OR SALE, AND ANY NEGOTIABLE INSTRUMENT
                EXECUTED BY YOU WILL BE RETURNED WITHIN TEN BUSINESS DAYS
                FOLLOWING RECEIPT BY THE SELLER OF YOUR CANCELLATION NOTICE, AND
                ANY SECURITY INTEREST ARISING OUT OF THE TRANSACTION WILL BE
                CANCELLED. IF YOU CANCEL, YOU MUST MAKE AVAILABLE TO THE SELLER
                AT YOUR RESIDENCE IN SUBSTANTIALLY AS GOOD CONDITION AS WHEN
                RECEIVED, ANY GOODS DELIVERED TO YOU UNDER THIS CONTRACT OR
                SALE; OR YOU MAY, IF YOU WISH, COMPLY WITH THE INSTRUCTIONS OF
                THE SELLER REGARDING THE RETURN SHIPMENT OF THE GOODS AT THE
                SELLER'S EXPENSE AND RISK. IF YOU DO MAKE THE GOODS AVAILABLE TO
                THE SELLER AND THE SELLER DOES NOT PICK THEM UP WITHIN TWENTY
                DAYS OF THE DATE OF CANCELLATION, YOU MAY RETAIN OR DISPOSE OF
                THE GOODS WITHOUT ANY FURTHER OBLIGATION. IF YOU FAIL TO MAKE
                THE GOODS AVAILABLE TO THE SELLER, OR IF YOU AGREED TO RETURN
                THE GOODS AND FAIL TO DO SO, THEN YOU REMAIN LIABLE FOR
                PERFORMANCE OF ALL OBLIGATIONS UNDER THE CONTRACT. TO CANCEL
                THIS TRANSACTION, MAIL OR DELIVER A SIGNED AND DATED COPY OF
                THIS CANCELLATION NOTICE OR ANY OTHER WRITTEN NOTICE, OR SEND A
                TELEGRAM TO:
                <br />
                Name of Seller __________________
                <br />
                <br />
                DATE OF TRANSACTION ______________________
                <br />
                NOT LATER THAN MIDNIGHT OF ______________________
                <br />I HEREBY CANCEL THIS TRANSACTION
                <br />
                <br />
                <br />
                <br />
                <hr />
                (Buyer's Signature)
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                (Date)
              </p>
            </div> */}
            <div
              className="terms-text" style={{ fontWeight: "normal", marginTop: "25px" }}
              dangerouslySetInnerHTML={{ __html: legalLanguageData?.description }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default DefAndConduct;
