import { Col, Row } from "antd";
import "./ContractSetup.less";

type Props = {
  companyInfo?: any;
};

const ContractHeader = (props: Props) => {
  const {
    companyStreetAddressOne,
    companyStreetAddressTwo,
    companyCity,
    companyStateProvince,
    companyPostalCode,
    officePhone,
    companyWebsiteUrl,
    companyLogo,
    companyName
  } = props?.companyInfo || {};

  // Create an array for address parts
  const addressParts = [
    companyStreetAddressOne,
    companyStreetAddressTwo ? `${companyStreetAddressTwo}` : null,
    companyCity,
    companyStateProvince,
    companyPostalCode,
  ].filter(Boolean);
  const headerWeb = companyWebsiteUrl ? `Website: ${companyWebsiteUrl}` : '';
  const headerTelephone = officePhone ? `Telephone: ${officePhone}` : '';
  return (
    <div className="ch-root">
      <Row justify="space-between">
        <Col span={14}>
          {/* <CertaproIcon />
          <p>CertaPro Painters® of Indianapolis</p> */}
          <img
            style={{
              width: 200,

              // marginLeft: -20,
              marginTop: 15,
            }}
            src={companyLogo}
            alt="logo"
          />
          <h1>CONTRACT</h1>
        </Col>
        <Col span={10}
          style={{
            marginTop: 30,
          }}
        >
          <h2>{companyName || ''}</h2>
          <h2 style={{ display: "flex", alignItems: "center" }}>
            Address: {addressParts.length > 0 ? addressParts.join(', ') : "No address available"}
          </h2>
          <h2>
            {headerTelephone}
          </h2>
          <h2 style={{ marginTop: 0, padding: 0 }}>
            {headerWeb}
          </h2>
        </Col>
      </Row>
    </div>
  );
};

export default ContractHeader;
