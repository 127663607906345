import { useState, useEffect, MouseEvent } from 'react';
import { Col, Row, Button, Drawer, Rate, Tooltip } from 'antd';
import { EditFilled, HeartFilled, PlusOutlined } from '@ant-design/icons';
import './LaborRates.less';
import AddSurface from '../../components/pop-ups/AddSurface';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import PaintSurfaces from './surfaces/PaintSurfaces';
import CarpentrySurfaces from './surfaces/CarpentrySurfaces';
import EquipmentSurfaces from './surfaces/EquipmentSurfaces';
import MiscSurfaces from './surfaces/MiscSurfaces';
import TravelSurfaces from './surfaces/TravelSurfaces';
import { getArrayClone } from '../../utils/helpers';
import api from '../../utils/api';
import Notes from '../notes/Notes';
import {
  setActiveProject,
  addServiceToProject,
  addRatesToOptions,
  removeServiceFromProject,
  addPlusUpdateModifiedProject,
  removeRatesFromProjectAndOptions,
} from '../../redux/project/action';
import { pricingCalculation } from '../../utils/priceCalculation';
import _ from 'lodash';
import AddDefaultModifiedSurface from '../../components/pop-ups/AddDefaultModifiedSurface';
import BulkEditPaintLaborRateModal from './components/BulkEditPaintLaborRateModal';
var crypto = require('crypto');

const LaborRates = () => {
  const dispatch = useDispatch<any>();
  const [tableData, setTableData] = useState<any>();
  const [showModalForEstimate, setShowModalForEstimate] = useState(false);
  const [visible, setVisible] = useState(false);

  const [showExpandRows, setShowExpandRows] = useState(false);
  const [isBulkEditModalOpen, setIsBulkEditModalOpen] = useState(false);

  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );
  useEffect(() => {
    setTableData(items[currentProject].projectRates);
  }, [items[currentProject].projectRates]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const toggleModal = (_: boolean) => {
    !showModalForEstimate
      ? setShowModalForEstimate(true)
      : setShowModalForEstimate(false);
  };

  const handleAddSurface = () => {
    !showModalForEstimate ? toggleModal(true) : toggleModal(false);
  };

  const replaceDefaultRateId = (data: any) => {
    return data?.map((item: any) => {
      return {
        ...item,
        defaultRate: item.defaultRate?._id,
      };
    });
  };

  const addSurfaceToProject = (
    surfaces: any,
    deletedArray: any,
    collapse: MouseEvent,
    category: string,
  ) => {
    let paintRates: any = [];
    let carpentryRates: any = [];
    let equipmentRates: any = [];
    let miscRates: any = [];
    let travelRates: any = [];

    for (const surface of surfaces.defaults) {
      switch (surface.category) {
        case 'paint':
          paintRates = surface.rate.map((item: any) => {
            let _id = crypto.randomBytes(12).toString('hex');

            return {
              ...item,
              _id: _id,
              type: item.type,
              project: items[currentProject].projectId,
              defaultRate: item,
              paintLaborRate: item.paintLaborMedium,
              primeLaborRate: item.primeLaborMedium,
              prepLaborRate: item.prepLaborMedium,
              prepLevel: 2,
              paintLevel: 2,
              primeLevel: 2,
              modified: false,
              isCloned: false,
              isDeleted: false,
            };
          });
          break;

        case 'carpentry':
          carpentryRates = surface.rate.map((item: any) => {
            let _id = crypto.randomBytes(12).toString('hex');

            return {
              ...item,
              _id: _id,
              project: items[currentProject].projectId,
              defaultRate: item,
              modified: false,
              isCloned: false,
              isDeleted: false,
            };
          });
          break;
        case 'equipment':
          equipmentRates = surface.rate.map((item: any) => {
            let _id = crypto.randomBytes(12).toString('hex');

            return {
              ...item,
              _id: _id,
              project: items[currentProject].projectId,
              defaultRate: item,
              type: 'daily',
              cost: item.dailyCost,
              modified: false,
              isCloned: false,
              isDeleted: false,
            };
          });
          break;
        case 'travel':
          travelRates = surface.rate.map((item: any) => {
            let _id = crypto.randomBytes(12).toString('hex');

            return {
              ...item,
              _id: _id,
              project: items[currentProject].projectId,
              defaultRate: item,
              modified: false,
              isCloned: false,
              isDeleted: false,
            };
          });
          break;
        case 'miscellaneous':
          miscRates = surface.rate.map((item: any) => {
            let _id = crypto.randomBytes(12).toString('hex');

            return {
              ...item,
              _id: _id,
              project: items[currentProject].projectId,
              defaultRate: item,
              modified: false,
              isCloned: false,
              isDeleted: false,
            };
          });
          break;
        default:
          break;
      }
    }

    let paintRatesClone = paintRates;
    let carpentryRatesClone = carpentryRates;
    let equipmentRatesClone = equipmentRates;
    let miscRatesClone = miscRates;
    let travelRatesClone = travelRates;

    // let paintRatesClone = replaceDefaultRateId(paintRates);
    // let carpentryRatesClone = replaceDefaultRateId(carpentryRates);
    // let equipmentRatesClone = replaceDefaultRateId(equipmentRates);
    // let miscRatesClone = replaceDefaultRateId(miscRates);
    // let travelRatesClone = replaceDefaultRateId(travelRates);

    const newBody = [
      {
        category: 'paint',
        rate: paintRatesClone,
      },
      {
        category: 'carpentry',
        rate: carpentryRatesClone,
      },
      {
        category: 'equipment',
        rate: equipmentRatesClone,
      },
      {
        category: 'travel',
        rate: travelRatesClone,
      },
      {
        category: 'miscellaneous',
        rate: miscRatesClone,
      },
    ];
    const body = [
      {
        category: 'paint',
        rate: paintRates,
      },
      {
        category: 'carpentry',
        rate: carpentryRates,
      },
      {
        category: 'equipment',
        rate: equipmentRates,
      },
      {
        category: 'travel',
        rate: travelRates,
      },
      {
        category: 'miscellaneous',
        rate: miscRates,
      },
    ];

    dispatch(addServiceToProject(items[currentProject].projectId, newBody));

    let project: any = _.cloneDeep(items[currentProject]);

    let paint = [];
    let carpentry = [];
    let misc = [];
    let equipment = [];
    let travel = [];

    for (const surface of body) {
      switch (surface.category) {
        case 'paint':
          project.projectRates.paintRates.push(...body[0].rate);

          for (const item of project.options) {
            let reduxObj = [];
            for (const element of surface.rate) {
              let condition = item.rates.paintRates.find(
                (entity: any) => entity.defaultRate == element.defaultRate._id,
              );
              let optionIndex = item.rates.paintRates.findIndex(
                (entity: any) => entity.projectLaborRate == element._id,
              );
              if (optionIndex !== -1) {
                item.rates.paintRates[optionIndex].isDeleted = false;
              }
              if (!condition || (condition && optionIndex === -1)) {
                let count = 0;
                let coats = 1;
                let color = 'TBD';
                let prepHours = count / element.prepLaborRate;
                let primeHours = element.primeHours ? element.primeHours : 0;
                let paintHours = (count * coats) / element.paintLaborRate;
                let primerGallons = element.primerGallons
                  ? element.primerGallons
                  : 0;
                let primeSelect = element.primeSelect
                  ? element.primeSelect
                  : 'spot';
                let paintGallons = (count * coats) / element.paintSpreadRate;
                const projectLaborRate = element._id;
                let _id = crypto.randomBytes(12).toString('hex');
                let newObj = {
                  ...element,
                  _id,
                  option: item.optionInfo._id,
                  projectLaborRate: projectLaborRate,
                  count,
                  coats,
                  color,
                  prepHours,
                  primeHours,
                  paintHours,
                  primerGallons,
                  primeSelect,
                  paintGallons,
                  totalPaintHours: prepHours + primeHours + paintHours,
                  paintMaterialCost:
                    element.paintMaterial?.priceAfterTax * paintGallons +
                    element.primerMaterial?.priceAfterTax * primerGallons,
                };
                paint.push(newObj);
                reduxObj.push(newObj);
              }
            }
            item.rates.paintRates.push(...reduxObj);
          }

          break;
        case 'carpentry':
          project.projectRates.carpentryRates.push(...body[1].rate);

          for (const item of project.options) {
            let reduxObj = [];
            for (const element of surface.rate) {
              let carpentryLaborRateConst = adminDefaults.wageDefaults.find(
                (item: any) => {
                  return item.title === 'Carpentry Wage Rate';
                },
              );
              let condition = item.rates.carpentryRates.find(
                (entity: any) =>
                  entity.defaultRate._id === element.defaultRate._id,
              );
              let optionIndex = item.rates.carpentryRates.findIndex(
                (entity: any) => entity.projectLaborRate == element._id,
              );
              if (optionIndex !== -1) {
                item.rates.carpentryRates[optionIndex].isDeleted = false;
              }
              if (!condition || (condition && optionIndex === -1)) {
                let count = 0;
                let carpentryMaterial = count * element.materialRate;
                let carpentryHours =
                  (1.08 * count * element.laborRate) /
                  carpentryLaborRateConst.wageValue;
                const projectLaborRate = element._id;
                let _id = crypto.randomBytes(12).toString('hex');
                let newObj = {
                  ...element,
                  _id,
                  option: item.optionInfo._id,
                  projectLaborRate: projectLaborRate,
                  count,
                  carpentryMaterial,
                  carpentryHours,
                };
                carpentry.push(newObj);
                reduxObj.push(newObj);
              }
            }

            item.rates.carpentryRates.push(...reduxObj);
          }

          break;
        case 'equipment':
          project.projectRates.equipmentRates.push(...body[2].rate);

          for (const entity of project.options) {
            let reduxObj = [];
            for (const element of surface.rate) {
              let condition = entity.rates.equipmentRates.find(
                (item: any) => item.defaultRate._id == element.defaultRate._id,
              );
              let optionIndex = entity.rates.equipmentRates.findIndex(
                (item: any) => item.projectLaborRate == element._id,
              );
              if (optionIndex !== -1) {
                entity.rates.equipmentRates[optionIndex].isDeleted = false;
              }
              if (!condition || (condition && optionIndex === -1)) {
                let count = 0;
                let periods = 1;
                let item = element.item;
                let tax = element.tax;
                let project = element.project;
                let defaultRate = element.defaultRate;
                let type = 'daily';
                let cost = element.dailyCost;
                let option = entity.optionInfo._id;
                let totalCost =
                  count *
                  periods *
                  (cost + element.surcharges + element.puAndDelivery) *
                  (1 + tax / 100);
                const projectLaborRate = element._id;
                let _id = crypto.randomBytes(12).toString('hex');
                let newObj = {
                  _id,
                  projectLaborRate: projectLaborRate,
                  count,
                  periods,
                  item,
                  surcharges: element.surcharges,
                  puAndDelivery: element.puAndDelivery,
                  tax,
                  project,
                  defaultRate,
                  type,
                  cost,
                  option,
                  totalCost,
                };
                equipment.push(newObj);
                reduxObj.push(newObj);
              }
            }

            entity.rates.equipmentRates.push(...reduxObj);
          }

          break;
        case 'travel':
          project.projectRates.travelRates.push(...body[3].rate);

          for (const item of project.options) {
            let reduxObj = [];
            for (const element of surface.rate) {
              let condition = item.rates.travelRates.find(
                (entity: any) =>
                  entity.defaultRate._id == element.defaultRate._id,
              );
              let optionIndex = item.rates.travelRates.findIndex(
                (entity: any) => entity.projectLaborRate == element._id,
              );
              if (optionIndex !== -1) {
                item.rates.travelRates[optionIndex].isDeleted = false;
              }
              if (!condition || (condition && optionIndex === -1)) {
                let rfmCount = 0;
                let dnCount = 0;
                let cost = element.cost;
                let totalCost = cost * rfmCount * dnCount;
                const projectLaborRate = element._id;
                let _id = crypto.randomBytes(12).toString('hex');
                let newObj = {
                  _id,
                  projectLaborRate: projectLaborRate,
                  rfmCount,
                  dnCount,
                  item: element.item,
                  cost,
                  project: element.project,
                  defaultRate: element.defaultRate,
                  option: item.optionInfo._id,
                  totalCost,
                };
                travel.push(newObj);
                reduxObj.push(newObj);
              }
            }

            item.rates.travelRates.push(...reduxObj);
          }

          break;
        case 'miscellaneous':
          project.projectRates.miscellaneousRates.push(...body[4].rate);

          for (const item of project.options) {
            let reduxObj = [];
            for (const element of surface.rate) {
              let condition = item.rates.miscellaneousRates.find(
                (entity: any) =>
                  entity.defaultRate._id == element.defaultRate._id,
              );
              let optionIndex = item.rates.miscellaneousRates.findIndex(
                (entity: any) => entity.projectLaborRate == element._id,
              );
              if (optionIndex !== -1) {
                item.rates.miscellaneousRates[optionIndex].isDeleted = false;
              }
              if (!condition || (condition && optionIndex === -1)) {
                let count = 0;
                let cost = element.cost;
                let totalCost = cost * count;
                const projectLaborRate = element._id;
                let _id = crypto.randomBytes(12).toString('hex');
                let newObj = {
                  _id,
                  projectLaborRate: projectLaborRate,
                  count,
                  item: element.item,
                  cost,
                  project: element.project,
                  defaultRate: element.defaultRate,
                  option: item.optionInfo._id,
                  totalCost,
                };
                misc.push(newObj);
                reduxObj.push(newObj);
              }
            }
            item.rates.miscellaneousRates.push(...reduxObj);
          }

          break;
      }
    }
    const payload = [
      {
        category: 'paint',
        rate: paint,
      },
      {
        category: 'carpentry',
        rate: carpentry,
      },
      {
        category: 'equipment',
        rate: equipment,
      },
      {
        category: 'travel',
        rate: travel,
      },
      {
        category: 'miscellaneous',
        rate: misc,
      },
    ];
    let modifiedPaint = [];
    let modifiedCarpentry = [];
    let modifiedEquipment = [];
    let modifiedMiscellaneous = [];
    let modifiedTravel = [];

    for (const modifiedSurface of surfaces.modified) {
      switch (modifiedSurface.category) {
        case 'paint':
          modifiedSurface.rate.forEach((item: any) => {
            let index = project.projectRates.paintRates.findIndex(
              (e: any) => e._id === item._id,
            );
            project.projectRates.paintRates[index].isDeleted = false;
          });
          for (const item of project.options) {
            let reduxObj = [];
            for (const element of modifiedSurface.rate) {
              let condition = item.rates.paintRates.find(
                (entity: any) => entity.projectLaborRate == element._id,
              );
              if (condition) {
                let optionIndex = item.rates.paintRates.findIndex(
                  (entity: any) => entity.projectLaborRate == element._id,
                );
                item.rates.paintRates[optionIndex].isDeleted = false;
              } else {
                let count = 0;
                let coats = 1;
                let color = 'TBD';
                let prepHours = count / element.prepLaborRate;
                let primeHours = element.primeHours ? element.primeHours : 0;
                let paintHours = (count * coats) / element.paintLaborRate;
                let primerGallons = element.primerGallons
                  ? element.primerGallons
                  : 0;
                let primeSelect = element.primeSelect
                  ? element.primeSelect
                  : 'spot';
                let paintGallons = (count * coats) / element.paintSpreadRate;
                const projectLaborRate = element._id;
                let _id = crypto.randomBytes(12).toString('hex');
                let newObj = {
                  ...element,
                  _id,
                  option: item.optionInfo._id,
                  projectLaborRate: projectLaborRate,
                  count,
                  coats,
                  color,
                  prepHours,
                  primeHours,
                  paintHours,
                  primerGallons,
                  primeSelect,
                  paintGallons,
                  totalPaintHours: prepHours + primeHours + paintHours,
                  paintMaterialCost:
                    element.paintMaterial?.priceAfterTax * paintGallons +
                    element.primerMaterial?.priceAfterTax * primerGallons,
                };
                modifiedPaint.push(newObj);
                reduxObj.push(newObj);
              }
            }
            item.rates.paintRates.push(...reduxObj);
          }
          break;

        case 'carpentry':
          modifiedSurface.rate.forEach((item: any) => {
            let index = project.projectRates.carpentryRates.findIndex(
              (e: any) => e._id === item._id,
            );
            project.projectRates.carpentryRates[index].isDeleted = false;
          });
          for (const item of project.options) {
            let reduxObj = [];
            for (const element of modifiedSurface.rate) {
              let condition = item.rates.carpentryRates.find(
                (entity: any) => entity.projectLaborRate == element._id,
              );
              if (condition) {
                let optionIndex = item.rates.carpentryRates.findIndex(
                  (entity: any) => entity.projectLaborRate == element._id,
                );
                item.rates.carpentryRates[optionIndex].isDeleted = false;
              } else {
                let carpentryLaborRateConst = adminDefaults.wageDefaults.find(
                  (item: any) => {
                    return item.title === 'Carpentry Wage Rate';
                  },
                );
                let count = 0;
                let carpentryMaterial = count * element.materialRate;
                let carpentryHours =
                  (1.08 * count * element.laborRate) /
                  carpentryLaborRateConst.wageValue;
                const projectLaborRate = element._id;
                let _id = crypto.randomBytes(12).toString('hex');
                let newObj = {
                  ...element,
                  _id,
                  option: item.optionInfo._id,
                  projectLaborRate: projectLaborRate,
                  count,
                  carpentryMaterial,
                  carpentryHours,
                };
                modifiedCarpentry.push(newObj);
                reduxObj.push(newObj);
              }
            }
            item.rates.carpentryRates.push(...reduxObj);
          }
          break;

        case 'equipment':
          modifiedSurface.rate.forEach((item: any) => {
            let index = project.projectRates.equipmentRates.findIndex(
              (e: any) => e._id === item._id,
            );
            project.projectRates.equipmentRates[index].isDeleted = false;
          });
          for (const item of project.options) {
            let reduxObj = [];
            for (const element of modifiedSurface.rate) {
              let condition = item.rates.equipmentRates.find(
                (entity: any) => entity.projectLaborRate == element._id,
              );
              if (condition) {
                let optionIndex = item.rates.equipmentRates.findIndex(
                  (entity: any) => entity.projectLaborRate == element._id,
                );
                item.rates.equipmentRates[optionIndex].isDeleted = false;
              } else {
                let count = 0;
                let periods = 1;
                let item = element.item;
                let tax = element.tax;
                let project = element.project;
                let defaultRate = element.defaultRate;
                let type = 'daily';
                let cost = element.dailyCost;
                let option = item.optionInfo._id;
                let totalCost =
                  count *
                  periods *
                  (cost + element.surcharges + element.puAndDelivery) *
                  (1 + tax / 100);
                const projectLaborRate = element._id;
                let _id = crypto.randomBytes(12).toString('hex');
                let newObj = {
                  _id,
                  projectLaborRate: projectLaborRate,
                  count,
                  periods,
                  item,
                  surcharges: element.surcharges,
                  puAndDelivery: element.puAndDelivery,
                  tax,
                  project,
                  defaultRate,
                  type,
                  cost,
                  option,
                  totalCost,
                };
                modifiedEquipment.push(newObj);
                reduxObj.push(newObj);
              }
            }
            item.rates.equipmentRates.push(...reduxObj);
          }
          break;

        case 'miscellaneous':
          modifiedSurface.rate.forEach((item: any) => {
            let index = project.projectRates.miscellaneousRates.findIndex(
              (e: any) => e._id === item._id,
            );
            project.projectRates.miscellaneousRates[index].isDeleted = false;
          });
          for (const item of project.options) {
            let reduxObj = [];
            for (const element of modifiedSurface.rate) {
              let condition = item.rates.miscellaneousRates.find(
                (entity: any) => entity.projectLaborRate == element._id,
              );
              if (condition) {
                let optionIndex = item.rates.miscellaneousRates.findIndex(
                  (entity: any) => entity.projectLaborRate == element._id,
                );
                item.rates.miscellaneousRates[optionIndex].isDeleted = false;
              } else {
                let count = 0;
                let cost = element.cost;
                let totalCost = cost * count;
                const projectLaborRate = element._id;
                let _id = crypto.randomBytes(12).toString('hex');
                let newObj = {
                  _id,
                  projectLaborRate: projectLaborRate,
                  count,
                  item: element.item,
                  cost,
                  project: element.project,
                  defaultRate: element.defaultRate,
                  option: item.optionInfo._id,
                  totalCost,
                };
                modifiedMiscellaneous.push(newObj);
                reduxObj.push(newObj);
              }
            }
            item.rates.miscellaneousRates.push(...reduxObj);
          }
          break;
        case 'travel':
          modifiedSurface.rate.forEach((item: any) => {
            let index = project.projectRates.travelRates.findIndex(
              (e: any) => e._id === item._id,
            );
            project.projectRates.travelRates[index].isDeleted = false;
          });
          for (const item of project.options) {
            let reduxObj = [];
            for (const element of modifiedSurface.rate) {
              let condition = item.rates.travelRates.find(
                (entity: any) => entity.projectLaborRate == element._id,
              );
              if (condition) {
                let optionIndex = item.rates.travelRates.findIndex(
                  (entity: any) => entity.projectLaborRate == element._id,
                );
                item.rates.travelRates[optionIndex].isDeleted = false;
              } else {
                let rfmCount = 0;
                let dnCount = 0;
                let cost = element.cost;
                let totalCost = cost * rfmCount * dnCount;
                const projectLaborRate = element._id;
                let _id = crypto.randomBytes(12).toString('hex');
                let newObj = {
                  _id,
                  projectLaborRate: projectLaborRate,
                  rfmCount,
                  dnCount,
                  item: element.item,
                  cost,
                  project: element.project,
                  defaultRate: element.defaultRate,
                  option: item.optionInfo._id,
                  totalCost,
                };
                modifiedTravel.push(newObj);
                reduxObj.push(newObj);
              }
            }
            item.rates.travelRates.push(...reduxObj);
          }
          break;
      }
    }

    let updatedProject = deleteMultipleSurfaces(deletedArray, project);

    dispatch(
      addPlusUpdateModifiedProject(items[currentProject].projectId, {
        surface: surfaces.modified,
        optionRates: {
          paint: modifiedPaint,
          carpentry: modifiedCarpentry,
          equipment: modifiedEquipment,
          misc: modifiedMiscellaneous,
          travel: modifiedTravel,
        },
      }),
    );

    dispatch(addRatesToOptions(payload, updatedProject, adminDefaults));

    toggleModal(false);
  };
  type IdsListType = {
    paintRatesId: string[];
    carpentryRatesId: string[];
    equipmentRatesId: string[];
    miscRatesId: string[];
    travelRatesId: string[];
  };

  const deleteMultipleSurfaces = (data: any, project: any) => {
    let idsList: IdsListType = {
      paintRatesId: [],
      carpentryRatesId: [],
      equipmentRatesId: [],
      miscRatesId: [],
      travelRatesId: [],
    };
    for (const surface of data.defaults) {
      switch (surface.category) {
        case 'paint':
          surface.ratesId.forEach((key: any) => {
            const index = project.projectRates.paintRates.findIndex(
              (item: any) =>
                !item.isDeleted &&
                item.defaultRate &&
                item.defaultRate?._id === key,
            );
            if (index !== -1) {
              project.projectRates.paintRates[index].isDeleted = true;
              let id = project.projectRates.paintRates[index]._id;
              setTableValues({ paintRates: project.projectRates.paintRates });
              for (const singleOption of project.options) {
                let optionIndex = singleOption.rates.paintRates.findIndex(
                  (item: any) => item.projectLaborRate === id,
                );
                if (optionIndex !== -1) {
                  singleOption.rates.paintRates[optionIndex].isDeleted = true;
                }
              }
              idsList.paintRatesId.push(id);
              // dispatch(removeServiceFromProject(id, "paint", project, adminDefaults));
            }
          });
          break;

        case 'carpentry':
          surface.ratesId.forEach((key: any) => {
            const index = project.projectRates.carpentryRates.findIndex(
              (item: any) =>
                !item.isDeleted &&
                item.defaultRate &&
                item.defaultRate?._id === key,
            );
            if (index !== -1) {
              project.projectRates.carpentryRates[index].isDeleted = true;
              let id = project.projectRates.carpentryRates[index]._id;
              setTableValues({
                carpentryRates: project.projectRates.carpentryRates,
              });
              for (const singleOption of project.options) {
                let optionIndex = singleOption.rates.carpentryRates.findIndex(
                  (item: any) => item.projectLaborRate === id,
                );
                if (optionIndex !== -1) {
                  singleOption.rates.carpentryRates[optionIndex].isDeleted =
                    true;
                }
              }
              idsList.carpentryRatesId.push(id);
              // dispatch(removeServiceFromProject(id, "paint", project, adminDefaults));
            }
          });
          break;

        case 'equipment':
          surface.ratesId.forEach((key: any) => {
            const index = project.projectRates.equipmentRates.findIndex(
              (item: any) =>
                !item.isDeleted &&
                item.defaultRate &&
                item.defaultRate?._id === key,
            );
            if (index !== -1) {
              project.projectRates.equipmentRates[index].isDeleted = true;
              let id = project.projectRates.equipmentRates[index]._id;
              setTableValues({
                equipmentRates: project.projectRates.equipmentRates,
              });
              for (const singleOption of project.options) {
                let optionIndex = singleOption.rates.equipmentRates.findIndex(
                  (item: any) => item.projectLaborRate === id,
                );
                if (optionIndex !== -1) {
                  singleOption.rates.equipmentRates[optionIndex].isDeleted =
                    true;
                }
              }
              idsList.equipmentRatesId.push(id);
              // dispatch(removeServiceFromProject(id, "paint", project, adminDefaults));
            }
          });
          break;

        case 'miscellaneous':
          surface.ratesId.forEach((key: any) => {
            const index = project.projectRates.miscellaneousRates.findIndex(
              (item: any) =>
                !item.isDeleted &&
                item.defaultRate &&
                item.defaultRate?._id === key,
            );
            if (index !== -1) {
              project.projectRates.miscellaneousRates[index].isDeleted = true;
              let id = project.projectRates.miscellaneousRates[index]._id;
              setTableValues({
                miscellaneousRates: project.projectRates.miscellaneousRates,
              });
              for (const singleOption of project.options) {
                let optionIndex =
                  singleOption.rates.miscellaneousRates.findIndex(
                    (item: any) => item.projectLaborRate === id,
                  );
                if (optionIndex !== -1) {
                  singleOption.rates.miscellaneousRates[optionIndex].isDeleted =
                    true;
                }
              }
              idsList.miscRatesId.push(id);
              // dispatch(removeServiceFromProject(id, "paint", project, adminDefaults));
            }
          });
          break;

        case 'travel':
          surface.ratesId.forEach((key: any) => {
            const index = project.projectRates.travelRates.findIndex(
              (item: any) =>
                !item.isDeleted &&
                item.defaultRate &&
                item.defaultRate?._id === key,
            );
            if (index !== -1) {
              project.projectRates.travelRates[index].isDeleted = true;
              let id = project.projectRates.travelRates[index]._id;
              setTableValues({
                travelRates: project.projectRates.travelRates,
              });
              for (const singleOption of project.options) {
                let optionIndex = singleOption.rates.travelRates.findIndex(
                  (item: any) => item.projectLaborRate === id,
                );
                if (optionIndex !== -1) {
                  singleOption.rates.travelRates[optionIndex].isDeleted = true;
                }
              }
              idsList.travelRatesId.push(id);
              // dispatch(removeServiceFromProject(id, "paint", project, adminDefaults));
            }
          });
          break;
      }
    }

    for (const surface of data.modified) {
      switch (surface.category) {
        case 'paint':
          surface.ratesId?.forEach((key: any) => {
            const index = project.projectRates.paintRates.findIndex(
              (item: any) => !item.isDeleted && item._id === key,
            );
            if (index !== -1) {
              project.projectRates.paintRates[index].isDeleted = true;
              let id = project.projectRates.paintRates[index]._id;
              setTableValues({ paintRates: project.projectRates.paintRates });
              for (const singleOption of project.options) {
                let optionIndex = singleOption.rates.paintRates.findIndex(
                  (item: any) => item.projectLaborRate === id,
                );
                if (optionIndex !== -1) {
                  singleOption.rates.paintRates[optionIndex].isDeleted = true;
                }
              }
              idsList.paintRatesId.push(id);
              // dispatch(
              //   removeServiceFromProject(id, "paint", project, adminDefaults)
              // );
            }
          });
          break;
        case 'carpentry':
          surface.ratesId?.forEach((key: any) => {
            const index = project.projectRates.carpentryRates.findIndex(
              (item: any) => !item.isDeleted && item._id === key,
            );
            if (index !== -1) {
              project.projectRates.carpentryRates[index].isDeleted = true;
              let id = project.projectRates.carpentryRates[index]._id;
              setTableValues({
                carpentryRates: project.projectRates.carpentryRates,
              });
              for (const singleOption of project.options) {
                let optionIndex = singleOption.rates.carpentryRates.findIndex(
                  (item: any) => item.projectLaborRate === id,
                );
                if (optionIndex !== -1) {
                  singleOption.rates.carpentryRates[optionIndex].isDeleted =
                    true;
                }
              }
              idsList.carpentryRatesId.push(id);
              // dispatch(
              //   removeServiceFromProject(id, "paint", project, adminDefaults)
              // );
            }
          });
          break;

        case 'equipment':
          surface.ratesId?.forEach((key: any) => {
            const index = project.projectRates.equipmentRates.findIndex(
              (item: any) => !item.isDeleted && item._id === key,
            );
            if (index !== -1) {
              project.projectRates.equipmentRates[index].isDeleted = true;
              let id = project.projectRates.equipmentRates[index]._id;
              setTableValues({
                equipmentRates: project.projectRates.equipmentRates,
              });
              for (const singleOption of project.options) {
                let optionIndex = singleOption.rates.equipmentRates.findIndex(
                  (item: any) => item.projectLaborRate === id,
                );
                if (optionIndex !== -1) {
                  singleOption.rates.equipmentRates[optionIndex].isDeleted =
                    true;
                }
              }
              idsList.equipmentRatesId.push(id);
              // dispatch(
              //   removeServiceFromProject(id, "paint", project, adminDefaults)
              // );
            }
          });
          break;

        case 'miscellaneous':
          surface.ratesId?.forEach((key: any) => {
            const index = project.projectRates.miscellaneousRates.findIndex(
              (item: any) => !item.isDeleted && item._id === key,
            );
            if (index !== -1) {
              project.projectRates.miscellaneousRates[index].isDeleted = true;
              let id = project.projectRates.miscellaneousRates[index]._id;
              setTableValues({
                miscellaneousRates: project.projectRates.miscellaneousRates,
              });
              for (const singleOption of project.options) {
                let optionIndex =
                  singleOption.rates.miscellaneousRates.findIndex(
                    (item: any) => item.projectLaborRate === id,
                  );
                if (optionIndex !== -1) {
                  singleOption.rates.miscellaneousRates[optionIndex].isDeleted =
                    true;
                }
              }
              idsList.miscRatesId.push(id);
              // dispatch(
              //   removeServiceFromProject(id, "paint", project, adminDefaults)
              // );
            }
          });
          break;

        case 'travel':
          surface.ratesId?.forEach((key: any) => {
            const index = project.projectRates.travelRates.findIndex(
              (item: any) => !item.isDeleted && item._id === key,
            );
            if (index !== -1) {
              project.projectRates.travelRates[index].isDeleted = true;
              let id = project.projectRates.travelRates[index]._id;
              setTableValues({ travelRates: project.projectRates.travelRates });
              for (const singleOption of project.options) {
                let optionIndex = singleOption.rates.travelRates.findIndex(
                  (item: any) => item.projectLaborRate === id,
                );
                if (optionIndex !== -1) {
                  singleOption.rates.travelRates[optionIndex].isDeleted = true;
                }
              }
              idsList.travelRatesId.push(id);
              // dispatch(
              //   removeServiceFromProject(id, "paint", project, adminDefaults)
              // );
            }
          });
          break;
      }
    }
    dispatch(removeRatesFromProjectAndOptions(idsList, project.projectId));

    return project;
  };

  const setTableValues = (data: any) => {
    setTableData({ ...tableData, ...data });
  };

  const handleSurfaceDelete = (
    key: string,
    type: any,
    modifiedKey: boolean,
  ) => {
    if (type === 'paint') {
      let project: any = _.cloneDeep(items[currentProject]);
      let index;
      if (modifiedKey) {
        index = project.projectRates.paintRates.findIndex(
          (item: any) => !item.isDeleted && item._id === key,
        );
      } else {
        index = project.projectRates.paintRates.findIndex(
          (item: any) =>
            !item.isDeleted &&
            item.defaultRate &&
            item.defaultRate?._id === key,
        );
      }
      if (index !== -1) {
        project.projectRates.paintRates[index].isDeleted = true;
        let id = project.projectRates.paintRates[index]._id;
        setTableValues({ paintRates: project.projectRates.paintRates });
        for (const singleOption of project.options) {
          let optionIndex = singleOption.rates.paintRates.findIndex(
            (item: any) => item.projectLaborRate === id,
          );
          singleOption.rates.paintRates[optionIndex].isDeleted = true;
          // singleOption.rates.paintRates.splice(index, 1);
        }
        dispatch(removeServiceFromProject(id, 'paint', project, adminDefaults));
      }
    }
  };
  return (
    <div>
      <div className="labor-container">
        {/* <AddSurface
          surface="paint"
          addSurface={addSurfaceToProject}
          rates={tableData}
          showModalForEstimate={showModalForEstimate}
          toggleModal={toggleModal}
        /> */}
        <AddDefaultModifiedSurface
          surface="paint"
          addSurface={addSurfaceToProject}
          rates={tableData}
          showModalForEstimate={showModalForEstimate}
          toggleModal={toggleModal}
          handleDelete={handleSurfaceDelete}
        />
        <Drawer
          placement="right"
          size="large"
          onClose={onClose}
          visible={visible}
        >
          <Notes />
        </Drawer>

        <div className="labor-header-container">
          <Row justify="space-between">
            <Col>
              <h1 className="labor-header-title ">Labor Rates</h1>
            </Col>
            <Row gutter={10}>
              <Col>
                <Button
                  type="primary"
                  className="add-surface"
                  style={{ width: 160 }}
                  onClick={() =>
                    showExpandRows
                      ? setShowExpandRows(false)
                      : setShowExpandRows(true)
                  }
                >
                  Expand All Rows
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className="add-surface"
                  onClick={showDrawer}
                  style={{ width: 120 }}
                >
                  Add Notes
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  className="add-surface"
                  onClick={handleAddSurface}
                >
                  Add Surface/Item
                </Button>
              </Col>
            </Row>
          </Row>
        </div>
        <hr className="labor-divider " />
        <div style={{ paddingBottom: 60 }}>
          {tableData?.paintRates?.length > 0 && (
            <div className="surface-table" style={{ marginTop: 45 }}>
              <div
                className="labor-rates-container"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <h1 className="labor-rates-title">Selected paint surfaces</h1>
                <Tooltip title="Bulk Edit Paint Material and Primer Material">
                  <Button
                    className="add-surface"
                    type="primary"
                    style={{
                      marginRight: '20px',
                      width: 140,
                    }}
                    icon={<EditFilled />}
                    onClick={() => setIsBulkEditModalOpen(true)}
                  >
                    Bulk Edit
                  </Button>
                </Tooltip>
              </div>
              <BulkEditPaintLaborRateModal
                isBulkEditModalOpen={isBulkEditModalOpen}
                setIsBulkEditModalOpen={setIsBulkEditModalOpen}
                projectPaintSurfaces={tableData?.paintRates}
              />
              <PaintSurfaces
                setTableValues={setTableValues}
                rates={tableData?.paintRates}
                showExpandRows={showExpandRows}
              />
            </div>
          )}

          {tableData?.carpentryRates?.length > 0 && (
            <div className="surface-table" style={{ marginTop: 45 }}>
              <div className="labor-rates-container">
                <h1 className="labor-rates-title">
                  Selected Carpentry surfaces
                </h1>
              </div>
              <CarpentrySurfaces
                setTableValues={setTableValues}
                rates={tableData?.carpentryRates}
                showExpandRows={showExpandRows}
              />
            </div>
          )}

          {tableData?.equipmentRates?.length > 0 && (
            <div className="surface-table" style={{ marginTop: 45 }}>
              <div className="labor-rates-container">
                <h1 className="labor-rates-title">Selected Equipment</h1>
              </div>
              <EquipmentSurfaces
                setTableValues={setTableValues}
                rates={tableData?.equipmentRates}
                showExpandRows={showExpandRows}
              />
            </div>
          )}

          {tableData?.miscellaneousRates?.length > 0 && (
            <div className="surface-table" style={{ marginTop: 45 }}>
              <div className="labor-rates-container">
                <h1 className="labor-rates-title">Selected Misc </h1>
              </div>
              <MiscSurfaces
                setTableValues={setTableValues}
                rates={tableData?.miscellaneousRates}
                showExpandRows={showExpandRows}
              />
            </div>
          )}

          {tableData?.travelRates?.length > 0 && (
            <div className="surface-table" style={{ marginTop: 45 }}>
              <div className="labor-rates-container">
                <h1 className="labor-rates-title">Selected Travel </h1>
              </div>
              <TravelSurfaces
                setTableValues={setTableValues}
                rates={tableData?.travelRates}
                showExpandRows={showExpandRows}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LaborRates;
