import { Form, message, Popconfirm, Table, Typography } from 'antd';
import axios from 'axios';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import React, { useEffect, useState } from 'react';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { API_BASE } from '../../../constant';
import api from '../../../utils/api';
import { getAuthUser } from '../../../utils/authToken';
import ActionDropDown from './ActionDropDown';
import CarpentryEditable from './CarpentryEditable';
import {
  removeServiceFromProject,
  bulkUpdateRates,
  addServiceToProject,
  addRatesToOptions,
  projectRateDeleteAndOptionRatesUpdate,
} from '../../../redux/project/action';
import _ from 'lodash';
import {
  deepEqualCheck,
  deepEqualCheckForClone,
} from '../../../utils/objectCheckers';
import { compareObjects } from '../../../utils/helpers';
var crypto = require('crypto');

type Props = {
  rates: any;
  setTableValues: any;
  showExpandRows: any;
};
let travelClone: any;
const TravelSurfaces = (props: Props) => {
  const dispatch = useDispatch<any>();
  const [data, setData] = useState(props?.rates);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const { currentProject, items, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );
  useEffect(() => {
    const project = _.cloneDeep(items[currentProject]); // Deep copy to avoid mutations
    const hasOptionTravelRates = project.options.some(
      (option: any) => option.rates?.travelRates?.length > 0,
    );
    if (hasOptionTravelRates) {
      const travelLaborRates = [...props.rates];
      let laborRatesNotFoundInOption: string[] = [];

      // Collect all travel rate IDs from all options
      const allTravelRateIds = project.options.flatMap((option: any) =>
        option.rates.travelRates.map((travelRate: any) => {
          if (!travelRate.isDeleted) return travelRate.projectLaborRate;
        }),
      );

      // Filter rates that are not found in any of the travelRates
      laborRatesNotFoundInOption = travelLaborRates
        .filter((rate) => !allTravelRateIds.includes(rate._id))
        .map((rate) => rate._id);

      setData(
        props.rates.filter(
          (item: any) => !laborRatesNotFoundInOption.includes(item._id),
        ),
      );
    } else {
      setData(props.rates);
    }
  }, [props.rates]);
  const isEditing = (record: any) => record._id === editingKey;

  const edit = (record: any) => {
    travelClone = { ...record };
    form.setFieldsValue({
      cost: '',
      ...record,
    });
    setEditingKey(record._id);
  };
  const cancel = () => {
    setEditingKey('');
  };

  const save = async (id: React.Key) => {
    try {
      const row = (await form.validateFields()) as any;

      const newData = [...data];

      const index = newData.findIndex((item) => id === item._id);

      if (index > -1) {
        const item = newData[index];
        const testData: any = { ...item, ...row };
        let updatedObject: any = {};
        for (const key in testData) {
          if (testData[key] !== travelClone[key]) {
            updatedObject[key] = testData[key];
          }
        }
        const body = {
          category: 'travel',
          rate: { ...updatedObject, modified: true },
          project: items[currentProject].projectId,
        };

        let project: any = _.cloneDeep(items[currentProject]);
        let projectTravelIndex = project.projectRates.travelRates.findIndex(
          (element: any) => element._id === item._id,
        );
        for (var key in updatedObject) {
          if (
            typeof project.projectRates.travelRates[projectTravelIndex][key] ===
            'number'
          ) {
            project.projectRates.travelRates[projectTravelIndex][key] =
              +updatedObject[key];
          } else {
            project.projectRates.travelRates[projectTravelIndex][key] =
              updatedObject[key];
          }
        }
        project.projectRates.travelRates[projectTravelIndex].modified = true;
        //some calculations will be required
        for (const singleOption of project.options) {
          let optionTravelIndex = singleOption.rates.travelRates.findIndex(
            (element: any) => element.projectLaborRate === item._id,
          );
          for (var key in updatedObject) {
            if (optionTravelIndex > -1) {
              if (
                typeof singleOption.rates.travelRates[projectTravelIndex][
                  key
                ] === 'number'
              ) {
                singleOption.rates.travelRates[optionTravelIndex][key] =
                  +updatedObject[key];
              } else {
                singleOption.rates.travelRates[optionTravelIndex][key] =
                  updatedObject[key];
              }
            }
          }
          if (optionTravelIndex > -1) {
            singleOption.rates.travelRates[optionTravelIndex].modified = true;
          }
        }
        dispatch(bulkUpdateRates(body, item._id, project, adminDefaults));

        // dispatch(setActiveProject(project));

        // api
        //   .patch(`rates/project/${item._id}`, body)
        //   .then(() => {})
        //   .catch((err) => console.error(err));
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleWhenEditedRateAlreadyExits = (
    rateThatExits: any,
    editedRate: any,
  ) => {
    console.log(rateThatExits, editedRate);
    let reduxObj = [];
    let project: any = _.cloneDeep(items[currentProject]);
    let optionRatesToBeDeletedIdArray = [];
    for (const singleOption of project.options) {
      const foundRateIndex = singleOption.rates.travelRates.findIndex(
        (item: any) => item.projectLaborRate === rateThatExits._id,
      );
      const foundEditedRateIndex = singleOption.rates.travelRates.findIndex(
        (item: any) => item.projectLaborRate === editedRate._id,
      );
      if (foundEditedRateIndex !== -1) {
        console.log(singleOption.rates.travelRates[foundEditedRateIndex]);
        singleOption.rates.travelRates[foundEditedRateIndex].isDeleted = true;
        optionRatesToBeDeletedIdArray.push(
          singleOption.rates.travelRates[foundEditedRateIndex]._id,
        );
      }
      if (foundRateIndex === -1) {
        let rfmCount = 0;
        let dnCount = 0;
        let cost = rateThatExits.cost;
        let totalCost = cost * rfmCount * dnCount;
        const projectLaborRate = rateThatExits._id;
        let _id = crypto.randomBytes(12).toString('hex');
        let newObj = {
          _id,
          projectLaborRate: projectLaborRate,
          rfmCount,
          dnCount,
          item: rateThatExits.item,
          cost,
          project: rateThatExits.project,
          defaultRate: rateThatExits.defaultRate,
          option: singleOption.optionInfo._id,
          totalCost,
        };
        reduxObj.push(newObj);
        singleOption.rates.travelRates.push(...reduxObj);
      }
    }
    const editedRateIndexInProject = project.projectRates.travelRates.findIndex(
      (item: any) => item._id === editedRate._id,
    );
    console.log(editedRateIndexInProject);
    project.projectRates.travelRates[editedRateIndexInProject].isDeleted = true;

    // project.projectRates.carpentryRates.splice(editedRateIndexInProject, 1);
    const body = {
      category: 'travel',
      projectRateToBeDeletedId: editedRate._id,
      optionRate: reduxObj,
      optionRatesToBeDeletedIdArray,
      project: project.projectId,
    };
    console.log(body, project);
    dispatch(
      projectRateDeleteAndOptionRatesUpdate(
        body,
        project.projectId,
        project,
        adminDefaults,
      ),
    );
  };

  const newOnSave = async (id: string) => {
    const row = (await form.validateFields()) as any;
    const newData = [...data];
    const index = newData.findIndex((item) => id === item._id);
    if (index > -1) {
      const item = newData[index];
      if (compareObjects(row, item)) {
        setEditingKey('');
        return;
      }
      console.log(item);
      let updatedObject: any = { ...item, ...row };
      updatedObject.modified = true;
      console.log(updatedObject);
      const notDeletedRates = props.rates.filter(
        (item: any) => item.isDeleted === false,
      );
      console.log(notDeletedRates, props.rates);
      let rateAlreadyExists: any;
      if (updatedObject.isCloned) {
        const isCloned = notDeletedRates.filter(
          (item: any) => item.isCloned === true,
        );
        console.log(isCloned);
        rateAlreadyExists = deepEqualCheckForClone(updatedObject, isCloned);
        console.log(rateAlreadyExists);
      } else {
        const isNotCloned = notDeletedRates.filter(
          (item: any) => item.isCloned === false,
        );
        console.log(isNotCloned);
        const temp = isNotCloned.filter(
          (item: any) => item.defaultRate._id === updatedObject.defaultRate._id,
        );
        console.log(temp);
        rateAlreadyExists = deepEqualCheck(updatedObject, temp);
        console.log(rateAlreadyExists);
      }

      if (rateAlreadyExists.result) {
        console.log('all things Done');
        handleWhenEditedRateAlreadyExits(rateAlreadyExists.rate, updatedObject);
        setEditingKey('');
        // props.toggleEditModal(false);
      } else {
        save(id);
        // props.toggleEditModal(false);
      }
    }
  };

  const handleTravelClone = (
    cloneData: any,
    index: number,
    surfaceName: string,
  ) => {
    const result = props.rates.some((obj: any) => {
      let name: string = obj.item;
      if (name.replaceAll(' ', '') === surfaceName[index].replaceAll(' ', '')) {
        return true;
      }
    });
    if (result === true) {
      message.error('Name already taken');
    } else {
      const newRates = {
        ...cloneData,
        item: surfaceName[index],
        project: items[currentProject].projectId,
        _id: crypto.randomBytes(12).toString('hex'),
        isCloned: true,
      };
      // delete newRates._id;
      // delete newRates.defaultRate;

      const notDeletedRates = props.rates.filter(
        (item: any) => item.isDeleted === false,
      );
      const isCloned = notDeletedRates.filter(
        (item: any) => item.isCloned === true,
      );
      console.log(isCloned);
      const rateAlreadyExists = deepEqualCheckForClone(newRates, isCloned);
      console.log(rateAlreadyExists);
      if (rateAlreadyExists) {
        message.error('Rate Already Exists');
      } else {
        const body = [
          {
            category: 'travel',
            rate: [newRates],
          },
        ];
        const newData = [...data];
        newData.push(body[0].rate[0]);
        setData(newData);

        let project = _.cloneDeep(items[currentProject]);
        dispatch(addServiceToProject(items[currentProject].projectId, body));

        project.projectRates.travelRates = newData;
        let tempTravelNewRates = [];
        for (const item of project.options) {
          let newRate = [];
          for (const element of body[0].rate) {
            let rfmCount = 0;
            let dnCount = 0;
            let cost = element.cost;
            let totalCost = cost * rfmCount * dnCount;
            const projectLaborRateId = element._id;
            let _id = crypto.randomBytes(12).toString('hex');
            newRate.push({
              _id,
              projectLaborRate: projectLaborRateId,
              rfmCount,
              dnCount,
              item: element.item,
              cost,
              project: element.project,
              option: item.optionInfo._id,
              totalCost,
              defaultRate: element.defaultRate,
            });
          }

          item.rates.travelRates.push(...newRate);
          tempTravelNewRates.push(...newRate);
        }
        let travelRequestBody = [
          {
            category: 'travel',
            rate: [...tempTravelNewRates],
          },
        ];
        dispatch(addRatesToOptions(travelRequestBody, project, adminDefaults));
      }
    }
  };

  const handleDelete = (id: string) => {
    let project: any = _.cloneDeep(items[currentProject]);
    let index = project.projectRates.travelRates.findIndex(
      (item: any) => item._id === id,
    );
    project.projectRates.travelRates[index].isDeleted = true;
    project.projectRates.travelRates.splice(index, 1);
    props.setTableValues({ travelRates: project.projectRates.travelRates });
    for (const singleOption of project.options) {
      let index = singleOption.rates.travelRates.findIndex(
        (item: any) => item.projectLaborRate === id,
      );
      if (index !== -1) {
        singleOption.rates.travelRates[index].isDeleted = true;
      }
    }
    dispatch(removeServiceFromProject(id, 'travel', project, adminDefaults));
  };

  const travelColumns = [
    {
      title: 'Item',
      dataIndex: 'item',
      editable: true,
      key: 'item',
      width: '200px',
    },
    {
      title: 'Cost Per',
      dataIndex: 'cost',
      render: (data: any) => {
        return (
          <>
            {data && (
              <>
                {parseFloat(data)?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  style: 'currency',
                  currency: 'USD',
                })}
              </>
            )}
          </>
        );
      },
      key: 'cost',
      editable: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: any, index: number) => {
        const editable = isEditing(record);
        return !editable ? (
          <>
            <ActionDropDown
              handleClone={handleTravelClone}
              handleDelete={handleDelete}
              handleEdit={edit}
              data={record}
              index={index}
              category="travel"
            />
          </>
        ) : (
          <span>
            <Typography.Link
              onClick={() => newOnSave(record._id)}
              style={{ marginRight: 8 }}
            >
              <CheckOutlined />
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>
                <CloseOutlined />
              </a>
            </Popconfirm>
          </span>
        );
      },
      width: '80px',
    },
  ];

  const mergedColumns = travelColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === 'item' ? 'text' : 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const showExpandableRows = () => {
    if (props.showExpandRows) {
      return data.map((row: any) => row._id);
    } else {
      return [];
    }
  };

  const expandedRowContent = (record: any) => {
    console.log(record);
    const options = items[currentProject].options;
    let listOfOptions = [];
    for (const singleOption of options) {
      const isFound = singleOption.rates.travelRates.some(
        (item: any) => item.projectLaborRate === record._id && !item.isDeleted,
      );
      if (isFound) {
        listOfOptions.push(singleOption.optionInfo.title);
      }
    }
    console.log(listOfOptions);
    return (
      <div style={{ width: '79%', margin: 'auto' }}>
        {listOfOptions.length === 0 ? (
          <Typography>No Options Created</Typography>
        ) : (
          <Typography>{listOfOptions.join(', ')}</Typography>
        )}
      </div>
    );
  };

  return (
    <Form form={form} component={false}>
      <Table
        rowKey="_id"
        components={{
          body: {
            cell: CarpentryEditable,
          },
        }}
        rowClassName={(record, index) =>
          index % 2 === 0
            ? 'table_row table-row-light'
            : 'table_row table-row-dark'
        }
        loading={!data ? true : false}
        pagination={false}
        columns={mergedColumns}
        dataSource={data
          .filter((item: any) => !item.isDeleted)
          .sort((a: any, b: any) => a.item.localeCompare(b.item))}
        expandable={{
          expandedRowRender: (record, index) => expandedRowContent(record),
          expandIcon: () => null,
          expandedRowKeys: showExpandableRows(),
        }}
      />
    </Form>
  );
};

export default TravelSurfaces;
