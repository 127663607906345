import { Col, Row } from "antd";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

type Props = {
  jobNumber?: string;
  date?: string;
  companyInfo?: any;
  projectSubtype?: string;
  projectType?: string;
};

const ProposalHeader = (props: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width:800px)" });

  const {
    companyStreetAddressOne,
    companyStreetAddressTwo,
    companyCity,
    companyStateProvince,
    companyPostalCode,
    officePhone,
    companyWebsiteUrl
  } = props?.companyInfo || {};

  // Create an array for address parts
  const addressParts = [
    companyStreetAddressOne,
    companyStreetAddressTwo ? `${companyStreetAddressTwo}` : null,
    companyCity,
    companyStateProvince,
    companyPostalCode,
  ].filter(Boolean);

  const headerWeb = companyWebsiteUrl ? `Website: ${companyWebsiteUrl}` : '';
  const headerTelephone = officePhone ? `Telephone: ${officePhone}` : '';

  let formattedDate = moment(props.date).format("MMMM DD, YYYY");
  return (
    <>
      {!isTabletOrMobile ? (
        <Row>
          <Col span={24}>
            <div className="proposal-header" style={{ paddingBottom: 30 }}>
              <div style={{ backgroundColor: "#FDB913" }}>
                <Row justify="space-between" style={{ flexFlow: "nowrap" }}>
                  <Col lg={7} md={7} className="header-left-section">
                    <div>
                      {/* <CertaproIcon /> <br /> */}
                      <img
                        style={{
                          width: 230,
                          marginLeft: 20,
                          marginTop: 20,
                        }}
                        src={props?.companyInfo?.companyLogo}
                        alt="logo"
                      />
                    </div>
                  </Col>
                  <Col
                    lg={7}
                    md={8}
                    offset={2}
                    style={{ marginTop: 30, marginBottom: 40 }}
                  >
                    <div className="no-spacing">
                      <p>{props?.companyInfo?.companyName}</p>
                      <p style={{ display: "flex", alignItems: "center" }}>
                        Address: {addressParts.length > 0 ? addressParts.join(', ') : "No address available"}
                      </p>
                      <p style={{ marginTop: 0, padding: 0 }}>
                        {headerTelephone}
                      </p>
                      <p style={{ marginTop: 0, padding: 0 }}>
                        {headerWeb}
                      </p>
                    </div>
                  </Col>
                  {/* <Col>
            <div style={{ marginTop: "65px" }}>
              <Row gutter={10} align="top">
                <Col>
                  <ProposalDocIcon />
                </Col>
                <Col>
                  <h1 className="document-title" style={{ paddingTop: 5 }}>
                    PROPOSAL
                  </h1>
                </Col>
              </Row>
            </div>
          </Col> */}
                  <Col
                    lg={7}
                    md={8}
                    style={{
                      marginTop: 30,
                      marginRight: 30,
                    }}
                  >
                    <p style={{ marginTop: 0, marginBottom: 0 }}>
                      Type: {props.projectType} {props.projectSubtype}
                    </p>
                    <p style={{ marginTop: 0, marginBottom: 0 }}>
                      Proposal #: {props.jobNumber}
                    </p>
                    <p style={{ marginTop: 0 }}>Date: {formattedDate}</p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <div
              className="proposal-header"
              style={{
                paddingBottom: 30,
                width: "max-content",
              }}
            >
              <div style={{ backgroundColor: "#FDB913" }}>
                <Row
                  justify="space-between"
                  style={{ flexFlow: "nowrap", width: "800px" }}
                >
                  <Col lg={7} md={7} className="header-left-section">
                    <div>
                      {/* <CertaproIcon /> <br /> */}
                      <img
                        style={{
                          width: 230,
                          marginLeft: 20,
                          marginTop: 20,
                        }}
                        src={props?.companyInfo?.companyLogo}
                        alt="logo"
                      />
                    </div>
                  </Col>
                  <Col
                    lg={7}
                    md={8}
                    offset={2}
                    style={{ marginTop: 30, marginBottom: 40 }}
                  >
                    <div className="no-spacing">
                      <p>{props?.companyInfo?.companyName}</p>
                      <p style={{ marginTop: 0, padding: 0 }}>
                        Address:{" "}
                        {`${props?.companyInfo?.companyStreetAddressOne}, ${props?.companyInfo?.companyStreetAddressTwo
                          ? props?.companyInfo?.companyStreetAddressTwo + ", "
                          : ""
                          }`}
                      </p>
                      <p
                        style={{ marginTop: 0, padding: 0 }}
                      >{`${props?.companyInfo?.companyCity}, ${props?.companyInfo?.companyStateProvince}, ${props?.companyInfo?.companyPostalCode}`}</p>
                      <p style={{ marginTop: 0, padding: 0 }}>
                        Telephone: {props?.companyInfo?.companyTelephone}
                      </p>
                      <p style={{ marginTop: 0, padding: 0 }}>
                        Website: {props?.companyInfo?.companyWebsite}
                      </p>
                    </div>
                  </Col>
                  {/* <Col>
            <div style={{ marginTop: "65px" }}>
              <Row gutter={10} align="top">
                <Col>
                  <ProposalDocIcon />
                </Col>
                <Col>
                  <h1 className="document-title" style={{ paddingTop: 5 }}>
                    PROPOSAL
                  </h1>
                </Col>
              </Row>
            </div>
          </Col> */}
                  <Col
                    lg={7}
                    md={8}
                    style={{
                      marginTop: 30,
                      marginRight: 30,
                    }}
                  >
                    <p style={{ marginTop: 0, marginBottom: 0 }}>
                      Type: {props.projectType} {props.projectSubtype}
                    </p>
                    <p style={{ marginTop: 0, marginBottom: 0 }}>
                      Proposal #: {props.jobNumber}
                    </p>
                    <p style={{ marginTop: 0 }}>Date: {formattedDate}</p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ProposalHeader;
