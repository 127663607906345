import { useEffect, useState } from "react";
import "./ProposalDefaultContainer.less";
import api from "../../utils/api";
import ProposalDefaultCard from "./ProposalDefaultCard";
import ProposalDefaultComponent from "./ProposalDefaultComponent";

function ProposalDefaultContainer() {
  const [showLegalLanguage, setShowLegalLanguage] = useState(false);
  const [showTwoYearWarrenty, setShowTwoYearWarrenty] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [legalLanguageData, setLegalLanguageData] = useState({ title: '', type: '', description: '' });
  const [twoYearWarrentyData, settwoYearWarrentyData] = useState({ title: '', type: '', description: '' });


  useEffect(() => {
    (async () => {
      await fetchData()
    })()
  }, [])

  const fetchData = async () => {
    try {
      // setLoading(true)
      const warrentyResponse: any = await api.get("proposal/proposal-default/warrenty")
      settwoYearWarrentyData(warrentyResponse[0])
      const legalLanguageResponse: any = await api.get("proposal/proposal-default/legalLanguage")
      setLegalLanguageData(legalLanguageResponse[0])
      // setLoading(false)
    } catch (error: any) {
      throw new Error(error)
    }
  }
  const updateTitle = (type: string, newTitle: string) => {
    if (type === "legalLanguage") {
      setLegalLanguageData((prev) => ({ ...prev, title: newTitle }));
    } else if (type === "warrenty") {
      settwoYearWarrentyData((prev) => ({ ...prev, title: newTitle }));
    }
  };

  const showLegalLanguageEdit = () => {
    setShowLegalLanguage(!showLegalLanguage)
  }
  const showTwoYearWarrentyEdit = () => {
    setShowTwoYearWarrenty(!showTwoYearWarrenty)
  }

  return (
    <div>
      <ProposalDefaultComponent
        // title="Legal Language"
        show={showLegalLanguage}
        data={legalLanguageData}
        onShowEdit={showLegalLanguageEdit}
        ProposalDefaultCard={ProposalDefaultCard}
        type="legalLanguage"
        fetchData={fetchData}
        updateTitle={updateTitle}
      />

      <ProposalDefaultComponent
        // title="Limited Two Year Warranty"
        show={showTwoYearWarrenty}
        data={twoYearWarrentyData}
        onShowEdit={showTwoYearWarrentyEdit}
        ProposalDefaultCard={ProposalDefaultCard}
        type="warrenty"
        fetchData={fetchData}
        updateTitle={updateTitle}
      />
    </div>
  );
};
export default ProposalDefaultContainer;
