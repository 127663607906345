export const paintOrderColumns: any = [
  {
    title: "Product",
    dataIndex: "product",
    width: "30%",
    render: (data: string) => {
      return <p style={{ marginTop: 15 }}>{data}</p>;
    },
  },
  {
    title: "Color",
    dataIndex: "color",
    render: (data: string) => {
      return <p>{data}</p>;
    },
  },
  {
    title: "Ordered Gallons",
    align: "center",
    width: "15%",
    render: (data: any) => {
      return <p style={{ marginTop: 15 }}>{data.orderedGallons}</p>;
    },
  },
  {
    title: "Estimated Gallons",
    dataIndex: "paintGallons",
    align: "center",
    width: "15%",
    render: (data: number) => {
      return (
        <p style={{ marginRight: 20, marginTop: 15 }}>{Math.ceil(data)}</p>
      );
    },
  },
];

export const primerOrderColumns: any = [
  {
    title: "Primer",
    dataIndex: "primer",
    width: "70%",
    render: (data: string) => {
      return <p style={{ marginTop: 15 }}>{data}</p>;
    },
  },
  {
    title: "Ordered Gallons",
    align: "center",
    width: "15%",
    render: (data: any) => {
      return <p style={{ marginTop: 15 }}>{data.orderedGallons}</p>;
    },
  },
  {
    title: "Estimated Gallons",
    dataIndex: "primeGallons",
    align: "center",
    width: "15%",
    render: (data: number) => {
      return <p style={{ marginRight: 20, marginTop: 15 }}>{data}</p>;
    },
  },
];
