import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Radio,
  Select,
  Input,
  DatePicker,
  Button,
  message,
  Modal,
} from "antd";
import "./SelectOptionForm.less";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import JobJacketCard from "./JobJacketCard";
import SkillsList from "../skills-card/SkillsList";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  postPrePresentationChecklist,
  sendMail,
  updatePrePresentationChecklist,
  updateProjectStateById,
} from "../../redux/project/action";
import EmailModal from "../../containers/email-template-modal/EmailModal";
import { customCompareFunctionPrepresentationCheckList } from "../../utils/helpers";

var crypto = require("crypto");
const { Option } = Select;
const { TextArea } = Input;

type Props = {};

const SelectOptionForm = (props: Props) => {
  const isEffectRunning = useRef(false);
  const [inputValue, setInputValue] = useState("");
  const [activePrcDescription, setActivePrcDescription] = useState("");
  const [projectStartDate, setProjectStartDate] = useState(null);
  const [jobCompletionDate, setJobCompletionDate] = useState(null);
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [showModal, setShowModal] = useState<any>(false);
  const [reqBody, setReqBody] = useState<any>();
  const [modalContent, setModalContent] = useState<any>("");
  const [data, setData] = useState<any>();
  const [formData, setFormData] = useState<any>();
  const [hide, setHide] = useState(false);
  const dispatch = useDispatch();
  const [checkedSkills, setCheckedSkills] = useState<any>([]);
  const [showEquipment, setShowEquipment] = useState<boolean>(false);
  const [updated, setUpdated] = useState<boolean>(false);
  const [showEmailModal, setShowEmailModal] = useState<any>(false);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailBody, setEmailBody] = useState<string>("");
  const [emailRecipients, setEmailRecipients] = useState<string>("");

  const history = useHistory();
  const { items, currentProject, adminDefaults } = useSelector(
    (state: RootStateOrAny) => state.offlineData,
  );

  useEffect(() => {
    if (items[currentProject]?.prePresentationChecklist) {
      form.setFieldsValue({
        ...items[currentProject]?.prePresentationChecklist,
        crew: items[currentProject]?.prePresentationChecklist?.crew?._id,
        projectStartDate:
          items[currentProject]?.prePresentationChecklist?.projectStartDate &&
          moment(
            items[currentProject]?.prePresentationChecklist.projectStartDate,
          ),
        jobCompleteDate:
          items[currentProject]?.prePresentationChecklist?.jobCompleteDate &&
          moment(
            items[currentProject]?.prePresentationChecklist.jobCompleteDate,
          ),
      });
      setHide(
        items[currentProject]?.prePresentationChecklist?.selectOptionFlag,
      );
      setData(items[currentProject]?.prePresentationChecklist);
      setCheckedSkills(
        items[currentProject]?.prePresentationChecklist?.listOfSkills,
      );
      setShowEquipment(
        items[currentProject]?.prePresentationChecklist?.showEquipment,
      );
    }
  }, []);

  const next = (values: any, redirect?: boolean) => {
    let crewObject;
    if (values?.crew) {
      crewObject = adminDefaults?.contractors.find(
        (item: any) => item._id === values?.crew,
      );
    }

    const body = {
      ...values,
      project: id,
      selectOptionFlag: hide,
      listOfSkills: checkedSkills,
      crew: crewObject,
      showEquipment: values?.showEquipment === "yes" ? true : false,
    };
    setReqBody(body);

    if (data) {
      const payload = {
        ...body,
        _id: data._id,
      };
      let statePrePresentationChecklist = items[currentProject]
        ?.prePresentationChecklist
        ? items[currentProject]?.prePresentationChecklist
        : {};
      let isEqualPrePresentationCheckList =
        customCompareFunctionPrepresentationCheckList(
          statePrePresentationChecklist,
          payload,
        );
      console.log(
        "isEqualPrePresentationCheckList",
        isEqualPrePresentationCheckList,
      );
      if (!isEqualPrePresentationCheckList) {
        dispatch(updatePrePresentationChecklist(payload));
      }

      if (data.crew !== body.crew) {
        if (body.crew === undefined) {
          setModalContent(
            "Do you want to send an email to notify all the registered contractors?",
          );
        } else {
          setModalContent(
            "Do you want to send an email to the assigned contractor with the updates that you just did?",
          );
        }
        setShowModal(true);
        // dispatch(sendMail(body, "pre-presentation-checklist/send-email"));
        // message.success("Email Sent");
      } else {
        if (redirect) {
          history.push(`/proposal-c/${id}`);
        }
      }
    } else {
      const _id = crypto.randomBytes(12).toString("hex");

      if (body.crew === undefined) {
        setModalContent(
          "Do you want to send an email to notify all the registered contractors?",
        );
      } else {
        setModalContent(
          "Do you want to send an email to the assigned contractor with the updates that you just did?",
        );
      }
      setShowModal(true);

      dispatch(postPrePresentationChecklist({ _id: _id, ...body }));
      // message.success("Email Sent");
      // let newBody = {
      //   _id: items[currentProject].projectId,
      //   projectState: 5,
      // };
      // dispatch(updateProjectStateById(newBody));
      // history.push(`/proposal-c/${id}`);
    }
    setUpdated(true);
  };

  useEffect(() => {
    const unListen = history.listen(() => {
      if ((formData || checkedSkills) && !updated && !isEffectRunning.current) {
        isEffectRunning.current = true; // Prevent re-entry
        console.log("FormData ", formData);
        Promise.resolve(next(formData !== undefined ? formData : data)) // Wrap in Promise//
          .finally(() => {
            isEffectRunning.current = false;
          });
      }
    });
    return () => {
      unListen();
    };
  }, [history, formData, checkedSkills]);

  const onChange = (e: any) => {
    if (e.target.value === "postJob") {
      form.setFieldsValue({
        crew: null,
      });
      setHide(true);
    } else {
      setHide(false);
    }
  };

  const handleSkillsChange = (e: any, item: any) => {
    if (e.target.checked) {
      setCheckedSkills([...checkedSkills, item]);
    } else {
      setCheckedSkills(
        checkedSkills.filter((skill: any) => skill._id !== item._id),
      );
    }
  };

  const handleShowEquipmentChange = (value: string) => {
    setShowEquipment(value === "yes");
  };

  const handleSkillsValue = (item: any) => {
    return checkedSkills.some((skill: any) => skill._id === item._id);
  };

  const handleCancel = () => {
    setShowModal(false);
    history.push(`/proposal-c/${id}`);
  };

  const handleOk = () => {
    let image = new Image();
    image.src = adminDefaults.companyInfo?.companyLogo;
    const totalLaborIndex = items[currentProject].pjcc.findIndex(
      (item: any) => item.name === "Total Labor",
    );
    if (reqBody.crew) {
      setEmailRecipients(`${reqBody?.crew?.email}`);
    } else {
      let emailArray = adminDefaults?.contractors?.map((item: any) => {
        return item.email;
      });
      setEmailRecipients(`${emailArray.join("; ")}`);
    }
    setEmailSubject(
      `New Job Available! - ${items[currentProject]?.projectInfo?.jobName}`,
    );
    setEmailBody(
      `New projects have just been released!\nClick this link to see all available jobs: "https://www.app.pro-driven.com/" \n\nNew Project Details: \n\nProject Name: ${
        items[currentProject]?.projectInfo?.jobName
      }\nSegment: ${
        items[currentProject]?.projectInfo?.segment?.segmentName
      }\nSubSegment: ${
        items[currentProject]?.projectInfo?.subSegment
      }\nStart Date: ${moment(reqBody.projectStartDate).format(
        "MMMM DD,YYYY",
      )} \nEnd Date: ${moment(reqBody.jobCompleteDate).format(
        "MMMM DD,YYYY",
      )}\nLabour Price: ${
        items[currentProject]?.pjcc[totalLaborIndex]?.totalAmount
      }\n\nTo view project details or apply for the project <a href = "https://www.app.pro-driven.com/contractor-work-order-paint/${
        reqBody?.project
      }">Click Here</a> \n<a href = "https://www.app.pro-driven.com"><img src="https://app.pro-driven.com/static/media/Pro-Driven.ddb4804b.jpg" alt="ProDriven Logo" width="125" height="35"></a><a href = "https://certapro.com"><img src="https://drive.google.com/uc?export=download&id=1UJIMjA-mDUmwYyMfiFqljx7DdyBwmKoY" alt="Certapro Logo" width="125" height="35"></a>
`,
    );

    setShowModal(false);
    setShowEmailModal(true);
  };

  const handleSendEmail = (body: any) => {
    dispatch(sendMail(body, "pre-presentation-checklist/send-email"));
    message.success("Email Sent");
    setShowEmailModal(false);
    history.push(`/proposal-c/${id}`);
  };

  const handleProjectStartDateChange = (date: any, dateString: any) => {
    setProjectStartDate(dateString);
  };

  const handleJobCompleteionDate = (date: any, dateString: any) => {
    setJobCompletionDate(dateString);
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    console.log("handleValuesChange", { ...data, ...allValues });
    setFormData({ ...data, ...allValues }); // Update local state
  };

  const handleFinish = (values: any) => {
    console.log("handleFinish", values);
    next(values, true); // Explicit save when "Next" is clicked
  };

  return (
    <>
      <div>
        <EmailModal
          showModal={showEmailModal}
          setShowModal={setShowEmailModal}
          emailSubject={emailSubject}
          emailBody={emailBody}
          emailRecipients={emailRecipients}
          sendEmail={handleSendEmail}
        />
        <Modal
          className="req-modal"
          title="Request Quote from paint vendor"
          visible={showModal}
          okText="Yes"
          cancelText="No"
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <h2>{modalContent}</h2>
        </Modal>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          onValuesChange={handleValuesChange}
        >
          <Row gutter={24}>
            <Col lg={15} sm={24}>
              <div className="pre-checklist-root">
                <h1 className="ppc-title">Pre-Presentation Checklist </h1>
                <hr style={{ border: "1px solid #E8E8E8", margin: 0 }} />
              </div>
              <>
                <div className="so-options-container pre-checklist-root">
                  <div className="so-root">
                    <Form.Item
                      label={<h4 className="so-title">Select option</h4>}
                    >
                      <div className="so-radio-group">
                        <Radio.Group
                          value={hide ? "postJob" : "assignDirectly"}
                          onChange={onChange}
                          buttonStyle="solid"
                        >
                          <Radio.Button
                            className="so-toggle-btn"
                            value="assignDirectly"
                          >
                            <p>Assign directly</p>
                          </Radio.Button>
                          <Radio.Button
                            className="so-toggle-btn"
                            value="postJob"
                          >
                            <p> Post job</p>
                          </Radio.Button>
                        </Radio.Group>
                      </div>
                    </Form.Item>
                    <Row gutter={17}>
                      <Col span={12}>
                        <div className="so-mb-20">
                          <Form.Item
                            hasFeedback
                            name="colorInfoInEstimate"
                            label={
                              <h1>
                                Has color information entered in estimates
                              </h1>
                            }
                          >
                            <Select className="so-select" size="large">
                              <Option value="yes">Yes</Option>
                              <Option value="no">No</Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="so-mb-20">
                          <Form.Item
                            hasFeedback
                            name="crew"
                            rules={[
                              {
                                required: !hide,
                                message: "",
                              },
                            ]}
                            label={
                              <h1>Which Crew is being awarded this project?</h1>
                            }
                          >
                            <Select
                              disabled={hide}
                              className="so-select"
                              size="large"
                            >
                              {adminDefaults?.contractors?.map((item: any) => {
                                return (
                                  <Option key={item?._id} value={item._id}>
                                    {item?.contractorCompanyName}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                        </div>

                        <div className="so-mb-20">
                          <Form.Item
                            validateStatus={projectStartDate ? "success" : ""}
                            hasFeedback
                            name="projectStartDate"
                            label={<h1>What is the project start date?</h1>}
                          >
                            <DatePicker
                              placeholder="Select Date"
                              className="so-date-picker"
                              value={projectStartDate}
                              onChange={handleProjectStartDateChange}
                            />
                          </Form.Item>
                        </div>
                        <div className="so-mb-20">
                          <Form.Item
                            hasFeedback
                            validateStatus={jobCompletionDate ? "success" : ""}
                            name="jobCompleteDate"
                            label={
                              <h1>What is the target job completion date?</h1>
                            }
                          >
                            <DatePicker
                              placeholder="Select Date"
                              className="so-date-picker"
                              value={jobCompletionDate}
                              onChange={handleJobCompleteionDate}
                            />
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item
                            hasFeedback
                            name="activePRC"
                            label={
                              <h1>Is there an Active PRC for this project?</h1>
                            }
                          >
                            <Select className="so-select" size="large">
                              <Option value="yes">Yes</Option>
                              <Option value="no">No</Option>
                            </Select>
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="so-mb-20">
                          <Form.Item
                            hasFeedback
                            name="productionTargets"
                            label={
                              <h1>Production targets been updated by PA?</h1>
                            }
                          >
                            <Select className="so-select" size="large">
                              <Option value="yes">Yes</Option>
                              <Option value="no">No</Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="so-mb-20">
                          <Form.Item
                            hasFeedback
                            name="leadBasedPainProject"
                            label={<h1>Is this a lead-based paint project?</h1>}
                          >
                            <Select className="so-select" size="large">
                              <Option value="yes">Yes</Option>
                              <Option value="no">No</Option>
                            </Select>
                          </Form.Item>
                        </div>

                        <div className="so-mb-20">
                          <Form.Item
                            hasFeedback
                            name="startDateFirmOrFlexible"
                            label={<h1>Is start date firm or flexible?</h1>}
                          >
                            <Select className="so-select" size="large">
                              <Option value="firm">Firm</Option>
                              <Option value="flexible">Flexible</Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="so-mb-20">
                          <Form.Item
                            hasFeedback
                            name="completionDateFirmOrFlexible"
                            label={
                              <h1>Is completion date firm or flexible?</h1>
                            }
                          >
                            <Select className="so-select" size="large">
                              <Option value="firm">Firm</Option>
                              <Option value="flexible">Flexible</Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item
                            validateStatus={
                              activePrcDescription ? "success" : ""
                            }
                            hasFeedback
                            name="activePrcDescription"
                            label={<h1>If so what it is?</h1>}
                          >
                            <Input
                              className="so-input"
                              value={activePrcDescription}
                              onChange={(e) =>
                                setActivePrcDescription(e.target.value)
                              }
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="jj-root">
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        validateStatus={inputValue ? "success" : ""}
                        className="paymentTerms--Exception"
                        hasFeedback
                        name="paymentTermsException"
                        label={<h2>Payment Terms Exception</h2>}
                      >
                        <TextArea
                          className="compensations-input"
                          rows={3}
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={15} justify={"space-between"}>
                    <Col span={12}>
                      <Form.Item
                        hasFeedback
                        name="correctionPeriod"
                        label={<h2>Correction Period</h2>}
                      >
                        <Input className="so-input3" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      {" "}
                      <Form.Item
                        hasFeedback
                        name="showEquipment"
                        label={
                          <h2>List equipment estimated on Sub-Contract</h2>
                        }
                        initialValue={false} // Keep it boolean for initial form value
                        valuePropName="checked" // This will sync the boolean value to the Select
                      >
                        <Select
                          className="so-input2"
                          size="large"
                          value={showEquipment ? "yes" : "no"} // Convert boolean to string for Select
                          onChange={handleShowEquipmentChange}
                        >
                          <Option value="yes">Yes</Option>
                          <Option value="no">No</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                {/* <JobJacketCard /> */}
              </>
            </Col>
            <Col className="gutter-row" lg={9} sm={24}>
              <SkillsList
                handleSkillsChange={handleSkillsChange}
                handleSkillsValue={handleSkillsValue}
              />
            </Col>
          </Row>

          <Row justify="center" style={{ marginBottom: "20px" }}>
            <col></col>
            <Col lg={12}>
              <Button htmlType="submit" type="primary" className="save-button">
                Next
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default SelectOptionForm;
