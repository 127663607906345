import React, { useState } from "react";
import { Input, Button, Popover, Row, Col, Tooltip } from "antd";
import "./NotesInput.less";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { handleNotesChange } from "../../redux/notes/action";
import { LinkIcon } from "../../utils/icons";

const { TextArea } = Input;

interface props {
  handleSubmit: (note: string) => void;
}

const NotesInput: React.FC<props> = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState("");
  const [open, setOpen] = useState(false);
  const [urlValue, setUrlValue] = useState("");
  const handleClick = () => {
    handleSubmit(input);
    setInput("");
  };

  const handleChange = (e: any) => {
    setInput(e.target.value);
  };

  const handleCancel = () => {
    setInput("");
  };

  const handleKeyDown = (event: any) => {
    if (event.ctrlKey && event.key === "k") {
      // Prevent the default action
      event.preventDefault();

      // Prompt for a URL
      const url = prompt("Enter a URL:");

      // Add the URL to the text area
      if (url) {
        setInput((prevValue) => `${prevValue}[${url}]`);
      }
    }
  };

  const handleAddLink = () => {
    if (urlValue !== "") setInput((prevValue) => `${prevValue} [${urlValue}] `);
    setOpen(false);
    setUrlValue("");
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleUrlChange = (e: any) => {
    setUrlValue(e.target.value);
  };

  return (
    <div
      style={{
        borderRadius: "5px",
        paddingLeft: "10px",
      }}
    >
      <div className="main-input-container">
        <div>
          <TextArea
            className="inputField"
            placeholder="Add Notes"
            autoSize={{ minRows: 3, maxRows: 3 }}
            bordered={false}
            value={input}
            // onKeyDown={handleKeyDown}
            onChange={handleChange}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "10px",
            }}
          >
            <Popover
              content={
                <>
                  <Row>
                    <Col>
                      <Input value={urlValue} onChange={handleUrlChange} />
                    </Col>
                    <Col>
                      <Button onClick={handleAddLink}>Apply</Button>
                    </Col>
                  </Row>
                </>
              }
              title="Add Link"
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
            >
              <Tooltip placement="left" title={"Add a Link"}>
                <LinkIcon
                  style={{
                    height: 30,
                    width: 28,
                    cursor: "pointer",
                    marginTop: 2,
                    marginRight: 5,
                  }}
                />
              </Tooltip>
            </Popover>
            <Button
              style={{
                color: "#FDB913",
                border: "1px solid #FDB913",
                borderRadius: "5px",
              }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={handleClick}
              style={{
                backgroundColor: "#FDB913",
                color: "#1F1F1F",
                borderRadius: "5px",
                fontSize: "15px",

                fontWeight: "normal",
                margin: "0px 10px 0px 5px",
                border: "1px solid #FDB913",
              }}
              className="postButton"
            >
              Post
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesInput;
